import React 			    from 'react';
import Form                 from 'app/components/form/Form';
import FormValidatorCommon  from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language             from 'app/utils/language/Language';
import Helpers 				from 'app/utils/helpers/Helpers'; 

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.list_of_wealth','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.list_of_wealth','en')}
		  			</div>
		  		</h3>
		        <Form.Radio     
		            label        = 'annual_income' 
		            ref          = { inputs.annual_income.ref }
	  				defaultValue = { props.User.details.annual_income } 
		            className    = 'AppStyles-full_size_input'
					options      =  {[
										{
											value      :'100 - 250 Juta', 
											description:<React.Fragment>
															{Language.get('realaccount.income_100_250','id')}
															<div className='AppStyles-label_secondary'>
																{Language.get('realaccount.income_100_250','en')}
															</div>
														</React.Fragment>
										},{
											value      :'250 - 500 Juta', 
											description:<React.Fragment>
															{Language.get('realaccount.income_250_500','id')}
															<div className='AppStyles-label_secondary'>
																{Language.get('realaccount.income_250_500','en')}
															</div>
														</React.Fragment>
										},{
											value      :'> 500 Juta', 
											description:<React.Fragment>
															{Language.get('realaccount.income_500_plus','id')}
															<div className='AppStyles-label_secondary'>
																{Language.get('realaccount.income_500_plus','en')}
															</div>
														</React.Fragment>
										}
									]} 
					sameValueDescription={true}
	      			onTouched    = {props.onTouched}
	      			required     = {1}
		        />
		        <h3 className='RealAccountStyles-form_category_title AppStyles-no_margin' >
		  			{Language.get('realaccount.wealth','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.wealth','en')}
		  			</div>
		  		</h3>
		  		<Form.Row>
		  			<Form.TextArea     
		  				label        = 'asset_address' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = { inputs.asset_address.ref }
		  				defaultValue = { props.User.details.asset_address }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.AmountTextbox     
		  				label        = 'asset_value' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = { inputs.asset_value.ref }
		  				defaultValue = { props.User.details.asset_value }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'bank_deposit' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.time_deposit_account.ref }
		  				defaultValue = { props.User.details.time_deposit_account }
	      				onTouched    = {props.onTouched}
		  			/>
		  			<Form.AmountTextbox     
		  				label        = 'amount' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.savings_amount.ref }
		  				defaultValue = { Helpers.is_hide_zero(props.User.details.savings_amount) }
	      				onTouched    = {props.onTouched}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'other' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = { inputs.other_asset_amount.ref }
		  				defaultValue = { props.User.details.other_asset_amount }
	      				onTouched    = {props.onTouched}
		  			/>
		  		</Form.Row>
		  	</React.Fragment>;

}

function RefValidations(props) {
	return { 
		annual_income          :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		asset_address          :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		asset_value            :{ ref: React.createRef(),validations:FormValidatorCommon.required_amount},
		time_deposit_account   :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									prevent : true,
									validate: [{
													as      : 'bank_name',
													category: 'deposit'
												}]
								}},
		savings_amount         :{ ref: React.createRef(),validations:FormValidatorCommon.optional_amount},
		other_asset_amount     :{ ref: React.createRef(),validations:{}}
	};
};

export default { Inputs, RefValidations }