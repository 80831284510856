/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Form Validator
 * ---------------------------------------------------------------------------------------------------------------------
 *  Form validations handlers and events
 *  Validation Options/Format:
 *  {
 *  	triggers: ['click','change','keypress'],         // Any js event
 *  	
 *  	prevent : false,                                 // If set to true invalid value will
 *  													 // prevent the last action effects.
 *  													 
 *  	validate: [                                      // Array of objects for validation setup
 *  				{
 *  					as              : 'required',    // validator name, this will be the reference
 *  													 // which validation function will be used. 
 *  													 // In this example it will be 'validate_require' 
 *  					
 *                      exclude_triggers: ['prevent'],   // exclude this validation setup from specific trigger/event
 *
 *	                    visible_only    : false,         // validate only if element is visible
 *
 *	                    ...others                        // you can add any property, this object setup will be passed 
 *	                    								 // to validator function as argument
 *  				}
 *  			]
 *  } 
 */

import Helpers from 'app/utils/helpers/Helpers';
import FormValidatorRules from './formvalidatorrules/FormValidatorRules';

/**	
 * This will apply all necessary events and handlers for form validations 
 * @param   form  form_ref form class instance
 * @return  void
 */
function apply(form) {
	var val_setup_list = [];
	for (var field_name in form.state.inputs) {

		// Create instance of callables to be used in this loop to prevent eslint error 'no-loop-func'
		let store_callables    = callables;
		
		var field_object = form.state.inputs[field_name];
		if (Helpers.is_object(field_object.validations)) {

			// The reactJS ref of this component
			let comp_ref     =  field_object.ref.current;
			let validate_arr =  field_object.validations.validate || [];
			let val_setup    =  { comp_ref, validate_arr, last_valid_Value:'' };


			// add validation setup to overall validation setup list
			val_setup_list.push(val_setup);



			/**
			 * ---------------------------------------------------------------------------------------------------------
			 * apply triggers
			 * ---------------------------------------------------------------------------------------------------------
			 */
			var triggers = field_object.validations.triggers;
			if (Helpers.is_array(triggers) && !Helpers.is_empty(triggers)) {


				triggers.forEach(function(trigger) {
					comp_ref.add_event(trigger, function(val_setup) {	

						return function(event) {
							// pass validation setup for this specific field
							store_callables.validate([val_setup], event.type);
						};

					}(val_setup));

				});

				comp_ref.add_event(['onKeyDown','onChange'] , function(val_setup) {

					return function(event) {

						if (Helpers.is_function(val_setup.comp_ref.prevent_clear_error)) {

							if (val_setup.comp_ref.prevent_clear_error()) {
								return false;
							}

						}

						if (Helpers.is_function(val_setup.comp_ref.error_clear)) {
							val_setup.comp_ref.error_clear();
						}
                    };

				}(val_setup));
			}


			/**
			 * ---------------------------------------------------------------------------------------------------------
			 * prevent event
			 * ---------------------------------------------------------------------------------------------------------
			 */
			if (field_object.validations.prevent) {

				comp_ref.add_event('onKeyDown', function(val_setup) {	

					return function(event_data) {

						var value = store_callables.get_comp_value(val_setup.comp_ref);

						if (!Helpers.is_empty(value)) {

							// pass validation setup for this specific field
							if (store_callables.validate([val_setup], 'prevent').overall_result) {
								val_setup.last_valid_value = value;
							}

						} else {

							val_setup.last_valid_value = '';

						}

					};

				}(val_setup));

				comp_ref.add_event(['onKeyUp','onInput','onBlur'] , function(val_setup) {

					return function(event_data) {

						var value    = store_callables.get_comp_value(val_setup.comp_ref);
						var validate = store_callables.validate([val_setup], 'prevent').overall_result;
	                    if (!Helpers.is_empty(value) && !validate) {
							store_callables.set_comp_value(val_setup.comp_ref, val_setup.last_valid_value)
	                    }

	                };

				}(val_setup));
			}
		}

	}

	form.add_event('onReset', function() {
		val_setup_list.forEach(function(val_setup) {
			if (val_setup.comp_ref !== null && Helpers.is_function(val_setup.comp_ref.error_clear)) {
				val_setup.comp_ref.error_clear();
			}
		});
	});

	form.add_event('onSubmit', function(event_data) {
		var validate = callables.validate(val_setup_list, 'onSubmit');

		if (validate.overall_result) {
			
			form.onValidateSuccess();

		} else {
			callables.focus_comp(validate.first_com_error);
			form.onValidateFail();

		}
	});
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Functions to be used for FormValidator.Apply
 * ---------------------------------------------------------------------------------------------------------------------
 */
var callables = {
	/**	
	 * This will execute validations for list fields validation setup given
	 * @param array  val_setup_list [
	 *                               	comp_ref: This is the component root reference object
	 *                                  validate: the validate setup for this component
	 *                              ]
	 * @param string event_type         
	 * @async return boolean   
	 */
	validate(val_setup_list, event_type) {
		var overall_result  = true;
		var first_com_error = null;

		val_setup_list.forEach(function(val_setup) {
			var comp_ref        = val_setup.comp_ref;
			var validate_length = val_setup.validate_arr.length;

			for (var i = 0; i < validate_length; i++) {
				var validate_obj = val_setup.validate_arr[i];

				if (callables.is_continue_validation(comp_ref, validate_obj, event_type)) {
					var value = callables.get_comp_value(comp_ref);
				  	var execute_val = FormValidatorRules[validate_obj.as](value,validate_obj);

					if (execute_val.result === false) {
						if (Helpers.is_function(comp_ref.error_handler) && event_type !== 'prevent') {
							comp_ref.error_handler(execute_val);
						}

						overall_result = false;

						if (first_com_error===null) {
							first_com_error = comp_ref;
						}
                        break;

                    } else if (Helpers.is_function(comp_ref.error_clear) && event_type !== 'prevent') {
                    	comp_ref.error_clear();
                    }
				}
			}

		});

		return { overall_result, first_com_error };
	},

	/**
	 * This will check if current validation setup is needed
	 * @param  object   comp_ref
	 * @param  object   validate_obj
	 * @param  string   event_type  
	 * @return boolean
	 */
	is_continue_validation(comp_ref, validate_obj, event_type) {

		// If validator does not exists
		if (!Helpers.is_function(FormValidatorRules[validate_obj.as])) {
	        return false;
	    }

		// Check if have custom function for checking if validation should continue
	    if (Helpers.is_function(validate_obj.stop) && validate_obj.stop()) {
	        return false;
	    }

		// Check if for visible state only
	    if (validate_obj.visible_only && !callables.check_visibility(comp_ref)) {
	        return false;
	    }
	    
		// Check if excluded from trigger
	    if (Helpers.is_array(validate_obj.exclude_triggers) && validate_obj.exclude_triggers.includes(event_type)) {
	        return false;
	    }

	    return true;
	},

	/**
	 * This will check visibility of ref
	 * @param  object com_ref 
	 * @return boolean
	 */
	check_visibility(comp_ref) {
		if (Helpers.is_function(comp_ref.is_visible)) {
			return comp_ref.is_visible();
		} 

		if (Helpers.isElement(comp_ref.current)) {
			return Helpers.is_visible(comp_ref.current);
		}

		return true;
	},

	/**
	 * This will set current component value
	 * @param  object comp_ref
	 * @param  mixed  value
	 * @return void
	 */
	set_comp_value(comp_ref,value) {
		if (Helpers.is_function(comp_ref.set_value)) {
			comp_ref.set_value(value);
		} else {
			comp_ref.value = value;
		}
	},

	/**
	 * This will get current component value
	 * @param  object comp_ref
	 * @return mixed
	 */
	get_comp_value(comp_ref) {
		return Helpers.is_function(comp_ref.value) ? comp_ref.value() : comp_ref.value;
	},

	/**
	 * This will set component to focus
	 * @param  object comp_ref 
	 * @return void
	 */
	focus_comp(comp_ref) {
		if (Helpers.is_function(comp_ref.set_focus)) {
			comp_ref.set_focus();
		} else {
			comp_ref.focus();
		}
	}

};

export default { apply };