function contents() {

	return {
		step1          : 'Profil Perusahaan',
		step2          : 'Simulasi Transaksi',
		step3          : 'Input Data',
		step4          : 'Pemberitahuan Resiko',
		step5          : 'Pemberian Amanat',
		step6          : 'Peraturan Perdagangan',
		step7          : 'Surat Pernyataan',
		step8          : 'Selesai',
		leave_page     : 'You haven’t saved your changes. Are you sure you want to leave this page?',
		step1_title    : 'Profil Perusahaan Pialang Berjangka',
		step2_asp_title: 'Pernyataan Telah Melakukan Simulasi Perdagangan Berjangka Komoditi',
		step2_pbk_title: 'PERNYATAAN TELAH MELAKUKAN SIMULASI PERDAGANGAN BERJANGKA KOMODITI',
		step3_title    : 'Aplikasi Pembukaan Rekening Transaksi Secara Elektronik On-line',
		step3_subtitle : 'ON-LINE ELECTRONIC ACCOUNT OPENING APPLICATION',
		step4_title    : 'Pernyataan Menerima Pemberitahuan Adanya Risiko',
		step5_title    : 'Pernyataan Perjanjian Pemberian Amanat',
		step6_title    : 'Pernyataan Peraturan Perdagangan (Trading Rules)',
		step7_title    : 'Pernyataan Bertanggung Jawab Atas',
		step7_subtitle_1 : 'Kode Akses Transaksi Nasabah',
		step7_subtitle_2 : '(Personal Access Password)',
		yes            : 'Ya',
		no             : 'Tidak',
		declare_on     : 'Menyatakan pada tanggal',
		statement_on   : 'Pernyataan pada tanggal',
		receive_on     : 'Menerima pada tanggal',
		step1_footer_1 : 'PERNYATAAN TELAH MEMBACA PROFIL PERUSAHAAN PIALANG BERJANGKA',
		step1_footer_2 : 'Dengan mengisi kolom “YA” di bawah ini, saya menyatakan bahwa saya telah membaca dan menerima'
						+' informasi',
		step1_footer_3 : ' PROFIL PERUSAHAAN PIALANG BERJANGKA',
		step1_footer_4 : ', mengerti dan memahami isinya.',
		age_requirement: 'Anda harus mempunyai umur minimal 21 Tahun',
		step2_asp_footer_1 : 'Dengan mengisi kolom “YA” di bawah ini, saya menyatakan bahwa saya telah melakukan simulasi '
						+'bertransaksi di bidang Perdagangan Berjangka Komoditi pada ',
		step2_asp_footer_2 : ', dan telah memahami tentang tata cara bertransaksi di bidang Perdagangan Berjangka Komoditi.',
		step2_pbk_footer_1 : 'Dengan mengisi kolom “YA” di bawah ini, saya menyatakan bahwa saya telah melakukan '
							+'simulasi bertransaksi di bidang Perdagangan Berjangka Komoditi pada ',
		step2_pbk_footer_2 : ', dan telah memahami tentang tata cara bertransaksi di bidang Perdagangan Berjangka Komoditi.',
		step2_footer_3 : 'Demikian Pernyataan ini dibuat dengan sebenarnya dalam keadaan sadar, sehat jasmani dan '
						+'rohani serta tanpa paksaan apapun dari pihak manapun.',
		indonesian     : 'WNI',
		foreigner      : 'WNA',
		male           : 'Laki-laki',
		female         : 'Perempuan',
		single         : 'Tidak Kawin',
		married        : 'Kawin',
		widowed        : 'Janda',
		personal       : 'Pribadi',
		family         : 'Keluarga',
		rent           : 'Sewa/Kontrak',
		others         : 'Lainnya',
		personal_data  : 'Data Pribadi Nasabah',
		investment_purpose : 'Data Tujuan Dan Latar Belakang Investasi Nasabah',
		hedging        : 'Lindung Nilai',
		gain           : 'Gain',
		speculation    : 'Spekulasi',
		trading_asp_header:'SURAT PERNYATAAN TELAH BERPENGALAMAN MELAKSANAKAN TRANSAKSI PERDAGANGAN BERJANGKA KOMODITI',
		trading_pbk_header:'SURAT PERNYATAAN TELAH BERPENGALAMAN MELAKSANAKAN TRANSAKSI PERDAGANGAN BERJANGKA KOMODITI',
		mailing_address_data: 'Data Alamat Surat Menyurat Nasabah',
		emergency_contact   : 'Dalam Keadaan darurat, pihak yang dapat dihubungi',
		job_data            : 'Data Pekerjaan Nasabah',
		private             : 'Swasta',
		self_employed       : 'Wiraswasta',
		house_wife          : 'Ibu RT',
		professional        : 'Profesional',
		public_servant      : 'Peg. Negeri',
		bumn_employee       : 'Peg. BUMN',
		student             : 'Mahasiswa',
		other_mention       : 'Lainnya, sebutkan',
		list_of_wealth      : 'Daftar Kekayaan',
		income_100_250      : 'Antara Rp100 - 250 juta',
		income_250_500      : 'Antara Rp250 - 500 juta',
		income_500_plus     : 'Di atas Rp500 juta',
		wealth              : 'Daftar Kekayaan',
		bank_account_withdraw: 'Data Rekening Bank Nasabah Untuk Penarikan',
		main_account         : 'Rekening Utama',
		current_account      : 'Giro',
		savings              : 'Tabungan',
		idr                  : 'IDR',
		usd                  : 'USD',
		other_account        : 'Rekening Lainnya',
		attached_documents   : 'Dokumen Yang Dilampirkan',
		max_file_size        : 'Maksimal 20MB',
		other_document_types : 'Rekening Koran Bank / Tagihan Kartu Kredit / No. '
								+'Rek Listrik atau Telpon / Nomor Pokok Wajib Pajak / Bukti Kepemilikan Tanah atau '
								+'Bangunan atau kendaraan / Surat Keterangan Bekerja / Tambahan dokumen lain',
		Step3_footer_1       : 'PERNYATAAN KEBENARAN DAN TANGGUNG JAWAB',
		Step3_footer_2       : 'Dengan mengisi kolom “YA” di bawah ini, saya menyatakan bahwa semua informasi dan semua'
								+' dokumen yang saya lampirkan dalam',
		Step3_footer_3       : 'APLIKASI PEMBUKAAN REKENING TRANSAKSI SECARA ELEKTRONIK ON-LINE',
		Step3_footer_4       : 'adalah benar dan tepat, Saya akan bertanggung jawab penuh apabila'
								+' dikemudian hari terjadi sesuatu hal sehubungan dengan ketidakbenaran data yang saya'
								+' berikan.',
		step4_footer_1       : 'Dengan mengisi kolom "YA" di bawah, saya menyatakan bahwa saya telah menerima',
		step4_footer_2       : '"DOKUMEN PEMBERITAHUAN ADANYA RISIKO"',
		step4_footer_3       : 'mengerti dan menyetujui isinya.',
		step5_footer_1       : 'Dengan mengisi kolom “YA” di bawah, saya menyatakan bahwa saya telah menerima',
		step5_footer_asp     : '“PERJANJIAN PEMBERIAN AMANAT TRANSAKSI KONTRAK DERIVATIF SISTEM PERDAGANGAN ALTERNATIF”',
		step5_footer_pbk     : '“PERJANJIAN PEMBERIAN AMANAT TRANSAKSI KONTRAK BERJANGKA”',
		step5_footer_3       : 'Mengerti dan menyetujui isinya.',
		step6_footer_1       : 'Dengan mengisi kolom "YA" di bawah ini, saya menyatakan bahwa saya telah membaca tentang',
		step6_footer_2       : '“PERATURAN PERDAGANGAN ',
		step6_footer_3        :'(TRADING RULES)”',
		step6_footer_4       : 'mengerti dan menerima ketentuan dalam bertransaksi.',
		step7_footer_1       : 'Dengan mengisi kolom “Ya” di bawah ini, saya menyatakan bahwa saya bertanggungjawab'
								+' sepenuhnya terhadap kode akses transaksi Nasabah',

		step7_footer_2       : '(Personal Access Password)',
		step7_footer_3       : ' dan tidak menyerahkan kode akses transaksi Nasabah ',
		step7_footer_4       : '(Personal Access Password)',
		step7_footer_5       : ' ke pihak lain, terutama kepada pegawai Pialang Berjangka atau pihak yang '
								+'memiliki kepentingan dengan Pialang Berjangka.',
		step7_footer_6       : 'PERINGATAN !',
		step7_footer_7       : 'Pialang Berjangka, Wakil Pialang Berjangka, Pegawai Pialang Berjangka, atau pihak yang'
								+' memiliki kepentingan dengan Pialang Berjangka dilarang menerima kode akses transaksi'
								+' Nasabah ',
		step7_footer_8       : '(Personal Access Password).',
		step7_footer_9       : 'Demikian Pernyataan ini dibuat dengan sebenarnya dalam keadaan sadar, sehat jasmani'
								+ ' dan rohani serta tanpa paksaan apapun dari pihak manapun.',
		deposit_to_account          : 'TERIMA KASIH TELAH MELAKUKAN REGISTRASI ELEKTRONIK ONLINE, '
										+'SILAHKAN MENYETORKAN DANA KE REKENING TERPISAH',
		send_deposit_proof          : 'dan kirimkan bukti penyetoran ke',
		sure_to_submit              : 'Apakah Informasi yang diisi sudah benar?',
		company_data                : 'Data Perusahaan',
		company_management_structure: 'Susunan Pengurus Perusahaan',
		contracts_traded            : 'Kontrak Berjangka Yang Diperdagangkan' ,
		company_customer_fee        : 'Biaya secara rinci yang dibebankan kepada Nasabah',
		company_trading_rules1      : 'Trade Table Contract For Difference (CFD)',
		company_trading_rules2      : 'Mata Uang Asing (Forex)',
		fax_number_short            : 'No Faksimili',
		permission_and_membership   : 'Izin dan Keanggotaan',
		derivative_contracts        : 'Kontrak Derivatif Dalam Sistem Perdagangan Alternatif yang Diperdagangkan',
		fee_subtitle_1              : 'Terlampir',
		fee_subtitle_2              : 'Trade Table',
		spa_fee_subtitle_3          : 'Kontrak Derivatif dalam Sistem Perdagangan Alternatif(SPA)',
		pbk_fee_subtitle_3          : 'Kontrak Berjangka Multilateral',
		broker_working_future       : 'Nama-nama Wakil Pialang Berjangka Yang bekerja pada',
		broker_appointed_1          : 'Nama-nama Wakil Pialang Berjangka yang secara khusus ditunjuk oleh',
		broker_appointed_2          : 'untuk melakukan verifikasi dalam rangka penerimaan Nasabah Elektronik On-Line',
		separated_account_number    : 'NOMOR REKENING TERPISAH (SEGREGATED ACCOUNT)',
		who_fill_out                : 'Yang mengisi formulir di bawah ini :',
		widower                     : 'Duda',
		bank                        : 'BANK',
		bank_propercase             : 'Bank',
		yes_in_sector               : 'Ya, Bidang',
		give_sequence               : 'Berikan urutan berdasarkan prioritas',
		short_separated_account     : 'Nomor Rekening Terpisah',
		disclosure_footer_1         : 'Dengan mengisi kolom “Ya” di bawah ini, saya menyatakan bahwa saya telah membaca dan menerima informasi',
		disclosure_footer_2         : 'PERNYATAAN PENGUNGKAPAN ',
		disclosure_footer_3         : '(DISCLOSURE STATEMENT), ',
		disclosure_footer_4         : 'mengerti dan memahami isinya ',
		company_shareholders: 'Susunan Pemegang Saham Perusahaan'
	};

}

export default contents;