import React, { Component } from 'react';
import classnames from 'classnames';
import Form from 'app/components/form/Form';
import FormValidatorCommon from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language from 'app/utils/language/Language';
import Modal from 'app/components/modal/Modal';
import RealAccountComponents from '../../../realaccountcomponents/RealAccountComponents';
import Helpers from 'app/utils/helpers/Helpers';
import Model from 'app/modules/model/Model';
import { callables as StoreMethods } from 'app/store/Store';
import ReactMoment from 'react-moment';
import FontIcons from 'app/components/fonticons/FontIcons';

function Inputs(inputs, props, component) {
	return <React.Fragment>
		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
			{Language.get('realaccount.investment_purpose', 'id')}
			<div className='RealAccountStyles-form_subtitle'>
				{Language.get('realaccount.investment_purpose', 'en')}
			</div>
		</h3>
		<Form.Row>
			<Form.Radio
				label='account_opening_purpose'
				ref={inputs.account_opening_purpose.ref}
				defaultValue={component.account_opening_defaultValue()}
				className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
				options={component.account_opening_options}
				optionsFormatted={false}
				formatter={account_opening_formatter(inputs, props, component)}
				onChange={component.account_purpose_changed}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Radio
				label='investment_experience'
				ref={inputs.has_investment_experience.ref}
				defaultValue={props.User.details.has_investment_experience}
				onChange={component.investment_experience_changed}
				className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
				options={[
					{
						value: 'true',
						description: investment_experience_textbox(inputs, props, component)
					},
					{
						value: 'false',
						description: <React.Fragment>
							{Language.get('realaccount.no', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.no', 'en')}
							</div>
						</React.Fragment>
					}
				]}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Radio
				label='trading_experience'
				ref={inputs.has_trading_experience.ref}
				defaultValue={props.User.details.has_trading_experience}
				className='AppStyles-full_size_input'
				options={[
					{
						value: 'true',
						description: <React.Fragment>
							{Language.get('realaccount.yes', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.yes', 'en')}
							</div>
						</React.Fragment>
					},
					{
						value: 'false',
						description: <React.Fragment>
							{Language.get('realaccount.no', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.no', 'en')}
							</div>
						</React.Fragment>
					}
				]}
				onChange={component.trading_experience_changed}
				onTouched={props.onTouched}
				required={1}
				afterInput={
					!Helpers.is_empty(props.User.details.trading_company) &&
					component.state.selected_trading_experience === 'true' &&
					<div className='RealAccountStyles-trading_company_display'>
						{props.User.details.trading_company}
					</div>
				}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Radio
				label='family_work_at'
				ref={inputs.family_work_at.ref}
				className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
				options={[
					{
						value: 1,
						description: <Form.Textbox
							className='RealAccountStyles-radio_input'
							onTouched={props.onTouched}
							ref={inputs.family_work_text.ref}
							beforeInput={
								<span
									className='RealAccountStyles-radio_text'
								>
									{Language.get(
										'realaccount.yes',
										'id'
									)},
									<div className='AppStyles-label_secondary'>
										{Language.get(
											'realaccount.yes',
											'en'
										)},
									</div>
								</span>
							}
							disabled={true}
						/>
					},

					{
						value: 2,
						description: <React.Fragment>
							{Language.get('realaccount.no', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.no', 'en')}
							</div>
						</React.Fragment>
					}
				]}
				defaultValue={Helpers.in_array(
					Model.get_step_name('step3'),
					component.props.Step.content.previous_progress
				) ? 2 : null}
				onChange={component.family_work_changed}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Radio
				label='bankrupt'
				ref={inputs.bankrupt.ref}
				className='AppStyles-full_size_input'
				options={[
					{
						value: 1,
						description: <React.Fragment>
							{Language.get('realaccount.yes', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.yes', 'en')}
							</div>
						</React.Fragment>
					},
					{
						value: 2,
						description: <React.Fragment>
							{Language.get('realaccount.no', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.no', 'en')}
							</div>
						</React.Fragment>
					}
				]}
				defaultValue={Helpers.in_array(
					Model.get_step_name('step3'),
					component.props.Step.content.previous_progress
				) ? 2 : null}
				onChange={component.bankrupt_changed}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Checkbox
				onTouched={props.onTouched}
				ref={inputs.not_money_laundering.ref}
				options={[
					{
						value: '1',
						description: 'Dana yang dipergunakan untuk bertransaksi adalah milik Nasabah sendiri dan bukan dana pihak lain, serta tidak diperoleh dari hasil penipuan, penggelapan, hasil pencucian uang maupun tindak pidana korupsi dan perbuatan melawan hukum lainnya serta tidak dimaksudkan untuk melakukan pencucian uang',
					}
				]}
				customTransform = {{transform : 'translateY(-150%)'}}
				required={1}
				requiredText={1}
				defaultValue={Helpers.in_array(
					Model.get_step_name('step3'),
					component.props.Step.content.previous_progress
				) ? '1' : null}

				disabled={Helpers.in_array(
					Model.get_step_name('step3'),
					component.props.Step.content.previous_progress
				) ? true : false}
				onChange={component.not_money_laundering_changed}
			/>
		</Form.Row>


	</React.Fragment>;

}

function account_opening_formatter(inputs, props, component) {
	return (item) => {
		switch (item) {
			case 'Lindung Nilai':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get('realaccount.hedging', 'id')}
						<div className='AppStyles-label_secondary'>
							{Language.get('realaccount.hedging', 'en')}
						</div>
					</React.Fragment>
				};
			case 'Spekulasi':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get('realaccount.speculation', 'id')}
						<div className='AppStyles-label_secondary'>
							{Language.get('realaccount.speculation', 'en')}
						</div>
					</React.Fragment>
				};
			case 'Lainnya':
				return {
					value: item,
					description: <Form.Textbox
						className='RealAccountStyles-radio_input'
						ref={inputs.account_opening_text.ref}
						defaultValue={
							component.show_account_purpose() ?
								props.User.details.account_opening_purpose : ''
						}
						onTouched={props.onTouched}
						beforeInput={
							<span
								className='RealAccountStyles-radio_text'
							>
								{Language.get(
									'realaccount.others',
									'id'
								)},
								<div className='AppStyles-label_secondary'>
									{Language.get(
										'realaccount.others',
										'en'
									)},
								</div>
							</span>
						}
						disabled={!component.show_account_purpose()}
					/>
				};

			// no default
		}
	}
}

function investment_experience_textbox(inputs, props, component) {
	return <Form.Textbox
		className='RealAccountStyles-radio_input'
		onTouched={props.onTouched}
		ref={inputs.investment_experience.ref}
		defaultValue={props.User.details.investment_experience}
		beforeInput={
			<span
				className='RealAccountStyles-radio_text'
			>
				{Language.get(
					'realaccount.yes_in_sector',
					'id'
				)}:
				<div className='AppStyles-label_secondary'>
					{Language.get(
						'realaccount.yes_in_sector',
						'en'
					)}
				</div>
			</span>
		}
		disabled={props.User.details.has_investment_experience !== 'true'}
	/>;
}

function RefValidations(props) {
	var has_investment_experience_ref = React.createRef();
	return {
		account_opening_purpose: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		},
		account_opening_text: { ref: React.createRef(), validations: {} },
		has_investment_experience: {
			ref: has_investment_experience_ref, validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		},
		investment_experience: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [
					{
						as: 'requiredif',
						type: 'nomessage',
						checker: () => (has_investment_experience_ref.current.value() === 'true')
					}
				]
			}
		},
		has_trading_experience: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}, store_exempt: true
		},
		family_work_at: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		},
		family_work_text: { ref: React.createRef(), validations: {} },
		bankrupt: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		},
		not_money_laundering: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		}
	};
};

function ModalUI(component) {
	// console.log(component, 'modalui')
	return <React.Fragment>
		<Modal
			ref={component.modals.trading_experience}
			header={
				component.props.User.details.account_type.toLowerCase() === 'multilateral' ?
					Language.get('realaccount.trading_pbk_header', 'id')
					:
					Language.get('realaccount.trading_asp_header', 'id')
			}
			body={<TradingExperienceForm
				{...component.props}
				inputs={component.inputs}
				onSubmitDone={component.trading_experience_submitted}
				unCheckTradingExperience={() => {
					component.inputs.has_trading_experience.ref.current.set_value('false');
					component.setState({
						selected_trading_experience: false
					})
				}}

			/>}
			size='large'
			disableClosing={true}
		/>
	</React.Fragment>;
}

class TradingExperienceFormRaw extends Component {

	constructor(props) {
		super(props);

		// create refs and validations
		this.inputs = {
			accept_statement: {
				ref: React.createRef(),
				validations: FormValidatorCommon.accept_statement
			},
			trading_account: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required' }]
				}
			},
			trading_company: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required' }]
				}
			}
		};
		this.state = {
			can_submit: false,
			loading: 1,
			submitting: 0,
			error: false,
			code: null
		};
		this.form = React.createRef();
	}

	componentDidMount = () => {
		this.check_loading();
	}

	componentDidUpdate = () => {
		this.check_loading();
	}

	render() {
		return <Form.Form
			onValidateSuccess={this.on_submit}
			inputs={this.inputs}
			loading={this.state.loading}
			submitting={this.state.submitting}
			ref={this.form}
			className= //{this.state.error && 'AppStyles-invisible'}
			{classnames([
				this.state.error && 'AppStyles-invisible',
				!this.state.error && 'RealAccountStyles-trading_experience_modal_form'
			])}
		>
			<Form.Row>
				<Form.TextView
					label='id_full_name'
					className='AppStyles-full_size_input'
				>{this.props.User.details.full_name}</Form.TextView>
			</Form.Row>
			<Form.Row>
				<Form.TextView
					label='id_place_birth'
					className='AppStyles-half_size_input'
				>{this.props.User.details.place_of_birth}</Form.TextView>
				<Form.TextView
					label='id_date_birth'
					className='AppStyles-half_size_input'
				>
					{
						!Helpers.is_empty(this.props.User.details.date_of_birth)
						&& <ReactMoment
							format={process.env.REACT_APP_DATE_DISPLAY}
							locale='id'
							element='span'
						>{this.props.User.details.date_of_birth}</ReactMoment>
					}
				</Form.TextView>
			</Form.Row>
			<Form.Row>
				<Form.TextView
					label='id_address'
					className='AppStyles-half_size_input'
				>{this.props.User.details.home_address}</Form.TextView>
				<Form.TextView
					label='id_postal_code'
					className='AppStyles-half_size_input'
				>{this.props.User.details.home_postal_code}</Form.TextView>
			</Form.Row>
			<Form.Row>
				<Form.TextView
					label='id_id_number'
					className='AppStyles-half_size_input'
				>{this.props.User.details.id_number}</Form.TextView>
				<Form.Textbox
					label='demo_account_number'
					ref={this.inputs.trading_account.ref}
					defaultValue={this.props.User.details.trading_account}
					className={classnames([
						'AppStyles-half_size_input',
						'RealAccountStyles-modal_demo_account'
					])}
					required={1}
				/>
			</Form.Row>
			<Form.Row>

				<div className='RealAccountStyles-select_yes'>
					<Form.Textbox
						ref={this.inputs.trading_company.ref}
						defaultValue={this.props.User.details.trading_company}
						onTouched={this.check_can_submit}
						required={1}
						className='AppStyles-full_size_input'
						beforeInput={
							<span className='RealAccountStyles-select_yes_text'>
								{Language.get('form_labels.select_yes', 'id')}
								<span className='RealAccountStyles-select_yes_mandatory'>* </span>
							</span>
						}
						afterInput={
							<span className='RealAccountStyles-select_yes_text'>
								{Language.get('form_labels.select_yes_2', 'id')}
							</span>
						}
					/>
				</div>
				<div className='RealAccountStyles-select_yes'>
					<p>
						{Language.get('form_labels.select_yes_3', 'id')}
					</p>
				</div>
			</Form.Row>
			<Form.Row className='RealAccountStyles-row_center RealAccountStyles-row_spaced'>
				{
					RealAccountComponents.AcceptStatement(
						this.inputs.accept_statement.ref,
						this.check_can_submit
					)
				}
			</Form.Row>
			<Form.Row className='RealAccountStyles-row_center'>
				<RealAccountComponents.Timer timerLabel={Language.get('realaccount.statement_on', 'id')} />
			</Form.Row>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span className='RealAccountComponentStyles-back_button'>
					<Form.SecondaryButton
						onClick={() => {
							this.props.unCheckTradingExperience()
							this.props.onSubmitDone()
						}}
						buttonProps={{ type: 'button' }}
					>
						<FontIcons.Material icon='arrow_back' /> {Language.get('form_labels.back')}
					</Form.SecondaryButton>
				</span>
				<Form.PrimaryButton
					buttonProps={{ disabled: !this.state.can_submit }}
					className='RealAccountComponentStyles-submit_button'
					main={1}
				>
					{Language.get('form_labels.save')}
				</Form.PrimaryButton>
			</div>
		</Form.Form>
	}

	check_loading = () => {
		if (this.state.loading === 1) {
			var has_user = !Helpers.is_empty(this.props.User.details);

			if (has_user) {
				this.setState({ loading: 0 });
			}
		}
	}

	check_can_submit = () => {
		this.setState({
			can_submit: parseInt(this.inputs.accept_statement.ref.current.value()) === 1
				&& !Helpers.is_empty(this.inputs.trading_company.ref.current.value())
		});
	}

	on_submit = (inputs) => {
		this.setState({ submitting: 1 });

		// NOTE: add has_trading_experience to 'true' for submit past trading exp form
		inputs.has_trading_experience = 'true';

		var component = this;

		Model.update_customer_trading(inputs, {
			success: () => {
				if (Helpers.is_function(component.props.onSubmitDone)) {
					component.props.onSubmitDone();
				}
			},
			fail: (response) => {
				component.setState({ submitting: 0 });
				component.form.current.submit_fail(response);
			},
			error: (xhr, status) => {
				component.setState({ submitting: 0 });
				component.form.current.submit_error(xhr, status);
			}
		});
	}
}
const TradingExperienceForm = StoreMethods.connect_stores(
	TradingExperienceFormRaw,
	['User'],
	Model.get_customer_basic
);

export default { Inputs, RefValidations, ModalUI }