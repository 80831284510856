import React,{Component}             from 'react';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import RealAccountComponents         from '../realaccountcomponents/RealAccountComponents';
import BasicInfoForm                 from '../basicinfoform/BasicInfoForm';
import { callables as StoreMethods } from 'app/store/Store';
import Form                          from 'app/components/form/Form';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Language                      from 'app/utils/language/Language';
import Modal                         from 'app/components/modal/Modal.js';
import Model                 		 from 'app/modules/model/Model';
import classnames                    from 'classnames';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';

class Step7 extends Component {

	constructor(props) {
		super(props);

	    this.inputs =   {

							accept_statement:{ 
												ref         : React.createRef(),
												validations : FormValidatorCommon.accept_statement, 
												store_exempt: true
											}
		    			};
		this.state  = {
						can_submit: false,
						loading   : 1,
						submitting: 0
					};
		this.modals = {
						confirm_submit: React.createRef()
					};
		this.form   = React.createRef();
	}

  	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step 7');
  		this.check_loading();
		  if(this.checkStep8()){
			this.myinterval(this.inputs,this.changeDateSuccess,this.changeTextDateSuccess)
		}
		//console.log(this.inputs.accept_statement.ref.current.value())
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	render() {

		return  <React.Fragment>
					<Form.Form 
						onValidateSuccess= {this.on_submit} 
						inputs           = {this.inputs} 
						loading          = {this.state.loading}
	    				storevalues      = {true}
	    				storename        = 'step7'
		    			submitting       = {this.state.submitting}
			    		ref              = {this.form}
		    			key              = {this.state.loading+'form'}
					>
						<RealAccountComponents.StepPageLayout
							step           = {7}
							header         = {
												<h3 
													className = {classnames([
																	'RealAccountStyles-content_title',
																	'AppStyles-no_margin'
																])}
												>
													<div>{Language.get('realaccount.step7_title')}</div>
													{
														<div className='RealAccountStyles-content_subtitle'>
														  	{Language.get('realaccount.step7_subtitle_1')}
														  	<i>{Language.get('realaccount.step7_subtitle_2')}</i>
														</div>
													}
												</h3>
											}
							body           = {
												<React.Fragment>
													{ BasicInfoForm.view(this.props) } 
													<Form.Row>
										      			<Form.TextView     
										      				label        = 'real_account_number' 
										      				className    = 'AppStyles-half_size_input'
										      			></Form.TextView>
										      		</Form.Row>
												</React.Fragment>
											}
							footer         = {
												<React.Fragment>
													<p> 
														{Language.get('realaccount.step7_footer_1','id')}
														<i>{Language.get('realaccount.step7_footer_2','id')}</i>
														{Language.get('realaccount.step7_footer_3','id')}
														<i>{Language.get('realaccount.step7_footer_4','id')}</i>
														{Language.get('realaccount.step7_footer_5','id')}
													</p>
													<div className='AppStyles-warning'>
														<h3 className='AppStyles-no_margin AppStyles-warning_title'>
															{Language.get('realaccount.step7_footer_6','id')}
														</h3>
														<p className='AppStyles-no_margin'> 
															{Language.get('realaccount.step7_footer_7','id')}
															<i>{Language.get('realaccount.step7_footer_8','id')}</i> 
														</p>
													</div>
													<p> 
														{Language.get('realaccount.step7_footer_9','id')}
													</p>
												</React.Fragment>
											}
							AcceptStatement= {RealAccountComponents.AcceptStatement(
												this.inputs.accept_statement.ref,
												this.on_accept_statement
											)}
							acceptTermsRef = {this.inputs.accept_statement.ref}
							canSubmit      = {this.state.can_submit}
							timerLabel     = {Language.get('realaccount.statement_on','id')}
						/>
					</Form.Form>

		    		<Modal 
		    			ref            = {this.modals.confirm_submit}
		    			body           = {<p>{Language.get('realaccount.sure_to_submit')}</p>}
		    			showCloseIcon  = {false}
		    			size           = 'small'
		    		/>
	    		</React.Fragment>;
	}


	on_accept_statement = () => {
		this.setState({ can_submit:  parseInt(this.inputs.accept_statement.ref.current.value())===1 });
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_user    = !Helpers.is_empty(this.props.User.details);

  			if (has_user) {
  				this.setState({ loading: 0 });
				if(this.checkStep8()){
					StoreMethods.step_enabled_change(8)
					this.disableAllandYa()
					this.changeDateSuccess()
					//console.log('step7')
				}
  			}
		}
	}

	continue_submit = (inputs) => {
		this.modals.confirm_submit.current.close();
  		this.setState({ submitting: 1 });
		var component = this;
  		
		Model.submit_customer_registration({
				precomplete: () => {
					component.setState({ submitting: 0 });
				},
				fail       : component.form.current.submit_fail,
				error      : component.form.current.submit_error
		});
		
	}

	cancel_submit = () => {
		this.modals.confirm_submit.current.close();
	}

	on_submit = (inputs) => {
		if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
		}else{
			this.modals.confirm_submit.current.open({
				footer: <React.Fragment>
							<Form.PrimaryButton   onClick={() => this.continue_submit(inputs)}>
								{Language.get('realaccount.yes')}
							</Form.PrimaryButton>
							<Form.SecondaryButton onClick={this.cancel_submit}>
								{Language.get('realaccount.no')}
							</Form.SecondaryButton>
						</React.Fragment>
			});
		}
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Step4')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		StoreMethods.step_enabled_change(8)
		//disable date
		//document.getElementById("date").disabled = true;
		//this.disableElement('FormStyles_dropdown')
		//this.disableElement('FormStyles_upload')
		//this.disableElement('FormStyles_textbox')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) === classname){
				//console.log(child)
				child.disabled = true;
			}
  		}
		/*console.log(elements)
	
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			for (var i = 0, length = elements.length; i < length; i++) {
				child = elements[i];
				if (child.className.substring(0, classname.length) == classname)
					  child[i].disabled = true;
			  }		
		}*/
		
	}

	myinterval = (inputs,changeDateSuccess,changeTextDateSuccess) =>{
		setInterval(function () {
			//console.log(changeDateSuccess)
			changeDateSuccess(changeTextDateSuccess)
			inputs.accept_statement.ref.current.set_value(1)
			inputs.accept_statement.ref.current.disable()
			if(parseInt(inputs.accept_statement.ref.current.value())===1){
				var interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
				// Clear any timeout/interval up to that id
				for (let i = 1; i < interval_id; i++) {
  					window.clearInterval(i);
				}
			}
		}, 1000);
	}

	changeDateSuccess(changeTextDateSuccess){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		//console.log(regSuc)
		if(regSuc){
			var children = document.body.getElementsByTagName('*');
			var child;
			for (var i = 0, length = children.length; i < length; i++) {
				child = children[i] || {};
				if(child.className){
					if (child.className === 'RealAccountComponentStyles-timer') {
						child.innerText = regSuc
						//console.log(child)
						
					}
				}
			}
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}

	//---------------------------
}

export default StoreMethods.connect_stores(Step7, ['User']);