import { combineReducers  }                             from 'redux';
import UserReducers,{ Selectors as UserSelectors}       from './UserReducers';
import StepReducers                                     from './StepReducers';
import BrokersReducers,{ Selectors as BrokersSelectors} from './BrokersReducers';
import AccountTypesReducers                             from './AccountTypesReducers';
import CountryCodesReducers                             from './CountryCodesReducers';
import CompanyReducers                                  from './CompanyReducers';
import Step1Reducers                                    from './Step1Reducers';
import Step4Reducers                                    from './Step4Reducers';
import Step5Reducers                                    from './Step5Reducers';
import Step6Reducers                                    from './Step6Reducers';
import CompanyAdsReducers                               from './CompanyAdsReducers';
import ConflictCourtReducers                            from './ConflictCourtReducers';
import DisclosureStatementReducers                      from './DisclosureStatementReducers';

const Selectors = {BrokersSelectors,UserSelectors};

export default combineReducers({
	UserReducers, 
	StepReducers, 
	BrokersReducers,
	AccountTypesReducers,
	CountryCodesReducers,
	CompanyReducers,
	Step1Reducers,
	Step4Reducers,
	Step5Reducers,
	Step6Reducers,
	CompanyAdsReducers,
	ConflictCourtReducers,
	DisclosureStatementReducers
});
export {Selectors};