import AjaxCore          			 from './ajaxcore/AjaxCore';
import Helpers 			 			 from 'app/utils/helpers/Helpers';
import { callables as StoreMethods } from 'app/store/Store';
import LocalForage                   from 'app/utils/localforage/LocalForage';
import Moment                        from 'moment';
import 'moment/locale/id';
import 'moment-timezone';

var step_names = {
					firstlogin           : 'Regol - First Login',
					step1                : 'Regol - Step 1 Completed',
					step2                : 'Regol - Step 2 Completed',
					disclosure1          : 'Disclosure Statement 1',
					step3                : 'Regol - Step 3 Completed',
					disclosure2          : 'Disclosure Statement 2',
					step4                : 'Regol - Step 4 Completed',
					disclosure3          : 'Disclosure Statement 3',
					step5                : 'Regol - Step 5 Completed',
					step6                : 'Regol - Step 6 Completed',
					regolsuccessful      : 'Regol Successful',
					verified             : 'Verified',
					inputresume          : 'Input Resume',
					realaccountactivated : 'Real Account Activated',
					documenttopialangsent: 'Document to Pialang Sent'
				};

var globals = {
				dispatching: [],
				step_map   : [
								{ progressName:step_names.firstlogin      , number: 1},
								{ progressName:step_names.step1           , number: 2},
								{ progressName:step_names.step2           , number: 3},
								{ progressName:step_names.disclosure1     , number: 3},
								{ progressName:step_names.step3           , number: 4},
								{ progressName:step_names.disclosure2     , number: 4},
								{ progressName:step_names.step4           , number: 5},
								{ progressName:step_names.disclosure3     , number: 5},
								{ progressName:step_names.step5           , number: 6},
								{ progressName:step_names.step6           , number: 7},
								{ progressName:step_names.regolsuccessful , number: 8},
								{ progressName:step_names.verified        , number: 9},
								{ progressName:step_names.inputresume     , number: 10},
								{ progressName:step_names.realaccountactivated , number: 11},
								{ progressName:step_names.documenttopialangsent, number: 12}
							],
				cant_edit_steps: ['Document to Pialang Sent'],
				model_storage  : LocalForage.createInstance({ name: 'model', driver : LocalForage.LOCALSTORAGE }),
				subscription   : {  login: [] }
			};

// set ajax custom token parser
AjaxCore.set_token_parser((xhr, response) => {
	if (Helpers.is_object(response)) {
		if (!Helpers.is_empty(response.data) && !Helpers.is_empty(response.data.token)) {
			return response.data.token;
		}
	} else {
		if (!Helpers.is_empty(xhr.getResponseHeader('Regol-Token'))) {
			return xhr.getResponseHeader('Regol-Token');
		}
	}
});

// set custom false positive response detector
AjaxCore.false_positive_detector((response) => {
	return (Helpers.is_object(response) && Helpers.is_object(response.error) && response.error.code!=='0');
});

// token changed
LocalForage.watch('model', 'login', (value) => {	
	globals.subscription['login'].forEach(func => {
		let ref = func.ref;
		return func.function(value, ref);
	});
});

var callables = {
	
	/**
	 * Get registration data
	 * @param  array items
	 * @param  object ajax_setup 
	 * @return             
	 */
	get_registration_data(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		if (!Helpers.in_array('getregistrationdata', globals.dispatching)) {

			globals.dispatching.push('getregistrationdata');
			var non_existing_keys = StoreMethods.get_not_exists(items);

			if (non_existing_keys.length > 0) {
				var orig_success  = ajax_setup.success;
				var orig_fail     = ajax_setup.fail;
				var orig_error    = ajax_setup.error;
				var orig_complete = ajax_setup.complete;
				ajax_setup = {...ajax_setup, ...{
					url    : 'getregistrationdata',
					is_csrf: true,
					data   : { company_id: process.env.REACT_APP_COMPANY_ID },
					success: (response) => {
								StoreMethods.update_brokers({ 
									error  : response.error, 
									content: response.data.brokers
								});

								StoreMethods.update_countryCodes({ 
									error  :response.error, 
									content:response.data.country_codes
								});

								StoreMethods.update_accountTypes({ 
									error  :response.error, 
									content:response.data.account_type
								});

								if (Helpers.is_function(orig_success)) {
									orig_success.apply(this,arguments);
								}
							},
					fail    : (response) => {
								StoreMethods.update_brokers(response);
								StoreMethods.update_countryCodes(response);
								StoreMethods.update_accountTypes(response);

								if (Helpers.is_function(orig_fail)) {
									orig_fail.apply(this,arguments);
								}
							},
					error    : (xhr, status) => {
								StoreMethods.update_brokers({ error: {code:status}, err_code: status  });
								StoreMethods.update_countryCodes({ error: {code:status}});
								StoreMethods.update_accountTypes({ error: {code:status}});
								
								if (Helpers.is_function(orig_error)) {
									orig_error.apply(this,arguments);
								}
							},
					complete: (response) => {
								Helpers.remove(globals.dispatching, (value) => (value === 'getregistrationdata'));

								if (Helpers.is_function(orig_complete)) {
									orig_complete.apply(this,arguments);
								}
							}
				}};

				AjaxCore.request(ajax_setup, { request_key:'getregistrationdata' });
			}
		}
	},
	
	/**
	 * This will get recaptcha public key
	 * @param  string recaptcha_for
	 * @return string
	 */
	recaptcha_public_key(recaptcha_for) {
        switch(recaptcha_for) {
            default: return process.env.REACT_APP_CAPTCHAKEY
        }
	},

	/**
	 * This will register user
	 * @param  object inputs     
	 * @param  object ajax_setup 
	 * @return void
	 */
	register(inputs, ajax_setup) {
		ajax_setup = ajax_setup || {};
		inputs     = inputs     || {}; 

		inputs.public_key = callables.recaptcha_public_key('register');

		ajax_setup = {...ajax_setup, ...{
			url    : 'register',
			is_csrf: true,
			data   : inputs
		}};

		// console.log(inputs ,'inputs')

		AjaxCore.request(ajax_setup, { request_key:'register' });
	},

	/**
	 * Login user
	 * @param  object inputs     
	 * @param  object ajax_setup 
	 * @return void
	 */
	login(inputs, ajax_setup) {
		ajax_setup = ajax_setup || {};
		inputs     = inputs     || {};

		inputs.public_key = callables.recaptcha_public_key('login');
		var orig_success = ajax_setup.success;
		ajax_setup = {...ajax_setup, ...{
			url    : 'login',
			is_csrf: true,
			data   : inputs,
			success: (response) => {
            			Helpers.set_cookie(process.env.REACT_APP_CUSTOMERID_NAME, response.data.customer_id);
						globals.model_storage.setItem('login', 1);

						if (Helpers.is_function(orig_success)) {
							orig_success(response);
						}
					}
		}};

		AjaxCore.request(ajax_setup, { request_key:'login' });
	},

	/**
	 * This will update user session after login
	 * @param  string customerID  
	 * @param  object ajax_setup
	 * @return void
	 */
	update_user_session(customerID, ajax_setup) {
		ajax_setup = ajax_setup || {};
		customerID = customerID || callables.get_customerID();

		var orig_success = ajax_setup.success;

		ajax_setup = {...ajax_setup, ...{
			url    : 'getcustomerbasicinfo',
			is_csrf: true,
			data   : { customer_id: customerID },
			success: (response) => {
						StoreMethods.update_user_details(response.data);
						StoreMethods.update_auth(true);

						if (Helpers.is_function(orig_success)) {
							orig_success(response);
						}
					},
			error  : callables.logout_frontend,
			fail   : callables.logout_frontend
		}};

		AjaxCore.request(ajax_setup, { request_key:'getcustomerbasicinfo', block: {} });
	},

	/**
	 * This will get user full info
	 * @param  object  ajax_setup 
	 * @return void
	 */
	get_user_full(ajax_setup) {
		ajax_setup = ajax_setup || {};

		if (!Helpers.in_array('getcustomerfullinfo',globals.dispatching) && !StoreMethods.is_user_full()) {
			globals.dispatching.push('getcustomerfullinfo');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcustomerfullinfo',
				data    : { customer_id: callables.get_customerID() },
				is_csrf : true,
				success : (response) =>  {	
							StoreMethods.update_user_details(response.data);

							if (Helpers.is_function(orig_success)) {
								orig_success.apply(this,arguments);
							}
						},
				fail    : (response) => {
							StoreMethods.update_user_error({ error: response.error });

							if (Helpers.is_function(orig_fail)) {
								orig_fail.apply(this,arguments);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_user_error({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error.apply(this,arguments);
							}
						},
				complete: (response) => {
							Helpers.remove(globals.dispatching, (value) => (value === 'getcustomerfullinfo'));
							StoreMethods.update_user_full(true);

							if (Helpers.is_function(orig_complete)) {
								orig_complete.apply(this,arguments);
							}
						}
			}};

			AjaxCore.request(ajax_setup, { request_key:'getcustomerfullinfo' });

		}

	},

	/**
	 * Logout user
	 * @param  object ajax_setup
	 * @return void
	 */
	logout(ajax_setup) {
		ajax_setup = ajax_setup || {};

		var original_success = ajax_setup.success;
		ajax_setup = {...ajax_setup, ...{
			url     : 'logout',
			is_csrf : true,
			data    : { customer_id: callables.get_customerID() },
			success : (response) => {
						callables.logout_frontend();

						if (Helpers.is_function(original_success)) {
							original_success(response);
						}
					}
		}};

		AjaxCore.request(ajax_setup, { request_key:'logout' });
	},

	/**
	 * This will logout frontend, delete cookies and reset store
	 * @return void
	 */
	logout_frontend() {
		Helpers.delete_cookie(process.env.REACT_APP_CUSTOMERID_NAME);
		Helpers.delete_cookie(process.env.REACT_APP_TOKEN_NAME);
		//AjaxCore.reset_token();
		//StoreMethods.clear_user();
		//StoreMethods.clear_step();
		globals.model_storage.setItem('login', 0);
		globals.model_storage.setItem('isNewLogin', 0, (value) => {
			globals.model_storage.removeItem('isNewLogin');
		});
	},

	/**
	 * Get current cuestomer ID from cookie
	 * @return string
	 */
	get_customerID() {
		return Helpers.get_cookie(process.env.REACT_APP_CUSTOMERID_NAME);
	},

	/**
	 * Get necessary data for step 1 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step1(items, ajax_setup) {
		
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		
		if (Helpers.in_array('Company', non_existing_keys)) {
			callables.get_company_details(items);
		}

		if (Helpers.in_array('Step1', non_existing_keys) && !Helpers.in_array('step1', globals.dispatching)) {
			globals.dispatching.push('step1');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			function step1_getter(user_type) {
				let api = null;
				
				if (user_type.toLowerCase() === 'bilateral') {
					api = 'getcompanybilateralcontent';
				} else {
					api = 'getcompanymultilateralcontent';
				}
				
				ajax_setup = {...ajax_setup, ...{
					url     : api,
					data    : { company_id: process.env.REACT_APP_COMPANY_ID,customer_id: callables.get_customerID() },
					success : (response) => {
								StoreMethods.update_Step1({ 
									error  : response.error, 
									content: response.data
								});

								if (Helpers.is_function(orig_success)) {
									orig_success.apply(this,arguments);
								}
							},
					fail    : (response) => {
								StoreMethods.update_Step1(response);

								if (Helpers.is_function(orig_fail)) {
									orig_fail.apply(this,arguments);
								}
							},
					error    : (xhr, status) => {
								StoreMethods.update_Step1({ error: {code:status}});
								
								if (Helpers.is_function(orig_error)) {
									orig_error.apply(this,arguments);
								}
							},
					complete: (response) => {
								Helpers.remove(globals.dispatching, (value) => (value === 'Step1'));

								if (Helpers.is_function(orig_complete)) {
									orig_complete.apply(this,arguments);
								}
							},
				}};

				AjaxCore.request(ajax_setup, { request_key:api });
			}

			var user = StoreMethods.get_user();
			if (user.auth) {

				if (Helpers.is_empty(user.details)) {
					callables.update_user_session(callables.get_customerID(), {
						success: (response) => {
									step1_getter(response.data.account_type);
								}
					});

				} else {
					step1_getter(user.details.account_type);
				}

			}

		}
	},

	/**
	 * Get necessary data for step 3 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step2(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('User', non_existing_keys)) {
			callables.update_user_session();
		}

		if (Helpers.in_array('Company', non_existing_keys)) {
			callables.get_company_details(items);
		}
		
	},

	/**
	 * Get necessary data for step 3 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step3(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);

		// country codes
		if (Helpers.in_array('CountryCodes', non_existing_keys)) {
			callables.get_country_codes(ajax_setup);
		}


		// country codes
		callables.get_user_full(ajax_setup);

		
		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}
	},

	/**
	 * Get necessary data for step 4 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step4(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		
		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}

		if (Helpers.in_array('Step4', non_existing_keys) && !Helpers.in_array('step4', globals.dispatching)) {
			globals.dispatching.push('step4');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;
			// var customer      = StoreMethods.get_user();

			function step4_getter(user_type) {
				let api = null;

				if (user_type.toLowerCase() === 'bilateral') {
					api = 'getrisknotificationcontent';
				} else {
					api = 'multilateral/getrisknotificationcontent';
				}

				ajax_setup = {...ajax_setup, ...{
					url     : api,
					data    : { company_id: process.env.REACT_APP_COMPANY_ID,customer_id: callables.get_customerID() },
					success : (response) => {
								StoreMethods.update_Step4({ 
									error  : response.error, 
									content: response.data
								});

								if (Helpers.is_function(orig_success)) {
									orig_success.apply(this,arguments);
								}
							},
					fail    : (response) => {
								StoreMethods.update_Step4(response);

								if (Helpers.is_function(orig_fail)) {
									orig_fail.apply(this,arguments);
								}
							},
					error    : (xhr, status) => {
								StoreMethods.update_Step4({ error: {code:status}});
								
								if (Helpers.is_function(orig_error)) {
									orig_error.apply(this,arguments);
								}
							},
					complete: (response) => {
								Helpers.remove(globals.dispatching, (value) => (value === 'Step4'));

								if (Helpers.is_function(orig_complete)) {
									orig_complete.apply(this,arguments);
								}
							},
				}};

				AjaxCore.request(ajax_setup, { request_key:'getrisknotificationcontent' });
			}

			var user = StoreMethods.get_user();
			if (user.auth) {

				if (Helpers.is_empty(user.details)) {
					callables.update_user_session(callables.get_customerID(), {
						success: (response) => {
									step4_getter(response.data.account_type);
								}
					});

				} else {
					step4_getter(user.details.account_type);
				}

			}
		}
	},

	/**
	 * Get necessary data for step 5 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step5(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		
		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}

		if (Helpers.in_array('Step5', non_existing_keys) && !Helpers.in_array('Step5', globals.dispatching)) {
			globals.dispatching.push('Step5');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			function step5_getter(user_type) {
				let api = null;

				if (user_type.toLowerCase() === 'bilateral') {
					api = 'getgrantagreementcontent';
				} else {
					api = 'multilateral/getgrantagreementcontent';
				}

				ajax_setup = {...ajax_setup, ...{
					url     : api,
					data    : { company_id: process.env.REACT_APP_COMPANY_ID,customer_id: callables.get_customerID() },
					success : (response) => {
								StoreMethods.update_Step5({ 
									error  : response.error, 
									content: response.data
								});

								if (Helpers.is_function(orig_success)) {
									orig_success(response);
								}
							},
					fail    : (response) => {
								StoreMethods.update_Step5(response);

								if (Helpers.is_function(orig_fail)) {
									orig_fail(response);
								}
							},
					error    : (xhr, status) => {
								StoreMethods.update_Step5({ error: {code:status}});
								
								if (Helpers.is_function(orig_error)) {
									orig_error(xhr, status);
								}
							},
					complete: (response) => {
								Helpers.remove(globals.dispatching, (value) => (value === 'Step5'));

								if (Helpers.is_function(orig_complete)) {
									orig_complete(response);
								}
							},
				}};

				AjaxCore.request(ajax_setup, { request_key:'getgrantagreementcontent' });
			}

			var user = StoreMethods.get_user();
			if (user.auth) {

				if (Helpers.is_empty(user.details)) {
					callables.update_user_session(callables.get_customerID(), {
						success: (response) => {
									step5_getter(response.data.account_type);
								}
					});

				} else {
					step5_getter(user.details.account_type);
				}

			}

		}
	},

	/**
	 * Get necessary data for step 6 page
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_step6(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		
		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}

		if (Helpers.in_array('Step6', non_existing_keys) && !Helpers.in_array('Step6', globals.dispatching)) {
			globals.dispatching.push('Step6');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			function step6_getter(user_type) {
				let api = null;

				if (user_type.toLowerCase() === 'bilateral') {
					api = 'gettraderegulationcontent';
				} else {
					api = 'multilateral/gettraderegulationcontent';
				}

				ajax_setup = {...ajax_setup, ...{
					url     : api,
					data    : { company_id: process.env.REACT_APP_COMPANY_ID,customer_id: callables.get_customerID() },
					success : (response) => {
								StoreMethods.update_Step6({ 
									error  : response.error, 
									content: response.data
								});

								if (Helpers.is_function(orig_success)) {
									orig_success.apply(this,arguments);
								}
							},
					fail    : (response) => {
								StoreMethods.update_Step6(response);

								if (Helpers.is_function(orig_fail)) {
									orig_fail.apply(this,arguments);
								}
							},
					error    : (xhr, status) => {
								StoreMethods.update_Step6({ error: {code:status}});
								
								if (Helpers.is_function(orig_error)) {
									orig_error.apply(this,arguments);
								}
							},
					complete: (response) => {
								Helpers.remove(globals.dispatching, (value) => (value === 'Step6'));

								if (Helpers.is_function(orig_complete)) {
									orig_complete.apply(this,arguments);
								}
							},
				}};

				AjaxCore.request(ajax_setup, { request_key:'gettraderegulationcontent' });
			}

			var user = StoreMethods.get_user();
			if (user.auth) {

				if (Helpers.is_empty(user.details)) {
					callables.update_user_session(callables.get_customerID(), {
						success: (response) => {
									step6_getter(response.data.account_type);
								}
					});

				} else {
					step6_getter(user.details.account_type);
				}

			}
		}
	},

	/**
	 * This will request file content via ajax
	 * @param  string file_path 
	 * @param  object ajax_setup 
	 * @return void
	 */
	request_file(file_path, ajax_setup) {
		ajax_setup=ajax_setup || {};
		AjaxCore.request({...ajax_setup, ...{
			baseURL : process.env.REACT_APP_DOCUMENT_RSO,
			url     : file_path,
			method  : 'get'
		}}, { request_key:file_path });
	},

	/**
	 * This will get company ads for registration page
	 * @param  array  items
	 * @param  string ajax_setup 
	 * @return void
	 */
	get_company_ads(items,ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('CompanyAds', non_existing_keys)&&!Helpers.in_array('getcompanyads',globals.dispatching)) {
			globals.dispatching.push('getcompanyads');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcompanyads',
				data    : { company_id: process.env.REACT_APP_COMPANY_ID },
				success : (response) => {
							StoreMethods.update_company_ads({ 
								error  : response.error, 
								content: response.data
							});

							if (Helpers.is_function(orig_success)) {
								orig_success.apply(this,arguments);
							}
						},
				fail    : (response) => {
							StoreMethods.update_company_ads(response);

							if (Helpers.is_function(orig_fail)) {
								orig_fail.apply(this,arguments);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_company_ads({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error.apply(this,arguments);
							}
						},
				complete: (response) => {
							Helpers.remove(globals.dispatching, (value) => (value === 'getcompanyads'));

							if (Helpers.is_function(orig_complete)) {
								orig_complete.apply(this,arguments);
							}
						},
			}};

			AjaxCore.request(ajax_setup, { request_key:'getcompanyads' });

		}
	},

	/**
	 * This will get company details
	 * @param  array  items
	 * @param  string ajax_setup 
	 * @return void
	 */
	get_company_details(items,ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		if (Helpers.in_array('Company', non_existing_keys)) {
			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcompanydetails',
				data    : { company_id: process.env.REACT_APP_COMPANY_ID },
				success : (response) => {
							StoreMethods.update_company({ 
								error  : response.error, 
								content: response.data
							});

							if (Helpers.is_function(orig_success)) {
								orig_success.apply(this,arguments);
							}
						},
				fail    : (response) => {
							StoreMethods.update_company(response);

							if (Helpers.is_function(orig_fail)) {
								orig_fail.apply(this,arguments);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_company({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error.apply(this,arguments);
							}
						}
			}};

			AjaxCore.request(ajax_setup, { request_key:'getcompanydetails' });

		}
	},

	/**
	 * This will get data for home page
	 * @param  array items 
	 * @return void
	 */
	get_home_data(items) {
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('User', non_existing_keys)) {
			callables.update_user_session();
		}

		if (Helpers.in_array('Company', non_existing_keys)) {
			callables.get_company_details(items);
		}
		
		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}
	},

	/**
	 * This will get data for realaccoutn page
	 * @param  array items 
	 * @return void
	 */
	get_realaccount_data(items) {
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('User', non_existing_keys)) {
			callables.update_user_session();
		}

		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress();
		}
	},

	/**
	 * This will get latest customer progress
	 * @param  object  ajax_setup 
	 * @param  boolean is_for_redirection
	 * @return void
	 */
	get_customer_progress(ajax_setup, is_for_redirection) {
		//console.log('get_customer_progress')
		ajax_setup = ajax_setup || {};

		if (!Helpers.in_array('getcustomerprogress',globals.dispatching)) {
			globals.dispatching.push('getcustomerprogress');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcustomerprogress',
				is_csrf : true,
				data    : { customer_id: callables.get_customerID() },
				success : async (response) => {
					//console.log('get_customer_progress success',response)
							if (!is_for_redirection) {

								let previous_progress = response.data.previous_progress;
								let current_progress  = response.data.current_progress;
								let progress_name     = current_progress[0];
								let progress_date     = new Date(current_progress[1]).toLocaleDateString();
								let current_date      = new Date().toLocaleDateString();
								let is_reregister     = Helpers.in_array('Verified', previous_progress);
								let regol_sucessfull_date = response.data.regol_sucessfull_date ?  Moment(new Date(response.data.regol_sucessfull_date)).format('DD-MM-YYYY') : '';
								let regol_sucessfull_date_utc = response.data.regol_sucessfull_date ?  response.data.regol_sucessfull_date : '';

								if (progress_date !== current_date || is_reregister) {

									let isNewLogin = await globals.model_storage.getItem('isNewLogin');

									if (!isNewLogin) {
										progress_name = callables.number_to_progressName(1);
									}

								}

								StoreMethods.set_step_progress({
									error            : response.error,
									progressName     : progress_name,
									previous_progress: previous_progress,
									regol_sucessfull_date: regol_sucessfull_date,
									regol_sucessfull_date_utc:regol_sucessfull_date_utc
								});
								
							}

							if (Helpers.is_function(orig_success)) {
								orig_success(response);
							}
						},
				fail    : (response) => {

							if (!is_for_redirection) {
								StoreMethods.set_step_progress(response);
							}

							if (Helpers.is_function(orig_fail)) {
								orig_fail(response);
							}
						},
				error   : (xhr, status) => {
							if (!is_for_redirection) {
								StoreMethods.set_step_progress({ error: {code:status} });
							}

							if (Helpers.is_function(orig_error)) {
								orig_error(xhr, status);
							}
						},
				complete: (response) => {
							Helpers.remove(globals.dispatching, (value) => (value === 'getcustomerprogress'));

							if (Helpers.is_function(orig_complete)) {
								orig_complete(response);
							}
						}
			}};

			AjaxCore.request(ajax_setup, { request_key:'getcustomerprogress' });
		}
	},
	
	/**
	 * This will update progress by progressName
	 * @param  object   ajax_setup      
	 * @return void         
	 */
	update_next_progressName(ajax_setup) {
		ajax_setup = ajax_setup || {};

		var original_success           = ajax_setup.success;
		var current_activeProgressName = StoreMethods.get_activeProgressName();
		var next_progressName          = callables.next_progressName(current_activeProgressName);

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerprogress',
			is_csrf : true,
			success : function(response) {

						globals.model_storage.getItem('isNewLogin', (err, value) => {
							if(value === 0) {
								globals.model_storage.setItem('isNewLogin', 1);
							}
						});

						callables.on_progressName_updated();

						if (Helpers.is_function(original_success)) {
							original_success(response);
						}
					},
			data    : { customer_id: callables.get_customerID(), step: next_progressName  }
		}};

		AjaxCore.request(ajax_setup, { request_key:'updatecustomerprogress' });
	},

	/**
	 * special for Customer 
	 * This will update progress by progressName
	 * @param  object   ajax_setup      
	 * @return void         
	 */
	 customer_data_update(ajax_setup) {
		ajax_setup = ajax_setup || {};

		var original_success           = ajax_setup.success;
		//var current_activeProgressName = StoreMethods.get_activeProgressName();
		var next_progressName          = 'Customer Data Updated'//callables.next_progressName(current_activeProgressName);

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerprogress',
			is_csrf : true,
			success : function(response) {

						globals.model_storage.getItem('isNewLogin', (err, value) => {
							if(value === 0) {
								globals.model_storage.setItem('isNewLogin', 1);
							}
						});
						//callables.on_customer_data_updated();

						if (Helpers.is_function(original_success)) {
							original_success(response);
						}
					},
			data    : { customer_id: callables.get_customerID(), step: next_progressName  }
		}};

		AjaxCore.request(ajax_setup, { request_key:'updatecustomerprogress' });
	},
	
	/**
	 * special for Customer CUD
	 *         
	 */
	 closeCUD() {
		LocalForage.setItem('CUD',false)
		window.location.reload(true)
	},

	/**
	 * special for Customer CUD
	 *         
	 */
	 if_regol_successfull(arr) {
		var done = false;
		var array = arr
		//console.log(this.props,temp,'step1')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
		}
		return done	
	},

	/**	
	 * This will update customer progress to next
	 * @param  void   ajax_setup   
	 * @return void
	 */
	submit_customer_registration(ajax_setup) {
		ajax_setup = ajax_setup || {};

		var original_success = ajax_setup.success;

		ajax_setup = {...ajax_setup, ...{
			url     : 'submitcustomerregistration',
			is_csrf : true,
			success : function(response) {
						callables.on_progressName_updated();

						if (Helpers.is_function(original_success)) {
							original_success(response);
						}
					},
			data    : { customer_id: callables.get_customerID() }
		}};

		AjaxCore.request(ajax_setup, { request_key:'submitcustomerregistration' });
	},

	/**
	 * This will update store to next progressName
	 * @return void
	 */
	on_progressName_updated() {

		var current_activeProgressName = StoreMethods.get_activeProgressName();
		var next_progressName          = callables.next_progressName(current_activeProgressName);

		StoreMethods.update_progressName(next_progressName);
		StoreMethods.update_activeProgressName(next_progressName);
		StoreMethods.add_previous_progress(next_progressName);
		
		var progress_number = callables.progressName_to_number(current_activeProgressName);
		if (callables.is_progressName_last(current_activeProgressName)) {
			StoreMethods.step_latest(progress_number+1);
		} else {
			StoreMethods.step_enabled_change(progress_number);
		}
	},

	/**	
	 * This will set store to previous progressName base on step map
	 * @return void
	 */
	update_previous_progressName() {
		var current_activeProgressName = StoreMethods.get_activeProgressName();
		var progressName_index = globals.step_map.findIndex((value)=>(value.progressName === current_activeProgressName));
		var current_progress   = globals.step_map[progressName_index];
		var previous_progress  = globals.step_map[progressName_index-1];
		
		if (current_progress.number !== previous_progress.number) {

			var first_progressName = callables.number_to_progressName(previous_progress.number);
			StoreMethods.update_activeProgressName(first_progressName);
			StoreMethods.step_change(previous_progress.number);

		} else {

			StoreMethods.update_activeProgressName(previous_progress.progressName);

		}
	},

	/**
	 * This will update activeProgressName, if current activeProgressName is not same with the given 
	 * @param  int number
	 * @return void
	 */
	number_to_activeProgressName(number) {
		var current_activeProgressName = StoreMethods.get_activeProgressName();
		if(current_activeProgressName === 'Customer Data Updated'){
			current_activeProgressName = 'Regol Successful'
		}
		var activeProgressName_number  = globals.step_map.find((value) => 
											(value.progressName === current_activeProgressName)
										).number;

		if (number !== activeProgressName_number) {
			var new_activeProgressName = callables.number_to_progressName(number);
			StoreMethods.update_activeProgressName(new_activeProgressName);
		}
	},

	/**
	 * This will check if progressName is last
	 * @param  string  progressName
	 * @return void
	 */
	is_progressName_last(progressName) {
		var progress_number    = callables.progressName_to_number(progressName);
		var steps              = globals.step_map.filter((value)=>(value.number === progress_number));
		var steps_length       = steps.length;
		var progressName_index = steps.findIndex((value)=>(value.progressName === progressName));
		return (steps_length-1) === progressName_index;
	},

	/**
	 * This will get the next progressName base on latest one from the step_map array
	 * @param  string current_progressName 
	 * @return string
	 */
	next_progressName(current_progressName) {
		var progressName_index = globals.step_map.findIndex((value)=>(value.progressName === current_progressName));
		return globals.step_map[progressName_index+1].progressName;
	},

	/**
	 * convert progressName to number
	 * @param  string progressName
	 * @return void
	 */
	progressName_to_number(progressName) {
		if(progressName === 'Customer Data Updated'){
			progressName = 'Regol Successful'
		}
		return Helpers.find(globals.step_map,(progress) => ( progress.progressName ===  progressName)).number;
	},

	/**
	 * convert number to progressName
	 * @param  string number
	 * @return void
	 */
	number_to_progressName(number) {
		return Helpers.find(globals.step_map,(progress) => ( progress.number ===  number)).progressName;
	},

	/**	
	 * This will update customer basic info
	 * @param  object inputs 
	 * @param  void   ajax_setup   
	 * @return void
	 */
	update_customer_basic(inputs, ajax_setup) {
		ajax_setup         = ajax_setup || {};
		inputs             = inputs || {};
		inputs.customer_id = callables.get_customerID();
		var orig_success   = ajax_setup.success;

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerbasicinfo',
			is_csrf : true,
			data    : inputs,
			success : () =>  {	
				
						delete inputs.customer_id;
						delete inputs.accept_statement;

						StoreMethods.update_user_details(inputs);

						if (Helpers.is_function(orig_success)) {
							orig_success.apply(this,arguments);
						}
					}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'updatecustomerbasicinfo' });
	},

	/**	
	 * This will update customer investment form details
	 * @param  object inputs 
	 * @param  void   ajax_setup   
	 * @return void
	 */
	update_customer_trading(inputs, ajax_setup) {
		ajax_setup         = ajax_setup || {};
		inputs             = inputs || {};
		inputs.customer_id = callables.get_customerID();
		var orig_success   = ajax_setup.success;

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomertradingdetails',
			is_csrf : true,
			data    : inputs,
			success : () =>  {	
						delete inputs.accept_statement;

						StoreMethods.update_user_details(inputs);

						if (Helpers.is_function(orig_success)) {
							orig_success.apply(this,arguments);
						}
					}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'updatecustomertradingdetails' });
	},

	/**
	 * This will get data for user basic info forms
	 * @param  array items 
	 * @return void
	 */
	get_customer_basic(items) {
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('User', non_existing_keys)) {
			callables.update_user_session();
		}
	},

	/**
	 * This will get available country codes from API
	 * @param  object ajax_setup
	 * @return void
	 */
	get_country_codes(ajax_setup) {
		ajax_setup = ajax_setup || {};

		if (!Helpers.in_array('getcountrycodes',globals.dispatching)) {
			globals.dispatching.push('getcountrycodes');

			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcountrycodes',
				success : (response) =>  {	

							StoreMethods.update_countryCodes({ 
								error  :response.error, 
								content:response.data.country_codes
							});

							if (Helpers.is_function(orig_success)) {
								orig_success.apply(this,arguments);
							}
						},
				fail    : (response) => {
							StoreMethods.update_countryCodes(response);

							if (Helpers.is_function(orig_fail)) {
								orig_fail.apply(this,arguments);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_countryCodes({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error.apply(this,arguments);
							}
						},
				complete: (response) => {
							Helpers.remove(globals.dispatching, (value) => (value === 'getcountrycodes'));

							if (Helpers.is_function(orig_complete)) {
								orig_complete.apply(this,arguments);
							}
						}
			}};

			AjaxCore.request(ajax_setup, { request_key:'getcountrycodes' });

		}
	},

	/**	
	 * This will update customer full info
	 * @param  object inputs 
	 * @param  void   ajax_setup   
	 * @return void
	 */
	update_customer_full(inputs, ajax_setup) {
		ajax_setup         = ajax_setup || {};
		inputs             = inputs || {};
		inputs.customer_id = callables.get_customerID();
		var orig_success   = ajax_setup.success;


		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerfullinfo',
			is_csrf : true,
			data    : inputs,
			success : () =>  {	
				
						delete inputs.customer_id;
						delete inputs.accept_statement;

						StoreMethods.update_user_details(inputs);

						if (Helpers.is_function(orig_success)) {
							orig_success.apply(this,arguments);
						}
					}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'updatecustomerfullinfo' });
	},

	/**
	 * This will update customer photo when rotation changed
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object rotation
	 * @param  object ajax_setup
	 * @return void
	 */
	rotate_customer_photo(rotation, ajax_setup) {
		ajax_setup = ajax_setup || {};

		var inputs = {};
		inputs.customer_id = callables.get_customerID();			
		inputs.rotation    = rotation * -1;

		ajax_setup = {...ajax_setup, ...{
			url: 'rotatecustomerphoto',
			is_csrf: true,
			data: inputs,
		}};

		AjaxCore.request(ajax_setup, { request_key:'rotatecustomerphoto' });
	},

	/**
	 * This will update customer valid ID when rotation changed
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object rotation
	 * @param  object ajax_setup
	 * @return void
	 */
	rotate_customer_valid_id(rotation, ajax_setup) {
		var inputs = {};
		inputs.customer_id = callables.get_customerID();
		inputs.rotation    = rotation * -1;
		
		ajax_setup = {...ajax_setup, ...{
			url: 'rotatecustomervalidid',
			is_csrf: true,
			data: inputs,
		}};

		AjaxCore.request(ajax_setup, { request_key:'rotatecustomervalidid' });
	},

	/**
	 * This will update customer other documents when rotation changed
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object rotation  
	 * @param  object ajax_setup
	 * @return void
	 */
	rotate_customer_other_document(rotation, ajax_setup) {		
		var inputs = {};
		inputs.customer_id = callables.get_customerID();
		inputs.order 	   = ajax_setup.order;
		inputs.rotation    = rotation * -1;

		ajax_setup = {...ajax_setup, ...{
			url: 'rotatecustomerotherdocument',
			is_csrf: true,
			data: inputs,
		}};

		AjaxCore.request(ajax_setup, { request_key:'rotatecustomerotherdocument' });
	},

	/**
	 * This will upload customer 
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object file       
	 * @param  string name      
	 * @param  object ajax_setup
	 * @return void
	 */
	upload_customer_photo(file, name, rotation, ajax_setup) {
		ajax_setup = ajax_setup || {};

		var form_data = new FormData();
		form_data.append('image'      , file, name);
		form_data.append('customer_id', callables.get_customerID());
		form_data.append('rotation'	  , rotation * -1);
		
		ajax_setup = {...ajax_setup, ...{
			url     : 'uploadcustomerphoto',
			is_csrf : true,
			data    : form_data,
    		config  : { headers: {'Content-Type': 'multipart/form-data' }}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'uploadcustomerphoto' });
	},

	/**
	 * This will upload customer 
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object file       
	 * @param  string name      
	 * @param  object ajax_setup
	 * @return void
	 */
	upload_customer_id(file, name, rotation, ajax_setup) {
		ajax_setup = ajax_setup || {};

		var form_data = new FormData();
		form_data.append('image'      , file, name);
		form_data.append('customer_id', callables.get_customerID());
		form_data.append('rotation'   , rotation * -1);

		ajax_setup = {...ajax_setup, ...{
			url     : 'uploadcustomervalidid',
			is_csrf : true,
			data    : form_data,
    		config  : { headers: {'Content-Type': 'multipart/form-data' }}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'uploadcustomervalidid' });
	},

	/**
	 * This will upload customer 
	 * rotation set to negative for clockwise, because in API it's only counter-clockwise when positive
	 * @param  object file       
	 * @param  string name      
	 * @param  object ajax_setup
	 * @return void
	 */
	upload_customer_other(file, name, rotation, ajax_setup) {
		ajax_setup = ajax_setup || {};

		var form_data = new FormData();
		form_data.append('file'       , file, name);
		form_data.append('customer_id', callables.get_customerID());
		form_data.append('rotation'   , rotation * -1);
		form_data.append('order'      , ajax_setup.order);

		ajax_setup = {...ajax_setup, ...{
			url     : 'uploadcustomerotherdocument',
			is_csrf : true,
			data    : form_data,
    		config  : { headers: {'Content-Type': 'multipart/form-data' }}
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'uploadcustomerotherdocument' });
	}, 

	/**
	 * This will determine if customer progressName can still acess steps
	 * @param  string progressName 
	 * @return boolean
	 */
	can_edit_progressNames(progressName) {
		return !Helpers.in_array(progressName, globals.cant_edit_steps);
	},

	/**
	 * This will get data for user basic info forms
	 * @param  array items 
	 * @return void
	 */
	private_route_data(items) {
		var non_existing_keys = StoreMethods.get_not_exists(items);

		if (Helpers.in_array('User', non_existing_keys)) {
			callables.update_user_session();
		}

		if (Helpers.in_array('Step', non_existing_keys)) {
			callables.get_customer_progress(items);
		}
	},

	/**
	 * This get customer photo
	 * @param  object ajax_setup
	 * @return void
	 */
	get_customer_photo(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {
			...ajax_setup, ...{
				url: 'getcustomerphotostream',
				responseType: 'blob',
				is_csrf: true,
				data: { customer_id: callables.get_customerID() }
			}
		};

		AjaxCore.request(ajax_setup, { request_key: 'getcustomerphotostream' });
	},

	/**
	 * This get customer photo
	 * @param  object ajax_setup
	 * @return void
	 */
	get_customer_id(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {
			...ajax_setup, ...{
				url: 'getcustomervalididstream',
				responseType: 'blob',
				is_csrf: true,
				data: { customer_id: callables.get_customerID() }
			}
		};

		AjaxCore.request(ajax_setup, { request_key: 'getcustomervalididstream' });
	},

	/**
	 * This get customer photo
	 * @param  object ajax_setup
	 * @return void
	 */
	get_other_documents(ajax_setup) {
		ajax_setup = ajax_setup || {};
		var inputs = {};

		inputs.customer_id = callables.get_customerID();
		inputs.order 	   = ajax_setup.order;

		ajax_setup = {
			...ajax_setup, ...{
				url: 'getcustomerotherdocumentstream',
				responseType: 'blob',
				is_csrf: true,
				data: inputs
			}
		};

		AjaxCore.request(ajax_setup, { request_key: 'getcustomerotherdocumentstream' });
	},

	/**
	 * This will delete customer other docs
	 * @param  object ajax_setup
	 * @return void
	 */
	delete_customer_other(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'deletecustomerotherdocument',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), order: ajax_setup.order},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'deletecustomerotherdocument' });
	},

	/**	
	 * This will get data for wysiwyg com placeholder customer
	 * @param  array  items      
	 * @param  object ajax_setup 
	 * @return void
	 */
	get_customer_view(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getcustomerview',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), keys:items },
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getcustomerview' });
	},

	/**	
	 * This will get data for wysiwyg com placeholder broker
	 * @param  array  items      
	 * @param  object ajax_setup 
	 * @return void
	 */
	get_broker_view(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getbrokerview',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), keys:items },
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getbrokerview' });
	},

	/**	
	 * This will get data for wysiwyg com placeholder company
	 * @param  array  items      
	 * @param  object ajax_setup 
	 * @return void
	 */
	get_company_view(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getcompanyview',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), keys:items },
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getcompanyview' });
	},

	/**	
	 * This will get data for wysiwyg com placeholder branch
	 * @param  array  items      
	 * @param  object ajax_setup 
	 * @return void
	 */
	get_branch_view(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getbranchview',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), keys:items },
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getbranchview' });
	},

	/**	
	 * This will get data for wysiwyg com placeholder current
	 * @param  array  items      
	 * @param  object ajax_setup 
	 * @return void
	 */
	get_current_view(items, ajax_setup) {
		ajax_setup = ajax_setup || {};

		if (Helpers.is_function(ajax_setup.success)) {

			setTimeout(() => {
				var data       = {};
				var whole_date = Moment().tz(process.env.REACT_APP_TIMEZONE).locale('id');

				items.forEach((item) => {

					switch (item) {
						case 'day'  : data[item] = whole_date.format('dddd');  break;
						case 'date' : data[item] = whole_date.format('DD');  break;
						case 'month': data[item] = whole_date.format('MMMM'); break;
						case 'year' : data[item] = whole_date.format('YYYY');  break;
						// no default
					}

				});

				ajax_setup.success({ data });

			},0);

		}

	},

	/**
	 * Get list of conflict court and the selected
	 * @param  object items
	 * @param  object ajax_setup
	 * @return void
	 */
	get_customer_conflictcourts(items, ajax_setup, is_force_get) {
		ajax_setup = ajax_setup || {};


		var non_existing_keys = StoreMethods.get_not_exists(items);
		var orig_success   = ajax_setup.success;
		var orig_fail      = ajax_setup.fail;
		var orig_error     = ajax_setup.error;
		// var orig_complete  = ajax_setup.complete;

		if (Helpers.in_array('ConflictCourt', non_existing_keys) || is_force_get) {

			ajax_setup = {...ajax_setup, ...{
				url     : 'getcustomerconflictcourts',
				is_csrf : true,
				data    : {customer_id:callables.get_customerID()},
				success : (response) => {
							StoreMethods.update_conflictcourt({ 
								error  : response.error, 
								content: response.data.courts
							});

							if (Helpers.is_function(orig_success)) {
								orig_success(response);
							}
						},
				fail    : (response) => {
							StoreMethods.update_conflictcourt(response);

							if (Helpers.is_function(orig_fail)) {
								orig_fail(response);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_conflictcourt({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error(xhr, status);
							}
						}
			}};
			
			AjaxCore.request(ajax_setup, { request_key:'getcustomerconflictcourts', block: {}});

		} else {

			var conflict_court = StoreMethods.get_conflictcourt();
			if (conflict_court.error.code.toString() === '0' ) {

				if (Helpers.is_function(orig_success)) {
					orig_success({error:conflict_court.error, data:{ courts: conflict_court.content } });
				}

			} else {
				if (Helpers.is_function(orig_fail)) {
					orig_fail(conflict_court);
				}
			}

		}

	},

	/**
	 * Update conflict court of the customer
	 * @param  string court_name
	 * @param  object ajax_setup
	 * @return void
	 */
	update_customer_conflictcourts(court_id, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerconflictcourt',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), court_id: court_id},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'updatecustomerconflictcourt' });
	},

	/**
	 * Get list of broker conflict branches
	 * @param  object ajax_setup
	 * @return void
	 */
	get_customer_conflictbranch(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getcustomerconflictbranch',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID()},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getcustomerconflictbranch' });
	},

	/**
	 * Get list of company branches 
	 * @param  object ajax_setup
	 * @return void
	 */
	get_branches(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getbranches',
			data    : {company_id:process.env.REACT_APP_COMPANY_ID},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getbranches', block:{} });
	},

	/**
	 * Get list of brokers
	 * @param  object ajax_setup
	 * @return void
	 */
	get_brokers(ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'getbrokers',
			data    : {company_id:process.env.REACT_APP_COMPANY_ID},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'getbrokers', block:{} });
	},

	/**
	 * Update conflict branch of the customer
	 * @param  string branch_name
	 * @param  object ajax_setup
	 * @return void
	 */
	update_customer_conflictbranch(branch_id, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'updatecustomerconflictbranch',
			is_csrf : true,
			data    : {customer_id:callables.get_customerID(), branch_id: branch_id},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'updatecustomerconflictbranch' });
	},

	/**
	 * This will get the customer account status
	 * @param {string} code
	 * @param {object} ajax_setup 
	 */
	get_customer_account_status(code, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
				url    : 'getcustomeraccountstatus',
				method : 'post',
				is_csrf: true,
				params : { code: code },
			}
		};

		AjaxCore.request(ajax_setup, { request_key: 'getcustomeraccountstatus' });
	},

	/**
	 * Activates customer account
	 * @param  string code
	 * @param  object ajax_setup
	 * @return void
	 */
	activate_customer_account(code, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'activatecustomeraccount',
			method  : 'post',
			is_csrf : true,
			params  : {code:code},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'activatecustomeraccount' });
	},

	/**
	 * This will get the broker telegram status
	 * @param {string} code
	 * @param {object} ajax_setup 
	 */

	get_broker_telegram_status(code, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
				url    : '/getbrokertelegramstatus',
				method : 'post',
				is_csrf: true,
				params : { code: code },
			}
		};

		AjaxCore.request(ajax_setup, { request_key: '/getbrokertelegramstatus' });
	},

	/**
	 * Activates broker telegram account
	 * @param  string code
	 * @param  object ajax_setup
	 * @return void
	 */

	activate_telegram_account(code, ajax_setup) {
		ajax_setup = ajax_setup || {};

		ajax_setup = {...ajax_setup, ...{
			url     : 'activatetelegramaccount',
			method  : 'post',
			is_csrf : true,
			params  : {code:code},
		}};
		
		AjaxCore.request(ajax_setup, { request_key:'activatetelegramaccount' });
	},

	/**
	 * This will redirect user base on customer progress
	 * @return void
	 */
	redirect_by_progress(fail, error) {
		callables.get_customer_progress({
			success: (response) => {
				let current_progress  = response.data.current_progress;
				let previous_progress = response.data.previous_progress;
				let is_reregister     = Helpers.in_array('Verified', previous_progress);
				var progress_number   = callables.progressName_to_number(current_progress[0]);

				if ((progress_number && progress_number < 9) && !is_reregister) {
					window.location = 'realaccount';
				} else {
					globals.model_storage.setItem('isNewLogin', 0);
					window.location = '';
				}
			}, 
			fail : res => fail(res)   ,
			error:  res => error(res)
		}, true);
	},

	/**
	 * This will get step name base on index
	 * @param  string index
	 * @return string
	 */
	get_step_name(index) {
		return step_names[index];
	},

	/**
	 * Get necessary data for disclosure statement
	 * @param  array  items    
	 * @param  object ajax_setup
	 * @return void
	 */
	get_disclosure_statement(items, ajax_setup) {
		ajax_setup            = ajax_setup || {};
		var non_existing_keys = StoreMethods.get_not_exists(items);
		var is_in_store       = !Helpers.in_array('DisclosureStatement', non_existing_keys);
		var is_dispatching    = Helpers.in_array('DisclosureStatement', globals.dispatching);

		if (!is_in_store && !is_dispatching) {
			globals.dispatching.push('DisclosureStatement');
			var orig_success  = ajax_setup.success;
			var orig_fail     = ajax_setup.fail;
			var orig_error    = ajax_setup.error;
			var orig_complete = ajax_setup.complete;

			ajax_setup = {...ajax_setup, ...{
				url     : 'getdisclosurestatementcontent',
				data    : { company_id: process.env.REACT_APP_COMPANY_ID, customer_id: callables.get_customerID() },
				success : (response) => {
							StoreMethods.update_disclosure_statement({ 
								error  : response.error, 
								content: response.data
							});

							if (Helpers.is_function(orig_success)) {
								orig_success.apply(this,arguments);
							}
						},
				fail    : (response) => {
							StoreMethods.update_disclosure_statement(response);

							if (Helpers.is_function(orig_fail)) {
								orig_fail.apply(this,arguments);
							}
						},
				error    : (xhr, status) => {
							StoreMethods.update_disclosure_statement({ error: {code:status}});
							
							if (Helpers.is_function(orig_error)) {
								orig_error.apply(this,arguments);
							}
						},
				complete: (response) => {
							Helpers.remove(globals.dispatching, (value) => (value === 'DisclosureStatement'));

							if (Helpers.is_function(orig_complete)) {
								orig_complete.apply(this,arguments);
							}
						},
			}};

			AjaxCore.request(ajax_setup, { request_key:'getdisclosurestatementcontent' });
		}
	},

	/**
	 * Subscribed the login token.
	 * @param {callback} func 
	 * @param {object} ref 
	 */
	subscribe_login_changed(func, ref) {
		
		globals.subscription['login'].push({
			id      : Helpers.uniqid(),
			name    : 'login_changed',
			ref     : ref,
			function: func
		});

	},

	/**
	 * Unsubscribed the login token.
	 * @param {object} data
	 */
	unsubscribe_login_changed(data) {
		data = data || {};
		let subscription_info = callables.get_subscription_info(data);

		if(!Helpers.is_empty(subscription_info)) {
			globals.subscription[data.key] = globals.subscription[data.key].filter( (info, index) => {
												return info.id !== subscription_info[index].id;
											});
		}
	},

	/**
	 * Return subscription info from globals.subscription via key and name.
	 * param data = {key, name}
	 * @param {object} data
	 */
	get_subscription_info(data) {

		if(!Helpers.is_empty(data)) {
			let subscription_info = globals.subscription[data.key].filter( info => {
										return info['name'] === data.name;
									});
			return subscription_info;
		}

	},
};

export default callables;