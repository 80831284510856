const ActionTypes = {update_disclosure_statement:'update_disclosure_statement'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object disclosure_statement
	 * @return object
	 */
	update_disclosure_statement: function(disclosure_statement) {
		return { type: ActionTypes.update_disclosure_statement, disclosure_statement };
	}

};

export default callables;
export { ActionTypes };