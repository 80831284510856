import React                             from 'react';
import { Route, Redirect, Switch, Link } from 'react-router-dom';
import Login                             from 'app/pages/login/Login';
import Registration                      from 'app/pages/registration/Registration';
import ErrorPages                        from 'app/pages/errorpages/ErrorPages';
import Activation                        from 'app/pages/activation/Activation';
import Language                          from 'app/utils/language/Language';
import { callables as StoreMethods }     from 'app/store/Store';

function PublicRoutes(props) {
    const with_container = props.with_container;

    return  (
        props.User.auth ?
            <Redirect to='/'/>
        :
            (
                <Switch>
                    <Route  
                        path   ='/login'  
                        render = {(props) => with_container(<Login {...props}/>)} 
                    />
                    <Route  
                        exact 
                        path  = '/' 
                        render = {(props) => with_container(<Registration {...props}/>)} 
                    />

                    <Route
                        exact
                        path      = '/realaccount/:id(step1|step2|step3|step4|step5|step6|step7)?'
                        render    = {(props) => <Redirect to="/" />}
                    />

                    <Route exact path='/a/:code' render={(props) => <Activation {...props} />} />

                    <Route exact path='/ta/:code' render={(props) => <Activation {...props} />} />
                    
                    <Route component={ErrorPages.NotFound} />
                </Switch>
            )
    );

}

function PublicRouteLinks(props) {

    switch(props.type) {

        case 'registration':
            return  <Link to='/login'>{ Language.get('registration.login') }</Link>;

        case 'registration_popup':
            return  <Link to='/login'>{ Language.get('registration.login_button') }</Link>;

        case 'login':
            return  <Link to='/'>{ Language.get('login.go_to_register') }</Link>;

        default:
            return  '';

    }
}

function PublicRouteComponents(props) {
    switch(props.type) {
        default: return  '';
    }
}


export default StoreMethods.user_connect(PublicRoutes);
export {PublicRouteLinks, PublicRouteComponents};