import React from 'react';
import Form from 'app/components/form/Form';
import FormValidatorCommon from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language from 'app/utils/language/Language';

function Inputs(inputs, props, component) {
	return <React.Fragment>
		<h3 className='RealAccountStyles-form_category_title AppStyles-no_margin'>
			{Language.get('realaccount.emergency_contact', 'id')}
			<div className='RealAccountStyles-form_subtitle'>
				{Language.get('realaccount.emergency_contact', 'en')}
			</div>
		</h3>
		<Form.Row>
			<Form.Textbox
				label='name'
				className='AppStyles-full_size_input'
				ref={inputs.emergency_detail_full_name.ref}
				defaultValue={props.User.details.emergency_detail_full_name}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
		<Form.Row>
			<Form.TextArea
				label='short_address'
				className='AppStyles-half_size_input'
				ref={inputs.emergency_detail_address.ref}
				defaultValue={props.User.details.emergency_detail_address}
				onTouched={props.onTouched}
				required={1}
			/>
			<Form.Textbox
				label='postal_code'
				className='AppStyles-half_size_input'
				ref={inputs.emergency_detail_postal_code.ref}
				defaultValue={props.User.details.emergency_detail_postal_code}
				onTouched={props.onTouched}
			/>
		</Form.Row>
		<Form.Row>
			<div className='AppStyles-half_size_input'>
				<div className='RealAccountStyles-number_code'>
					<Form.Textbox
						label='short_phone_number'
						className='AppStyles-full_size_input'
						ref={inputs.emergency_detail_phone_area_code.ref}
						defaultValue={props.User.details.emergency_detail_phone_area_code}
						onTouched={props.onTouched}
						required={1}
						placeholder={component.area_code_placeholder}
					/>
				</div>
				<div className='RealAccountStyles-number'>
					<Form.Textbox
						label='blank'
						className='AppStyles-full_size_input'
						ref={inputs.emergency_detail_phone.ref}
						defaultValue={props.User.details.emergency_detail_phone}
						onTouched={props.onTouched}
						required={1}
						placeholder={component.contact_number_placeholder}
					/>
				</div>
			</div>
			<Form.Textbox
				label='relationship'
				ref={inputs.emergency_detail_relationship.ref}
				className='AppStyles-half_size_input'
				defaultValue={props.User.details.emergency_detail_relationship}
				onTouched={props.onTouched}
				required={1}
			/>
		</Form.Row>
	</React.Fragment>;

}

function RefValidations(props) {
	return {
		emergency_detail_full_name: { ref: React.createRef(), validations: FormValidatorCommon.full_name_with_limit },
		emergency_detail_address: { ref: React.createRef(), validations: FormValidatorCommon.home_address },
		emergency_detail_postal_code: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				prevent: true,
				validate: [{ as: 'no_special_characters' }]
			}
		},
		emergency_detail_phone_area_code: {
			ref: React.createRef(), validations:
			{
				triggers: ['onBlur'],
				prevent: true,
				validate: [{ as: 'required', exclude_triggers: ['prevent'] }, { as: 'is_number' },
				{
					as: 'max_length',
					max: 4
				},
				]
			}
		},
		emergency_detail_phone: { ref: React.createRef(), validations: FormValidatorCommon.required_number },
		emergency_detail_relationship: {
			ref: React.createRef(), validations: {
				triggers: ['onBlur'],
				validate: [{ as: 'required' }]
			}
		}
	};
};
// , 	[
// 	{
// 		as: 'max_length',
// 		max: 4
// 	},
// ]

export default { Inputs, RefValidations }