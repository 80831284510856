import React 			    from 'react';
import classnames           from 'classnames';
import Form                 from 'app/components/form/Form';
import FormValidatorCommon  from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language             from 'app/utils/language/Language';

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.personal_data','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.personal_data','en')}
		  			</div>
		  		</h3>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'full_name' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = {inputs.full_name.ref}
		  				defaultValue = {props.User.details.full_name}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'place_of_birth' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = {inputs.place_of_birth.ref}
		  				defaultValue = {props.User.details.place_of_birth}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  			<Form.DatePicker     
		  				label        = 'date_of_birth' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.date_of_birth.ref }
		  				defaultValue = { props.User.details.date_of_birth }
	      				onTouched    = {props.onTouched}
	      				onChange     = {component.check_age}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
			        <Form.Radio     
			            label        = 'gender' 
			            ref          = { inputs.gender.ref }
		  				defaultValue = { props.User.details.gender } 
			            className    = 'AppStyles-half_size_input'
						options      = {[
											{
												value      :'L', 
												description:<React.Fragment>
																{Language.get('realaccount.male','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.male','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'P', 
												description:<React.Fragment>
																{Language.get('realaccount.female','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.female','en')}
																</div>
															</React.Fragment>
											}
									 	]} 
	      				onTouched    = {props.onTouched}
	      				required     = {1}
			        />
			        <Form.Radio     
			            label        = 'nationality' 
			            ref          = { inputs.nationality.ref }
		  				defaultValue = { props.User.details.nationality } 
			            className    = 'AppStyles-half_size_input'
						options      = {[
											{
												value      :'WNI', 
												description:<React.Fragment>
																{Language.get('realaccount.indonesian','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.indonesian','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'WNA', 
												description:<React.Fragment>
																{Language.get('realaccount.foreigner','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.foreigner','en')}
																</div>
															</React.Fragment>
											}
									 	]} 
	      				onTouched    = {props.onTouched}
						onChange	 = {component.nationality_changed}
	      				required     = {1}
			        />
		  		</Form.Row>
				<Form.Row>
	    			<Form.idTypeNumber 
	    				inputs    = {inputs}
	    				id_type   = {{required:1,defaultValue:props.User.details.id_type  ,onTouched:props.onTouched}}
	    				id_number = {{required:1,defaultValue:props.User.details.id_number,onTouched:props.onTouched}}
	    			/>
		  		</Form.Row>
				<Form.Row>
			        <Form.Textbox     
			            label        = 'tax_number' 
			            ref          = { inputs.tax_number.ref }
		  				defaultValue = { props.User.details.tax_number } 
			            className    = 'AppStyles-full_size_input'
	      				onTouched    = {props.onTouched}
	      				required     = {props.User.details.is_tax_number_required}
			        />
		  		</Form.Row>
		  		<Form.Row>
			        <Form.Textbox     
			            label        = 'mother_maiden_name' 
			            ref          = { inputs.mother_maiden_name.ref }
		  				defaultValue = { props.User.details.mother_maiden_name } 
			            className    = 'AppStyles-full_size_input'
	      				onTouched    = {props.onTouched}
	      				required     = {1}
			        />
		  		</Form.Row>
				<Form.Row>
			        <Form.Radio     
			            label        = 'marital_status' 
			            ref          = { inputs.marital_status.ref }
		  				defaultValue = { props.User.details.marital_status } 
			            className    = 'AppStyles-half_size_input'
						options      = {[
											{
												value      :'Tidak Kawin', 
												description:<React.Fragment>
																{Language.get('realaccount.single','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.single','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Kawin', 
												description:<React.Fragment>
																{Language.get('realaccount.married','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.married','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Janda', 
												description:<React.Fragment>
																{Language.get('realaccount.widowed','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.widowed','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Duda', 
												description:<React.Fragment>
																{Language.get('realaccount.widower','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.widower','en')}
																</div>
															</React.Fragment>
											}
									 	]} 

		  				onChange     = {component.marital_status_changed}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
			        />
			        <Form.Textbox     
			            label        = 'spouse' 
			            ref          = { inputs.spouse_name.ref }
		  				defaultValue = { props.User.details.spouse_name } 
			            className    = {classnames([
			            					'AppStyles-half_size_input',
		  								])}
	      				onTouched    = {props.onTouched}
	      				required     = {component.show_marital_status()}
	      				disabled     = {!component.show_marital_status()}
			        />
		  		</Form.Row>
				<Form.Row>
		  			<Form.TextArea     
		  				label        = 'address' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.home_address.ref }
		  				defaultValue = { props.User.details.home_address }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  			<Form.Textbox     
		  				label        = 'postal_code' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = {inputs.home_postal_code.ref}
		  				defaultValue = {props.User.details.home_postal_code}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
			        <Form.Radio     
			            label        = 'home_ownership_status' 
			            ref          = { inputs.home_ownership_status.ref }
		  				defaultValue = {component.ownership_status_defaultValue()}
			            className    = 'AppStyles-full_size_input RealAccountStyles-radio_with_input'
						options      = {[
											{
												value      :'Pribadi', 
												description:<React.Fragment>
																{Language.get('realaccount.personal','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.personal','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Keluarga', 
												description:<React.Fragment>
																{Language.get('realaccount.family','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.family','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Sewa/Kontrak', 
												description:<React.Fragment>
																{Language.get('realaccount.rent','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.rent','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'Lainnya', 
												description:<Form.Textbox     
												  				className    = 'RealAccountStyles-radio_input'
	      														onTouched    = {props.onTouched}
	      														ref          = {inputs.other_ownership_status.ref}
		  														defaultValue = { 
		  																		component.show_other_ownership() ?
		  																		props.User.details.home_ownership_status:''
		  																	}
												  				beforeInput= {
											  								<span 
											  									className='RealAccountStyles-radio_text'
											  								> 
																				{Language.get(
																					'realaccount.others',
																					'id'
																				)}
																				<div className='AppStyles-label_secondary'>
																					{Language.get(
																						'realaccount.others',
																						'en'
																					)}
																				</div>
											  								</span>
											  							}
											  					disabled={!component.show_other_ownership()}
													        />
											}
									 	]} 

		  				onChange     = {component.ownership_status_changed}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
					/>
			    </Form.Row>
				<Form.Row>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.Textbox     
			      				label        = 'phone_number' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.personal_phone_area_code.ref }
			      				defaultValue = { props.User.details.personal_phone_area_code }
	      						onTouched    = {props.onTouched}
	      						onChange     = {() => {
	      											component.check_contact_group('personal_phone')
	      										}}
	      						placeholder  = {component.area_code_placeholder}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.Textbox     
			      				label        = 'blank' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.personal_phone.ref }
			      				defaultValue = { props.User.details.personal_phone }
	      						onTouched    = {props.onTouched}
	      						onChange     = {() => {
	      											component.check_contact_group('personal_phone')
	      										}}
	      						placeholder  = {component.contact_number_placeholder}
			      			/>
			      		</div>
					</div>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.Textbox     
			      				label        =  'fax_number' 
			      				className    =  'AppStyles-full_size_input'
			      				ref          =  { inputs.personal_fax_area_code.ref }
			      				defaultValue =  { props.User.details.personal_fax_area_code }
	      						onTouched    =  { props.onTouched}
	      						onChange     =  {() => {
	      											component.check_contact_group('personal_fax')
	      										}}
	      						placeholder  = {component.area_code_placeholder}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.Textbox     
			      				label        =  'blank' 
			      				className    =  'AppStyles-full_size_input'
			      				ref          =  { inputs.personal_fax.ref }
			      				defaultValue =  { props.User.details.personal_fax }
	      						onTouched    =  {props.onTouched}
	      						onChange     =  {() => {
	      											component.check_contact_group('personal_fax')
	      										}}
	      						placeholder  = {component.contact_number_placeholder}
			      			/>
			      		</div>
					</div>
		  		</Form.Row>
				<Form.Row>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.CountryCodeDropdown    
			      				ref          = {inputs.personal_country_code.ref} 
			            		className    = 'AppStyles-full_size_input'
			      				defaultValue = {props.User.details.personal_country_code}
	      						onTouched    = {props.onTouched}
	      						required     = {1}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.MobileNumberInput 
			      				label        = 'mobile_number' 
			      				ref          = { inputs.personal_mobile_number.ref } 
			      				className    = 'AppStyles-full_size_input'
			      				defaultValue = {props.User.details.personal_mobile_number}
	      						onTouched    = {props.onTouched}
	      						required     = {1}
			      			/>
		      			</div>
					</div>
			    </Form.Row>
		    </React.Fragment>;

}

function RefValidations(props,component) {
	var tax_number_stop = () => (!component.props.User.details.is_tax_number_fix);
	const id_type_ref   = React.createRef();
	const nationality   = React.createRef();

	return { 
		full_name             : { ref: React.createRef(),validations:FormValidatorCommon.full_name_with_limit },
		place_of_birth        : { ref: React.createRef(),validations:FormValidatorCommon.place_of_birth },
		date_of_birth         : { ref: React.createRef(),validations:{
									triggers: ['onChange'],
									validate: [
												{as: 'required', type:'date_of_birth'},
												{as: 'truthy', checker: component.birth_marital_valid, type:'age'}
											]
								}},
		id_type               : {  ref: id_type_ref     ,validations:FormValidatorCommon.id_type},
		id_number             : {  
									ref        : React.createRef(), 
									validations: FormValidatorCommon.id_number(id_type_ref)
								},
		nationality           : { ref: nationality,validations:{
									triggers: ['onBlur'],
									validate: [{ as: 'required'}] 
								}},
		tax_number            : {ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									prevent : true,
									validate: [
										{ 
											as  :'required', 
											type:'npwp',
											stop:() => (!component.props.User.details.is_tax_number_required) 
										},
										{
											as         : 'tax_number',
											nationality: () => (nationality.current.value())
										},
										{
											as         : 'tax_number_max',
											stop       : tax_number_stop,
											nationality: () => (nationality.current.value())
										},
										{
											as              : 'tax_number_min',
											stop_op         : tax_number_stop,
											nationality     : () => (nationality.current.value()),
											exclude_triggers: ['prevent']
										}
									] 
								}},
		gender                : { ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									validate: [{ as: 'required'}] 
								}},
		mother_maiden_name    : { ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									prevent : true,
									validate: [
										{ 
													as: 'required', 
													type:'mother_maiden_name',
													exclude_triggers: ['prevent']
												},
												{
													as: 'full_name'
												}
											] 
								}},
		marital_status          :{ ref: React.createRef(),validations:{
									triggers:['onBlur', 'onChange'],
									validate:[
												{as: 'required'}, 
												{as: 'truthy', checker: component.birth_marital_valid, type:'age'}
												
											]
								}},
		spouse_name             :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									prevent : true,
			    					validate: [{
													as              : 'requiredif',
													checker         : component.show_marital_status,
													exclude_triggers: ['prevent']
												},
												{
													as: 'full_name'
												}
											]
			    				}},
		home_address            :{ ref: React.createRef(),validations:FormValidatorCommon.home_address  },
		home_postal_code        :{ ref: React.createRef(),validations:FormValidatorCommon.postal_code },
		personal_phone_area_code:{ ref: React.createRef(),validations:FormValidatorCommon.optional_area_code(
									component.contact_grouped_required('personal_phone'),[
										{
											as: 'max_length',
											max: 4
										},
									]
								 )},
		personal_phone          :{ ref: React.createRef(),validations:FormValidatorCommon.optional_number(
									component.contact_grouped_required('personal_phone')
								 )},
		personal_fax_area_code  :{ 
									ref        : React.createRef(),
									validations: FormValidatorCommon.optional_area_code(
													component.contact_grouped_required('personal_fax'),[
														{
															as: 'max_length',
															max: 4
														},
													]
												)
								},
		personal_fax           : { 
									ref        : React.createRef(),
									validations: FormValidatorCommon.optional_number(
													component.contact_grouped_required('personal_fax')
												)
								},
		personal_country_code :{ ref: React.createRef(),validations:FormValidatorCommon.country_code},
		personal_mobile_number:{ ref: React.createRef(),validations:FormValidatorCommon.mobile_number},
		home_ownership_status :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
								}},
		other_ownership_status:{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{
			    									as:'requiredif',
			    									type:'nomessage',
			    									checker:component.show_other_ownership
			    							}]
			    				}}
	};
};

export default { Inputs, RefValidations }