import React	 		 from 'react';
import classnames        from 'classnames';
import TableStyles       from './scss/TableStyles.module.scss';

function Table(props) {
	return  <div className={TableStyles.table_container}>
				<table
					className = {classnames([
									TableStyles.table, 
									props.striped  && TableStyles.table_striped,
									props.bordered && TableStyles.table_bordered
								])}
				>
					{props.children}
				</table>
			</div>;
}

export default Table;