import React,{Component} from 'react';
import NpmScroll         from 'scroll';
import classnames        from 'classnames';
import CarouselStyles    from './scss/CarouselStyles.module.scss';
import Helpers           from 'app/utils/helpers/Helpers';
import FontIcons         from 'app/components/fonticons/FontIcons';

class Hqueue extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.id                    = Helpers.uniqid();
        this.slider                = React.createRef();
        this.scroller              = React.createRef();
        this.visible_exposed       = props.visible_exposed    || 75;   
        this.exposed_percentage    = props.exposed_percentage || 25;  
        this.select_exposed        = props.select_exposed     || 'auto';    
        this.visibility_delay      = props.visibility_delay   || false; 
        this.scroll_duration       = props.scroll_duration    || 200; 
        this.last_scroll_left      = null; 
        this.animated_scroll       = false; 
        this.scroll_instance       = null;
        this.initial_move          = false;
        this.initial_active        = props.active || 0;
        this.state                 = {
                                        dom_calculation     : {
                                                                scroller_scroll_area : 0,
                                                                scroller_offset_left : 0,
                                                                scroller_offset_right: 0,
                                                                snap_length          : 0,
                                                                snap_points          : []
                                                            },
                                        initialized         : false,
                                        is_overflow         : false,
                                        calculating_overflow: false,
                                        scrolling           : false,
                                        adjusting           : false,
                                        dragging            : false,
                                        has_arrow           : props.hasOwnProperty('has_arrow') ? props.has_arrow:true,
                                        active              : this.initial_active,
                                        visibility          : {}
                                    }
    }

    resize_event = () => {
        Helpers.event_delay(() => {
            this.initialize();
        }, 100, 'carousel_hq_resize'+this.id);
    }

    componentDidMount = () => {
        this.initialize();
        window.addEventListener('resize',this.resize_event,this.id);
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.resize_event,this.id);
    }

    render() {
        return  <div 
                    ref       = {this.slider} 
                    className = {classnames([
                                    CarouselStyles.hqueue, 
                                    this.state.is_overflow && CarouselStyles.is_overflow, 
                                    this.state.has_arrow   && CarouselStyles.has_arrow
                                ])}
                > 
                    {
                        this.state.has_arrow && this.state.is_overflow && 
                        <div className={CarouselStyles.left_arrow} onClick={this.previous}>
                            {
                                this.props.hasOwnProperty('left_arrow') ?  
                                    this.props.left_arrow
                                :
                                    <span className={CarouselStyles.arrow_text}>
                                        <FontIcons.Material icon='chevron_left'/>
                                    </span>
                            }
                        </div>
                    }
                    <div 
                        className   = {CarouselStyles.wrapper} 
                        ref         = {this.scroller}
                        onTouchStart= {this.touchStart}
                        onTouchEnd  = {this.touchEnd}
                        onScroll    = {this.scroll}
                    >
                        {this.props.children}
                    </div>
                    {
                        this.state.has_arrow && this.state.is_overflow && 
                        <div className={CarouselStyles.right_arrow}  onClick={this.next}>
                            {
                                this.props.hasOwnProperty('right_arrow') ?  
                                    this.props.right_arrow
                                :
                                    <span className={CarouselStyles.arrow_text}>
                                        <FontIcons.Material icon='chevron_right'/>
                                    </span>
                            }
                        </div>
                    }
                </div>;
    }

    initialize = () => {
                    this.setState({ initialzed: true, calculating_overflow: true }, this.calculate_overflow);
                }

    calculate_overflow = () => {
                            this.setState({ is_overflow: hqueue_is_overflow(this.scroller) }, this.calculate_dom);
                        }

    calculate_dom      = () => {
                            hqueue_calculate(this.scroller, this.props.children, (calculations) => {
                                this.setState({ dom_calculation: calculations },()=>{
                                    this.initial_move = true;
                                    this.move(this.initial_active);
                                });
                            })
                        }

    touchStart = () => {
                    this.setState({ dragging: true });
                }

    touchEnd   = () => {
                    this.setState({ dragging: false }, this.scroll_end);
                }

    scroll      = () => {  
                    if (!this.animated_scroll) {
                        this.scroll_cancel();
                    }

                    this.setState({ scrolling: true });

                    var scroller_dom    = this.scroller.current;

                    if (this.props.get_visible) {
                        hqueue_visibles({
                            id               : this.id,
                            is_overflow      : this.state.is_overflow,
                            scroller         : this.scroller,
                            children         : this.props.children,
                            visibility_delay : this.visibility_delay,
                            visible_exposed  : this.visible_exposed
                        }, (visibles) => {
                            this.setState({visibility:visibles});
                        });
                    }

                    if (!this.animated_scroll && this.last_scroll_left === null) {
                        this.last_scroll_left = scroller_dom.scrollLeft
                    }

                    if (!this.animated_scroll) {
                        Helpers.event_delay_animation(this.scroll_end, 100, this.id);
                    }
                }

    scroll_end  = () => {
                    this.setState({ scrolling: false }, () => {
                        var scroll_left = this.scroller.current.scrollLeft;
                        var scrolling   = this.state.scrolling;
                        var dragging    = this.state.dragging;
                        if (scrolling===false && dragging === false && scroll_left !== this.last_scroll_left) {
                            var scroll_direction = (scroll_left > this.last_scroll_left)? 'left':'right';
                            this.last_scroll_left = null;
                            this.adjust({
                                direction: scroll_direction,
                                type     : 'auto'
                            });
                        }
                    });
                }

    adjust      = (options) => {
                    if (this.state.is_overflow) {

                        if (Helpers.is_empty(options.index) && Helpers.is_empty(options.type)) {
                            throw new Error('atleast one on options.index or options.type should have value.');
                        }

                        this.setState({ adjusting: true });

                        var index = null;
                        if (Helpers.is_empty(options.index)) {
                            // must provide type 'auto or arrow' if index is not present
                            index = hqueue_nextindex(this.scroller,this.state.dom_calculation,{
                                            exposed_percentage: this.exposed_percentage,
                                            select_exposed    : this.select_exposed,
                                            direction         : options.direction,
                                            type              : options.type
                                        });
                        } else {
                            index = options.index;
                        }
                        var scroll_to = hqueue_nextpoint(this.state.dom_calculation, options.direction, index);
                        this.scroll_cancel();
                        this.animated_scroll = true;
                        this.scroll_instance =  NpmScroll.left(this.scroller.current, scroll_to, {
                                                    duration: this.scroll_duration
                                                }, () => {
                                                    this.initial_active  = index;
                                                    this.setState({ active: index, adjusting: false });
                                                    this.animated_scroll = false;
                                                    // make sure
                                                    if (!Helpers.is_empty(this.scroller.current)) {
                                                        if (this.scroller.current.scrollLeft!==scroll_to) {
                                                            this.scroller.current.scrollTo(scroll_to,0);
                                                        } 
                                                    }
                                                });
                    }
                }

    scroll_cancel = () => {
                        if (this.scroll_instance!==null) {
                            this.scroll_instance();
                        }
                    }

    move          =  (index) => {
                        if (this.initial_move) {
                            var active_item    = this.props.children[index].ref.current;
                            var has_adjustment = false;
                            if (!Helpers.is_empty(active_item)) {
                                this.scroll_cancel();
                                // var scroller_dom   = this.scroller.current;
                                var dom_calculation= this.state.dom_calculation;
                                var item_left_offset=Helpers.get_offset(active_item).left;

                                if (item_left_offset-dom_calculation.scroller_offset_left < 0) {
                                    this.adjust({ direction: 'right', index: index });
                                    has_adjustment = true;
                                } else {
                                    var item_right_offset = Helpers.offset_right(active_item);
                                    if (item_right_offset-dom_calculation.scroller_offset_right < 0) {
                                        this.adjust({ direction: 'left', index: index });
                                        has_adjustment = true;
                                    } else {
                                        this.setState({ active: index });
                                    }
                                }
                                return has_adjustment;

                            } else {
                                this.setState({ active: index });
                            } 
                        } else {
                            this.initial_active = index;
                        }
                    }

    next          = () => {
                        this.adjust({ direction: 'left', type: 'arrow' });
                    }

    previous      = () => {
                        this.adjust({ direction: 'right', type: 'arrow' });
                    }

}

/**
 * This will check and update is_overflow if scrollbar is possible for the scroller
 * @param  oibject scroller react ref
 * @return void
 */
function hqueue_is_overflow(scroller) {
	return scroller.current.scrollWidth > scroller.current.offsetWidth;
}

/**
 * This will start the carousel to roll
 * @param  oibject  scroller react ref
 * @param  array    children  
 * @param  function callback  
 * @return void
 */
function hqueue_calculate(scroller, children, callback) {
	var calculations          = {};
	var scroller_dom          = scroller.current;
    var scroller_width        = scroller_dom.offsetWidth;
    var scroller_offsets      = Helpers.get_offset(scroller_dom);
    var scroller_padding_left = parseFloat(Helpers.get_css(scroller_dom,'padding-left'));
    var scroller_padding_right= parseFloat(Helpers.get_css(scroller_dom,'padding-right'));
    calculations.scroller_scroll_area  = scroller_dom.scrollWidth - scroller_width;
    calculations.scroller_offset_left  = scroller_offsets.left+scroller_padding_left;
    calculations.scroller_offset_right = Helpers.offset_right(scroller_dom)+scroller_padding_right;

    var scroll_position      = 0;
    calculations.snap_points = [];
    children.forEach(function(child) {
    	var child_dom = child.ref.current;
    	var width     = child_dom.offsetWidth;
        calculations.snap_points.push({
            width       : width,
            scroll_left : scroll_position,
            scroll_right: scroll_position - (scroller_dom.offsetWidth - width)
        });
        scroll_position += width;
    });

   	calculations.snap_length = calculations.snap_points.length;

    if (Helpers.is_function(callback)) {
    	callback(calculations);
    }
}

/**
 * This will get all visible item
 * @object state
 * @return void
 */
function hqueue_visibles(state, callback) {

	if (state.is_overflow) {

		function detect() {
			var visibility            = {};
			var scroller_dom          = state.scroller.current;
			var left_padding          = parseFloat(Helpers.get_css(scroller_dom,'padding-left'));
            var scroller_left_offset  = Helpers.get_offset(scroller_dom).left + left_padding;
           	var right_padding         = parseFloat(Helpers.get_css(scroller_dom,'padding-right'));
            var scroller_right_offset = Helpers.offset_right(scroller_dom) + right_padding;

            state.children.forEach((child, index) => {
    			var child_dom       = child.ref.current;
				var item_width      = child_dom.offsetWidth;
                var exposed_percent = Helpers.get_percent_value(
                                        item_width, 
                                        state.visible_exposed
                                    );

                var item_left_offset    = Helpers.get_offset(child_dom).left - scroller_left_offset;
                var exposed_area_left   = item_width + item_left_offset;
                if (exposed_area_left <= exposed_percent) {

                    visibility[index] = false;

                } else {

                    var item_right_offset  = Helpers.offset_right(child_dom)-scroller_right_offset;
                    var exposed_area_right = item_width + item_right_offset;

                    if (exposed_area_right <= exposed_percent) {
                        visibility[index] = false;
                    } else {
                        visibility[index] = true;
                    }
                }
            });

            if (Helpers.is_function(callback)) {
                callback(visibility);
            }
		}

        if (state.visibility_delay === false) {
            detect();
        } else {
            Helpers.event_delay(detect, state.visibility_delay, 'carousel_hqueue_visible' + state.id);
        }


	} else {

        if (Helpers.is_function(callback)) {
            callback({});
        }

	}

}

 /**
 * This will get the next snap element index
 * @param  object scroller           react ref               
 * @param  string scroll_direction 
 * @param  string type               [auto(default),arrow]     
 * @return void
 */
function hqueue_nextindex(scroller, dom_calculation, state) {
    var scroll_left      = scroller.current.scrollLeft;
    var scroll_direction = state.direction;
    var type             = state.type || 'auto';
    var snap_point       = null;
    var activate_point   = null;

    if (scroll_direction === 'left') {

        var scroll_right = dom_calculation.scroller_scroll_area - scroll_left;
        for (let i = dom_calculation.snap_length - 1; i >= 0; i--) {
            snap_point     = dom_calculation.snap_points[i];
            scroll_right   -= snap_point.width;
            activate_point = hqueue_exposed(state, {
                                    scroll_direction: scroll_direction,
                                    type            : type,
                                    exposed_area    : scroll_right*-1,
                                    snap_point      : snap_point,
                                    index           : i
                                });

            if (activate_point!==false) {
                break;
            }
        }

    } else {

        for (let i = 0; i < dom_calculation.snap_length; i++) {
            snap_point     = dom_calculation.snap_points[i];
            scroll_left    -= snap_point.width;
            activate_point = hqueue_exposed(state, {
                                    scroll_direction: scroll_direction,
                                    type            : type,
                                    exposed_area    : scroll_left*-1,
                                    snap_point      : snap_point,
                                    index           : i
                                });

            if (activate_point!==false) {
                break;
            }
        }

    }

    return activate_point;
}

/**
 * This will check if index is currently exposed and is candidate as next snap point
 * @param  object state      
 * @param  object options [scroll_direction, type, snap_point, exposed_area, index]
 * @return boolean/int     
 */
function hqueue_exposed(state, options) {
    switch(options.type) {
        case 'arrow':

            if (options.exposed_area >= 0) {
                return options.index;
            } else {
                return false;
            }

        default:
            if (options.exposed_area > 0) {
                var select_exposed = null;

                if (state.select_exposed === 'auto') {

                    select_exposed = Helpers.get_percent_value(
                                            options.snap_point.width,
                                            state.exposed_percentage
                                        );

                } else {

                    select_exposed = state.select_exposed;

                }

                if (select_exposed <= options.exposed_area) {

                    return options.index;

                } else {

                    if (options.scroll_direction === 'left') {
                        return options.index - 1;
                    } else {
                        return options.index + 1;
                    }

                }

            } else {

                return false;

            }
    }
}

/**
 * This will get the next scroll position 
 * @param  object dom_calculation
 * @param  string direction 
 * @param  int    index   
 * @return void
 */
function hqueue_nextpoint(dom_calculation, scroll_direction, index) {
    if (scroll_direction === 'left') {
        return dom_calculation.snap_points[index].scroll_right;
    } else {
        return dom_calculation.snap_points[index].scroll_left;
    }
}

export default {Hqueue};