import React	 		    from 'react';
import classnames           from 'classnames';
import Form                 from 'app/components/form/Form';
import FormValidatorCommon  from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language             from 'app/utils/language/Language';

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.bank_account_withdraw','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.bank_account_withdraw','en')}
		  			</div>
		  		</h3>
		  		{bank_inputs('main' , component, {
		  			bank_account_name: {required:1},
		  			bank_name        : {required:1},
		  			branch_name      : {required:1},
		  			account_number   : {required:1},
		  			account_type     : {
		  									required:1,  
		  								 	onChange :(event_data) => { 
		  								 				component.account_type_changed(event_data, 'main'); 
		  								 			}
		  								},
		  			currency         : {required:1},
		  			phone_area_code     :{
		  									onChange :() => { component.check_contact_group('main_account_phone'); }
		  								},
		  			account_phone       :{
		  									onChange :() => { component.check_contact_group('main_account_phone'); }
		  								}
		  		})}
		  		{bank_inputs('other', component, {
		  			bank_account_name:  {
		  								 	required :component.state.other_bank_required, 
		  									onChange :component.check_other_banks
		  								},
		  			bank_name        :  {
		  								 	required :component.state.other_bank_required, 
		  									onChange :component.check_other_banks
		  								},
		  			branch_name      :  {
		  								 	required :component.state.other_bank_required, 
		  									onChange :component.check_other_banks
		  								},
		  			account_number   :  {
		  								 	required :component.state.other_bank_required, 
		  									onChange :component.check_other_banks
		  								},
		  			account_type     :  {
		  									clearOnClick: true,
		  								 	required :component.state.other_bank_required, 
		  								 	onChange :(event_data) => { 
		  								 				component.account_type_changed(event_data, 'other');
		  								 				component.check_other_banks();
		  								 			}
		  								},
		  			currency         :  {
		  									clearOnClick: true,
		  								 	required :component.state.other_bank_required, 
		  									onChange :component.check_other_banks
		  								},
		  			phone_area_code     :{
		  								 	onChange :() => { 
		  								 				component.check_contact_group('other_account_phone');
		  								 				component.check_other_banks();
		  								 			}
		  								},
		  			account_phone       :{
		  								 	onChange :() => { 
		  								 				component.check_contact_group('other_account_phone');
		  								 				component.check_other_banks();
		  								 			}
		  								}
		  		})}
		  	</React.Fragment>;

}

function bank_inputs(type, component, add_props) {
	var inputs = component.inputs;
	var props  = component.props;

	return  <React.Fragment>
		        <h3 className='RealAccountStyles-form_category_title AppStyles-no_margin'>
		  			{Language.get('realaccount.'+type+'_account','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.'+type+'_account','en')}
		  			</div>
		  		</h3>
				<Form.Row>
		  			<Form.Textbox     
		  				{...add_props.bank_account_name}
		  				label        = 'name' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = { inputs[type+'_account_bank_account_name'].ref }
		  				defaultValue = { props.User.details[type+'_account_bank_account_name'] }
		  				onTouched    = { props.onTouched }
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				{...add_props.bank_name}
		  				label        = 'bank_name' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs[type+'_account_bank_name'].ref }
		  				defaultValue = { props.User.details[type+'_account_bank_name'] }
		  				onTouched    = { props.onTouched }
		  			/>
		  			<Form.Textbox     
		  				{...add_props.branch_name}
		  				label        = 'branch' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs[type+'_account_branch_name'].ref }
		  				defaultValue = { props.User.details[type+'_account_branch_name'] }
		  				onTouched    = { props.onTouched }
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				{...add_props.account_number}
		  				label        = 'account_number' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs[type+'_account_number'].ref }
		  				defaultValue = { props.User.details[type+'_account_number'] }
		  				onTouched    = { props.onTouched }
		  			/>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.Textbox     
		  						{...add_props.phone_area_code}
			      				label        = 'bank_phone_number' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs[type+'_account_phone_area_code'].ref }
			      				defaultValue = { props.User.details[type+'_account_phone_area_code'] }
		  						onTouched    = { props.onTouched }
	      						placeholder  = {component.area_code_placeholder}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.Textbox     
		  						{...add_props.account_phone}
			      				label        = 'blank' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs[type+'_account_phone'].ref }
			      				defaultValue = { props.User.details[type+'_account_phone'] }
		  						onTouched    = { props.onTouched }
	      						placeholder  = {component.contact_number_placeholder}
			      			/>
			      		</div>
					</div>
		  		</Form.Row>
		  		<Form.Row>
			        <Form.Radio     
		  				{...add_props.account_type}
			            label        = 'account_type' 
			            ref          = { inputs[type+'_account_type'].ref }
		  				defaultValue = {component.account_type_defaultValue(type)}
			            className    = 'AppStyles-full_size_input RealAccountStyles-radio_with_input'
						options      = {[
											{
												value      :'Giro', 
												description:<React.Fragment>
																{Language.get('realaccount.current_account','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.current_account','en')}
																</div>
															</React.Fragment>
											},
											{
												value:'Tabungan', 
												description:<React.Fragment>
																{Language.get('realaccount.savings','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.savings','en')}
																</div>
															</React.Fragment>
											},
											{
												value:'Lainnya', 
												description:<Form.Textbox     
					  											className    = {classnames(['RealAccountStyles-radio_input'])}
												  				ref          = {inputs[type+'_other_account_type'].ref}
												  				defaultValue =  {
												  									component.show_other_account_type(type) ?
												  									props.User.details[type+'_account_type']:''
												  								}
											      				onTouched    = {props.onTouched}
											      				required     = {1}
											      				beforeInput  = {
													  								<span 
													  									className='RealAccountStyles-radio_text'
													  								> 
																						{Language.get(
																							'realaccount.others',
																							'id'
																						)}:
																						<div className='AppStyles-label_secondary'>
																							{Language.get(
																								'realaccount.others',
																								'en'
																							)}
																						</div>
													  								</span>
													  							}
																disabled = {!component.show_other_account_type(type)}
												  			/>
											}
									 	]}
		  				onTouched    = { props.onTouched }
			        />
		  			
		  		</Form.Row>
		  		<Form.Row>
			        <Form.Radio     
		  				{...add_props.currency}
			            label        = 'currency' 
			            ref          = { inputs[type+'_account_currency'].ref }
		  				defaultValue = { props.User.details[type+'_account_currency'] } 
			            className    = 'AppStyles-half_size_input'
						options      = {[
											{
												value      :'IDR', 
												description:<React.Fragment>
																{Language.get('realaccount.idr','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.idr','en')}
																</div>
															</React.Fragment>
											},
											{
												value      :'USD', 
												description:<React.Fragment>
																{Language.get('realaccount.usd','id')}
																<div className='AppStyles-label_secondary'>
																	{Language.get('realaccount.usd','en')}
																</div>
															</React.Fragment>
											}
									 	]}
		  				onTouched    = { props.onTouched }
			        />
		  		</Form.Row>
	  		</React.Fragment>;
}

const account_name_validate = [{
	as              : 'bank_account_name'
},{
	as				: 'max_length',
	max 			: 50
}];

function RefValidations(props, component) {
	return { 
		main_account_bank_account_name: { ref: React.createRef(),validations: {
											triggers: ['onBlur'],
											prevent : true,
											validate: [{
														as              : 'required',
														type            : 'full_name',
														exclude_triggers: ['prevent']
													},...account_name_validate]
										}},
		main_account_bank_name        : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											prevent : true,
					    					validate: [
														{
															as              : 'required',
															exclude_triggers: ['prevent']
														},
														{
															as      : 'bank_name',
															category: 'withdraw'
														}
													]
					    				}},
		main_account_branch_name      : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'required'}]
					    				}},
		main_account_number           : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											prevent : true,
					    					validate: [
					    								{
					    									as: 'required', 
					    									exclude_triggers: ['prevent'] 
					    								},
					    								{as: 'is_number'}
					    							]
					    				}},
		main_account_phone_area_code  : { ref: React.createRef(),validations:FormValidatorCommon.optional_area_code(
											component.contact_grouped_required('main_account_phone'),[
												{
													as: 'max_length',
													max: 4
												},
											]
										)},
		main_account_phone            : { ref: React.createRef(),validations:FormValidatorCommon.optional_number(
											component.contact_grouped_required('main_account_phone')
										)},
		main_account_type             : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'required'}]
					    				}},
		main_other_account_type       : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											validate: [
														{
															as     : 'requiredif',
															type   : 'nomessage',
															checker: () => {
																		let val = component.account_type_currentValue('main');
																		return val === component.other_account_value;
																	}
														}
													]
										}},
		main_account_currency         : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'required'}]
					    				}},

		other_account_bank_account_name: { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											prevent : true,
					    					validate: [
					    						{
					    							as              : 'requiredif', 
					    							checker         : component.other_banks_required,
					    							exclude_triggers: ['prevent'] 
					    						},
					    						...account_name_validate
					    					]
					    				}},
		other_account_bank_name        : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											prevent : true,
					    					validate: [
														{
															as              : 'requiredif',
															checker         : component.other_banks_required,
															exclude_triggers: ['prevent']
														},
														{
															as      : 'bank_name',
															category: 'withdraw'
														}
													]
					    				}},
		other_account_branch_name      : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'requiredif', checker: component.other_banks_required }]
					    				}},
		other_account_number           : { ref: React.createRef(),validations:{
											prevent : true,
											triggers: ['onBlur'],
					    					validate: [
					    								{
					    									as              : 'requiredif', 
					    									checker         : component.other_banks_required,
					    									exclude_triggers: ['prevent']  
					    								},
					    								{as: 'is_number'}
					    							]
					    				}},
		other_account_phone_area_code  : { ref: React.createRef(),validations:FormValidatorCommon.optional_area_code(
											component.contact_grouped_required('other_account_phone'),[
												{
													as: 'max_length',
													max: 4
												},
											]
										)},
		other_account_phone            : { ref: React.createRef(),validations:FormValidatorCommon.optional_number(
											component.contact_grouped_required('other_account_phone')
										)},
		other_account_type             : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'requiredif', checker: component.other_banks_required }]
					    				}},
		other_other_account_type        :{ ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											validate: [
														{
															as     : 'requiredif',
															type   : 'nomessage',
															checker: () => {
																		let val = component.account_type_currentValue('other');
																		return val === component.other_account_value;
																	}
														}
													]
										}},
		other_account_currency         : { ref: React.createRef(),validations:{
											triggers: ['onBlur'],
					    					validate: [{as: 'requiredif', checker: component.other_banks_required }]
					    				}}
	};
};

export default { Inputs, RefValidations }