import { ActionTypes } from '../actions/AccountTypesActions';

var initial_state = {};

var callables = {

	[ActionTypes.update_AccountTypes](state, action) {
		return Object.assign({}, state, action.AccountTypes);
	}
};

export default function(state = initial_state, action) {

	if (callables.hasOwnProperty(action.type)) {
		return callables[action.type](state, action);
	}

	return state;
};