import React,{Component}             from 'react';
import HomeTheme                     from 'theme/pages/home/Home';
import { callables as StoreMethods } from 'app/store/Store';
import Model                 		 from 'app/modules/model/Model';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';


class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {loading: 1, error: false, code: null};
	}

  	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'HomePage');
  		this.check_loading();
		//console.log('here',this.props)
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	get_links = () => {
		var links = {};

		if (!Helpers.is_empty(this.props.User) && !Helpers.is_empty(this.props.Company)) {
						
			const { account_type } = this.props.User.details;
			const {
				etrade_demo_link,
				etrade_real_link,
				etrade_demo_link_pbk,
				etrade_real_link_pbk
			} = this.props.Company.content;
			let tmp_demo_link;
			let tmp_real_link;

			if (account_type === "Bilateral") {
				tmp_demo_link = !Helpers.is_empty(etrade_demo_link) ? etrade_demo_link : null;
				tmp_real_link = !Helpers.is_empty(etrade_real_link) ? etrade_real_link : null;
			} else {
				tmp_demo_link = !Helpers.is_empty(etrade_demo_link_pbk) ? etrade_demo_link_pbk : null;
				tmp_real_link = !Helpers.is_empty(etrade_real_link_pbk) ? etrade_real_link_pbk : null;
			}

			links = {
						demo_link: tmp_demo_link,
						real_link: tmp_real_link
					};
		}
		
		return links;
	}
	
	render() {
		return  <React.Fragment>
					<HomeTheme 
						{...this.props} 
						state                 = {this.state} 
						showRealAccountButton = {
													!Helpers.is_empty(this.props.Step) 
													&& !Helpers.is_empty(this.props.Step.content) 
													&& Model.can_edit_progressNames(this.props.Step.content.progressName)
												}
						className             = {this.state.error && 'AppStyles-hidden'}
						links				  = {this.get_links()}
					/>
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message' 
							code      = {this.state.code} 
							type      = 'error' 
						/> 
					}
				</React.Fragment>;
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_user    = !Helpers.is_empty(this.props.User.details);
  			var has_company = !Helpers.is_empty(this.props.Company);
  			var has_step    = !Helpers.is_empty(this.props.Step);
  			if (has_user && has_step && has_company) {
  				this.setState({ loading: 0 }, () => {
  					if (this.props.Company.error.code !== '0') {
						this.setState({ error: true, code: this.props.Company.error.code });
			  		}

			  		if (this.props.Step.error.code !== '0') {
						this.setState({ error: true, code: this.props.Step.error.code });
			  		}
  				});
				if(this.checkStep8()){
					StoreMethods.step_enabled_change(8)
					//this.disableAllandYa()
					//console.log('step3Disclosure')
				}
  			}
		}
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Home')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		//disable date
		//document.getElementById("date").disabled = true;
		//this.disableElement('FormStyles_dropdown')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var elements = [], child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) == classname)
      			elements.push(child);
  		}
		//console.log(elements)
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			
		}
		
	}
}

export default StoreMethods.connect_stores(Home,['User','Company','Step'],Model.get_home_data);
