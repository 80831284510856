import React			    from 'react';
import Form                 from 'app/components/form/Form';
import Language             from 'app/utils/language/Language';
import Helpers              from 'app/utils/helpers/Helpers'; 

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.attached_documents','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.attached_documents','en')}
		  			</div>
		  		</h3>
		  		<Form.Row>
			  		<Form.UploadBox 
			  			label        = 'recent_photo'
				        className    = 'AppStyles-full_size_input'
		  				labelSubText = {Language.get('realaccount.max_file_size','id')}
			            ref          = { inputs.recent_photo.ref }
			            accept       = 'image/jpg,image/jpeg,image/png'
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      				defaultValue = {
											!Helpers.is_empty(component.state.recent_photo[0]) &&
											[{
												is_src: true,
												name: component.state.recent_photo[0].original_file_name,
												src: component.state.recent_photo[0].file_path
											}]
	      								}
			  		/>
		  		</Form.Row>
		  		<Form.Row>
			  		<Form.UploadBox 
			  			label        = 'id_types_1'
				        className    = 'AppStyles-full_size_input'
		  				labelSubText = {Language.get('realaccount.max_file_size','id')}
			            ref          = { inputs.id_photo.ref }
			            accept       = 'image/jpg,image/jpeg,image/png'
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      				defaultValue = {
											!Helpers.is_empty(component.state.id_photo[0]) &&
											[{
												is_src: true,
												name: component.state.id_photo[0].original_file_name,
												src: component.state.id_photo[0].file_path
											}]
	      								}
			  		/>
		  		</Form.Row>
		  		<Form.Row>
			  		<Form.UploadBox 
			  			label        = 'other_document'
				        className    = 'AppStyles-full_size_input'
		  				labelSubText =  {
		  									<React.Fragment>
			  									<div>{Language.get('realaccount.other_document_types','id')}</div>
			  									<div>{Language.get('realaccount.other_document_types','en')}</div>
			  									<div>({Language.get('realaccount.max_file_size','id')})</div>
			  								</React.Fragment>
		  								}
		  				labelSubTextRaw = {true}
			            ref             = { inputs.other_document_1.ref }
	      				onTouched       = {props.onTouched}
	      				required        = {1}
			            accept          = {
			            					'.pdf,.doc,.docx,application/pdf,application/msword,'
			            					+'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			            					+'image/jpg,image/jpeg,image/png'
			            				}
	      				defaultValue = {get_document(component.state.other_documents,1)}
			  		/>
		  		</Form.Row>
		  		<Form.Row>
			  		<Form.UploadBox 
				        className    =  'AppStyles-full_size_input'
			            ref          =  {inputs.other_document_2.ref}
	      				onTouched    =  {props.onTouched}
			            accept       =  {
			            					'.pdf,.doc,.docx,application/pdf,application/msword,'
			            					+'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			            					+'image/jpg,image/jpeg,image/png'
			            				}
	      				defaultValue = {get_document(component.state.other_documents,2)}
			  		/>
		  		</Form.Row>
		  		<Form.Row>
			  		<Form.UploadBox 
				        className    =  'AppStyles-full_size_input'
			            ref          =  {inputs.other_document_3.ref}
	      				onTouched    =  {props.onTouched}
			            accept       =  {
			            					'.pdf,.doc,.docx,application/pdf,application/msword,'
			            					+'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			            					+'image/jpg,image/jpeg,image/png'
			            				}
	      				defaultValue = {get_document(component.state.other_documents,3)}
			  		/>
		  		</Form.Row>
		  	</React.Fragment>;
}

function get_document(document_array, document_order) {
	var document_item = document_array.find((document_item) => (document_item.order === document_order));
	return !Helpers.is_empty(document_item) 
			&& [{
				is_src: true,
				name  : document_item.original_file_name,
				src   :  document_item.file_path
			}];
}

function RefValidations(props) {
	var other_document_1 = React.createRef();
	var other_document_2 = React.createRef();
	var other_document_3 = React.createRef();

	return { 
		recent_photo                   : { ref: React.createRef(),validations:{
											triggers: ['onChange'],
					    					validate: [{as: 'required', type: 'document'}]
					    				}, store_exempt: true},
		id_photo                       : { ref: React.createRef(),validations:{
											triggers: ['onChange'],
					    					validate: [{as: 'required', type: 'document'}]
					    				}, store_exempt: true},
		other_document_1               : { ref: other_document_1,validations:{
											triggers: ['onChange'],
					    					validate: [
					    								{
					    									as     : 'requiredif', 
					    									type   : 'document',
					    									checker: () => (
					    										Helpers.is_empty(other_document_1.current.value())
					    									)
					    								}
					    							]
					    				}, store_exempt: true},
		other_document_2               : { ref: other_document_2,validations:{}, store_exempt: true },
		other_document_3               : { ref: other_document_3,validations:{}, store_exempt: true }
	};
};

export default { Inputs, RefValidations }