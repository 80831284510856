import React                from 'react';
import CustomerActivation   from './activationpages/customeractivation/CustomerActivation';
import TelegramActivation   from './activationpages/telegramactivation/TelegramActivation';

function Activation(props) {
    return props.match.path === '/a/:code' 
            ? <CustomerActivation {...props} /> 
            : <TelegramActivation {...props} />;
}

export default Activation;