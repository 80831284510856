export default {
	'-1'  : 'ERR_00017',
	'-2'  : 'ERR_00018',
	'-3'  : 'ERR_00019',
	'-4'  : 'ERR_00008',
	'-5'  : 'ERR_00020',
	'-6'  : 'ERR_00021',
	'-7'  : 'ERR_00007',
	'-10' : 'ERR_00024',
	'-11' : 'ERR_00030',
	'-100': 'ERR_00022'
};