import React                               from 'react';
import  { 
            Route, 
            Redirect, 
            Switch, 
            NavLink 
        }                                   from 'react-router-dom';
import classnames                           from 'classnames';
import Home                                 from 'app/pages/home/Home';
import RealAccount                          from 'app/pages/realaccount/RealAccount';
import ErrorPages                           from 'app/pages/errorpages/ErrorPages';
import Activation                           from 'app/pages/activation/Activation';
import { callables as StoreMethods }        from 'app/store/Store';
import Language                             from 'app/utils/language/Language';
import FontIcons                            from 'app/components/fonticons/FontIcons';
import Model                                from 'app/modules/model/Model';
import Helpers                              from 'app/utils/helpers/Helpers';
import Loader                               from 'app/components/loader/Loader';

import LocalForage                          from 'app/utils/localforage/LocalForage';

function PrivateRoutes(props) {
    const with_container = props.with_container;

    return  (
                !props.User.auth ?
                    <Redirect to='/' />
                :
                    !Helpers.is_empty(props.User) && !Helpers.is_empty(props.Step) ? 
                        <Switch> 
                            <Route
                                exact
                                path      = '/realaccount/:id(step1|step2|step3|step4|step5|step6|step7|step8)?'
                                render    = {(props) => with_container(<RealAccount {...props}/>)} 
                            />
                            <Route exact path='/' render={(props) => with_container(<Home {...props}/>)} />

                            <Route exact path='/login'   render={(props) => <Redirect to="/" />} />
                            
                            <Route exact path='/a/:code' render={(props) => <Activation {...props} />} />

                            <Route exact path='/ta/:code' render={(props) => <Activation {...props} />} />

                            <Route component={ErrorPages.NotFound} />
                        </Switch>
                    :
                        <Loader.Screen />
            );

}


function PrivateRouteLinksRaw(props) {
    if (!Helpers.is_empty(props.User) && !Helpers.is_empty(props.Step) && !Helpers.is_empty(props.Step.content)) {
        switch(props.type) {
            case 'primary':
                    if(checkStep8()){
                        return  <React.Fragment>
                            {
                                Model.can_edit_progressNames(props.Step.content.progressName) &&
                                <NavLink 
 
                                    to        = '/realaccount/step8' 
                                    className = {classnames(['Routes-menu_link','Routes-realaccount'])}
                                    onClick   = {pengkinianData}
                                >
                                    <div className='Routes-link_text'>
                                        <FontIcons.Material icon='edit' /> Pengkinian Data
                                    </div>
                                </NavLink>
                            }

                            <NavLink 
                                to        = '/'           
                                className = {classnames(['Routes-menu_link','Routes-home'])}
                            >
                                <div className='Routes-link_text'>
                                    <FontIcons.Material icon='home' /> {Language.get('menu.home')}
                                </div>
                            </NavLink>

                            </React.Fragment>;
                    }else{
                        return  <React.Fragment>
                            {
                                Model.can_edit_progressNames(props.Step.content.progressName) &&
                                <NavLink 
                                    to        = '/realaccount' 
                                    className = {classnames(['Routes-menu_link','Routes-realaccount'])}
                                >
                                    <div className='Routes-link_text'>
                                        <FontIcons.Material icon='person_add' /> {Language.get('menu.realaccount')}
                                    </div>
                                </NavLink>
                            }

                            <NavLink 
                                to        = '/'           
                                className = {classnames(['Routes-menu_link','Routes-home'])}
                            >
                                <div className='Routes-link_text'>
                                    <FontIcons.Material icon='home' /> {Language.get('menu.home')}
                                </div>
                            </NavLink>

                            </React.Fragment>;
                    }
                    
                
                

            case 'realaccount': 
                    return  <NavLink 
                                to        = '/realaccount' 
                                className = {classnames(['Routes-menu_link','Routes-realaccount'])}
                            >
                                <FontIcons.Material icon='person_add' /> {Language.get('menu.realaccount')}
                            </NavLink>;
            case 'home': 
                    return  <NavLink 
                                to        = '/'           
                                className = {classnames(['Routes-menu_link','Routes-home'])}
                            >
                                <div className='Routes-link_text'>
                                    <FontIcons.Material icon='home' /> {Language.get('menu.home')}
                                </div>
                            </NavLink>;
            case 'step1':
            case 'step2': 
            case 'step3': 
            case 'step4': 
            case 'step5': 
            case 'step6': 
            case 'step7':
            case 'step8':
                    return  <NavLink 
                                tabIndex = {props.tabIndex}
                                to       = {'/realaccount/'+props.type}
                            >
                                {props.children}
                            </NavLink>;                
            default: return  '';
        }

    } else {

        return '';

    }
}

//Added on 20220614---------
function checkStep8(){
    var done = false;
    var temp = StoreMethods.get_FormChange()
    var array = temp.StepReducers.content.previous_progress
    //console.log(temp,'Private Route')
    for (let index = 0; index < array.length; index++) {
        if(array[index]==='Regol Successful'){
            done = true;
            break;
        }
        
    }

    return done
}

function pengkinianData(){
    LocalForage.setItem('CUD', true, function (err) {
        // if err is non-null, we got an error
        LocalForage.getItem('CUD', function (err, value) {
          // if err is non-null, we got an error. otherwise, value is the value
          //console.log('disini',value)
            StoreMethods.step_enabled_change(8)
          //console.log(this.props)
          //if(window.location.pathname.indexOf('step8') > -1){
            //window.location.reload(false)
          //}
          //window.location.reload(false) //bikin rusak di chrome
        });
    });
}

function PrivateRouteComponents(props) {
    
    switch(props.type) {

        case 'redirect_step':
            return <Redirect to={'/realaccount/step' + props.number} />;
        default: return  '';
    }
}

const PrivateRouteLinks = StoreMethods.connect_stores(PrivateRouteLinksRaw,['User','Step'], Model.private_route_data);
export default StoreMethods.connect_stores(PrivateRoutes,['User','Step'], Model.private_route_data);
export {
    PrivateRouteLinks,
    PrivateRouteComponents
};