import { ActionTypes } from '../actions/Step5Actions';
// import Helpers         from 'app/utils/helpers/Helpers';

var initial_state = {};

var callables = {

	[ActionTypes.update_step5](state, action) {
		return Object.assign({}, state, action.step5);
	}
};

export default function(state = initial_state, action) {

	if (callables.hasOwnProperty(action.type)) {
		return callables[action.type](state, action);
	}

	return state;
};