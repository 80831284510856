import React 			 	 from 'react';
import ErrorPageStyles       from './scss/ErrorPageStyles.module.scss';
import Image                 from 'app/components/image/Image';
// import Language              from 'app/utils/language/Language';

function NotFound() {
	return  <div className={ErrorPageStyles.not_found}>
	            <Image.Load 
	                className = {ErrorPageStyles.not_found_image}
	                src       = {process.env.REACT_APP_RSO + '/images/error/error_404.png'} 
	                ratio     = {{width:300,height:332}}
	            />
	  		</div>;
}

function HasExpired() {
	return  <div className={ErrorPageStyles.has_expired}>
	            <Image.Load 
	                className = {ErrorPageStyles.has_expired_image}
	                src       = {process.env.REACT_APP_RSO + '/images/error/page_expired.png'} 
	                ratio     = {{width:720,height:720}}
	            />
	  		</div>;
}

export default { NotFound, HasExpired };