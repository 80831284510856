import React,{Component}             from 'react';
import classnames 					 from 'classnames';
import RealAccountComponents         from '../realaccountcomponents/RealAccountComponents';
import { callables as StoreMethods } from 'app/store/Store';
import Form                          from 'app/components/form/Form';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Language                      from 'app/utils/language/Language';
import Model                 		 from 'app/modules/model/Model';
import Wysiwyg                 		 from 'app/components/wysiwyg/Wysiwyg';
import Loader                        from 'app/components/loader/Loader';
import Table                         from 'app/components/table/Table';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';

class Step1 extends Component {

	constructor(props) {
		super(props);

		// create refs and validations
	    this.inputs =   {
							accept_statement:{ ref: React.createRef(),validations:FormValidatorCommon.accept_statement},
							wysiwyg_0       :{ 
												ref        :React.createRef(),
												validations:{}
											},
							wysiwyg_1       :{ 
												ref        :React.createRef(),
												validations:{}
											},
							wysiwyg_2       :{ 
												ref        :React.createRef(),
												validations:{}
											},
							wysiwyg_3      :{ 
												ref        :React.createRef(),
												validations:{}
											}
						};
		this.state  =   {
							can_submit: false,
							loading   : 1,
							ui_loading: 1,
							submitting: 0,
							brokers   : {},
							branches  : {}
					    };
		this.form   = React.createRef();
		this.wysiwyg_loaded  = [];
		this.wysiwyg_length  = 0;
		this.wysiwyg_changed = false;
		this.unmounted       = false;
	}

  	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step 1');
  		var component = this;
		  //console.log('mount')
		Model.get_branches({
				success: (response) => {

							// sort branches
							Helpers.sort_branches(response.data.branches, {
																				hq_flag: 'is_hq',
																				branch_order: 'order',
																				branch_name: 'branch_name',
																			});
							!this.unmounted &&
							component.setState({ branches: { 
								error  : response.error,
								content: response.data.branches  
							}});
						},
				fail    :(response) => {
							!this.unmounted &&
							component.setState({ branches: { error: true, code: response.error.code }});
						},
				error   :(xhr, status) => {
							!this.unmounted &&
							component.setState({ branches: { error: true, code: status }});
						}
  		});

		Model.get_brokers({
				success: (response) => {
							!this.unmounted &&
							component.setState({ brokers: { 
								error  : response.error,
								content: response.data.brokers  
							}});
						},
				fail    :(response) => {
							!this.unmounted && 
							component.setState({ brokers: { error: true, code: response.error.code }});
						},
				error   :(xhr, status) => {
							!this.unmounted &&
							component.setState({ brokers: { error: true, code: status }});
						}
  		});
  		
  		this.check_loading();
  	}

  	componentWillUnmount = () => {
		this.unmounted = true;
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
		//console.log('update')
  	}

  	commodity_futures_system = () => {
  		if (this.props.Step1.content && !Helpers.is_empty(this.props.AccountTypeCode)) {
  			var wysiwyg = this.props.Step1.content.find((file) => (
  							file.document_code === 'COM_1_'+this.props.AccountTypeCode
  						));
			var assign_ref= this.inputs['wysiwyg_0'].ref; 
  			return <Wysiwyg.Parse 
						file      = {wysiwyg.file_path}
						className = 'RealAccountStyles-com_1_spa'
						onLoad    = {(id) => {
										this.wysiwyg_onload(
											assign_ref,
											id
										)
									}} 
					/>

  		} else {
  			return null;
  		}
  	}

  	alternative_trading_system = () => {
  		if (this.props.Step1.content && !Helpers.is_empty(this.props.AccountTypeCode)) {
  			var wysiwyg   = this.props.Step1.content.find((file) => (
  								file.document_code === 'COM_2_'+this.props.AccountTypeCode
  							));
			var assign_ref= this.inputs['wysiwyg_1'].ref; 
  			return <Wysiwyg.Parse 
						className = 'RealAccountStyles-com_2_spa'
						file      = {wysiwyg.file_path}
						onLoad    = {(id) => {
										this.wysiwyg_onload(
											assign_ref,
											id
										)
									}} 
					/>

  		} else {
  			return null;
  		}
  	}

  	company_customer_fee = () => {
  		if (this.props.Step1.content && !Helpers.is_empty(this.props.AccountTypeCode)) {
  			var wysiwyg   = this.props.Step1.content.find((file) => (
  								file.document_code === 'TBL_1_'+this.props.AccountTypeCode
  							));
			var assign_ref= this.inputs['wysiwyg_2'].ref; 
  			return <Wysiwyg.Parse 
						file   = {wysiwyg.file_path}
						onLoad = {(id) => {
									this.wysiwyg_onload(
										assign_ref,
										id
									)
								}} 
					/>

  		} else {
  			return null;
  		}
  	}

  	company_trading_rules = () => {
  		if (this.props.Step1.content && !Helpers.is_empty(this.props.AccountTypeCode)) {
  			var wysiwyg   = this.props.Step1.content.find((file) => (
  								file.document_code === 'TBL_2_'+this.props.AccountTypeCode
  							));
			var assign_ref= this.inputs['wysiwyg_3'].ref; 
  			return <Wysiwyg.Parse 
						file   = {wysiwyg.file_path}
						onLoad = {(id) => {
									this.wysiwyg_onload(
										assign_ref,
										id
									)
								}} 
					/>

  		} else {
  			return null;
  		}
  	}

	render() {
		return  <Form.Form 
					onValidateSuccess= {this.on_submit} 
					inputs           = {this.inputs} 
					loading          = {this.state.ui_loading}
		    		submitting       = {this.state.submitting}
		    		ref              = {this.form}
				>
					<RealAccountComponents.StepPageLayout
						step           = {1}
						body           = {
											this.state.loading ? <Loader.Ghost/> : 
											<React.Fragment>

												<div className='RealAccountStyles-block RealAccountStyles-block_top'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.name','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.company_name
															}
														</div>
													</div>
												</div>
												
												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.short_address','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.address 
																+' '+this.props.Company.content.city
																+' '+this.props.Company.content.postal_code
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.phone_number_short','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.phone_area_code
																+'-'+this.props.Company.content.phone
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.one_word_fax','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.fax_area_code
																+'-'+this.props.Company.content.fax
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.short_email_address','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.email_address
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.website','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															<a 
																href   = {this.props.Company.content.website}
																target = '_blank'
																rel    = 'noopener noreferrer'
															>
																{
																	this.props.Company 
																	&& this.props.Company.content.website
																}
															</a>
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block RealAccountStyles-block_bottom'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.branch_office','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															<ol>
																{ 
																	this.state.branches.content &&
																	this.state.branches.content.map(
																		(branch,index) => (
																			<li key={index}>
																				{ branch.address }
																				<b>{' '+branch.city}</b>
																				{' '+branch.postal_code }
																			</li>
																		)
																	)
																}
															</ol>
														</div>
													</div>
												</div>

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.company_management_structure','id')}
										  		</h3>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.president_commissioner','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.president_commissioner
															}
														</div>
													</div>
												</div>

												{
													this.props.Company &&
													this.props.Company.content.commissioner.split(/\r?\n/).map((item,index) => (
														<div className='RealAccountStyles-block' key={index}>
															<div className='RealAccountStyles-label'>
																{Language.get('form_labels.commissioner','id')}
															</div>
															<div className='RealAccountStyles-content'>
																<div className='RealAccountStyles-content_colon'>:</div>
																<div className='RealAccountStyles-content_text'>
																	{item}
																</div>
															</div>
														</div>
													))
												}

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.company_ceo','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.ceo
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.company_director','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.compliance_director
															}
														</div>
													</div>
												</div>

												{
													this.props.Company &&
													this.props.Company.content.director.split(/\r?\n/).map((item,index) => (
														<div className='RealAccountStyles-block' key={index}>
															<div className='RealAccountStyles-label'>
																{Language.get('form_labels.director','id')}
															</div>
															<div className='RealAccountStyles-content'>
																<div className='RealAccountStyles-content_colon'>:</div>
																<div className='RealAccountStyles-content_text'>
																	{item}
																	
																</div>
															</div>
														</div>
													))
												}
												
												<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.company_shareholders','id')}
										  		</h3>
												
												{
													this.props.Company &&
													this.props.Company.content.shareholder.split(/\r?\n/).map((item,index) => (
														<div className='RealAccountStyles-block' key={index} >
															<ol start={index+1}>
																<li>{item}</li>	
															</ol>
														</div>
													))
												}
												
										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.permission_and_membership','id')}
										  		</h3>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.company_bappebti_license','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.bappebti_license
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.future_exchange_number','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.futures_exchange_membership
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.future_clearing_number','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.futures_clearing_membership
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.customer_acceptance_license','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.customer_online_acceptance_license
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.alternative_trading_system','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.approval_trading_participant_system
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.implementation_trading_system','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.implementation_alternative_trading_system
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.complaint_email_address','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.email_address_complaint
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.complaint_phone_number','id')} 
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															{
																this.props.Company 
																&& this.props.Company.content.phone_area_code
																+'-'+this.props.Company.content.phone
															}
														</div>
													</div>
												</div>

												<div className='RealAccountStyles-block'>
													<div className='RealAccountStyles-label'>
														{Language.get('form_labels.conflict_courts_step1','id')}
													</div>
													<div className='RealAccountStyles-content'>
														<div className='RealAccountStyles-content_colon'>:</div>
														<div className='RealAccountStyles-content_text'>
															<ol>
																<li>
																	Musyawarah untuk mencapai mufakat
																</li>
																<li>
																	Bursa Berjangka Jakarta
																</li>
																<li>
																	{
																		this.props.Company
																		&& this.props.Company.content.court
																		+ ' / Badan Arbitrase Perdagangan Berjangka Komoditi (BAKTI)'
																	}
																</li>
															</ol>
														</div>
													</div>
												</div>

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.contracts_traded','id')}
										  		</h3>
										  		{this.commodity_futures_system()}

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.derivative_contracts','id')}
										  		</h3>

										  		{this.alternative_trading_system()}

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			{Language.get('realaccount.company_customer_fee','id')}:
										  			<div className='RealAccountStyles-form_subtitle'>
										  				{Language.get('realaccount.fee_subtitle_1','id')+' '}
										  				<i>{Language.get('realaccount.fee_subtitle_2','id')}</i>
										  				{this.props.AccountTypeCode === 'SPA' ? 
										  					' '+Language.get('realaccount.spa_fee_subtitle_3','id')
										  				:
										  					' '+Language.get('realaccount.pbk_fee_subtitle_3','id')
										  				}
										  			</div>
										  		</h3>
												{this.company_customer_fee()}
												{this.company_trading_rules()}

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			<div>
										  				{Language.get('realaccount.broker_working_future','id')+' '}
										  				{this.props.Company && this.props.Company.content.company_name}:
										  			</div>
										  		</h3>
										  		{
													this.state.brokers.content && 
													<div className='RealAccountStyles-table RealAccountStyles-broker'>
														<Table bordered={true}>
															<thead>
																<tr>
																	<th>
																		{Language.get('form_labels.office','id')}
																	</th>
																	<th>
																		{Language.get('form_labels.name','id')}
																	</th>
																	<th>
																		{Language.get('form_labels.license_number','id')}
																	</th>
																</tr>
															</thead>
															<tbody>
																{this.broker_active_grouped().map((broker, index) => {
																	let count     = broker.brokers.length;
																	let tr_broker = broker.brokers.map((broker_item, broker_index) => {													
																						broker_index++;
																						return (
																							<tr 
																								key       = {broker_index}
																								className = {classnames([
																												"RealAccountStyles-broker_td_style",	
																												"RealAccountStyles-broker_td_border_bottom_"+(broker_index === count)
																											])}
																							>
																								<td>{broker_item.broker_name}</td>
																								<td>{broker_item.license_number}</td>
																							</tr>
																						);
																					});
																	
																	let tr_branch = <tr
																						className = {classnames([
																										"RealAccountStyles-broker_td_style",
																										"RealAccountStyles-broker_tr_style"
																									])}
																					>
																						<td rowSpan={++count}>{broker.branch_name}</td>
																						<td></td>
																						<td></td>
																			 		</tr>
																	
																	return (
																				<React.Fragment key={index}>
																					{tr_branch}
																					{tr_broker}
																				</React.Fragment>
																			);											
												  				})}
															</tbody>
														</Table>
													</div>
												}

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			<div>
										  				{Language.get('realaccount.broker_appointed_1','id')+' '}
										  				{this.props.Company && this.props.Company.content.company_name}
										  				{' '+Language.get('realaccount.broker_appointed_2','id')}:
										  			</div>
										  		</h3>
										  		{
													this.state.brokers.content && 
													<div className='RealAccountStyles-table RealAccountStyles-broker'>
														<Table bordered={true}>
															<thead>
																<tr>
																	<th>
																		{Language.get('form_labels.office','id')}
																	</th>
																	<th>
																		{Language.get('form_labels.name','id')}
																	</th>
																	<th>
																		{Language.get('form_labels.license_number','id')}
																	</th>
																</tr>
															</thead>
															<tbody>
																{this.broker_active_assigned().map((broker, index) => {
																	let count = broker.brokers.length;
																	let tr_broker = broker.brokers.map((broker_item, broker_index) => {													
																						broker_index++;
																						return (
																							<tr 
																								key={broker_index} 
																								className = {classnames([
																												"RealAccountStyles-broker_td_style",
																												"RealAccountStyles-broker_td_border_bottom_"+(broker_index === count)
																											])}
																							>
																								<td>{broker_item.broker_name}</td>
																								<td>{broker_item.license_number}</td>
																							</tr>
																						);
																					});
																	
																	let tr_branch = <tr 
																						className = {classnames([
																										"RealAccountStyles-broker_td_style",														
																										"RealAccountStyles-broker_tr_style"
																									])}
																					>
																						<td rowSpan={++count}>{broker.branch_name}</td>
																						<td></td>
																						<td></td>
																			 		</tr>
																	
																	return (
																				<React.Fragment key={index}>
																					{tr_branch}
																					{tr_broker}
																				</React.Fragment>
																			);											
												  				})}
															</tbody>
														</Table>
													</div>
												}

										  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
										  			<div>
										  				{Language.get('realaccount.separated_account_number','id')+' '}
										  				{this.props.Company && this.props.Company.content.company_name}
										  			</div>
										  		</h3>
										  		{
													this.props.Company && 
													<div className='RealAccountStyles-table RealAccountStyles-bank'>
														<Table bordered={true}>
															<thead>
																<tr>
																	<th>
															    		{Language.get('realaccount.bank_propercase','id')}
																	</th>
																	<th>
															    		{Language.get('realaccount.short_separated_account','id')}
																	</th>
																</tr>
															</thead>
															<tbody>
																{this.bank_grouped().map((bank, index) => (
																	<tr key={index}>
																		<td>
															    			{Language.get('realaccount.bank','id')}
																			{' '+bank.bank_name} 
																			{' '+Language.get('form_labels.branch','id')} 
																			{' '+bank.bank_branch}
																		</td>
																		<td>
																			{ bank.bank_accounts.map(
																				(account,account_index) => (
																					<div key={account_index}>
																						<div className='RealAccountStyles-currency'>
																							{account.currency}
																						</div>
																						{account.account_number}
																					</div>
																				)
																			)}
																		</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</div>
												}

											</React.Fragment>
										 }
						footer         = {
											<React.Fragment>
												<h3 
													className='AppStyles-no_margin'
												>{Language.get('realaccount.step1_footer_1','id')} </h3>
												<p> 
													{Language.get('realaccount.step1_footer_2','id')} 
													<b>{Language.get('realaccount.step1_footer_3','id')}</b>
													{Language.get('realaccount.step1_footer_4','id')}
												</p>
											</React.Fragment>
										}
						AcceptStatement= {RealAccountComponents.AcceptStatement(
											this.inputs.accept_statement.ref,
											this.on_accept_statement
										)}
						acceptTermsRef = {this.inputs.accept_statement.ref}
						canSubmit      = {this.state.can_submit}
						timerLabel     = {Language.get('realaccount.declare_on','id')}
					/>
				</Form.Form>;
	}

	broker_active_grouped = () => {
		var grouped = [];

		if (!Helpers.is_empty(this.state.brokers.content)) {

			this.state.brokers.content.forEach((broker) => {
				if (broker.is_active === 1) {
					var index = grouped.findIndex((grouped_broker) => (grouped_broker.branch_name === broker.branch_name));

					if (index <= -1) {
						grouped.push({
							branch_name: broker.branch_name,
							is_hq      : broker.is_hq,
							order      : broker.order,
							brokers    : [broker]
						});
					} else {
						grouped[index].brokers.push(broker);
					}
				}
			});

		}

		// sort branches
		Helpers.sort_branches(grouped, {
											hq_flag     : 'is_hq',
											branch_order: 'order',
											branch_name : 'branch_name',
										});
		for(let branch in grouped) {
			// sort grouped.brokers by broker_name
			Helpers.sort_array_object_by_key(grouped[branch].brokers, 'broker_name');
		}

		return grouped;

	}
	
	broker_active_assigned = () => {
		var grouped = [];

		if (!Helpers.is_empty(this.state.brokers.content)) {

			this.state.brokers.content.forEach((broker) => {
				if (broker.is_active === 1 && broker.is_assigned) {
					var index = grouped.findIndex((grouped_broker) => (grouped_broker.branch_name === broker.branch_name));

					if (index <= -1) {
						grouped.push({
							branch_name: broker.branch_name,
							is_hq      : broker.is_hq,
							order      : broker.order,
							brokers    : [broker]
						});
					} else {
						grouped[index].brokers.push(broker);
					}
				}
			});

		}
		
		// sort grouped by branch_name
		Helpers.sort_branches(grouped, {
											hq_flag     : 'is_hq',
											branch_order: 'order',
											branch_name : 'branch_name',
										});
		
		for(let branch in grouped) {
			// sort grouped.brokers by broker_name
			Helpers.sort_array_object_by_key(grouped[branch].brokers, 'broker_name');
		}

		return grouped;

	}

	bank_grouped = () => {
		var grouped      = [];
		let sorted_banks = [];

		if (!Helpers.is_empty(this.props.Company)) {

			sorted_banks = [...this.props.Company.content.bank_accounts];
			Helpers.sort_array_object_by_key(sorted_banks, 'currency');

			sorted_banks.forEach((bank) => {

				var index = grouped.findIndex((grouped_bank) => (grouped_bank.bank_name === bank.bank_name));
				
				if (index <= -1) {
					grouped.push({
						bank_name    : bank.bank_name,
						bank_accounts: [bank],
						bank_branch  : bank.bank_branch
					});
				} else {
					grouped[index].bank_accounts.push(bank);
				}

			});

		}

		return grouped;
	}

	on_accept_statement = () => {
		this.setState({ can_submit:  parseInt(this.inputs.accept_statement.ref.current.value())===1 });
	}

	on_submit = () => {
  		this.setState({ submitting: 1 });
		//Added on 20220614--------- 
		if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
			//StoreMethods.step_change(2)
			//StoreMethods.step_latest(2)
			//StoreMethods.set_step_progress('Regol - Step 1 Completed')
			//StoreMethods.update_progressName('Regol - Step 1 Completed')
			//StoreMethods.update_activeProgressName('Regol - Step 1 Completed')
		}else{
			Model.update_next_progressName({
				precomplete: () => {
					this.setState({ submitting: 0 });
				},
				fail    : this.form.current.submit_fail,
				error   : this.form.current.submit_error
		  });
		}  
  		
	}

	wysiwyg_onload = (wysiwyg_ref, id) => {
		this.wysiwyg_loaded.push(id);
		this.check_ui_loading();
	}


	check_ui_loading = () => {
		if (this.state.ui_loading===1) {
			if (this.wysiwyg_loaded.length >= this.wysiwyg_length) {
				this.setState({ ui_loading: 0 }, ()=>{
					
				});
			}
		}
		//added by Lukas 20220614
		if(this.checkStep8()){
			this.disableAllandYa()
			StoreMethods.step_enabled_change(8)
			this.changeDateSuccess()
			//console.log('here')
		}
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_company      = !Helpers.is_empty(this.props.Company);
  			var has_step1        = !Helpers.is_empty(this.props.Step1);
  			var has_brokers      = !Helpers.is_empty(this.state.brokers);
  			var has_branches     = !Helpers.is_empty(this.state.branches);
  			// var component        = this;

  			if (has_step1 && has_company && has_branches && has_brokers) {

  				this.setState({ loading: 0 },() => {

			  		if (this.props.Step1.error.code !== '0') {

						this.setState({ error: true, code: this.props.Step1.error.code });

			  		} else {

			  			var wysiwyg_length = this.props.Step1.content.length;
			  			if (wysiwyg_length > 0) {
			  				this.wysiwyg_length = wysiwyg_length;
			  			}

			  		}

  					if (this.props.Company.error.code !== '0') {
						this.setState({ error: true, code: this.props.Company.error.code });
			  		}

  					if (this.state.brokers.error.code !== '0') {
						this.setState({ error: true, code: this.state.brokers.error.code });
			  		}


  					if (this.state.branches.error.code !== '0') {
						this.setState({ error: true, code: this.state.branches.error.code });
			  		}
		
  				});

  			}
		}
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(this.props,temp,'step1')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		//enable step 8
		StoreMethods.step_enabled_change(8)
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				if(temp[keys].ref.current){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		//console.log('CDS',regSuc)
		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}
}

export default StoreMethods.connect_stores(Step1,[
	'User.AccountTypeCode',
	'Step1',
	'Company'
],Model.get_step1);