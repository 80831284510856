import React, { Component } from 'react';
import classnames from 'classnames';
import Form from 'app/components/form/Form';
import Language from 'app/utils/language/Language';
import Image from 'app/components/image/Image';
import LoginStyles from './scss/LoginStyles.module.scss';
import { RouteLinks } from 'app/components/routes/Routes';
import Model from 'app/modules/model/Model';
import GoogleAnalytics from 'app/utils/googleanalytics/GoogleAnalytics';
import LocalForage from 'app/utils/localforage/LocalForage';

class Login extends Component {

	constructor(props) {
		super(props);

		// create refs and validations
		this.inputs = {
			username: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required', type: 'credential' }]
				}
			},
			password: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required', type: 'credential' }]
				}
			},
			captcha: {
				ref: React.createRef(), validations: {
					triggers: ['onChange'],
					validate: [{ as: 'required', type: 'captcha' }]
				}
			}
		};
		this.form = React.createRef();
		this.state = { submitting: 0 };

		this.toast_id = 'Login-authenticating';
	}

	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Login Page');

	}


	render() {
		return (
			<div className={LoginStyles.wrapper}>
				<div className={LoginStyles.background_filler}></div>
				<div className={LoginStyles.container}>
					<div className={classnames([LoginStyles.header, 'AppStyles-container'])}>
						<div className={LoginStyles.logo_container}>
							<Image.Logo />
						</div>
					</div>
					<div className={classnames([LoginStyles.body, 'GlobalStyles-slant_container_secondary'])}>
						<div className={classnames([
							LoginStyles.body_background,
							'GlobalStyles-slant_container_background'
						])}
						>
						</div>
						<div className={classnames([LoginStyles.body_content_boundary, 'AppStyles-container'])}>
							<div className={LoginStyles.body_content_wrapper}>
								<h3 className={classnames([LoginStyles.login_header_msg, 'AppStyles-no_margin'])}>
									{Language.get('login.login_header_msg', 'id')}
								</h3>
								<Form.Form
									inputs={this.inputs}
									onValidateSuccess={this.submit_login}
									ref={this.form}
									submitting={this.state.submitting}
									className={LoginStyles.form}
								>

									<Form.Row>
										<Form.Textbox
											label='username'
											ref={this.inputs.username.ref}
											className={LoginStyles.input}
										/>
									</Form.Row>
									<Form.Row>
										<Form.Passwordbox
											label='password'
											ref={this.inputs.password.ref}
											className={LoginStyles.input}
										/>
									</Form.Row>
									<Form.Row>
										<Form.Captcha
											className={classnames([
												LoginStyles.captcha
											])}
											ref={this.inputs.captcha.ref}
										/>
									</Form.Row>
									<Form.Row buttonContainer>
										<Form.PrimaryButton main={1}>
											{Language.get('form_labels.login')}
										</Form.PrimaryButton>
										<div className={LoginStyles.register_link}>
											{
												Language.get('login.dont_have_account')
											} <RouteLinks type='login' />
										</div>
									</Form.Row>
								</Form.Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	submit_login = (inputs) => {
		var component = this;
		this.setState({ submitting: 1 });

		Model.login(inputs, {
			// this.modal.current.close
			success: (res) => {
				if (res.data.initial_login) {
					component.form.current.display_message(
						'first_login',
						'success', {
						footer: <React.Fragment>
							<Form.SecondaryButton onClick={() => {
								this.form.current.close_modal()
								LocalForage.drop_existing_storage(() => {
									Model.redirect_by_progress((res) => {
										this.setState({ submitting: 0 });
										component.form.current.submit_fail(res)
										component.inputs.captcha.ref.current.reset();
										component.reset_form()
									})
								})
							}}>
								{Language.get('form_labels.ok')}
							</Form.SecondaryButton>
						</React.Fragment>
					}
					);
				} else {
					//   LocalForage.drop_existing_storage(Model.redirect_by_progress);	
					LocalForage.drop_existing_storage(() => {
						Model.redirect_by_progress((res) => {
							this.setState({ submitting: 0 });
							component.form.current.submit_fail(res)
							component.inputs.captcha.ref.current.reset();
							component.reset_form()
						})
					})
				}

			},
			fail: (response) => {
				this.setState({ submitting: 0 });

				let custom_error_message = {};

				if (response.error.code === '-11') {
					custom_error_message = {
						formatter: (error) => {
							let username = this.inputs.username.ref.current.value().toLowerCase();
							return (
								<React.Fragment>
									<b>{username}</b> {error.message}
								</React.Fragment>
							);
						}
					}
				}

				component.form.current.display_message(response.error.code, 'error', custom_error_message);
				component.inputs.captcha.ref.current.reset();
			},
			error: (xhr, status) => {
				this.setState({ submitting: 0 });
				component.form.current.submit_error(xhr, status);
				component.inputs.captcha.ref.current.reset();
			}
		});

	}

	reset_form = () => {
		this.form.current.reset();
	}
}

export default Login;
