import React, { Component }          from 'react';
import classnames                    from 'classnames';
import AdFooterStyles                from './scss/AdFooterStyles.module.scss';
import { callables as StoreMethods } from 'app/store/Store';
import Helpers                       from 'app/utils/helpers/Helpers';
import Model                         from 'app/modules/model/Model';
import Loader                        from 'app/components/loader/Loader';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import Wysiwyg                       from 'app/components/wysiwyg/Wysiwyg';
import Carousel                      from 'app/components/carousel/Carousel';
// import Image                         from 'app/components/image/Image';

class AdFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: 1, error:false, code:null};
    }

    componentDidMount = () => {
        this.check_loading();
    }

    componentDidUpdate = () => {
        this.check_loading();
    }
    
	render() {
    	return (
    		<div className={AdFooterStyles.content}>
                <div className={AdFooterStyles.wrapper}>
                    <div 
                        className = {classnames([
                                        AdFooterStyles.boundary,
                                        this.state.error && 'AppStyles-invisible'
                                    ])}
                    >
                        <div className={AdFooterStyles.picture_container}>
                            {   
                                this.state.loading 
                                || !this.props.CompanyAds.content 
                                || !this.props.CompanyAds.content.footer ? <Loader.Ghost blocks = {3}/> : 
                                <Carousel.Hqueue> 

                                    {
                                        
                                        this.props.CompanyAds.content.secondary.map((file,index) => (
                                            <div 
                                                className = {AdFooterStyles.picture_content} 
                                                key       = {index} 
                                                ref       = {React.createRef()}
                                            >
                                                <div className={AdFooterStyles.picture_content_display}>
                                                    <Wysiwyg.Parse 
                                                        file         = {file.file_path}
                                                        loaderBlocks = {3}
                                                        className    = {AdFooterStyles.wysiwyg}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }

                                </Carousel.Hqueue>
                            }
                        </div>
                        <div className={AdFooterStyles.company_info_wording}>
                            {   
                                this.state.loading ? <Loader.Ghost  blocks = {3}/> : 
                                <React.Fragment>
                                    {
                                        this.props.CompanyAds.content
                                        &&this.props.CompanyAds.content.footer 
                                        && this.props.CompanyAds.content.footer.map((file,index) => (
                                            <Wysiwyg.Parse 
                                                key          = {index} 
                                                file         = {file.file_path}
                                                loaderBlocks = {3}
                                                className    = {AdFooterStyles.wysiwyg}
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {
                        this.state.error 
                        && <MessageDisplay.OnPage 
                                code     = {this.state.code} 
                                type     = 'error' 
                                className= {AdFooterStyles.footer_error} 
                            /> 
                    }
                </div>
      		</div>
    	);
 	}

    check_loading = () => {
        if (this.state.loading===1) {
            var has_CompanyAds = !Helpers.is_empty(this.props.CompanyAds);

            if (has_CompanyAds) {

                if (this.props.CompanyAds.error.code === '0') {
                    this.setState({ loading: 0 });
                } else {
                    this.setState({ loading: 0, error: true, code: this.props.CompanyAds.error.code});
                }
            }
        }
    }
}

export default StoreMethods.connect_stores(AdFooter,['CompanyAds'], Model.get_company_ads);