import React, { useState, useEffect } from 'react';
import Layout from 'app/components/layout/Layout';
import Helpers from 'app/utils/helpers/Helpers';
import Model from 'app/modules/model/Model';
import Loader from 'app/components/loader/Loader';
import { ToastContainer } from 'app/components/toast/Toast';
import { callables as StoreMethods } from 'app/store/Store';
import Language from 'app/utils/language/Language';
import Modal from './components/modal/Modal';
import Form from 'app/components/form/Form';
import GoogleAnalytics from 'app/utils/googleanalytics/GoogleAnalytics';
import { Helmet } from "react-helmet";

import './scss/AppStyles.module.scss';

/**
 * callback for login token changed
 * Modal will pop up in other tab asking if they want to refresh the page
 * @param {int} value 
 * @param {obj} ref 
 */
function login_callback(value, ref) {

    function proceed_refresh() {
        window.location = '/';
    }

    function cancel_refresh() {
        ref.current.close();
    }

    if (parseInt(value) === 1 && !StoreMethods.is_user_login()) {

        if (!Helpers.is_empty(ref)) {
            ref.current.open({
                footer: <React.Fragment>
                    <Form.PrimaryButton onClick={proceed_refresh}>
                        {Language.get('realaccount.yes')}
                    </Form.PrimaryButton>
                    <Form.SecondaryButton onClick={cancel_refresh}>
                        {Language.get('realaccount.no')}
                    </Form.SecondaryButton>
                </React.Fragment>
            });
        }

    } else if (parseInt(value) === 0 && StoreMethods.is_user_login()) {
        window.location = '/login';
    }
}

function App(props) {
    const [loading, set_loading] = useState(true);
    const [initiated, set_initiated] = useState(false);
    const [lastCustomerID, set_lastCustomerID] = useState(null);
    const modal_ref = React.useRef();

    useEffect(() => {
        GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKER_ID, { debug: true });
    }, [])

    useEffect(() => {

        if (!initiated) {

            set_initiated(true);

            console.log('%cv' + props.appVersion, [
                'background: rgba(30, 87, 153,.3)',
                'color     : rgba(255,255,255,.6)',
                'padding   : 4px',
                'opacity   : .1'
            ].join(';'));
        }

        load_initial_data();

        Model.subscribe_login_changed(login_callback, modal_ref);

        return () => {
            Model.unsubscribe_login_changed({
                key: 'login',
                name: 'login_changed'
            });
        }

    }, [loading, props.User.auth]);

    /** 
     * This will load primary viewdata
     * @return void
     */
    function load_initial_data() {
        var customerID = Model.get_customerID();

        if (Helpers.is_empty(customerID)) {

            load_app();

        } else {

            if (lastCustomerID !== customerID) {

                set_loading(true);

                Model.update_user_session(customerID, {
                    complete: load_app
                });

            }

        }

        set_lastCustomerID(customerID);
    }

    /**
     * This will load the app
     * @return  void
     */
    function load_app(response) {
        set_loading(false);
    }

    return <React.Fragment>
        {loading ?
            (<Loader.Screen />)
            :
            (<Layout />)
        }
        <Helmet>
            <title>Regol PT {process.env.REACT_APP_TITLEPIALANG}</title>
        </Helmet>
        <ToastContainer />
        <Modal
            ref={modal_ref}
            body={<p>{Language.get('warning.session_changed')}</p>}
            size='small'
            disableClosing={true}
        />
    </React.Fragment>;
}


export default StoreMethods.user_connect(App);