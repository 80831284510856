import { ActionTypes } from '../actions/StepActions';
import Model           from 'app/modules/model/Model';
// import Helpers         from 'app/utils/helpers/Helpers';

var initial_state = {};

var callables = {

	[ActionTypes.clear_step](state, action) {
		return Object.assign({});
	},

	[ActionTypes.set_step_progress](state, action) {
		if (action.progress.error.code === '0' ) {
			//console.log(action.progress.previous_progress,"reducer")
			var regsus = Model.if_regol_successfull(action.progress.previous_progress);
			var activeStep= Model.progressName_to_number(action.progress.progressName);
			var new_state = {
								stepTotal         : 8,
								enabledSteps      : regsus ? 8 : activeStep,
								activeStep        : activeStep,
								activeProgressName: action.progress.progressName,
								hasChanges        : false,
								progressName      : action.progress.progressName,
								previous_progress : action.progress.previous_progress,
								regol_sucessfull_date: action.progress.regol_sucessfull_date,
								regol_sucessfull_date_utc: action.progress.regol_sucessfull_date_utc
							};

			return Object.assign({}, state, { error:action.progress.error, content: new_state});

		} else {
			return Object.assign({}, state, action.progress);

		}
	},

	[ActionTypes.update_activeStep](state, action) {
		var new_content = Object.assign({},state.content,{ activeStep: action.activeStep, hasChanges:false});
		return Object.assign({}, state, { content: new_content });
	},

	[ActionTypes.update_enabledSteps](state, action) {
		var new_content = Object.assign({},state.content,{ enabledSteps: action.enabledSteps });
		return Object.assign({}, state, { content: new_content });
	},

	[ActionTypes.update_hasChanges](state, action) {
		var new_content = Object.assign({},state.content,{ hasChanges: action.hasChanges });
		return Object.assign({}, state, { content: new_content });
	},

	[ActionTypes.update_progressName](state, action) {
		var new_content = Object.assign({},state.content,{ progressName: action.progressName });
		return Object.assign({}, state, { content: new_content });
	},

	[ActionTypes.update_activeProgressName](state, action) {
		var new_content = Object.assign({},state.content,{ activeProgressName: action.progressName });
		return Object.assign({}, state, { content: new_content });
	},

	[ActionTypes.add_previous_progress](state, action) {
		var new_previous_progress = [...state.content.previous_progress];
		new_previous_progress.push(action.progressName);
		var new_content           = Object.assign({},state.content,{ previous_progress: new_previous_progress});
		return Object.assign({}, state, { content: new_content });
	}
};

export default function(state = initial_state, action) {

	if (callables.hasOwnProperty(action.type)) {
		return callables[action.type](state, action);
	}

	return state;
};