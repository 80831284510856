import React,{useEffect} from 'react';
import classnames        from 'classnames';
import RealAccountStyles from './scss/RealAccountStyles.module.scss';
import {RouteLinks}      from 'app/components/routes/Routes';
import Language          from 'app/utils/language/Language';
import FontIcons         from 'app/components/fonticons/FontIcons';
import Carousel          from 'app/components/carousel/Carousel';
// import Helpers           from 'app/utils/helpers/Helpers';

function StepperLinkText(number,enabledSteps) {
	return  <React.Fragment>
				<div className={RealAccountStyles.stepper_number}>
					{  
						enabledSteps > number?
							<span className={RealAccountStyles.stepper_done_circle}>
								<FontIcons.Material icon='done' />
							</span>
						:
							<span className={RealAccountStyles.stepper_number_circle}>{number}</span>
					}
				</div>
				<div className={RealAccountStyles.stepper_text}>
					{Language.get('realaccount.step'+number, 'id')}
				</div>
			</React.Fragment>
}

function StepperLink(number, activeStep, enabledSteps) {
	return  <div 
				className = {classnames([
								RealAccountStyles.stepper_link,
								enabledSteps >= number ? 
									RealAccountStyles.stepper_enabled : RealAccountStyles.stepper_disabled,
								enabledSteps >  number && RealAccountStyles.stepper_next_enabled,
								activeStep  ===  number && RealAccountStyles.stepper_active
							])} 
				key       = {number}
				ref       = {React.createRef()}
			>
				<div 
					className = {classnames([
									RealAccountStyles.stepper_left_line,
									RealAccountStyles.stepper_line
								])}
				></div>
				{
					enabledSteps >= number && activeStep !== number ? 
						<RouteLinks 
							tabIndex = {-1}
							type     = {'step'+number} 
							key      = {number}
						>
							{StepperLinkText(number,activeStep,enabledSteps)}
						</RouteLinks>
					:
						<div 
							className = {RealAccountStyles.stepper_readonly_text}
						>
							{StepperLinkText(number,activeStep,enabledSteps)}
						</div>
				}
				<div 
					className = {classnames([
									RealAccountStyles.stepper_right_line,
									RealAccountStyles.stepper_line]
								)}
				></div>
			</div>;
}

function RealAccount(props) {
	var hqueue_ref = React.createRef();

	useEffect(() => {
		if (hqueue_ref.current!==null) {
			hqueue_ref.current.move(props.Step.content.activeStep-1);
		}
	},[props.Step.content.activeStep]);

	return  <React.Fragment>
				<div className={classnames([RealAccountStyles.stepper,'AppStyles-container'])}>
					<Carousel.Hqueue active={props.Step.content.activeStep-1} ref={hqueue_ref}>
						{
							[...Array(props.Step.content.stepTotal)].map((val,index) => {
								return StepperLink(
									index+1, 
									props.Step.content.activeStep, 
									props.Step.content.enabledSteps
								);
							})
						}
					</Carousel.Hqueue>
				</div>
				<div className={RealAccountStyles.pages}>
					{props.pages[props.Step.content.activeStep]}
				</div>
			</React.Fragment>;
}

export default RealAccount;