/**
 * ---------------------------------------------------------------------------------------------------------------------
 * NOTES
 * ---------------------------------------------------------------------------------------------------------------------
 * - Use classes instead of functions only, so refs could be assign for every components.
 * 
 */

import React        from 'react';
import classnames   from 'classnames';
import LoaderStyles from './scss/LoaderStyles.module.scss';

function Spinner(props) {
	return  <div className={classnames([LoaderStyles.wrapper,props.className])}>
		        <div className={LoaderStyles.spinners}>
	        		{ 	
	        			[...Array(12)].map((value, index) => 
	        				<div className={LoaderStyles.spinners_child} key={index}></div>
	        			)
					}
		        </div>
		    </div>;

}

function Screen(props) {
	return  <Spinner/>;
}

export default { Spinner, Screen };