import React 					   from 'react';
import FormValidatorCommon         from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language                    from 'app/utils/language/Language';
import Form                        from 'app/components/form/Form';
import Moment                      from 'moment';
import Helpers                     from 'app/utils/helpers/Helpers'; 
import ReactMoment                 from 'react-moment';
import 'moment/locale/id';
import 'moment-timezone';

function editable(inputs, props, component) {
	return 	<React.Fragment>
				<p>{Language.get('realaccount.who_fill_out','id')}</p>
	    		<Form.Row>
	      			<Form.Textbox     
	      				label        = 'id_full_name' 
	      				className    = 'AppStyles-full_size_input'
	      				ref          = {inputs.full_name.ref}
	      				defaultValue = {props.User.details.full_name}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      			/>
	      		</Form.Row>
	    		<Form.Row>
	      			<Form.Textbox     
	      				label        = 'id_place_birth' 
	      				className    = 'AppStyles-half_size_input AppStyles-half_size_input'
	      				ref          = { inputs.place_of_birth.ref }
	      				defaultValue = { props.User.details.place_of_birth }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      			/>
	      			<Form.DatePicker
	      				label        = 'id_date_birth' 
	      				className    = 'AppStyles-half_size_input'
	      				ref          = {inputs.date_of_birth.ref}
	      				defaultValue = {props.User.details.date_of_birth}
	      				onTouched    = {props.onTouched}
	      				onChange     = {(event_data) => {
	      									event_data = event_data || {};

											// early checking
											if (event_data.setter !== 'form') {
												var date_of_birth = inputs.date_of_birth.ref.current.value();
												if(!Helpers.is_empty(date_of_birth)) {
													var age = Moment().diff(Moment(date_of_birth), 'years');
													if (age<17) {
														component.form.current.display_message('ERR_00029','error');
													}																
												}
											}

	      								}}
	      				required     = {1}
	      			/>
	      		</Form.Row>
	    		<Form.Row>
	      			<Form.TextArea     
	      				label        = 'id_address' 
	      				className    = 'AppStyles-half_size_input'
	      				ref          = { inputs.home_address.ref }
	      				defaultValue = { props.User.details.home_address }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      			/>
	      			<Form.Textbox     
	      				label        = 'id_postal_code' 
	      				className    = 'AppStyles-half_size_input'
	      				ref          = { inputs.home_postal_code.ref }
	      				defaultValue = { props.User.details.home_postal_code }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
	      			/>
	      		</Form.Row>
	    		<Form.Row>
	    			<Form.idTypeNumber
	    				inputs        = {inputs}
	    				noTranslation = {true}
	    				id_type       = {{ 
		    								defaultValue:props.User.details.id_type,   
		    								onTouched   : props.onTouched,
		      								required    : 1
		    							}}
	    				id_number = {{ 
	    								defaultValue:props.User.details.id_number, 
	    								onTouched   : props.onTouched,
	      								required    : 1
	    							}}
	    			/>
	      		</Form.Row>
			</React.Fragment>;
}

function inputs() {
	const id_type_ref = React.createRef();
	const date_birth_ref = React.createRef();

	function check_age() {
		var date_of_birth = date_birth_ref.current.value();

		if(!Helpers.is_empty(date_of_birth)) {
			var age = Moment().diff(Moment(date_of_birth), 'years');
			if (age<17) {
				return false;
			}																
		}

		return true;
	}

	return 	{
				full_name       :{ ref: React.createRef(),validations:FormValidatorCommon.full_name_with_limit },
				place_of_birth  :{ ref: React.createRef(),validations:FormValidatorCommon.place_of_birth  },
				date_of_birth   :{ 
								 	ref        :date_birth_ref,
									validations:{
													triggers: ['onChange'],
													validate: [
																{ as: 'required', type: 'date_of_birth' },
																{ as: 'truthy', type: 'age', checker: check_age}
															]
												}
								},
				home_address    :{ ref: React.createRef(),validations:FormValidatorCommon.home_address },
				home_postal_code:{ ref: React.createRef(),validations: FormValidatorCommon.postal_code },
				id_type         :{ ref: id_type_ref      ,validations:FormValidatorCommon.id_type},
				id_number       :{  
									ref: React.createRef(), 
									validations: FormValidatorCommon.id_number(id_type_ref) 
								}
			};
}

function view(props) {
	return 	<React.Fragment>
				<p>{Language.get('realaccount.who_fill_out','id')}</p>
	    		<Form.Row>
	      			<Form.TextView     
	      				label        = 'id_full_name' 
	      				className    = 'AppStyles-full_size_input'
	      			>{ props.User.details.full_name }</Form.TextView>
	      		</Form.Row>
	    		<Form.Row>
	      			<Form.TextView     
	      				label        = 'id_place_birth' 
	      				className    = 'AppStyles-half_size_input'
	      			>{ props.User.details.place_of_birth }</Form.TextView>
	      			<Form.TextView
	      				label        = 'id_date_birth' 
	      				className    = 'AppStyles-half_size_input'
	      			>
						<ReactMoment 
							format  ={process.env.REACT_APP_DATE_DISPLAY} 
							locale  ='id'
							element ='span'
						>
							{ props.User.details.date_of_birth }
						</ReactMoment>
					</Form.TextView>
	      		</Form.Row>
	    		<Form.Row>
	      			<Form.TextView     
	      				label        = 'id_address' 
	      				className    = 'AppStyles-half_size_input'
	      			>{ props.User.details.home_address }</Form.TextView>
	      			<Form.TextView     
	      				label        = 'id_postal_code' 
	      				className    = 'AppStyles-half_size_input'
	      			>{ props.User.details.home_postal_code }</Form.TextView>
	      		</Form.Row>
	    		<Form.Row>
	      			<Form.TextView     
	      				label        = 'id_id_types' 
	      				className    = 'AppStyles-half_size_input'
	      			>{ props.User.details.id_type }</Form.TextView>
	      			<Form.TextView     
	      				label        = 'id_id_number' 
	      				className    = 'AppStyles-half_size_input'
	      			>{ props.User.details.id_number }</Form.TextView>
	      		</Form.Row>
			</React.Fragment>;
}

export default { editable, inputs, view };