const ActionTypes = {
						update_activeStep        :'update_activeStep',
						update_enabledSteps      :'update_enabledSteps',
						update_doneSteps         :'update_doneSteps',
						update_hasChanges        :'update_hasChanges',
						set_step_progress        :'set_step_progress',
						clear_step               :'clear_step',
						update_progressName      :'update_progressName',
						update_activeProgressName:'update_activeProgressName',
						add_previous_progress    :'add_previous_progress'
					};

var callables = {
	/**	
	 * Update whole step store data
	 * @param  string progress
	 * @return object
	 */
	set_step_progress: function(progress) {
		return { type: ActionTypes.set_step_progress, progress };
	},

	/**	
	 * Clear whole step store
	 * @param  
	 * @return object
	 */
	clear_step: function() {
		return { type: ActionTypes.clear_step };
	},

	/**	
	 * Update activeStep to the store
	 * @param  object activeStep
	 * @return object
	 */
	update_activeStep: function(activeStep) {
		return { type: ActionTypes.update_activeStep, activeStep };
	},

	/**	
	 * Update enabledStep to the store
	 * @param  object enabledSteps
	 * @return object
	 */
	update_enabledSteps: function(enabledSteps) {
		return { type: ActionTypes.update_enabledSteps, enabledSteps };
	},

	/**	
	 * Update enabledStep to the store
	 * @param  object is_done
	 * @return object
	 */
	update_doneSteps: function(is_done) {
		return { type: ActionTypes.update_doneSteps, is_done };
	},

	/**	
	 * Update enabledStep to the store
	 * @param  boolean hasChanges
	 * @return object
	 */
	update_hasChanges: function(hasChanges) {
		return { type: ActionTypes.update_hasChanges, hasChanges };
	},

	/**	
	 * Update progressName to the store
	 * @param  string progressName
	 * @return object
	 */
	update_progressName: function(progressName) {
		return { type: ActionTypes.update_progressName, progressName };
	},

	/**	
	 * Update progressName to the store
	 * @param  string progressName
	 * @return object
	 */
	update_activeProgressName: function(progressName) {
		return { type: ActionTypes.update_activeProgressName, progressName };
	},

	/**	
	 * Update progressName to the store
	 * @param  string progressName
	 * @return object
	 */
	add_previous_progress: function(progressName) {
		return { type: ActionTypes.add_previous_progress, progressName };
	}
};

export default callables;
export { ActionTypes };