const ActionTypes = {update_step4:'update_step4'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object files
	 * @return object
	 */
	update_step4: function(step4) {
		return { type: ActionTypes.update_step4, step4 };
	}

};

export default callables;
export { ActionTypes };