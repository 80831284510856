import React, { Component }          from 'react';
import classnames                    from 'classnames';
import Image                         from 'app/components/image/Image';
import AdHeader                      from './adheader/AdHeader';
import AdFooter                      from './adfooter/AdFooter';
import RegistrationForm              from './registrationform/RegistrationForm';
import RegistrationStyles            from './scss/RegistrationStyles.module.scss';
import { callables as StoreMethods } from 'app/store/Store';
import Model                 		 from 'app/modules/model/Model';
import Helpers 			 			 from 'app/utils/helpers/Helpers';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';

class Registration extends Component {
	
	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Registration Page');
	}

  	render() {
		const has_AdHeader = !Helpers.is_empty(this.props.CompanyAds) && this.props.CompanyAds.content 
		&& this.props.CompanyAds.content.main;

    	return (
      		<div className={!has_AdHeader && RegistrationStyles.has_no_AdHeader}>
				<div className={classnames([
									RegistrationStyles.header,
									'AppStyles-container'
								])}
				>
					<div className={RegistrationStyles.logo_container}>
						<Image.Logo/>
					</div>
				</div>
				<div 
					className = {classnames([
									RegistrationStyles.body,
									'GlobalStyles-slant_container_secondary'
								])}
				>
					<div 
						className = {classnames([
										RegistrationStyles.body_background,
										'GlobalStyles-slant_container_background'
									])}
					>
					</div>
		    		<div className={RegistrationStyles.body_content_wrapper}>
		    			<div 
		    				className = {classnames([
											RegistrationStyles.form_container,
											RegistrationStyles.main_display_boundary
										])} 
		    			>
		    				<div className={classnames([
		    									RegistrationStyles.main_display,
		    									'.RegistrationStyles-form_main_display'
		    								])}
		    				>
		    					<RegistrationForm/>
					    	</div>
			    		</div>
		    			<div 
		    				className = {classnames([
											RegistrationStyles.adheader_container,
											RegistrationStyles.main_display_boundary
										])}
		    			>
		    				<div className={RegistrationStyles.main_display}>
		    					<AdHeader/>
		    				</div>
		    			</div>
		      		</div>
	      		</div>
	      		<div className = {RegistrationStyles.adfooter_wrapper}>
		      		<div 
		      			className = {classnames([
		      							RegistrationStyles.adfooter_container,
										'AppStyles-container'
		      						])}
		      		>
						<AdFooter/>
		      		</div>
	      		</div>
	      	</div>
    	);
 	}
}

export default StoreMethods.connect_stores(Registration,['CompanyAds'], Model.get_company_ads);
