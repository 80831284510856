import WarningEn             from './config/en/Warning';
import WarningId             from './config/id/Warning';
import ErrorEn               from './config/en/Error';
import ErrorId               from './config/id/Error';
import FormLabelsEn          from './config/en/FormLabels';
import FormLabelsId          from './config/id/FormLabels';
import RegistrationEn        from './config/en/Registration';
import RegistrationId        from './config/id/Registration';
import LoginEn               from './config/en/Login';
import LoginId               from './config/id/Login';
import MenuEn                from './config/en/Menu';
import MenuId                from './config/id/Menu';
import HomeEn                from './config/en/Home';
import HomeId                from './config/id/Home';
import RealAccountEn         from './config/en/RealAccount';
import RealAccountId         from './config/id/RealAccount';
import SuccessEn             from './config/en/Success';
import SuccessId             from './config/id/Success';
import ActivationEn             from './config/en/Activation';
import ActivationId             from './config/id/Activation';
import Helpers               from 'app/utils/helpers/Helpers';
import ErrorMap              from './errormap/ErrorMap';

var language_set  = 'en';
var language_init = {
						en: {
								warning     : WarningEn,
								error       : ErrorEn,
								success     : SuccessEn,
								form_labels : FormLabelsEn,
								registration: RegistrationEn,
								login       : LoginEn,
								menu        : MenuEn,
								home        : HomeEn,
								realaccount : RealAccountEn,
								activation  : ActivationEn
							},
						id: {
								warning     : WarningId,
								error       : ErrorId,
								success     : SuccessId,
								form_labels : FormLabelsId,
								registration: RegistrationId,
								login       : LoginId,
								menu        : MenuId,
								home        : HomeId,
								realaccount : RealAccountId,
								activation  : ActivationId
							}
					}; 
var languages 	  = {
						en: {},
						id: {}
					}; 

var callables = {

	get_strict(category,language) {
		language          = language || language_set;
		var segments      = category.split('.');
		var first_segment = segments.shift();

		if (!languages[language].hasOwnProperty(first_segment)) {
			if (Helpers.is_function(language_init[language][first_segment])) {
				languages[language][first_segment] = language_init[language][first_segment]();
			}
		} 

		return Helpers.get_property(languages[language], category);
	},

	get(category, language) {
		var text = callables.get_strict(category,language);
		return Helpers.is_empty(text) ? category : text;
	},

	has(category, language) {
		return !Helpers.is_empty(callables.get_strict(category, language));
	},

	/**
	 * This will convert the code base on langauge error/success mapping,
	 * together with its header if it have assigned one.
	 * If its not in map list, it will try to get the code instead
	 * @param  string code 
	 * @param  string type
	 * @param  string language
	 * @return object {header, message}
	 */
	convert_get(code, type, language) {
		type = type || 'error';

		code = callables.converted_code(code, type);

		return {
			header : callables.get_strict(type+'.'+code+'_header', language),
			message: callables.get_strict(type+'.'+code, language),
			converted_code: code
		};
	},

	/**
	 * This will convert code using ErrorMap object
	 * @param  string code
	 * @param  string type 
	 * @return string
	 */
	converted_code(code, type) {

		if (type === 'error') {

			if (Helpers.is_prefix(code, 'NET_')) {

				return code==='NET_0' ? 'ERR_00023' : 'ERR_00022';

			} else if(ErrorMap.hasOwnProperty(code)) {

				return ErrorMap[code];

			}

		}

		return code;
	}
};

export default callables;