function contents() {

	return {
		ERR_00001 : 'This field is required',
		ERR_00002 : 'One or more field in a group is blank',
		ERR_00003 : 'Names may only contain alphabet, periods, commas, and single quote.',
		ERR_00004 : 'ID Card number may only contains number and alphabet.',
		ERR_00005 : 'ID Card number may only contains number.',
		ERR_00006 : 'Email is not valid.',
		ERR_00007 : 'invalid reCaptcha.',
		ERR_00008 : 'Username/Password is incorrect.',
		ERR_00009 : 'You can not proceed further because you have a family member that is working at '
					+'CoFTRA/Futures Exchange/Derivatives Clearing House.',
		ERR_00010 : 'You can not proceed further because you were declared bankrupt.',
		ERR_00011 : 'Place of birth is required.',
		ERR_00012 : 'Date of birth is required.',
		ERR_00013 : 'Home address is required.',
		ERR_00014 : 'City is required.',
		ERR_00015 : 'Postal code is required.',
		ERR_00016 : "Mother’s name is required.",
		ERR_00017 : 'The requested data does not match. Please try to clear your browser cache and reload this page.',
		ERR_00018 : 'Please fill out all required fields with correct information, or choose one from the given options.',
		ERR_00019 : 'Session expired or invalid. Please reload this page.',
		ERR_00020 : 'This customer account already exists. You can login your account through login page.',
		ERR_00021 : 'Please complete the previous steps, or try to reload this page.',
		ERR_00022 : 'There was a problem in processing the request. Please try again later.',
		ERR_00023 : 'Network disconnected. Please check your internet connection.',
		ERR_00024 : 'Your account is not active yet. Please contact your broker to activate your account.',
		ERR_00025 : 'The purpose is only for speculation.',
		ERR_00026 : 'NPWP must be filled up with 15 or 16 digit number.',
		ERR_00027 : 'NPWP is required.',
		ERR_00028 : 'Require to upload a document.',
		ERR_00029 : 'Your age does not meet the minimum requirement.',
		ERR_00030 : 'access denied! Account already used.',
		ERR_00031 : 'NPWP must be filled up with at least 5 characters.',
		ERR_00032 : 'This field must be filled up with at least 5 digit number.',
		ERR_00033 : 'This field requires 16 digits.',
		ERR_00034 : 'This field requires 12 to 16 digits.'
	};

}

export default contents;