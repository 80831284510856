function contents() {

	return {
		account_demo: 'AKUN DEMO',
		etrade_demo : 'Etrade Demo',
		tradeprofx  : 'TradeproFX'
	};

}

export default contents;