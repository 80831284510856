import React, { Component }  from 'react';
import classnames            from 'classnames';
import ReactModal 			 from 'react-responsive-modal';
import ModalStyles           from './scss/ModalStyles.module.scss';
import Helpers               from 'app/utils/helpers/Helpers';

class Modal extends Component {

	constructor(props) {
		super(props);
	    this.state = {
	    				open     : false,
	    				parts    : {
	    								header   : this.props.header,
	    								subheader: this.props.subheader,
	    								body     : this.props.body,
	    								footer   : this.props.footer
	    						   }
	    			};
	    this.onClose = null;
	}

	open = (parts) => {
		parts = parts || {};

        var {
			onClose,
			formatter,
            ...filtered_parts
        } = parts;

        this.onClose = parts.onClose;

		// this.setState({ open: true, parts: {...{},...this.state.parts,...filtered_parts}},() => {
		// 	document.querySelector('html').style.overflow = 'visible';
		// });
		this.setState({ open: true, parts: {
			header   : filtered_parts.header || this.state.parts.header,
			subheader: filtered_parts.subheader || this.state.parts.subheader,
			body     : filtered_parts.body || this.state.parts.body,
			footer   : filtered_parts.footer || this.state.parts.footer
	   }},() => {
			document.querySelector('html').style.overflow = 'visible';
		});
	}

	close = () => {
		this.setState({ open: false },() => {
			document.querySelector('html').style.overflow = '';

			if(!this.props.header && !this.props.subheader && !this.props.body && !this.props.footer){
				this.setState({parts:{}})
			}
			if (Helpers.is_function(this.onClose)) {
				this.onClose();
				this.onClose = null;
			}
		});
	}

	render() {
	    return (
        	<ReactModal 
        		{...this.props} 
        		closeOnEsc          = { this.props.disableClosing ? false : this.props.closeOnEsc }
        		closeOnOverlayClick = { this.props.disableClosing ? false : this.props.closeOnOverlayClick }
        		showCloseIcon       = { this.props.disableClosing ? false : this.props.showCloseIcon }

        		open       = {this.state.open} 
        		onClose    = {this.close} 
        		classNames = {{ 
        						overlay:classnames([
        									ModalStyles.overlay, 
        									this.props.size && ModalStyles[this.props.size], 
        									this.props.className
        								]),
        						modal  : ModalStyles.modal
        					}}
        		center
        	>
        		{	
        			Object.keys(this.state.parts).map((key, index) => (
          				this.state.parts[key] 
          				&&  <div className={classnames([ModalStyles[key], ModalStyles.parts])} key={index}>
          						{this.state.parts[key]}
          					</div>
					))
        		}
        	</ReactModal>
	    );
	}

}

export default Modal;