/**
 * ---------------------------------------------------------------------------------------------------------------------
 * NOTES
 * ---------------------------------------------------------------------------------------------------------------------
 * - Use classes instead of functions only, so refs could be assign for every components.
 * 
 */

import React, { Component }                           from 'react';
import classnames                                     from 'classnames';
import ReCAPTCHA                                      from 'react-google-recaptcha';
import AddEvent                                       from 'app/utils/addevent/AddEvent';
import Helpers                                        from 'app/utils/helpers/Helpers';
import Language                                       from 'app/utils/language/Language';
import form_validator                                 from './formvalidator/FormValidator';
import FormStyles                                     from './scss/FormStyles.module.scss';
import FontIcons                                      from 'app/components/fonticons/FontIcons';
import Loader                                         from 'app/components/loader/Loader';
import                                                     'react-dates/initialize';
import { SingleDatePicker }                           from 'react-dates';
import                                                     'react-dates/lib/css/_datepicker.css';
import Moment                                         from 'moment';
import LocalForage                                    from 'app/utils/localforage/LocalForage';
import Image                                          from 'app/components/image/Image';
import 'moment/locale/id';
import {RouteChangeSubscribe, RouteChangeUnsubscribe} from 'app/components/routes/Routes';
import Modal                                          from 'app/components/modal/Modal';
import MessageDisplay                                 from 'app/components/messagedisplay/MessageDisplay';
import Model                                          from 'app/modules/model/Model';
import Table                                          from 'app/components/table/Table';

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Form Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Custom propsdate *  - validations      : validation object t be passed to FormValidator
 *  - onValidateSuccess: callback function if all form validation passed.
 *  - onValidateFail   : callback function if form validation failed.
 *  
 */
class Form extends Component {

    constructor(props) {
        super(props);
        this.ref   = React.createRef();
        this.state = {inputs: this.props.inputs || {}, idcaptcha:''}

        if (props.storevalues) {
            let key = 'form_' + props.storename;

            this.form_store = LocalForage.createInstance({ name: key });
            LocalForage.storage_subscribe(key, this.form_store);
            RouteChangeSubscribe(props.storename, this.form_store.clear);
        }

        this.modal          = React.createRef();
        new AddEvent(this,['onSubmit','onReset','onValidateSuccess','onValidateFail']);
    }

    componentDidMount = () => {
        if (this.props.inputsManualUpdate!==true) {
            this.inputs_complete();
        }
    }


    update_inputs = (inputs) => {
        this.setState({ inputs: inputs }, this.inputs_complete);
    }

    inputs_complete = () => {
        form_validator.apply(this);

        if (this.props.storevalues) {
            var form_store      = this.form_store;
            
            for (var field_name in this.state.inputs) {

                var field_object   = this.state.inputs[field_name];
                var comp_ref       = field_object.ref.current;
                let event_delay_id = Helpers.uniqid();

                if (!field_object.store_exempt) {
                    form_store.getItem(field_name,function(field_name, comp_ref) {
                        return (err, value) => {
                            if (!Helpers.is_empty(value) && Helpers.is_function(comp_ref.set_value)) {
                                comp_ref.set_value(value,'form');
                            }
                        }

                    }(field_name, comp_ref));

                    comp_ref.add_event(['onChange'] , function(field_name, comp_ref) {

                        return function(event) {
                            Helpers.event_delay_animation(()=> {
                                form_store.setItem(field_name, comp_ref.value()).then(() => {
                                    return form_store.getItem(field_name);
                                });
                            }, 1200, event_delay_id);
                        };

                    }(field_name, comp_ref));

                }
            }
        }

    }

    componentWillUnmount = () => {
        if (this.props.storevalues) {
            RouteChangeUnsubscribe(this.props.storename);
        }
    }

    submit = (event_data) => {
        event_data.preventDefault();

        if (Helpers.is_function(this.props.onSubmit)) {
            this.props.onSubmit(event_data);
        }

        this.trigger_event('onSubmit',event_data);
    }

    reset = () => {
        for (var field_name in this.state.inputs) {
            var field_object = this.state.inputs[field_name];
            var comp_ref     =  field_object.ref.current;
            if (comp_ref !== null &&Helpers.is_function(comp_ref.reset)) {
                comp_ref.reset();
            }
        }

        if (Helpers.is_function(this.props.onReset)) {
            this.props.onReset();
        }

        this.trigger_event('onReset');
    }
    
    onValidateSuccess = () => {

        var validated_field_values = {};
        for (var field_name in this.state.inputs) {
            var field_object = this.state.inputs[field_name];
            
            var comp_ref     = field_object.ref.current;
            if (comp_ref) {
                var value        = Helpers.is_function(comp_ref.value) ? comp_ref.value() : comp_ref.value;
                validated_field_values[field_name] = value;
            }
        }

        if (Helpers.is_function(this.props.onValidateSuccess)) {
            this.props.onValidateSuccess(validated_field_values);
        }

        this.trigger_event('onValidateSuccess');
    }
    
    onValidateFail = () => {
        if (Helpers.is_function(this.props.onValidateFail)) {
            this.props.onValidateFail();
        }
        
        this.trigger_event('onValidateFail');
    }

    display_message = (code, type, modal_options) => { 
        modal_options     = modal_options || {};
        var message_parts = MessageDisplay.message_parts(code, type, modal_options);
        this.modal.current.open({...modal_options, ...{
            header: (   
                        <div className={classnames([FormStyles.form_modal_header, FormStyles['form_modal_'+type]])}>
                                <div  className={FormStyles.modal_icon_container}>
                                    <div className={FormStyles.form_modal_icon}>
                                        {message_parts.icon}
                                    </div>
                                </div>
                                {message_parts.header}
                        </div>
                    ),
            body    : message_parts.body,
            footer  : modal_options.footer ||  
                    (
                        <React.Fragment>
                            {message_parts.actions.map((value)=>(value))} 
                            <SecondaryButton onClick={this.modal.current.close}>
                                { Language.get('form_labels.close') }
                            </SecondaryButton>
                        </React.Fragment>
                    )
        }});
    }

    submit_fail = (response) => {
        this.display_message(response.error.code, 'error');
    }

    submit_error = (xhr, status) => {
        this.display_message(status, 'error');
    }

    close_modal = () => {
        this.modal.current.close()
    }

    render() {

        var {
            onValidateSuccess,
            onValidateFailed,
            inputs,
            storevalues,
            storename,
            storerouteclear,
            inputsManualUpdate,
            ...filtered_props
        } = this.props;

        // var storerouteclear = this.props.hasOwnProperty('storerouteclear') ? this.props.storerouteclear : true;

        return  <React.Fragment>
                    <form 
                    style={this.props.style}
                        {...filtered_props} 
                        ref         = {this.ref} 
                        onSubmit    = {this.submit}
                        className   = { classnames([
                                        FormStyles.form,
                                        this.props.className,
                                        this.props.loading && FormStyles.loading,
                                        this.props.submitting && FormStyles.submitting
                                    ])} 
                    >
                        { this.props.children }
                    </form>
                    <Modal ref={this.modal} size='small' disableClosing={true} focusTrapped={false}/>
                </React.Fragment>;
    }

}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * InputWrap Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Standard wrapper for all form fields, with label and error state
 */
class InputWrap extends Component {

    constructor(props) {
        super(props);
        this.state = {
                        has_error: false,
                        err_code : ''
                    };
        this.ref   = React.createRef();
    }

    render() {
        return  <div 
                    className   = { classnames([
                                    FormStyles.input_wrap,
                                    'FormStyles-input_wrap',
                                    this.props.className,
                                    this.state.has_error && FormStyles.has_error
                                ])} 
                    ref         = {this.ref}
                    style={this.props.style}
                >
                    <label 
                        className={
                                    classnames(['GlobalStyles-label', this.props.label === 'blank' && FormStyles.blank])
                                }
                    >
                        <div className={ FormStyles.label_primary }>
                            { 
                                this.props.hasOwnProperty('label') && 
                                <div className={FormStyles.label_text}>
                                    <div className='GlobalStyles-ghost_ui'></div>
                                    {Language.get('form_labels.' + this.props.label,'id')}
                                    {
                                        !Helpers.is_empty(
                                            Language.get('form_labels.' + this.props.label,'id').trim()
                                        ) &&
                                        this.props.required && <span className={FormStyles.required}>*</span>
                                    }
                                </div>
                            }
                        </div>
                        {
                            !Helpers.is_empty(Language.get_strict('form_labels.' + this.props.label,'en')) &&
                            <div className={ classnames([FormStyles.label_secondary, 'AppStyles-label_secondary']) }>
                                { 
                                    this.props.hasOwnProperty('label') && 
                                    <div className={FormStyles.label_text}>
                                        <div className='GlobalStyles-ghost_ui'></div>
                                        {Language.get_strict('form_labels.' + this.props.label,'en')}
                                        {
                                            !Helpers.is_empty(this.props.labelSubText) &&
                                            <span className={classnames([
                                                                FormStyles.label_sub_text,
                                                                Helpers.is_empty(Language.get_strict(
                                                                    'form_labels.' + this.props.label,
                                                                    'en'
                                                                ).trim()) 
                                                                && FormStyles.label_sub_nospace
                                                            ])}>
                                                { !this.props.labelSubTextRaw && '(' }
                                                    {this.props.labelSubText}
                                                { !this.props.labelSubTextRaw && ')' }
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </label>
                    <div className={classnames([FormStyles.input_container,'FormStyles-input_container'])}>
                        <div className='GlobalStyles-ghost_ui'></div>
                        { this.props.children  }
                        { this.props.postLabel }
                    </div>
                    <div className={FormStyles.input_wrap_error}>
                        <div className={FormStyles.input_error_content}>
                            <div>
                                { 
                                    this.state.has_error 
                                    &&  !Helpers.is_empty(Language.get_strict('error.'+this.state.err_code,'id'))
                                    &&  <div className={FormStyles.input_error_id}>
                                            {Language.get_strict('error.'+this.state.err_code, 'id')}
                                        </div>
                                }
                                { 
                                    this.state.has_error 
                                    &&  !Helpers.is_empty(Language.get_strict('error.'+this.state.err_code,'en'))
                                    &&  <div className={classnames([FormStyles.input_error_en, 'AppStyles-notif_en'])}>
                                            {Language.get_strict('error.'+this.state.err_code, 'en')}
                                        </div>
                                }
                            </div>                            
                        </div>
                    </div>
                </div>;
    }

    /** 
     * Handles component state for errors from FormValidator
     * @param  object error_object 
     * @return 
     */
    error_handler = (error_object) => {
        this.setState({ has_error: true,  err_code: error_object.err_code });
    }

    /** 
     * Remove any error state to the component
     * @return 
     */
    error_clear = () => {
        this.setState({ has_error: false,  err_code: '' });
    }

    scroll_to_view = () => {
        this.ref.current.scrollIntoView();
    }

    is_visible = () => {
        return Helpers.is_visible(this.ref.current);
    }
}

/**
 * This will check if component is touched, then trigger onTouched event
 * @param  object component instance
 * @param  mixed  event_data
 * @return boolean
 */
function execute_isTouched(component, event_data) {
    if (component.state.initial_value!==component.value()) {

        if (Helpers.is_function(component.props.onTouched)) {
            component.props.onTouched(event_data);
        }

        component.trigger_event('onTouched');
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Input Component
 * ---------------------------------------------------------------------------------------------------------------------
 * input tag with InputWrap component
 */
class Input extends Component {

    constructor(props) {
        super(props);
        this.state  = { 
                        input_has_content: false,
                        value            : props.defaultValue || '', 
                        old_value        : props.defaultValue || '',
                        initial_value    : props.defaultValue || '', 
                        disabled         : props.disabled     || false, 
                    };
        this.ref            = React.createRef();
        this.input          = React.createRef();
        this.event_delay_id = Helpers.uniqid();
        new AddEvent(this,['onFocus','onBlur','onKeyDown','onChange','onKeyUp','onInput','onTouched','onPaste']);
    }

    check_has_content = () => {
        var input_has_content = !Helpers.is_empty(this.input.current.value);
        if (this.state.input_has_content !== input_has_content) {
            this.setState({ input_has_content: input_has_content });
        }
    }

    componentDidMount() {
        this.check_has_content();
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    input_dom = () => {
        return this.input.current;
    }

    blurred = (event_data) => {
        this.check_has_content();

        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
    }

    keydown = (event_data) => {
        this.check_has_content();

        if (this.props.valueTrail===true) {
            this.setState({ old_value: this.state.value, value: this.input_dom().value });
        }
        
        
        if (Helpers.is_function(this.props.onKeyDown)) {
            this.props.onKeyDown(event_data);
        }

        this.trigger_event('onKeyDown');
    }

    onchange_callback = (event_data) => {
        if (Helpers.is_function(this.props.onChange)) {
            this.props.onChange(event_data);
        }

        this.trigger_event('onChange');
        execute_isTouched(this, event_data);
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @return void
     */
    change_value = (to, event_data) => {
        this.check_has_content();


        if (this.props.valueTrail===true) {

            this.setState({ old_value: this.state.value, value: to },() => this.onchange_callback(event_data));

        } else {
            this.onchange_callback(event_data)
        }
    }

    /**
     * This will set the current value of this component base on input data
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        this.change_value(event_data.target.value);
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @return void
     */
    set_value = (value, setter) => {
        if (this.input_dom()) {
            this.input_dom().value = value;
            this.change_value(value, {setter: setter});
        }
    }

    keyup = (event_data) => {
        if (Helpers.is_function(this.props.onKeyUp)) {
            this.props.onKeyUp(event_data);
        }

        this.trigger_event('onKeyUp');
        execute_isTouched(this, event_data);
    }

    paste = (event_data) => {
        if (Helpers.is_function(this.props.onPaste)) {
            this.props.onPaste(event_data);
        }

       
        this.trigger_event('onPaste');
        execute_isTouched(this, event_data);
    }

    on_input = (event_data) => {
        if (Helpers.is_function(this.props.onInput)) {
            this.props.onInput(event_data);
        }

       
        this.trigger_event('onInput');
        execute_isTouched(this, event_data);
    }

    /**
    * This will get the current value of this component
    * @return string
    */
    value = () => {

        if (Helpers.is_empty(this.input_dom())) {
            return null;
        } else {
            return this.input_dom().value;
        }
    }

    /**
    * Revert component value to before current value is
    * @return 
    */
    revert_value = () => {
      this.set_value(this.state.old_value);
    }

    /**    
    * Reset input
    * @return void
    */
    reset = () => {
      this.set_value(this.state.initial_value);
    }

    /**
    * This will set focus to main element of this component
    * @return 
    */
    set_focus = () => {
        if (!Helpers.is_empty(this.input_dom())) {
            this.input_dom().focus();
        }
    }

    disable = (callback) => {
        this.setState({ disabled: true }, callback);
        this.disabled = true;
    }

    enable = (callback) => {
        this.setState({ disabled: false }, callback);
        this.disabled = false;
    }

    is_enabled = () => {
        return !this.disabled;
    }

    render() {
        return  <InputWrap {...this.props} ref={ this.ref }>
                    {this.props.children}
                    {this.props.beforeInput}
                    <input 
                        placeholder  = {this.props.placeholder}
                        className    =  { classnames([
                                            FormStyles.textbox,
                                            this.state.input_has_content ? FormStyles.input_has_content : null
                                        ])} 
                        ref          =  { this.input }
                        onBlur       =  { this.blurred }
                        onKeyDown    =  { this.keydown }
                        onKeyUp      =  { this.keyup   }
                        onChange     =  { this.changed }
                        onInput      =  { this.on_input}
                        onPaste      =  { this.paste}
                        type         =  { this.props.type }
                        defaultValue =  { this.props.defaultValue }
                        disabled     =  { this.state.disabled }
                    />
                    {this.props.afterInput}
                </InputWrap>;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Text Component
 * ---------------------------------------------------------------------------------------------------------------------
 * standard textbox design, textbox using Input component
 */
class Textbox extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef(); 
    }

    componentDidMount() {
        this.add_event     = this.ref.current.add_event;
        this.trigger_event = this.ref.current.trigger_event;
        this.value         = this.ref.current.value;
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.set_value     = this.ref.current.set_value;
        this.set_focus     = this.ref.current.set_focus;
        this.revert_value  = this.ref.current.revert_value;
        this.reset         = this.ref.current.reset;
        this.is_visible    = this.ref.current.is_visible;
        this.enable        = this.ref.current.enable;
        this.disable       = this.ref.current.disable;
        this.is_enabled    = this.ref.current.is_enabled;
    }

    render() {
        return  <Input {...this.props} type="text" ref={this.ref} />;
    }
 }

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Password Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Standard password design, password using Input component
 */
class Passwordbox extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef(); 
        this.state = {
            show_password: false
        };
    }

    componentDidMount() {
        this.add_event     = this.ref.current.add_event;
        this.trigger_event = this.ref.current.trigger_event;
        this.value         = this.ref.current.value;
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.set_value     = this.ref.current.set_value;
        this.set_focus     = this.ref.current.set_focus;
        this.revert_value  = this.ref.current.revert_value;
        this.reset         = this.ref.current.reset;
        this.is_visible    = this.ref.current.is_visible;
        this.enable        = this.ref.current.enable;
        this.disable       = this.ref.current.disable;
        this.is_enabled    = this.ref.current.is_enabled;
      }

    /**
     * Diplay password when clicked
     */
    show_password = event => {
        this.setState({
            show_password: !this.state.show_password
        });
    }

    /**
     * Eye button for show password
     */
    Eye = (props) => {
        return <span
                    className = {FormStyles.eye_wrap}
                    onClick   = {props.toggleEvent}
                    title     = {props.show_password ? 'Hide password' : 'Show password'}
                >
                    <FontIcons.Material icon={props.show_password ? 'visibility' : 'visibility_off'} />
                </span>
    }

    render() {
        return  <Input 
                    {...this.props} 
                    className   = { classnames([
                                        this.props.className,
                                        FormStyles.password
                                    ])
                                }
                    type        = {this.state.show_password ? "text" : "password"} 
                    ref         = {this.ref} 
                    afterInput  = {
                                    <this.Eye 
                                        show_password = {this.state.show_password}
                                        toggleEvent   = {this.show_password}
                                    />
                                }
                />;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Row Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Standard design for each form rows
 */
function Row(props) {
    return  <div className={classnames([
                                    FormStyles.rows,
                                props.className,
                                props.hasOwnProperty('buttonContainer') ? FormStyles.row_for_button : null
                            ])}
            >
                {props.children}
            </div>;
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Captcha Component
 * ---------------------------------------------------------------------------------------------------------------------
 * google ReCaptcha
 */
class Captcha extends Component {

    constructor (props) {
        super(props);
        this.state         =   { 
                                key         : Model.recaptcha_public_key(this.props.for), 
                                is_validated: false,
                                rescale     : false,
                                value       : null
                          };
        this.id           = Helpers.uniqid();
        this.ref          = React.createRef(); 
        this.content      = React.createRef(); 
        this.recaptcha    = React.createRef(); 
        this.min_viewport = 304;
        new AddEvent(this,['onChange', 'onReset']);
    }

    reset = () => {
        this.setState({ value: null }, () => {
            this.recaptcha.current.reset();
            this.trigger_event('onReset');
        });
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data 
     * @return void
     */
    change_value = (value) => {
        this.setState({ value: value, is_validated: true  }, () => {
            this.trigger_event('onChange');
        }); 
    }

    /**
    * This will get the current value of this component
    * @return string
    */
    value = () => {
        return this.state.value;
    }
    
    resize_event = () => {
        Helpers.event_delay(() => {
            this.rescale();
        }, 100, this.id);
    }

    componentDidMount() {
        this.rescale();
        window.addEventListener('resize',this.resize_event,this.id);
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rescale === false) {
            this.rescale_process();
        }           
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.resize_event,this.id);
    }

    focus = () => {

    }

    render() {
        return  <InputWrap 
                    {...this.props} 
                    ref       = {this.ref} 
                    className = {classnames([this.props.className, FormStyles.captcha_input_wrap])}
                >
                    <div className = {FormStyles.capctha_boundary} >
                        <div    className = {FormStyles.capctha_container} 
                                style     = {
                                                this.state.rescale ? {
                                                    width       : this.state.rescale.parent_width,
                                                    height      : this.state.rescale.parent_height,
                                                    marginBottom: this.state.rescale.parent_margin_bottom,
                                                    marginRight : this.state.rescale.parent_margin_right
                                                } : {}
                                            }
                        >
                            <div 
                                className = {FormStyles.capctha_content} 
                                ref       = {this.content}

                                style     = {
                                                this.state.rescale ? {
                                                    width          : this.state.rescale.width,
                                                    height         : this.state.rescale.height,
                                                    transform      : 'scale('+this.state.rescale.scale+')',
                                                    WebkitTransform: 'scale('+this.state.rescale.scale+')'
                                                } : {}
                                            }
                            >
                                <ReCAPTCHA
                                    className = {FormStyles.capctha}
                                    sitekey   = {this.state.key}
                                    ref       = {this.recaptcha}
                                    onChange  = {this.change_value}
                                />
                            </div>
                        </div>
                    </div>
                </InputWrap>;
    }

    /** 
     * Remove any error state to the component
     * @return void
     */
    main_dom() {
        return this.ref.current;
    }

    /** 
     * This will decide if component should rescale or not
     * @return void
     */
    rescale = () => {

        if (this.state.rescale !== false) {
            this.setState({ rescale: false  });
        } else { 
            this.rescale_process();
        }

    }

    /** 
     * This will rescale the element
     * @return void
     */ 
    rescale_process = () => {
        if (this.content.current!==null) {
            var rescale = Helpers.rescale_width({
                            width : this.content.current.offsetWidth,
                            height: this.content.current.offsetHeight
                        }, this.min_viewport);

            if (rescale!==this.state.rescale) {
                this.setState({ rescale: rescale  });
            }
        }
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Button Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Standard button design
 */
class Button extends Component {
    render() {
        var {buttonProps,...filtered_props} = this.props;
        return  <div {...filtered_props} className={classnames([FormStyles.button_wrapper, this.props.className])}>
                    <div className={FormStyles.button_space}>
                        <div className='GlobalStyles-ghost_ui'></div>
                        <button 
                            {...this.props.buttonProps} 
                            className= { classnames([FormStyles.button, 'GlobalStyles-button']) }
                        >
                            { this.props.children }
                        </button>
                    </div>
                </div>;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * PrimaryButton Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Primary button design, using Button component
 */
class PrimaryButton extends Component {
    render() {
        return  <Button
                    {...this.props} 
                    className  = { classnames([    
                                    FormStyles.primary_button,
                                    this.props.className,
                                    'GlobalStyles-primary_button',
                                    this.props.main && FormStyles.is_main_button
                                ])}
                    buttonProps= {this.props.buttonProps}
                >
                    {
                        this.props.main &&
                        <div className={FormStyles.button_spinner}>
                            <Loader.Spinner/>
                        </div>
                    }
                    { this.props.children }
                </Button>;
    }
}
class CustomButton extends Component {
    render() {
        return  <Button
                    className  = { classnames([    
                                    FormStyles.primary_button,
                                    this.props.className,
                                    'GlobalStyles-primary_button',
                                    
                                ])}
                    buttonProps= {this.props.buttonProps}
                >
                    { this.props.children }
                </Button>;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * SecondaryButton Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Secondary button design, using Button component
 */
class SecondaryButton extends Component {
    render() {
        return  <Button
                    {...this.props} 
                    className  = { classnames([
                                    FormStyles.secondary_button,
                                    this.props.className,
                                    'GlobalStyles-secondary_button'
                                ])}
                    buttonProps= {this.props.buttonProps}
                >
                    { this.props.children }
                </Button>;
    }
}

/**
 * This will get the options if formatter props was passed
 * @param  array  final_options [description]
 * @param  object item          [description]
 * @param  object props         [description]
 * @return void
 */
function final_options_formatted(final_options,item,props) {
    var formatted = props.formatter(item.value,item.index);
    if (Helpers.is_object(formatted)) {
        final_options.push(formatted);
    } else if(Helpers.is_array(formatted)) {
        formatted.forEach((value)=>{
            final_options.push(value);
        });
    }
}

/**
 * This will be used to get the final option form for select, option_box and checked options
 * @param  boolean optionsFormatted
 * @param  object  options           
 * @param  object  props
 * @return array
 */
function final_options(optionsFormatted, options, props) {
    var final_options = [];
    props             = props || {};

    if (optionsFormatted) {

        return final_options_with_placeholder(options, props);


    } else if (Helpers.is_object(options)) {
        Object.keys(options).forEach(function(value,index) {
            if (Helpers.is_function(props.formatter)) {
                final_options_formatted(final_options,{value, index},props);
            } else {
                final_options.push({
                    value      : value,
                    description: options[value]
                });
            }
        });

    } else if (Helpers.is_array(options)) {
        options.forEach(function(value,index) {
            if (Helpers.is_function(props.formatter)) {
                final_options_formatted(final_options,{value, index},props);
            } else {
                if (props.sameValueDescription === true) {
                    final_options.push({
                        value      : value,
                        description: value
                    });
                } else {
                    final_options.push({
                        value      : index,
                        description: value
                    });
                }
            }
        });

    }

    return final_options_with_placeholder(final_options, props);
}

/**
 * Check if the element have placeholder then put it on the beginning of the array 
 * @param {array} final_options 
 * @param {object} props 
 * @return array
 */
function final_options_with_placeholder(final_options, props) {    
    let add_placeholder = true;

    if(!Helpers.is_empty(props.placeholder)) {        
        if(final_options.length > 0) {
            if (props.placeholder === final_options[0].description) {
                add_placeholder = false;
            }
        }

        if(add_placeholder) {
            final_options.unshift({
                value: '',
                description: props.placeholder
            });
        }        
    }

    return final_options;
}

// If placeholder, the value would be an empty string
function options_initial_value(final_options, props) {
    if (Helpers.is_empty(props.placeholder)) {
        var first_option = '';
        if (final_options.length > 0) {
            first_option = final_options[0].value;
        }
        return props.defaultValue || first_option;
    } else {
        return "";
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Dropdown Component
 * ---------------------------------------------------------------------------------------------------------------------
 * regular dropdown design
 */
class Dropdown extends Component {

    constructor(props) {
        super(props);
        var optionsFormatted  = props.hasOwnProperty('optionsFormatted')? props.optionsFormatted:true;
        var final_options_val = final_options(optionsFormatted,props.options,props);
        var initial_value     = options_initial_value(final_options_val,props);
        this.state            = { 
                                    select_has_content: false,
                                    value             : initial_value, 
                                    old_value         : initial_value,
                                    initial_value     : initial_value, 
                                    final_options     : final_options_val, 
                                    optionsFormatted  : optionsFormatted
                                };
        this.ref       = React.createRef();
        this.dropdown  = React.createRef();
        new AddEvent(this,['onFocus','onBlur','onChange','onTouched']);
    }

    check_has_content = () => {
        var select_has_content = !Helpers.is_empty(this.dropdown_dom().value);
        if (this.state.select_has_content !== select_has_content) {
            this.setState({ select_has_content: select_has_content });
        }
    }

    componentDidMount() {
        this.check_has_content();
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    dropdown_dom = () => {
      return this.dropdown.current;
    }

    blurred = (event_data) => {
        this.check_has_content();

        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
        execute_isTouched(this, event_data);
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @param  string setter
     * @return void
     */
    change_value = (to, event_data, setter) => {
        this.check_has_content();
        event_data = event_data || {};
        event_data.setter = setter;
        this.setState({ old_value: this.state.value, value: to },()=>{
            
            if (Helpers.is_function(this.props.onChange)) {
                this.props.onChange(event_data);
            }

            this.trigger_event('onChange');
            execute_isTouched(this, event_data);

        });
    }

    /**
     * This will set the current value of this component base on input data
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        this.change_value(event_data.target.value);
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @param  string setter
     * @return void
     */
    set_value = (value, setter) => {
        if (this.dropdown_dom()) {
            this.dropdown_dom().value = value;
            this.change_value(value, null, setter);
        }
    }

    /**
     * This will get the current value of this component
     * @return string
     */
    value = () => {
      return this.state.value;
    }

    /**
     * Revert component value to before current value is
     * @return 
     */
    revert_value = () => {
      this.set_value(this.state.old_value);
    }

    /**    
     * Reset input
     * @return void
     */
    reset = () => {
       this.set_value(this.state.initial_value);
    }

    /**
     * This will set focus to main element of this component
     * @return 
     */
    set_focus = () => {
       this.dropdown_dom().focus();
    }

    /**
     * This will update/set dtopdown options
     * @return 
     */
    /**
     * This will update/set dtopdown options
     * @param  object/array options 
     * @param  string       setter   
     * @return void
     */
    set_options = (options, setter) => {
        var final_options_val = final_options(this.state.optionsFormatted,options,this.props);
        var initial_value     = options_initial_value(final_options_val, this.props);
        this.setState({ final_options: final_options_val }, () => {
            this.setState({initial_value: initial_value},() => {
                this.set_value(initial_value, setter);
            });
        });
    }

    render() {
        return  <InputWrap {...this.props} ref={ this.ref }>
                    <select
                        value        = {this.state.value}
                        className    = { classnames([
                                        FormStyles.dropdown,
                                        this.state.select_has_content ? FormStyles.select_has_content : null
                                     ])} 
                        ref          = { this.dropdown }
                        onBlur       = { this.blurred }
                        onChange     = { this.changed } 
                    >

                        {
                            this.state.final_options.map((item, index) => (
                               <option value={ item.value } key={index}>{ item.description }</option>
                            ))
                        }

                    </select>
                </InputWrap>;
    }
}

/**
 * Table format, radio button in right
 * @param  object component 
 * @return object
 */
function table_right_option(component) {

    return  <Table bordered={true}>
                { 
                    component.props.header && 
                    <thead>
                        <tr>
                            {
                                component.props.header.map((item, index) => (<td key={index}>{item}</td>))
                            }
                        </tr>
                    </thead>
                }
                <tbody>
                    {
                        component.state.final_options.map((item, index) => {
                            var ToggledContent = component.props.hasOwnProperty('toggledContent')
                                                 && component.props.toggledContent.hasOwnProperty(item.value)
                                                 && component.props.toggledContent[item.value];
                            var option_props   = component.props.optionProps || {};
                            var option_prop    = option_props[index]    || {};
                            return <tr key={index}>
                                <td>{item.description}</td>
                                <td>
                                    <div 
                                        className = {classnames([
                                                        FormStyles.option_box,
                                                        ToggledContent && FormStyles.option_box_has_toggle,
                                                        'FormStyles-option_box'
                                                    ])}  
                                        key       = {index}
                                    >
                                        <div className={FormStyles.option_box_space}>
                                            <input 
                                                value    = { item.value } 
                                                type     = 'radio'
                                                checked  = { component.state.value === item.value }
                                                name     = { component.name }
                                                onChange = { component.changed } 
                                                ref      = {index<=0 && component.first_option_box}
                                                className= {FormStyles.option_box_input}
                                                onBlur   = {component.blurred}
                                                onClick  = {component.clicked}
                                                disabled = { component.state.disabled }
                                                {...option_prop}
                                            /> 
                                            <div 
                                                className = {classnames([
                                                                FormStyles.option_box_facade,
                                                                'FormStyles-option_box_facade'
                                                            ])}
                                            ></div>
                                            { 
                                                ToggledContent ? 
                                                    <div className={FormStyles.option_box_toggle}>
                                                        <div className={FormStyles.option_box_toggle_content}>
                                                            {ToggledContent}
                                                        </div>
                                                    </div> 
                                                : null 
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>



}

/**
 * Standard format, radio button width description
 * @param  object component 
 * @return object
 */
function standard_option(component) {
    return component.state.final_options.map((item, index) => {
        var ToggledContent = component.props.hasOwnProperty('toggledContent')
                             && component.props.toggledContent.hasOwnProperty(item.value)
                             && component.props.toggledContent[item.value];
        var option_props   = component.props.optionProps || {};
        var option_prop    = option_props[index]    || {};

        return <div 
                    className = {classnames([
                                    FormStyles.option_box,
                                    ToggledContent && FormStyles.option_box_has_toggle,
                                    'FormStyles-option_box'
                                ])}  
                    key       = {index}
                >
                    <div className={FormStyles.option_box_space}>
                        <input 
                            value    = { item.value } 
                            type     = 'radio'
                            checked  = { component.state.value.toString() === item.value.toString() }
                            name     = { component.name }
                            onChange = { component.changed } 
                            ref      = {index<=0 && component.first_option_box}
                            className= {FormStyles.option_box_input}
                            onBlur   = {component.blurred}
                            onClick  = {component.clicked}
                            disabled = { component.state.disabled }
                            {...option_prop}
                        /> 
                        <div 
                            className = {classnames([
                                            FormStyles.option_box_facade,
                                            'FormStyles-option_box_facade'
                                        ])}
                        ></div>
                        {
                            component.props.labeled!==false && 
                            <div className={classnames([
                                                FormStyles.option_box_label,
                                                'FormStyles-option_box_label'
                                            ])}
                            >{item.description}</div>
                        }
                        { 
                            ToggledContent ? 
                                <div className={FormStyles.option_box_toggle}>
                                    <div className={FormStyles.option_box_toggle_content}>
                                        {ToggledContent}
                                    </div>
                                </div> 
                            : null 
                        }
                    </div>
                </div>
    });
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Radio Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Radio component
 */
class Radio extends Component {
    constructor(props) {
        super(props);
        var optionsFormatted = props.hasOwnProperty('optionsFormatted')? props.optionsFormatted:true;
        this.state            = { 
                                    value            : props.defaultValue || '', 
                                    old_value        : props.defaultValue || '',
                                    initial_value    : props.defaultValue || '', 
                                    final_options    : final_options(optionsFormatted,props.options,props), 
                                    optionsFormatted : optionsFormatted, 
                                    disabled         : props.disabled || false
                                };
        this.ref              = React.createRef();
        this.first_option_box = React.createRef();
        this.name             = Helpers.uniqid();
        this.disabled         = props.disabled || false;
        new AddEvent(this,['onFocus','onBlur','onChange','onTouched','onClick']);
    }

    componentDidMount() {
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    /**
     * This will get the current value of this component
     * @return string
     */
    value = () => {
        return this.state.value;
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @return void
     */
    change_value = (to, event_data) => {
        this.setState({ old_value: this.state.value, value: to },()=>{

            if (Helpers.is_function(this.props.onChange)) {
                this.props.onChange(event_data);
            }

            this.trigger_event('onChange');
            execute_isTouched(this, event_data);

        });
    }

    /**
     * This will set the current value of this component base on input data
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        this.change_value(event_data.target.value);
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @param  string setter
     * @return void
     */
    set_value = (value, setter) => {
        this.change_value(value, {setter: setter});
    }

    /**
     * This will update/set dtopdown options
     * @return 
     */
    set_options = (options) => {
        this.setState({ final_options: final_options(this.state.optionsFormatted,options,this.props) });
    }

    /**
     * Revert component value to before current value is
     * @return 
     */
    revert_value = () => {
        this.set_value(this.state.old_value);
    }

    /** 
     * Reset input
     * @return void
     */
    reset = () => {
        this.set_value(this.state.initial_value);
    }

    /**
     * This will set focus to main element of this component
     * @return 
     */
    set_focus = () => {
        this.first_option_box.current.scrollIntoView();
        this.first_option_box.current.focus();
    }

    blurred = (event_data) => {
        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
        execute_isTouched(this, event_data);
    }

    clicked = (event_data) => {
        
        if (this.props.clearOnClick) {
            if(event_data.target.value === this.state.value) {
                this.set_value('');
            }
        }

        if (Helpers.is_function(this.props.onClick)) {
            this.props.onClick(event_data);
        }

        this.trigger_event('onClick');
    }

    disable = () => {
        this.setState({ disabled: true });
        this.disabled = true;
    }

    enable = () => {
        this.setState({ disabled: false });
        this.disabled = false;
    }

    is_enabled = () => {
        return !this.disabled;
    }

    render() {   
        var option_display = null;   
        
        switch(this.props.displayFormat) {
            case 'table-right':
                option_display = table_right_option(this);
                break;
            default:
                option_display = standard_option(this);
        }

        return  <InputWrap 
                    {...this.props} 
                    ref       = { this.ref } 
                    className = { classnames([
                                    FormStyles.option_box_wrapper,
                                    FormStyles.radio,
                                    this.props.className
                                ])} 
                >
                    <div className={classnames([FormStyles.option_box_boundary], 'FormStyles-option_box_boundary')}>
                        {this.props.beforeInput}
                        {option_display}
                        {this.props.afterInput}
                    </div>
                </InputWrap>;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * TextView Component
 * ---------------------------------------------------------------------------------------------------------------------
 * Text View Only
 */
function TextView(props) {
    return <InputWrap 
                {...props} 
                className = { classnames([
                                FormStyles.textview_wrapper,
                                props.className
                            ])} 
            >
                <div 
                    className = {classnames([
                                    FormStyles.textview,
                                    Helpers.is_empty(props.children) ? null : FormStyles.textview_has_content
                                ])}
                >
                    <div className={FormStyles.textview_content}>{props.children}</div>
                </div>
            </InputWrap>;
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Input Component
 * ---------------------------------------------------------------------------------------------------------------------
 * input tag with InputWrap component
 */
class UploadBox extends Component {

    constructor(props) {
        super(props);
        this.state  = { 
                        input_has_content: false,
                        value            : props.defaultValue || '', 
                        old_value        : props.defaultValue || '',
                        initial_value    : props.defaultValue || '', 
                        rotation_degree  : 0,
                    };
        this.ref   = React.createRef();
        this.input = React.createRef();
        this.unmounted = false;
        new AddEvent(this,['onFocus','onBlur','onChange','onInput','onTouched']);
    }

    check_has_content = () => {
        var input_has_content = !Helpers.is_empty(this.input.current.value);
        if (this.state.input_has_content !== input_has_content) {
            this.setState({ input_has_content: input_has_content });
        }
    }

    componentDidMount() {
        this.check_has_content();
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    input_dom = () => {
        return this.input.current;
    }

    blurred = (event_data) => {
        this.check_has_content();

        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
        execute_isTouched(this, event_data);
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @return void
     */
    change_value = (to, event_data) => {


        if (!Helpers.is_empty(to) && typeof to == 'object' && to.length <=0 ) {
            to = null;
        }

        this.setState({ old_value: this.state.value, value: to },()=>{
            
            if (Helpers.is_function(this.props.onChange)) {
                this.props.onChange(event_data);
            }

            this.trigger_event('onChange');
            execute_isTouched(this, event_data);

        });
    }

    /**
     * This will set the current value of this component base on input data
     * Then reset rotation to 0
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        this.change_value(event_data.target.files);
        this.reset_rotation();
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @return void
     */
    set_value = (value) => {
        this.input_dom().value = '';
        this.change_value(value);
    }

    on_input = (event_data) => {
        if (Helpers.is_function(this.props.onInput)) {
            this.props.onInput(event_data);
        }

        this.trigger_event('onInput');
        execute_isTouched(this, event_data);
    }

    /**
     * This will get the current value of this component
     * @return string
     */
    value = () => {
        return this.state.value;
    }

    /**
     * Revert component value to before current value is
     * @return 
     */
    revert_value = () => {
        this.set_value(this.state.old_value);
    }

    /** 
     * Reset input
     * @return void
     */
    reset = () => {
        this.set_value(this.state.initial_value);
    }

    /** 
     * Clear input
     * @return void
     */
    clear = () => {
        this.set_value(null);
    }

    /**
     * This will set focus to main element of this component
     * @return 
     */
    set_focus = () => {
        this.input.current.focus();
    }

    is_empty = () => {
        return (Helpers.is_empty(this.state.value));
    }

    image_preview = (file, rotation_degree) => {
        return <Image.Preview 
                    file      = { file } 
                    className = {   
                                    classnames([
                                        FormStyles.upload_image_preview,
                                        FormStyles['upload_image_rotate_'+rotation_degree]
                                    ])
                                }
                />;
    }

    document_preview = () => {
        return  <div className={FormStyles.upload_document_preview}>
                    <FontIcons.Material icon='note'/>
                </div>;
    }

    /**
     * Set rotation_degree to 0
     * @retun
     */
    reset_rotation = () => {
        !this.unmounted && this.setState({
            rotation_degree: 0
        });
    }

    /**
     * This will get the current rotation_degree of the image.
     * @return value
     */
    rotate = () => {
        return this.state.rotation_degree;
    }
    
    /**
     * Change the state value of rotation_degree when the rotate button was clicked.
     * @return
     */
    rotate_image = () => {
        var rotation_degree = this.state.rotation_degree + 90;

        !this.unmounted && this.setState({
           rotation_degree: rotation_degree < 360 ? rotation_degree : 0
        });  
    }

    file_previews = () => {
        return  <React.Fragment>

                    {Object.keys(this.state.value).map((key) => {
                        var file = this.state.value[key];
                        var is_image = file.type ? file.type.includes('image') : Helpers.is_image(file.name);
                        return  <div className={FormStyles.upload_preview} key={file.name}>
                                    {
                                        is_image ? 
                                        this.image_preview(file, this.state.rotation_degree) : this.document_preview(file)
                                    }
                                    <div className={FormStyles.upload_file_name}>
                                        {file.name}

                                        { 
                                            is_image && 
                                            <span 
                                                className = {FormStyles.upload_file_rotate} 
                                                title     = 'Rotate'
                                                onClick   = {() => {  this.rotate_image() }}
                                            >
                                                <FontIcons.Material icon='rotate_right'/>
                                            </span>
                                        } 
                                    </div>
                                </div>;

                    })}
                    
                </React.Fragment>;
    }

    render() {
        return  <InputWrap 
                    {...this.props} 
                    className = { classnames([FormStyles.upload_box,this.props.className]) } 
                    ref       = { this.ref }
                >
                    {
                        this.is_empty() ? 
                        <div className={FormStyles.upload_box_placeholder}>
                            <div className={FormStyles.upload_box_icon}><FontIcons.Material icon='cloud_upload'/></div>
                            <div className={FormStyles.upload_box_text}>{Language.get('form_labels.select_file')}</div>
                        </div> : this.file_previews()
                    }
                    <input 
                        placeholder = '' 
                        className   = { classnames([
                                        FormStyles.textbox,
                                        this.state.input_has_content ? FormStyles.input_has_content : null
                                    ])} 
                        ref         = { this.input }
                        onBlur      = { this.blurred }
                        onChange    = { this.changed }
                        onInput     = { this.on_input}
                        type        = 'file'
                        multiple    = {this.props.multiple}
                        accept      = {this.props.accept}
                        title       = 'Upload'
                    />
                    {
                        !this.is_empty() && 
                        <React.Fragment>
                            <div className={FormStyles.upload_box_clear} onClick={this.clear}>
                                <FontIcons.Material icon='close'/>
                            </div>
                            <div className={FormStyles.upload_box_edit}>
                                <FontIcons.Material icon='edit'/>
                            </div>
                        </React.Fragment>
                    }
                </InputWrap>;
    }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Checkbox Component
 * ---------------------------------------------------------------------------------------------------------------------
 * option_box component
 */
class Checkbox extends Component {
    constructor(props) {
        super(props);
        var optionsFormatted = props.hasOwnProperty('optionsFormatted')? props.optionsFormatted:true;
        this.state            = { 
                                    value            : props.defaultValue || [], 
                                    old_value        : props.defaultValue || [],
                                    initial_value    : props.defaultValue || [], 
                                    final_options    : final_options(optionsFormatted,props.options,props), 
                                    optionsFormatted : optionsFormatted, 
                                    disabled         : props.disabled || false
                                };
        this.ref              = React.createRef();
        this.first_option_box = React.createRef();
        this.name             = Helpers.uniqid();
        this.disabled         = props.disabled || false;
        new AddEvent(this,['onFocus','onBlur','onChange','onTouched']);
    }

    componentDidMount() {
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    /**
     * This will get the current value of this component
     * @return string
     */
    value = () => {
        return this.state.value;
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @return void
     */
    change_value = (to, event_data) => {
        this.setState({ old_value: this.state.value, value: to },()=>{
            
            if (Helpers.is_function(this.props.onChange)) {
                this.props.onChange(event_data);
            }

            this.trigger_event('onChange');
            execute_isTouched(this, event_data);

        });
    }

    /**
     * This will set the current value of this component base on input data
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        if (event_data.target.checked) {
            this.change_value([...this.state.value].concat([event_data.target.value]));
        } else {
            this.change_value(this.state.value.filter((value) => (event_data.target.value !== value)));
        }
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @param  string setter
     * @return void
     */
    set_value = (value, setter) => {
        this.change_value(value, { setter: setter });
    }

    /**
     * This will update/set dtopdown options
     * @return 
     */
    set_options = (options) => {
        this.setState({ final_options: final_options(this.state.optionsFormatted,options,this.props) });
    }

    /**
     * Revert component value to before current value is
     * @return 
     */
    revert_value = () => {
        this.set_value(this.state.old_value);
    }

    /** 
     * Reset input
     * @return void
     */
    reset = () => {
        this.set_value(this.state.initial_value);
    }

    /**
     * This will set focus to main element of this component
     * @return 
     */
    set_focus = () => {
        this.first_option_box.current.scrollIntoView({block :"end"});
        this.first_option_box.current.focus();
        window.scrollBy(0, (window.innerHeight / 2) - 8);
    }

    blurred = (event_data) => {
        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
        execute_isTouched(this, event_data);
    }

    disable = (callback) => {
        this.setState({ disabled: true }, callback);
        this.disabled = true;
    }

    enable = (callback) => {
        this.setState({ disabled: false }, callback);
        this.disabled = false;
    }

    is_enabled = () => {
        return !this.disabled;
    }


    render() {      
        return  <InputWrap 
                    {...this.props} 
                    ref       = { this.ref } 
                    className = { classnames([
                                    FormStyles.option_box_wrapper,
                                    FormStyles.checkbox,
                                    this.props.className
                                ])} 
                >
                    <div className={FormStyles.option_box_boundary}>
                        {
                            this.state.final_options.map((item, index) => {
                                var ToggledContent = this.props.hasOwnProperty('toggledContent')
                                                     && this.props.toggledContent.hasOwnProperty(item.value)
                                                     && this.props.toggledContent[item.value];
                                var option_props   = this.props.optionProps || {};
                                var option_prop    = option_props[index]    || {};
                                return <div 
                                            className = {classnames([
                                                            FormStyles.option_box,
                                                            ToggledContent && FormStyles.option_box_has_toggle,
                                                            'FormStyles-option_box'
                                                        ])}  
                                            key       = {index}
                                        >
                                            <div className={FormStyles.option_box_space}>
                                                <input 
                                                    value    = { item.value } 
                                                    type     = 'checkbox'
                                                    checked  = { Helpers.in_array(String(item.value),this.state.value)}
                                                    name     = { this.name }
                                                    onChange = { this.changed }
                                                    ref      = { index<=0 && this.first_option_box }
                                                    className= { FormStyles.option_box_input }
                                                    onBlur   = { this.blurred }
                                                    disabled = { this.state.disabled }
                                                    style = {this.props.customTransform}
                                                    {...option_prop}
                                                /> 
                                                <div 
                                                                                                    style = {this.props.customTransform}
                                                    className = {classnames([
                                                                    FormStyles.option_box_facade,
                                                                    'FormStyles-option_box_facade'
                                                                ])}
                                                >
                                                    <FontIcons.Material icon='done' className={FormStyles.checkbox_check}/>
                                                </div>
                                                {
                                                    this.props.labeled!==false && 
                                                    <div style={{textAlign:'justify'}} className={classnames([
                                                                        FormStyles.option_box_label,
                                                                        'FormStyles-option_box_label'
                                                                    ])}
                                                    >{item.description} {this.props.requiredText && <span style={{color:'red'}}>*</span>}</div>
                                                }
                                                { 
                                                    ToggledContent ? 
                                                        <div className={FormStyles.option_box_toggle}>
                                                            <div className={FormStyles.option_box_toggle_content}>
                                                                {ToggledContent}
                                                            </div>
                                                        </div> 
                                                    : null 
                                                }
                                            </div>
                                        </div>
                            })
                        }
                    </div>
                </InputWrap>;
    }
}

function InputGroup(props) {
    return  <div {...props} className={classnames([FormStyles.input_group, props.className])}>
                <div className={ FormStyles.input_group_content }>
                    <label>
                        <div className={ FormStyles.label_primary }>
                            { Language.get('labels.' + props.label) }
                        </div>
                    </label>
                    { props.children }
                </div>
            </div>;
}

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * TextArea Component
 * ---------------------------------------------------------------------------------------------------------------------
 * TextArea tag with textareaWrap component
 */
class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state  = { 
                        input_has_content: false,
                        value            : props.defaultValue || '', 
                        old_value        : props.defaultValue || '',
                        initial_value    : props.defaultValue || '', 
                        disabled         : props.disabled     || false, 
                    };
        this.ref            = React.createRef();
        this.input          = React.createRef();
        this.event_delay_id = Helpers.uniqid();
        new AddEvent(this,['onFocus','onBlur','onKeyDown','onChange','onKeyUp','onInput','onTouched','onPaste']);
    }

    check_has_content = () => {
        var input_has_content = !Helpers.is_empty(this.input.current.value);
        if (this.state.input_has_content !== input_has_content) {
            this.setState({ input_has_content: input_has_content });
        }
    }

    componentDidMount() {
        this.check_has_content();
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    input_dom = () => {
        return this.input.current;
    }

    blurred = (event_data) => {
        this.check_has_content();

        if (Helpers.is_function(this.props.onBlur)) {
            this.props.onBlur(event_data);
        }

        this.trigger_event('onBlur');
    }

    keydown = (event_data) => {
        this.check_has_content();

        if (this.props.valueTrail===true) {
            this.setState({ old_value: this.state.value, value: this.input_dom().value });
        }
        
        
        if (Helpers.is_function(this.props.onKeyDown)) {
            this.props.onKeyDown(event_data);
        }

        this.trigger_event('onKeyDown');
    }

    onchange_callback = (event_data) => {
        if (Helpers.is_function(this.props.onChange)) {
            this.props.onChange(event_data);
        }

        this.trigger_event('onChange');
        execute_isTouched(this, event_data);
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @return void
     */
    change_value = (to, event_data) => {
        this.check_has_content();


        if (this.props.valueTrail===true) {

            this.setState({ old_value: this.state.value, value: to },() => this.onchange_callback(event_data));

        } else {
            this.onchange_callback(event_data)
        }
    }

    /**
     * This will set the current value of this component base on input data
     * @param  mixed  value
     * @return void
     */
    changed = (event_data) => {
        this.change_value(event_data.target.value);
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @return void
     */
    set_value = (value, setter) => {
        if (this.input_dom()) {
            this.input_dom().value = value;
            this.change_value(value, {setter: setter});
        }
    }

    keyup = (event_data) => {
        if (Helpers.is_function(this.props.onKeyUp)) {
            this.props.onKeyUp(event_data);
        }

        this.trigger_event('onKeyUp');
        execute_isTouched(this, event_data);
    }

    paste = (event_data) => {
        if (Helpers.is_function(this.props.onPaste)) {
            this.props.onPaste(event_data);
        }

       
        this.trigger_event('onPaste');
        execute_isTouched(this, event_data);
    }

    on_input = (event_data) => {
        if (Helpers.is_function(this.props.onInput)) {
            this.props.onInput(event_data);
        }

       
        this.trigger_event('onInput');
        execute_isTouched(this, event_data);
    }

    /**
    * This will get the current value of this component
    * @return string
    */
    value = () => {

        if (Helpers.is_empty(this.input_dom())) {
            return null;
        } else {
            return this.input_dom().value;
        }
    }

    /**
    * Revert component value to before current value is
    * @return 
    */
    revert_value = () => {
      this.set_value(this.state.old_value);
    }

    /**    
    * Reset input
    * @return void
    */
    reset = () => {
      this.set_value(this.state.initial_value);
    }

    /**
    * This will set focus to main element of this component
    * @return 
    */
    set_focus = () => {
        if (!Helpers.is_empty(this.input_dom())) {
            this.input_dom().focus();
        }
    }

    disable = (callback) => {
        this.setState({ disabled: true }, callback);
        this.disabled = true;
    }

    enable = (callback) => {
        this.setState({ disabled: false }, callback);
        this.disabled = false;
    }

    is_enabled = () => {
        return !this.disabled;
    }

    render() {
        return  <InputWrap {...this.props} ref={ this.ref }>
                    {this.props.children}
                    {this.props.beforeInput}
                    <textarea 
                        placeholder  = {this.props.placeholder}
                        className    =  { classnames([
                                            FormStyles.textarea_input,
                                            this.state.input_has_content ? FormStyles.input_has_content : null
                                        ])} 
                        ref          =  { this.input }
                        onBlur       =  { this.blurred }
                        onKeyDown    =  { this.keydown }
                        onKeyUp      =  { this.keyup   }
                        onChange     =  { this.changed }
                        onInput      =  { this.on_input}
                        onPaste      =  { this.paste}
                        type         =  { this.props.type }
                        defaultValue =  { this.props.defaultValue }
                        disabled     =  { this.state.disabled }
                    />
                    {this.props.afterInput}
                </InputWrap>;
    }
}

/**
 * Template only of ID type and number pair
 */
class idTypeNumber extends Component {

    render() {
        return  <React.Fragment>     
                    <this.idType   {...this.props} ref={this.props.inputs.id_type.ref} />
                    <this.idNumber {...this.props} ref={this.props.inputs.id_number.ref} />
                </React.Fragment>;

    }

    idType = React.forwardRef((props, ref) => (
        <Dropdown    
            {...props.id_type }
            optionsFormatted     =  {false}
            sameValueDescription =  {true}
            label                =  {props.noTranslation !== true ?  'id_types' : 'id_id_types'}
            options              =  {['KTP','Paspor','SIM', 'KITAS']}
            ref                  =  {ref}
            onChange             =  {(event_data) => {
                                        if (event_data.setter!=='init') {
                                            props.inputs.id_number.ref.current.set_value('');
                                        }
                                    }}
            className            = {classnames(['AppStyles-half_size_input', FormStyles.id_types])}
        />
    ))


    idNumber = React.forwardRef((props, ref) => (
        <Textbox     
            {...props.id_number}
            label        =  {props.noTranslation !== true ?  'id_number' : 'id_id_number'}
            ref          = { ref } 
            className    = 'AppStyles-half_size_input'
        />
    ))
}

class DatePicker extends Component {

    constructor(props) {
        super(props);
        Moment.locale(props.locale || 'id');

        var initial_value = this.to_valid_value(props.defaultValue || null);
        
        this.state  = { 
                        datepicker_has_content: false,
                        value                 : initial_value, 
                        old_value             : initial_value,
                        initial_value         : initial_value, 
                        focused               : false, 
                        year_scope            : props.yearScope || -100,
                        display_format        : props.displayFormat || process.env.REACT_APP_DATE_DISPLAY,
                        value_format          : props.valueFormat   || process.env.REACT_APP_DATE_VALUE
                    };
        this.ref        = React.createRef();
        this.datepicker = React.createRef();
        this.focused=false;
        new AddEvent(this,['onFocus','onBlur','onTouched','onChange']);
    }

    componentDidMount() {
        this.check_has_content();
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.is_visible    = this.ref.current.is_visible;
    }

    render() {
        return  <InputWrap 
                    {...this.props} 
                    className = {classnames([FormStyles.datepicker, this.props.className])} 
                    ref       = { this.ref }
                >
                    <SingleDatePicker 
                        date               = {this.state.value}
                        ref                = {this.datepicker}
                        onDateChange       = {value => {this.change_value(value)}}
                        focused            = {this.state.focused}
                        numberOfMonths     = {this.props.numberOfMonths || 1}
                        onFocusChange      = {this.focus_changed}
                        renderMonthElement = {this.renderMonthElement}
                        isOutsideRange     = {(day) => (this.props.isOutsideRange || (Moment().diff(day) < 0))}
                        displayFormat      = {this.state.display_format}
                    />
                    
                </InputWrap>;
    }

    returnYears = () => {
        let years = []
        for(let i = Moment().year() + this.state.year_scope; i <= Moment().year(); i++) {
            years.push(<option value={i} key={i}>{i}</option>);
        }
        return years;
    }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className = {FormStyles.datepicker_select}>
                <select
                    value     = {month.month()}
                    onChange  = {(e) => onMonthSelect(month, parseInt(e.target.value))}
                >
                    {Moment.months().map((label, value) => (
                        <option value={value} key={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div className = {FormStyles.datepicker_select}>
                <select 
                    value     = {month.year()} 
                    onChange  = {(e) => onYearSelect(month, parseInt(e.target.value))}
                >
                    {this.returnYears()}
                </select>
            </div>
        </div>
    )

    check_has_content = () => {
        var datepicker_has_content = !Helpers.is_empty(this.value());
        if (this.state.datepicker_has_content !== datepicker_has_content) {
            this.setState({ datepicker_has_content: datepicker_has_content });
        }
    }

    /**
     * this will change the value and trigger necessary events
     * @param  string to         
     * @param  object event_data [description]
     * @param  string setter
     * @return void
     */
    change_value = (to, event_data) => {
        event_data  = event_data || {};
        this.setState({ old_value: this.state.value, value: to },() => {
            
            if (Helpers.is_function(this.props.onChange)) {
                this.props.onChange(event_data);
            }

            this.trigger_event('onChange');

            if (Helpers.is_function(this.props.onInput)) {
                this.props.onInput(event_data);
            }

            execute_isTouched(this, event_data);
        });
    }

    /**
     * This will get the current value of this component
     * @return string
     */
    value = () => {
        return Helpers.is_empty(this.state.value) ? null :  Moment(this.state.value).format(this.state.value_format);
    }

    /**
     * This will set the current value of this component base on passed param
     * @param  mixed  value
     * @return void
     */
    set_value = (value, setter) => {
        this.change_value(this.to_valid_value(value), {setter});
    }

    prevent_clear_error() {
        return !this.focused;
    }

    /**
     * This will transform to valid value
     * @param  mixed  value
     * @return object
     */
    to_valid_value = (value) => {
        if (Helpers.isString(value)) {
            return Moment(value);
        } else {
            return value;
        }
    }

    focus_changed = (event_data) => {
        this.setState(event_data);
        this.focused = event_data.focused;
        this.check_has_content();

        if (event_data.focused) {
            if (Helpers.is_function(this.props.onFocus)) {
                this.props.onFocus(event_data);
            }

            this.trigger_event('onFocus');
        } else {
            if (Helpers.is_function(this.props.onBlur)) {
                this.props.onBlur(event_data);
            }

            this.trigger_event('onBlur');
        }

        execute_isTouched(this, event_data);
    }

    /**
     * This will set focus to main element of this component
     * @return 
     */
    set_focus = () => {
        this.focused = true;
        this.setState({ focused: true },() => {
            this.ref.current.scroll_to_view();
        });
    }
}

function countryCode_formatter(value) {
    var options = [];

    value.calling_codes.forEach((calling_code) => {

        if (!Helpers.is_empty(calling_code)) {
            options.push({ 
                value      : calling_code,
                description: '+'+calling_code+' - '+value.name
            });
        }

    });

    return options;
}

const CountryCodeDropdown = React.forwardRef((props, ref) => (
                                <Dropdown    
                                    {...props}
                                    optionsFormatted = {false}
                                    label            = 'country_code' 
                                    defaultValue     = {props.defaultValue || 62}
                                    formatter        = {countryCode_formatter}
                                    ref              = {ref} 
                                />
                            ));

const MobileNumberInput = React.forwardRef((props, ref) => (
                                <Textbox     
                                    {...props}
                                    label        = 'mobile_number' 
                                    ref          = { ref } 
                                    placeholder  = '8********'
                                />
                            ));


class AmountTextbox extends Component {
    constructor(props) {
        super(props);
        this.ref          = React.createRef();
        this.uniqid       = Helpers.uniqid();
        this.thousand     = this.props.thousand      || '.';
        this.decimal      = this.props.decimal       || ',';
        this.decimalDigit = this.props.decimalDigit  || 2;
    }

    componentDidMount() {
        this.add_event     = this.ref.current.add_event;
        this.trigger_event = this.ref.current.trigger_event;
        this.error_handler = this.ref.current.error_handler;
        this.error_clear   = this.ref.current.error_clear;
        this.set_focus     = this.ref.current.set_focus;
        this.revert_value  = this.ref.current.revert_value;
        this.reset         = this.ref.current.reset;
        this.is_visible    = this.ref.current.is_visible;
        this.enable        = this.ref.current.enable;
        this.disable       = this.ref.current.disable;
        this.is_enabled    = this.ref.current.is_enabled;
        this.format_amount_value();
    }

    originalValue = () => (this.ref.current ? this.ref.current.value() : '');

    value = () => {
        if (!Helpers.is_empty(this.originalValue())) {
            return Helpers.money_to_number(this.originalValue(), this.decimal, this.thousand);
        } else {
            return null;
        }
    }

    set_value = (value, setter) => {
        return this.ref.current ? this.ref.current.set_value(this.replace_decimal_delimeter(value)) : '';
    }

    format_value = (event_data) => {
        event_data = event_data || {};
        if (event_data.setter !== 'formatter' && !Helpers.is_empty(this.originalValue())) {

            Helpers.event_delay(() => {

                if (this.ref.current) {

                    var value          = this.originalValue();
                    var value_length   = value.length;
                    var caret_position = this.ref.current.input_dom().selectionStart;
                    var set_caret      = caret_position === value_length ? 0 : 1;

                    if (value !== '') {
                        this.ref.current.set_value(this.money_format(value),'formatter');
                    }

                    if (set_caret === 1 && this.ref.current.input_dom() === document.activeElement) {
                        var caret_indent = value_length - this.originalValue().length;
                        Helpers.set_caret_position(caret_position - caret_indent, this.ref.current.input_dom());
                    }

                }

            }, 600, 'Form.AmountTextbox');

        }
    }

    /**
     * This will format the value on load w/o caret.
     * Will create event if the current ref exist.
     */
    format_amount_value = () => {

        if (this.ref.current) {
            Helpers.event_delay(() => {                
                let value = this.originalValue();

                if (value !== '') {
                    this.ref.current.set_value(this.money_format(value), 'formatter');
                }

            }, 600, this.uniqid);  
        }

    }

    money_format = (value) => {
        return !Helpers.is_empty(value) ? Helpers.money_format(value,this.decimalDigit, {
            decimal_delimeter : this.decimal,
            thousand_delimeter: this.thousand,
        }) : '';
    }

    replace_decimal_delimeter = (value) => {
        return !Helpers.is_empty(value) ? Helpers.replace_all(value,'.',this.decimal) : '';
    }

    render() {
        return  <Input 
            {...this.props} 
            defaultValue = {this.money_format(this.replace_decimal_delimeter(this.props.defaultValue))}
            type         = "text" 
            ref          = {this.ref} 
            onBlur       = {this.format_value}
        />;
    }
}

export default { 
    Textbox, 
    Passwordbox, 
    Row, 
    Captcha, 
    PrimaryButton, 
    SecondaryButton, 
    Form, 
    Dropdown, 
    Radio,
    TextView,
    UploadBox,
    Checkbox,
    InputGroup,
    TextArea,
    idTypeNumber,
    DatePicker,
    CountryCodeDropdown,
    MobileNumberInput,
    AmountTextbox,
    CustomButton
};
