function contents() {

	return {
		SUC_00001: 'Thank you for registering your Demo Account SPA, we will process your registration request, '
					+'we will send your username and password via email.',
		SUC_00002: 'Demo Account was sent',
		SUC_00003: 'Thank you for registering your Demo Account PBK, we will process your registration request, '
					+'we will send your username and password via email.',
		SUC_00004: 'Thank You, Your Telegram is already registered.',
		first_login : ''
	};

}

export default contents;