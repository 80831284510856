import React       from 'react';
import classnames  from 'classnames';
import ImageStyles from './scss/ImageStyles.module.scss';

function Logo(props) {
    var {Load,...filtered_props} = props;
    
    return  <div className={ImageStyles.logo_wrapper}>
    			<props.Load 
               		{...filtered_props}
                	className = {classnames([props.className, ImageStyles.logo])}
                	src       = {process.env.REACT_APP_RSO + `/images/logo/${process.env.REACT_APP_COMPANYNAME}.svg`} 
                	ratio     = {{width:ImageStyles.logo_width,height:ImageStyles.logo_height}}
            	/>
            </div>;
}

function VerticalLogo(props) {
    var {Load,...filtered_props} = props;
    
    return  <div className={ImageStyles.logo_wrapper}>
    			<props.Load 
               		{...filtered_props}
                	className = {classnames([props.className, ImageStyles.logo_vertical])}
                	src       = {process.env.REACT_APP_RSO + `/images/logo/${process.env.REACT_APP_COMPANYNAME}_vertical.svg`} 
                	ratio     = {{width:ImageStyles.vertical_logo_width,height:ImageStyles.vertical_logo_height}}
            	/>
            </div>;
}

export default {Logo, VerticalLogo};