import React               from 'react';
import classnames          from 'classnames';
import Image               from 'app/components/image/Image';
import {RouteLinks}        from 'app/components/routes/Routes';
import PrivateLayoutStyles from './scss/PrivateLayoutStyles.module.scss'
import Language            from 'app/utils/language/Language';
import FontIcons           from 'app/components/fonticons/FontIcons';
import Form                from 'app/components/form/Form';

function PrivateLayout(props) {
	return  <React.Fragment>
				<div className={PrivateLayoutStyles.header}>
					<div className='AppStyles-container'>
						<div className={PrivateLayoutStyles.header_boundary}>

							<div className={PrivateLayoutStyles.logo_container}>
								<a 
									href   = {props.Company.website}
									target = '_blank'
									rel    = 'noopener noreferrer'
								><Image.Logo className={PrivateLayoutStyles.logo}/></a>
							</div>

							<div className={PrivateLayoutStyles.mobile_menu_burger}>
								<Form.SecondaryButton 
									className = {PrivateLayoutStyles.menu_burger_button}
									onClick   = {props.showMenu}
								>
									<FontIcons.Material icon='menu' />
								</Form.SecondaryButton>
							</div>

							<div 
								className = {classnames([
												PrivateLayoutStyles.menu,
												props.menu && PrivateLayoutStyles.active
											])}
							>
								<div 
									className = {PrivateLayoutStyles.menu_clickable_background}
									onClick   = {props.hideMenu}
								></div>
								<div className = {PrivateLayoutStyles.menu_content}>
									<div className={PrivateLayoutStyles.mobile_menu_header}>
										<div className={PrivateLayoutStyles.menu_header_text}>MENU</div>
										<button 
											className = {PrivateLayoutStyles.menu_hide_button}
											onClick   = {props.hideMenu}
										>
											<FontIcons.Material icon='chevron_right' />
										</button>
									</div>
									<RouteLinks type='primary'/>
		                            <button className={PrivateLayoutStyles.logout} onClick={props.logout}>
	                            		<div className={PrivateLayoutStyles.logout_text}>
		                            		<FontIcons.Material icon='exit_to_app' /> {Language.get('menu.logout')}
		                            	</div>
		                            </button>
	                            </div>
							</div>

						</div>
					</div>
				</div>
				<div className={PrivateLayoutStyles.body}>
					{ props.children }
				</div>
			</React.Fragment>;
}

export default PrivateLayout;