const ActionTypes = {update_conflictcourt:'update_conflictcourt'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object conflict_court
	 * @return object
	 */
	update_conflictcourt: function(conflict_court) {
		return { type: ActionTypes.update_conflictcourt, conflict_court };
	}

};

export default callables;
export { ActionTypes };