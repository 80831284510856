const ActionTypes = {update_brokers:'update_brokers'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object brokers
	 * @return object
	 */
	update_brokers: function(brokers) {
		return { type: ActionTypes.update_brokers, brokers };
	}

};

export default callables;
export { ActionTypes };