import React,{useEffect,useRef}                                 from 'react';
import PrivateRoutes,{PrivateRouteLinks,PrivateRouteComponents} from './privateroutes/PrivateRoutes';
import PublicRoutes,{PublicRouteLinks,PublicRouteComponents}    from './publicroutes/PublicRoutes';
import { BrowserRouter, Prompt, withRouter }                    from 'react-router-dom';
import { callables as StoreMethods }                            from 'app/store/Store';
import Helpers                                                  from 'app/utils/helpers/Helpers';

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
});

var globals = {
    route_subscriptions: {}
};

class Routes extends React.Component{

    with_container = (component) => {
        return <this.props.container>{component}</this.props.container>;
    }

    render() {

        return  (
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <RouteChange/>
                        <ScrollToTop>
                            {
                                this.props.User.auth ? 
                                    <PrivateRoutes with_container={this.with_container}/> 
                                : 
                                    <PublicRoutes  with_container={this.with_container} />
                            }
                        </ScrollToTop>
                    </BrowserRouter>
                );

    }
}

function RouteLinksComponent(props) {
    return  (
        props.User.auth ? 
            <PrivateRouteLinks {...props}/> 
        : 
            <PublicRouteLinks  {...props}/>
    );
}


function RouteComponentsComponent(props) {
    switch(props.type) {
        case 'prompt':
            return <Prompt {...props}/>
        default: 
            return  (
                props.User.auth ? 
                    <PrivateRouteComponents {...props}/> 
                : 
                    <PublicRouteComponents  {...props}/>
            );
    }
}

const RouteLinks      = StoreMethods.user_connect(RouteLinksComponent);
const RouteComponents = StoreMethods.user_connect(RouteComponentsComponent);


function RouteChangeSubscribe(key, callback) {
    if (!globals.route_subscriptions.hasOwnProperty(key)) {
        globals.route_subscriptions[key] = callback;
    }
}


function RouteChangeUnsubscribe(key) {
    if (!globals.route_subscriptions.hasOwnProperty(key)) {
        delete globals.route_subscriptions[key];
    }
}


const RouteChange = withRouter((props) => {
    const mounted = useRef();

    useEffect(()=> {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            for (const key in globals.route_subscriptions) {
                if (Helpers.is_function(globals.route_subscriptions[key])) {
                    globals.route_subscriptions[key]();
                }
            }
        }
    },[props.location.pathname]);

    return null;
});

export default StoreMethods.user_connect(Routes);
export { RouteLinks, RouteComponents, RouteChangeSubscribe, RouteChangeUnsubscribe };