function contents() {

	return {
		'home'       : 'Home',
		'realaccount': 'Buat Real Account',
		'logout'     : 'Logout',
		'logging_out': 'Logging Out',
		'please_wait': 'Please wait...'
	};

}

export default contents;