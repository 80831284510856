import _ from 'lodash';

var callables = {
	/**
	 * This will check if value is empty, almost equivalent to PHP empty
	 * types  empty values: null, undefined
	 * string empty values: ''
	 * array  empty values: length = 0
	 * object empty values: No properties
	 * @return object
	 */
	is_empty(value, strict) {
	    // undefined and null
	    if (typeof value == 'undefined' || value == null) {
	        return true;
	    }

	    // string
	    if (typeof value == 'string') {
	        return strict ? (value.trim() === '') : (value === '');
	    }

	    // array
	    if (callables.is_array(value)) {
	        return (value.length <= 0);
	    }


	    // object
	    if (callables.is_object(value)) {
	        for (var prop in value) {
	            if (value.hasOwnProperty(prop)) {
	                return false;
	            }
	        }
	        return true
	    }

	    return false;
	},

	/**	
	 * This will check if value is an object
	 * @param  mixed   value 
	 * @return boolean
	 */
	is_object(value) {
		return value && typeof value === 'object' && value.constructor === Object;
	},

	/**	
	 * This will check if value is an array
	 * @param  mixed   value 
	 * @return boolean
	 */
	is_array(value) {
		return Array.isArray(value);
	},

	/**	
	 * This will check if value is a function
	 * @param  mixed   value 
	 * @return boolean
	 */
	is_function (value) {
		return (typeof value === "function");
	},

	/**	
	 * Check if dom is visible
	 * @param  dom  dom 
	 * @return boolean
	 */
	// is_visible (dom) {
	// 	return dom.offsetWidth > 0 && dom.offsetHeight > 0;
	// },
    
    /**
     * This will check if string is alphabetic
     * @param  string  string 
     * @return boolean
     */
    is_alpha_space: function(string) {
        return /^[A-Za-z\s]+$/.test(string);
    },

    /**
     * This will get a property of an object based on string index
     * @param  object   object    
     * @param  string   index    
     * @return mixed
     */
    get_property: function (object, index) {
        var delimeter = null;

        delimeter = callables.set_default(delimeter,'.');
        return index.split(delimeter).reduce(function (object, index) { 

            if (!callables.is_object(object) || typeof object[index] === 'undefined') {

                return null;

            } else {

                return object[index]; 

            }

        }, object);

    },

    /**
     * This will set default value if original is not acceptable
     * @param  mixed value         
     * @param  mixed default_value 
     * @return mixed
     */
    set_default: function(value, default_value) {

        if (value !== undefined && value !== null) {
            
            if (value.trim !== '' ) {
                
                return value;
            }
        }
        
        return default_value; 

    },

    /**
     * This will get greatest common divisor of 2 numbers
     * @param  number number1
     * @param  number number2
     * @return int
     */
    get_gcd: function(number1, number2) {

	    if (number2 === 0) {
	    	return number1;
	    } else {
	    	return callables.get_gcd(number2, number1 % number2);
	    } 

    },

    /**
     * This will get the lowest ratio of 2 numbers
     * @param  number number1
     * @param  number number2
     * @return object {num1,num2}
     */
    lowest_ratio: function(number1, number2) {
    	var gcd = callables.get_gcd(number1, number2);
    	return {num1:number1/gcd, num2:number2/gcd};
    },
                  
    /**
     * generate unique ID
     * @param  string delimeter default = '', unqid suppose to be using '-' as default delimeter 
     *                          but using hypen causes issue when we assign it as element ID or object ID
     * @return string
     */
    uniqid: function(delimeter) {

        delimeter = delimeter || '-';

        var _padLeft = function (paddingString, width, replacementChar) {

                        if (paddingString.length >= width) {

                            return paddingString;

                        } else {

                            return _padLeft(replacementChar + paddingString, width, replacementChar || ' ');

                        }

                    };

        var _s4 = function (number) {
                    var hexadecimalResult = number.toString(16);
                    return _padLeft(hexadecimalResult, 4, '0');
                };

        var _cryptoGuid = function (delimeter) {

                            var buffer = new window.Uint16Array(8);
                            window.crypto.getRandomValues(buffer);

                            return [

                                _s4(buffer[0]) + _s4(buffer[1]), 
                                _s4(buffer[2]), _s4(buffer[3]),
                                _s4(buffer[4]), _s4(buffer[5]) + _s4(buffer[6]) + _s4(buffer[7])

                            ].join(delimeter);
                        };

        var _guid = function (delimeter) {
                        var currentDateMilliseconds = new Date().getTime();
                        var pattern =            'xxxxxxxx'
                                      +delimeter +'xxxx'
                                      +delimeter +'4xxx'
                                      +delimeter +'yxxx'
                                      +delimeter +'xxxxxxxxxxxx';

                        return pattern.replace(
                            /[xy]/g, 
                            function (currentChar) {
                                var randomChar = (currentDateMilliseconds + Math.random() * 16) % 16 | 0;
                                currentDateMilliseconds = Math.floor(currentDateMilliseconds / 16);
                                return (currentChar === 'x' ? randomChar : ((randomChar & 0x7) | 0x8))
                                    .toString(16);
                            }
                        );
                    };

        var create = function (delimeter) {

                        if (!callables.is_empty(window.crypto)) {

                            if (!callables.is_empty(window.crypto.getRandomValues)) {
                                return _cryptoGuid(delimeter);
                            }

                        }

                        return _guid(delimeter);
                    };

        return create(delimeter);

    },
                    
    /**
     * This will get cookie by name
     * @param  string name 
     * @return string
     */
    get_cookie: function(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length >= 2) return parts.pop().split(";").shift();
    },

    /** 
     * This will set cookies
     * @param string      name 
     * @param string/int  value 
     * @param int         days  
     */
    set_cookie: function (name,value,days) {
        var expires = "";

        if (days) {

            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();

        }

        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },

    /** 
     * This will delete cookies
     * @param string  name 
     */
    delete_cookie(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    
    /**
     * add a wrapper for console.log function
     * @param  Boolean is_debug_enabled
     * @return function
     */
    debug: function(is_debug_enabled) {
        return function(message) {
            if (is_debug_enabled) {
                console.warn(message);
            }
        }
    },
        
    /**
     * This will get percentage of a number from another number
     * @param   int total
     * @param   int count
     * @returns int
     */       
    get_percentage: function(total, count) {
        return Math.floor((count / total) * 100);
    },
    
    /**
     * This will get percentage of a number from another number
     * @param   int total
     * @param   int percent
     * @returns int
     */       
    get_percent_value: function(total, percent) {
        return Math.floor((percent / 100) * total);
    },
    
    /**
     * This will get average of percentage listed in an object
     * If the object has element with value less than 0, then that value will over rule
     * @param  object/array
     * @return int
     */
    get_percent_average: function(list) {

        var sum        = 0;
        var item_count = 0;
        for (var item in list) {

            if (list[item] < 0) {
                return list[item]
            } else {
                sum += list[item];
                item_count++;
            }
        }

        return Math.floor(sum / item_count);
    },

    /** 
     * This will compute adjustments for element rescales
     * @param  object   main_element_size 
     * @param  number   view_width        
     * @return object                  
     */
    rescale_width: function(main_element_size, view_width) {
        var main_element_width    = parseFloat(main_element_size.width);
        var main_element_height   = parseFloat(main_element_size.height);

        if (main_element_width < view_width) {

            var adjustments = {};

            var width_percentage = (main_element_width / view_width);

            // get the aspect ratio
            var height_size = (view_width * main_element_height) / main_element_width;
            var height_diff = height_size - main_element_height;
            var width_diff  = view_width - main_element_width;

            // for iframe
            adjustments.scale  = width_percentage;
            adjustments.width  = view_width;
            adjustments.height = height_size;

            // for parent
            adjustments.parent_height         = height_diff + main_element_height;
            adjustments.parent_width          = width_diff  + main_element_width;
            adjustments.parent_margin_bottom  = 0-height_diff;
            adjustments.parent_margin_right   = 0-width_diff;

            return adjustments;

        } else {

            return false;

        }
    },

    /** 
     * Used to have delay for events execution and prevent spamming
     * Identical to debounce
     * @param  int milliseconds
     * @return function
     */
    event_delay: function() {
        var timers = {};
        return function (callback, latency_ms, id) {
            id         = id || 'default';
            latency_ms = latency_ms || 300;

            if (timers[id]) {
                clearTimeout(timers[id]);
            }

            timers[id] = setTimeout(callback, latency_ms);
        };
    }(),

    /**
     * This will fix some doms that are not scrollable and will select the right DOM instead
     * @param  dom scrollable 
     * @return dom
     */
    scrollable_mend: function(scrollable) {
        if (scrollable===document.body) {
            return window;
        } else if(scrollable===document) {
            return window;
        } else {
            return scrollable;
        }
    },

    /**
     * This will check fot IOS device
     * @returns boolean
     */
    is_ios: function() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    /**
     * 
     * @param {type} haystack
     * @param {type} needle
     * @returns {Array}
     */
    unset:  function(haystack, needle) {
        return haystack.filter(function(val) {
            return val !== needle;
        });
    },

    /**
     * Capitalize first letter of each words
     * @param  string  string   
     * @return string
     */
    ucwords: function(string) {

        string = string.toLowerCase();
        return string.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function(string){
            return string.toUpperCase();
        });

    },
        
    /**
     * This will remove characters if its on the beggining of stirng 
     * @param   string prefix
     * @param   string value
     * @returns string
     */
    remove_prefix: function(prefix, value) {

        if (value.substr(0, prefix.length) === prefix) {
            
            return value.slice(prefix.length);
            
        } else {
            
            return value;
        }

    },

    /**
     * This will get top and left offset of an element
     * @param  dom element 
     * @return object
     */
    get_offset(element) {
        var de   = document.documentElement;
        var box  = element.getBoundingClientRect();
        var top  = box.top + window.pageYOffset  - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        return { top: top, left: left };
    },

    /**
     *  Get css property of element
     * @param  dom    element 
     * @param  string attibute 
     * @param  mixed  pseudoElt
     * @return string
     */
    get_css(element, attibute, pseudoElt) {
        return window.getComputedStyle(element, pseudoElt).getPropertyValue(attibute);
    },

    /**
     * This will get right offset of the element
     * @param  dom element 
     * @return int
     */
    offset_right(element) {
        var offsets = element.getBoundingClientRect();
        return window.innerWidth - (offsets.left + offsets.width);
    },

    /**
     * request animation frame with fallback
     * @return string
     */
    requestAnimationFrame() {
        var request_animation = window.requestAnimationFrame ||
                                window.mozRequestAnimationFrame ||
                                window.webkitRequestAnimationFrame ||
                                function (fn) {
                                    window.setTimeout(fn, 15)
                                };

        return request_animation.apply(window, arguments);
    },

    /**
     * request animation frame with fallback
     * @param  string   id         
     * @return void
     */
    cancelAnimationFrame(id) {
        var cancel_animation = window.cancelAnimationFrame ||
                                window.mozCancelAnimationFrame ||
                                window.mozCancelRequestAnimationFrame ||
                                window.webkitCancelAnimationFrame ||
                                window.webkitCancelRequestAnimationFrame ||
                                function (fn) {
                                    window.clearTimeout(id)
                                };

        return cancel_animation.apply(window, arguments);
    },

    /**
     * Same as event delay, but better used if event callback is timing sensitive
     * @param  function callback  
     * @param  int      latency_ms 
     * @param  string   id         
     * @return void 
     */
    event_delay_animation: function() {
        var timers = {};

        /**
         * [description]
         */
        return function(callback, latency_ms, id) {
            if (!callables.is_empty(timers[id])) {
                callables.cancelAnimationFrame(timers[id]);
            }

            var start_time = Date.now();

            function step() {
                var now = Date.now();
                var elapsed = now - start_time;

                if (elapsed < latency_ms) {
                    callables.cancelAnimationFrame(timers[id]);
                    timers[id] = callables.requestAnimationFrame(step);
                } else {
                    callback();
                }
            }

            timers[id] = callables.requestAnimationFrame(step);
        };
    }(),

    /**
     * This will check if 2 array is equal
     * @param  array  array1 
     * @param  array  array2
     * @return boolean
     */
    is_array_equal(array1, array2) {
        return _(array1).xorWith(array2, _.isEqual).isEmpty();
    },

    /**
     * This will find if value exists in array
     * @param  mixed needle  
     * @param  array haystack
     * @return boolean
     */
    in_array(needle, haystack) {
        return _.includes(haystack, needle);
    },

    /**
     * Check if string is alpha numeric only
     * @param  string  string void
     * @return boolean
     */
    is_alpha_num: function(string) {
        return /^[a-zA-Z0-9]+$/.test(string);
    },

    /**
     * check if a string contains number
     * @param  string string 
     * @return boolean
     */
    is_number_only: function(string) {
        return /^[0-9]+$/.test(string);
    },

    /**
     * check if a string contains number
     * @param  string string 
     * @return boolean
     */
    is_money: function(string) {
        return /^[0-9.,]+$/.test(string);
    },

    /**
     * Check if string is valid email
     * @param  string  string 
     * @return boolean
     */
    is_email: function(string) {
        // var regex = new RegExp([
        //                 '^(([^<>()[\\]\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\.,;:\\s@\"]+)*)',
        //                 '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
        //                 '[0-9]{1,3}\])|(([a-zA-Z\\-0-9]+\\.)+',
        //                 '[a-zA-Z]{2,}))$'
        //             ].join(''));
        var regex = new RegExp([
                        '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)',
                        '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
                        '[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+',
                        '[a-zA-Z]{2,}))$'
                    ].join(''));
        return regex.test(string);
    },

    /**
     * Check if element dom is visible
     * @param  dom  element [description]
     * @return boolean
     */
    is_visible: function(element) {
        return !!( element.offsetWidth || element.offsetHeight || element.getClientRects().length );
    },

    /**
     * This will check if element prefix is the given string
     * @param  string  string
     * @param  string  prefix 
     * @return boolean
     */
    is_prefix: function(string, prefix) {
        return string && string.substr(0, prefix.length) === prefix;
    },

    /**
     * This will check if string is valid decimal
     * @param  string  string 
     * @return boolean
     */
    is_decimal: function(string) {
        return /^\d+(\.\d+)?$/.test(string);
    },

    /**
     * This will check if string is valid decimal
     * @param  string  string 
     * @return boolean
     */
    is_starting_decimal: function(string) {
        return /^\d+(\.)?$/.test(string);
    },

    /**
     * This will remove unecessary trailing zeros from decimal string
     * @param  string string 
     * @return string
     */
    remove_trailing_zero: function(string) {
        return callables.is_empty(string) ? '' : string.toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
    },

    /**
     * This will add padding to left
     * @param  string value 
     * @param  int    length 
     * @return string
     */
    str_pad_right: function(value, length, padding) {
        value              = String(value);
        var cur_length     = value.length;
        var missing_digits = length - cur_length;

        if (missing_digits > 0) {
            for (var i = 0; i<missing_digits; i++) {
                value = value + padding;
            }
        }

        return value;
    },

    money_to_number: function(number, decimal, thousand) {
        number = String(callables.replace_all(number, thousand, ''));
        number = String(callables.replace_all(number, decimal , '.'));

        return number;
    },

    /**
     * This will convert number into money format
     * @param  number number  
     * @param  int    decimals
     * @param  object options  
     * @return string
     */
    money_format: function(number, decimals, options) {
        decimals = decimals === undefined ? 2 : decimals;
        options  = options  || {};
        options.decimal_delimeter  = options.decimal_delimeter  || '.';
        options.thousand_delimeter = options.thousand_delimeter || ',';

        number            = callables.money_to_number(number, options.decimal_delimeter, options.thousand_delimeter);
        var parsed_number = parseFloat(number);

        if (isNaN(parsed_number) || parsed_number === 0) {
            number = '0';
        }


        var split_parts    = number.split('.');

        // whole number formatitng
        var whole_number = split_parts[0];
        var digits_array = [];
        var ctr          = 0;
        while (whole_number.length > 3) {
            ctr++;
            digits_array.splice(ctr, 0, options.thousand_delimeter + whole_number.slice(-3));
            whole_number = whole_number.slice(0, -3);
        }
        var formatted_whole_number = (parsed_number < 0 ? '-' : '')+whole_number+digits_array.reverse().join('');

        // decimal number formatitng
        var decimal_number = split_parts[1] || '';
        if (decimals <= 0) {

            decimal_number = '';

        } else {

            // var decimal_length = decimal_number.length;
            if (decimal_number.length > decimals) {
                decimal_number = decimal_number.substring(0,decimals);
            }

            if (decimal_number.length < decimals) {
                decimal_number = callables.str_pad_right(decimal_number,decimals,'0');
            }

        }

        var formatted_decimal_number = null;

        if (decimal_number.length > 0) {
            formatted_decimal_number = options.decimal_delimeter + decimal_number;
        } else {
            formatted_decimal_number = '';
        }


        return formatted_whole_number + formatted_decimal_number;
    },

    /**
     * This will replace all occurence of a string in another string
     * @param  string   string     
     * @param  string   needle    
     * @param  string   replacement 
     * @return string
     */
    replace_all: function (string, needle, replacement) {
        
        if ( callables.is_empty(string) !== true && callables.is_empty(needle) !== true) {

            /* covert all first to string */
            string      = string.toString();
            needle      = needle.toString();
            replacement = (! callables.is_empty(replacement)) ? replacement.toString() : "";

            return string.split(needle).join(replacement);
        }
        
        return string;

    },

    /**
     * This will set element current caret position
     * @param  int position 
     * @param  dom element
     * @return void
     */
    set_caret_position: function(position, element) {
        var caret_position = element;

        if (caret_position.createTextRange) {

            var caretMove = caret_position.createTextRange();
            caretMove.move('character', position);
            caretMove.select();

        } else {

            if (caret_position.selectionStart) {
                caret_position.focus();
                caret_position.setSelectionRange(position, position);
            } else {
                caret_position.focus();
            }

        }
    },

    /**
	 * Check if the file is an image extension
	 * @param string file
	 * @return boolean
	 */
	is_image: function(file) {
        return file.match(/.(jpg|jpeg|png|gif|jfif)$/i) ? true : false;
    },

    /**
     * This will return blank if the value is 0
     * @param decimal value 
     */
    is_hide_zero: function(value) {
        return (parseInt(value) === 0) ? '' : value;
    },

    /**
     * Sort array object by key
     * @param array array
     * @param key string
     */
    sort_array_object_by_key: function (array, key) {
        if (callables.is_array(array)) {
            array.sort((current, next) => {
                return (
                    current[key].toLowerCase() < next[key].toLowerCase() ? -1 :
                    current[key].toLowerCase() > next[key].toLowerCase() ?  1 : 0
                )
            });
        }
        
    },
    
    /**
     * This will sort the branches by
     * 1. HQ flag
     * 2. Branch Order
     * 3. Branch Name
     * 
     * @param {array} branches 
     * @param {object} keys 
     */
    sort_branches: function(branches, keys) {
        
        if(callables.is_array(branches)) {
            
            if(!callables.is_empty(keys)) {

                branches.sort((item1, item2) => {

                    if (item1[keys.hq_flag]) {
                        return -1;
                    }

                    if (item2[keys.hq_flag]) {
                        return 1;
                    }

                    if (item1[keys.branch_order] < item2[keys.branch_order]) {
                        return -1;
                    }

                    if (item1[keys.branch_order] > item2[keys.branch_order]) {
                        return 1;
                    }

                    if (item1[keys.branch_name].toLowerCase() < item2[keys.branch_name].toLowerCase()) {
                        return -1;
                    }

                    if (item1[keys.branch_name].toLowerCase() > item2[keys.branch_name].toLowerCase()) {
                        return 1;
                    }

                    return 0;
                });
                
            }

        }

    }

};

/**
 * This will disable body scroll
 * @param  string  id 
 * @param  boolean is_enable default = true
 * @param  string  add_class if is_enable = true ignore this
 *                 RECOMMENDED: adding class and putting the styles via css
 * @return void
 */
callables['body_scroll'] = (function() {
                            var ids                 = [];
                            var classes             = {};
                            // var last_direction      = null;
                            var body                = document.body;
                            var scrollable          = callables.scrollable_mend(body);
                            var lock_scroll_left    = 0;
                            var lock_scroll_top     = 0;
                            // var last_scroll_left    = scrollable.scrollLeft;
                            var last_scroll_top     = scrollable.scrollTop;
                            var has_scroll_lock     = false;
                            var last_direction_top  = null;
                            // var last_direction_left = null;

                            if (callables.is_ios()) {

                                scrollable.addEventListener('scroll', function() {  
                                    // var current_scroll_left = scrollable.scrollLeft;
                                    var current_scroll_top  = scrollable.scrollTop;

                                    if (has_scroll_lock) {

                                        if (current_scroll_top < lock_scroll_top && last_direction_top === 'up') {

                                            scrollable.scrollTop(0);

                                        }


                                        if (current_scroll_top > lock_scroll_top && last_direction_top === 'down') {

                                            scrollable.scrollTop(lock_scroll_top + 20);

                                        }

                                        scrollable[0].scrollTo(lock_scroll_left, lock_scroll_top);

                                    } else {

                                        last_direction_top  = current_scroll_top > last_scroll_top  ? 'down': 'up' ;
                                        last_scroll_top     = current_scroll_top;


                                        // last_direction_left = current_scroll_left > last_scroll_left ?'left':'right';
                                        // last_scroll_left    = current_scroll_left;

                                    }

                                });

                            }
                            
                            return function(id, is_enable, add_class) {
                                if (is_enable!==false) {

                                    ids = callables.unset(ids, id);

                                    if (classes.hasOwnProperty(id)) {
                                        body.classList.remove(classes[id]);

                                        delete classes[id];
                                    }

                                    // remove scroll lock JS
                                    if (ids.length <= 0) {
                                        has_scroll_lock  = false;
                                    }

                                } else { 

                                    ids.push(id);

                                    // Add inline style or class
                                    if (!callables.is_empty(add_class)) {
                                        classes[id] = add_class;
                                        body.classList.add(add_class);
                                    } 

                                    // add scroll lock JS
                                    if (has_scroll_lock===false) {
                                        has_scroll_lock  = true;
                                        lock_scroll_left = scrollable.scrollLeft;
                                        lock_scroll_top  = scrollable.scrollTop;
                                    }
                                }
                                
                            };

                        }());
    
export default { ...callables, ..._};