/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Loader default layout and container
 * ---------------------------------------------------------------------------------------------------------------------
 * 
 * 
 */

import React,{useState}     from 'react';
import LoaderTheme          from 'theme/components/loader/Loader';
import LoaderStyles         from './scss/LoaderStyles.module.scss';

function Spinner(props) {
	return LoaderTheme.hasOwnProperty('Spinner') ? <LoaderTheme.Spinner {...props} /> : <div>Loading...</div>;	

}

function Screen(props) {
	return LoaderTheme.hasOwnProperty('Screen') ? <LoaderTheme.Screen {...props} />   : <Spinner/>;	
}

function Ghost(props) {
	const [blocks] = useState(props.blocks || 20);
	const [min   ] = useState(props.min    || 20);

	let block_ui = []

	
	for (let i = 0; i < blocks; i++) {
	    block_ui.push(
	    	<div 
	    		key       = {i}
	    		className = {LoaderStyles.ghost_block}
	    		style     = {{
		    					width: (Math.floor(Math.random() * ((100-min) + 1)) + min) + '%'
		    				}} 
	    	>
		    	<div className='GlobalStyles-ghost_ui'></div>
	    	</div>
	    )
	}

	return LoaderTheme.hasOwnProperty('Ghost') ? <LoaderTheme.Ghost {...props} blockUI={block_ui}/> : 
		<div {...props} className={LoaderStyles.ghost}>{block_ui}</div>;	
}

export default { Spinner, Screen, Ghost };