import React,{Component}             from 'react';
import Step4Wysiwyg                  from './step4wysiwyg/Step4Wysiwyg';
import { callables as StoreMethods } from 'app/store/Store';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Loader                        from 'app/components/loader/Loader';
import Model                 		 from 'app/modules/model/Model';
import DisclosureStatement       	 from '../disclosurestatement/DisclosureStatement';

class Step4 extends Component {

	constructor(props) {
		super(props);
		this.state  = { loading   : 1 };
	}

  	componentDidMount = () => {
  		this.check_loading();
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	render() {
		if (this.state.loading) {
			return <Loader.Spinner />;
		} else if (this.props.Step.content.activeProgressName===Model.get_step_name('disclosure2')) {
			return <Step4Wysiwyg {...this.props} />;
		} else {
			return <DisclosureStatement step={4} key={4}/>;
		} 
	}

	check_loading = () => {
		if (this.state.loading===1) {
			var has_Step = !Helpers.is_empty(this.props.Step);

  			if (has_Step) {
  				this.setState({ loading: 0 });
				if(this.checkStep8()){
					StoreMethods.step_enabled_change(8)
					this.changeDateSuccess()
					//this.disableAllandYa()
					//console.log('step4')
				}
  			}
		}
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Step4')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		//disable date
		document.getElementById("date").disabled = true;
		this.disableElement('FormStyles_dropdown')
		this.disableElement('FormStyles_upload')
		this.disableElement('FormStyles_textbox')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) == classname){
				//console.log(child)
				child.disabled = true;
			}
  		}
		/*console.log(elements)
	
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			for (var i = 0, length = elements.length; i < length; i++) {
				child = elements[i];
				if (child.className.substring(0, classname.length) == classname)
					  child[i].disabled = true;
			  }		
		}*/
		
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}

	//---------------------------
}

export default StoreMethods.connect_stores(Step4, ['Step'], Model.get_realaccount_data);