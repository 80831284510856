import React			    from 'react';
import classnames           from 'classnames';
import Form                 from 'app/components/form/Form';
import FormValidatorCommon  from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language             from 'app/utils/language/Language';
import Helpers              from 'app/utils/helpers/Helpers'; 

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.job_data','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.job_data','en')}
		  			</div>
		  		</h3>
				<Form.Row>
		  			<Form.Radio     
		  				label                = 'job_type' 
		  				className            = 'AppStyles-full_size_input RealAccountStyles-radio_with_input'
		  				ref                  = {inputs.job_type.ref}
		  				defaultValue         = {component.job_type_defaultValue()}
		  				options              = {component.job_type_options}
		  				onChange             = {component.job_type_changed}
	      				onTouched            = {props.onTouched}
	      				required             = {1}
            			optionsFormatted     = {false}
            			formatter            = {job_data_formatter(inputs, props, component)}

		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'company_name' 
		  				className    = 'AppStyles-full_size_input'
		  				ref          = { inputs.company_name.ref }
		  				defaultValue = { props.User.details.company_name }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<Form.Textbox     
		  				label        = 'company_line' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.company_line.ref }
		  				defaultValue = { props.User.details.company_line }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  			<Form.Textbox     
		  				label        = 'position' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = {inputs.position.ref}
		  				defaultValue = {props.User.details.position}
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  		</Form.Row>
				<Form.Row>
		  			<div className = 'AppStyles-half_size_input'>
		  				<Form.Dropdown     
			  				optionsFormatted     = {false}
			  				label                = 'current_tenure' 
			  				className            = 'AppStyles-full_size_input RealAccountStyles-input_labled_year'
			  				ref                  = {inputs.current_tenure.ref}
			  				defaultValue         = {props.User.details.current_tenure}
			  				options              = {Helpers.range(0,22)}
			  				formatter            = {(value) => {
			  											var final_value = value!==0?value:'';
														var description = "";

			  											if (value!==0) {
			  												description = value > 20 ? '>= 21' : value
			  											} else {
			  												description = '--';
			  											}

			  											return {  
			  												value      : final_value, 
			  												description: description
			  											};
			  										}}
			  				postLabel            = {
			  											<span className='RealAccountStyles-post_label_year'>
										  					Tahun
										  				</span>
										  			}
	      					onTouched    = {props.onTouched}
			  			/>
			  		</div>
		  			<div className = 'AppStyles-half_size_input'>
			  			<Form.Dropdown     
			  				optionsFormatted     = {false}
			  				label                = 'previous_tenure' 
			  				className            = 'AppStyles-full_size_input  RealAccountStyles-input_labled_year'
			  				ref                  = {inputs.previous_tenure.ref}
			  				defaultValue         = {props.User.details.previous_tenure}
			  				options              = {Helpers.range(0,22)}
			  				formatter            = {(value) => {
			  											var final_value = value!==0?value:'';
														var description = "";

			  											if (value!==0) {
			  												description = value > 20 ? '>= 21' : value
			  											} else {
			  												description = '--';
			  											}

			  											return {  
			  												value      : final_value, 
			  												description: description
			  											};
			  										}}
			  				postLabel            = {
			  											<span className='RealAccountStyles-post_label_year'>
										  					Tahun
										  				</span>
										  			}
	      					onTouched    = {props.onTouched}
			  			/>
			  		</div>
		  		</Form.Row>
				<Form.Row>
		  			<Form.TextArea     
		  				label        = 'office_address' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.office_address.ref }
		  				defaultValue = { props.User.details.office_address }
	      				onTouched    = {props.onTouched}
	      				required     = {1}
		  			/>
		  			<Form.Textbox     
		  				label        = 'postal_code' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = { inputs.office_postal_code.ref }
		  				defaultValue = { props.User.details.office_postal_code }
	      				onTouched    = { props.onTouched }
		  			/>
		  		</Form.Row>
		  		<Form.Row>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.Textbox     
			      				label        = 'office_phone' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.office_phone_area_code.ref }
			      				defaultValue = { props.User.details.office_phone_area_code }
	      						onTouched    = { props.onTouched }
	      						onChange     =  {() => {
	      											component.check_contact_group('office_phone')
	      										}}
	      						placeholder  = {component.area_code_placeholder}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.Textbox     
			      				label        = 'blank' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.office_phone.ref }
			      				defaultValue = { props.User.details.office_phone }
	      						onTouched    = { props.onTouched }
	      						onChange     =  {() => {
	      											component.check_contact_group('office_phone')
	      										}}
	      						placeholder  = {component.contact_number_placeholder}
			      			/>
			      		</div>
					</div>
					<div className = 'AppStyles-half_size_input'>
		      			<div className = 'RealAccountStyles-number_code'>
			      			<Form.Textbox     
			      				label        = 'office_fax' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.office_fax_area_code.ref }
			      				defaultValue = { props.User.details.office_fax_area_code }
	      						onTouched    = { props.onTouched }
	      						onChange     =  {() => {
	      											component.check_contact_group('office_fax')
	      										}}
	      						placeholder  = {component.area_code_placeholder}
			      			/>
			      		</div>
		      			<div className = 'RealAccountStyles-number'>
			      			<Form.Textbox     
			      				label        = 'blank' 
			      				className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.office_fax.ref }
			      				defaultValue = { props.User.details.office_fax }
	      						onTouched    = { props.onTouched }
	      						onChange     =  {() => {
	      											component.check_contact_group('office_fax')
	      										}}
	      						placeholder  = {component.contact_number_placeholder}
			      			/>
			      		</div>
					</div>
		  		</Form.Row>
		  	</React.Fragment>;
}

function job_data_formatter(inputs, props, component) {
	return (item) => {
		switch(item) {
			case 'Swasta':  
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.private',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.private',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Wiraswasta':  
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.self_employed',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.self_employed',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Ibu RT':  
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.house_wife',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.house_wife',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Profesional': 
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.professional',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.professional',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Peg. Negeri': 
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.public_servant',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.public_servant',
											'en'
										)}
									</div>
								</React.Fragment> 
				};
			case 'Peg. BUMN':
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.bumn_employee',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.bumn_employee',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Mahasiswa':
				return { 
					value      : item, 
					description:<React.Fragment>
									{Language.get(
										'realaccount.student',
										'id'
									)}
									<div 
										className='AppStyles-label_secondary'
									>
										{Language.get(
											'realaccount.student',
											'en'
										)}
									</div>
								</React.Fragment>
				};
			case 'Lainnya, sebutkan':
				return { 
					value      :item, 
					description:<Form.Textbox     
					  				className    = {classnames(['RealAccountStyles-radio_input'])}
					  				ref          = {inputs.other_job_type.ref}
					  				defaultValue = {
					  									component.show_other_job() ?
					  									props.User.details.job_type:''
					  								}
				      				onTouched    = {props.onTouched}
				      				required     = {1}
				      				beforeInput  = {
						  								<span 
						  									className='RealAccountStyles-radio_text'
						  								> 
															{Language.get(
																'realaccount.other_mention',
																'id'
															)}:
															<div className='AppStyles-label_secondary'>
																{Language.get(
																	'realaccount.other_mention',
																	'en'
																)}
															</div>
						  								</span>
						  							}

									disabled = {!component.show_other_job()}
					  			/>
				};
			// no default
		}
	}
}

function RefValidations(props, component) {
	return { 
		job_type               :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		other_job_type         :{ ref: React.createRef(), validations:{
									triggers: ['onBlur'],
									validate: [
												{ 
													as     : 'requiredif',
													type   : 'nomessage',
													checker: () => (
																component.job_type_currentValue() === component.other_job_value
															)
												}
											] 
								}},
		company_name           :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		company_line           :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		position               :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		current_tenure         :{ ref: React.createRef(),validations:{}},
		previous_tenure        :{ ref: React.createRef(),validations:{}},
		office_address         :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
			    					validate: [{as: 'required'}]
			    				}},
		office_postal_code     :{ ref: React.createRef(),validations:{
											triggers: ['onBlur'],
											prevent : true,
											validate: [{as: 'no_special_characters'}]
										}},
		office_phone_area_code :{ ref: React.createRef(),validations:FormValidatorCommon.optional_area_code(
									component.contact_grouped_required('office_phone'), 	[
										{
											as: 'max_length',
											max: 4
										},
									]
								)},
		office_phone           :{ ref: React.createRef(),validations:FormValidatorCommon.optional_number(
									component.contact_grouped_required('office_phone')
								)},
		office_fax_area_code   :{ ref: React.createRef(),validations:FormValidatorCommon.optional_area_code(
									component.contact_grouped_required('office_fax'),	[
										{
											as: 'max_length',
											max: 4
										},
									]
								)},
		office_fax             :{ ref: React.createRef(),validations:FormValidatorCommon.optional_number(
									component.contact_grouped_required('office_fax')
								)}
	};
}

export default { Inputs, RefValidations }