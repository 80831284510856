function contents() {

	return {
		'full_name'                 : 'Nama Lengkap (Sesuai Kartu Identitas)',
		'id_types'                  : 'KTP/SIM/Paspor/KITAS',
		'id_types_1'                : 'KTP/SIM/Paspor/KITAS',
		'id_number'                 : 'No. Identitas (KTP/SIM/Paspor/KITAS)',
		'email_address'             : 'Alamat Email',
		'country_code'              : 'Kode Negara',
		'phone_number'              : 'No. Telepon Rumah',
		'branch'                    : 'Cabang',
		'broker'                    : 'Wakil Pialang',
		// 'account_type'              : 'Bilateral / Multilateral',
		'register'                  : 'Daftar',
		'login'                     : 'Masuk',
		'username'                  : 'Username',
		'password'                  : 'Password',
		'accept_statement'          : 'Pernyataan Menerima',
		'declaration_of_truth'      : 'Pernyataan Kebenaran dan Tanggung jawab',
		'submit'                    : 'Lanjut',
		'back'                      : 'Kembali',
		'accept_truth_responsibilty': 'Pernyataan kebenaran dan tanggung jawab',
		'mobile_number'             : 'No Handphone',
		'place_of_birth'            : 'Tempat Lahir',
		'date_of_birth'             : 'Tanggal Lahir',
		'address'                   : 'Alamat Rumah (Sesuai Kartu Identitas)',
		'city'                      : 'Kota',
		'postal_code'               : 'Kode Pos',
		'demo_account_number'       : 'No Demo Account',
		'nationality'               : 'Warga Negara',
		'tax_number'                : 'Nomor Pokok Wajib Pajak (NPWP)',
		'gender'                    : 'Jenis Kelamin',
		'mother_maiden_name'        : 'Nama Ibu Kandung',
		'marital_status'            : 'Status Perkawinan',
		'home_ownership_status'     : 'Status Kepemilikan',
		'fax_number'                : 'No. Faksimili Rumah',
		'area_code'                 : 'Kode Area',
		'account_opening_purpose'   : 'Tujuan Pembukaan Rekening',
		'investment_experience'     : 'Pengalaman Investasi',
		'family_work_at'			: 'Apakah Anda memiliki anggota keluarga yang bekerja di '
									+'BAPPEBTI / Bursa Berjangka / Lembaga Kliring Berjangka?',
		'bankrupt'                  : 'Apakah anda telah dinyatakan pailit oleh pengadilan?',
		'name'                      : 'Nama',
		'relationship'              : 'Hubungan dengan anda',
		'job_type'                  : 'Pekerjaan',
		'company_name'              : 'Nama Perusahaan',
		'company_line'              : 'Bidang Usaha',
		'position'                  : 'Jabatan',
		'current_tenure'            : 'Lama Bekerja',
		'previous_tenure'           : 'Kantor Sebelumnya',
		'office_address'            : 'Alamat Kantor',
		'office_phone'              : 'No. Telepon Kantor',
		'office_fax'                : 'No Faksimili',
		'other_job_type'            : ' ',
		'annual_income'             : 'Penghasilan Per Tahun',
		'asset_address'             : 'Rumah, Lokasi',
		'asset_value'               : 'Nilai Jual Objek Pajak (NJOP)',
		'bank_deposit'              : 'Deposit Bank',
		'amount'                    : 'Jumlah',
		'other'                     : 'Lainnya',
		'bank_name'                 : 'Nama Bank',
		'account_number'            : 'Nomor A/C',
		'account_type'              : 'Jenis Rekening',
		'currency'                  : 'Mata Uang',
		'recent_photo'              : 'Foto Terkini',
		'select_file'               : 'Drop files or click to upload',
		'other_document'            : 'Dokumen Lainnya Yang Dilampirkan',
		'captcha'                   : ' ',
		'select_yes'                : 'Dengan mengisi kolom “YA” di bawah ini, saya menyatakan bahwa saya telah '
									+'memiliki pengalaman yang mencukupi dalam melaksanakan transaksi Perdagangan '
									+'Berjangka karena pernah bertransaksi pada Perusahaan Pialang Berjangka PT',
		'select_yes_2'              : ' dan telah memahami tentang tata cara bertransaksi Perdagangan Berjangka.',
		'select_yes_3'				: 'Demikian Pernyataan ini dibuat dengan sebenarnya dalam keadaan sadar, '
									+'sehat jasmani dan rohani serta tanpa paksaan apapun dari pihak manapun.',
		'close'                     : 'Tutup',
		'reload'                    : 'Muat Ulang',
		'spouse'                    : 'Nama Istri / Suami',
		'phone_number_short'        : 'No. Telepon',
		'website'                   : 'Website',
		'president_commissioner' 	: 'Komisaris Utama',
		'commissioner'				: 'Komisaris',
		'company_ceo'               : 'Direktur Utama',
		'company_director'          : 'Direktur Kepatuhan',
		'director'                  : 'Direktur',
		'company_bappebti_license'  : 'Nomor Izin Usaha dari Bappebti',
		'future_exchange_number'    : 'Nomor Keanggotaan Bursa Berjangka',
		'future_clearing_number'    : 'Nomor Keanggotaan Lembaga Kliring Berjangka',
		'alternative_trading_system': 'Nomor Persetujuan sebagai Peserta Sistem Perdagangan Alternatif',
		'implementation_trading_system': 'Nama Penyelenggara Sistem Perdagangan Alternatif',
		'complaint_email_address'   : 'Alamat Email Jika Terjadi Keluhan',
		'conflict_courts_step1'     : 'Sarana penyelesaian perselisihan',
		'blank'                     : ' ',
		'real_account_number'       : 'No Account',
		'short_fax_number'          : 'No. Faksimili',
		'one_word_fax'              : 'Faksimili',
		'short_address'             : 'Alamat',
		'short_phone_number'        : 'No. Telepon',
		'bank_phone_number'         : 'No. Telepon',
		'branch_office'             : 'Kantor Cabang',
		'head_office'               : 'Kantor Pusat',
		'complaint_phone_number'    : 'Nomor telepon jika terjadi Keluhan',
		'trading_experience'        : 'Pengalaman Transaksi Perdagangan Berjangka',
		'short_email_address'       : 'Email',
		'customer_acceptance_license': 'Nomor Izin Penerimaan Nasabah Secara Elektronik On-Line',
		'save'                       : 'Simpan',
		'dashed_email'              : 'E-mail',
		'id_full_name'              : 'Nama Lengkap',
		'id_place_birth'            : 'Tempat Lahir',
		'id_date_birth'             : 'Tanggal Lahir',
		'id_address'                : 'Alamat Rumah',
		'id_postal_code'            : 'Kode Pos',
		'id_id_types'               : 'KTP/SIM/Paspor/KITAS',
		'id_id_number'              : 'No. Identitas (KTP/SIM/Paspor/KITAS)',
		'office'                    : 'Kantor',
		'license_number'            : 'Nomor Izin',
		'office_list'               : 'DAFTAR KANTOR',
		'choosed_office'            : 'KANTOR YANG DIPILIH (salah satu)'
	}; 

}

export default contents;