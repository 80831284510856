function contents() {

	return {
		ERR_00001: 'Kolom ini perlu diisi',
		ERR_00002: 'Kolom ini perlu diisi',
		ERR_00003: 'Nama hanya dapat berisi alfabet, titik, koma, dan tanda kutip tunggal.',
		ERR_00004: 'Kartu Identitas hanya bisa diisi dengan angka dan huruf.',
		ERR_00005: 'Kartu Identitas hanya bisa diisi dengan angka.',
		ERR_00006: 'Email tidak valid.',
		ERR_00007: 'reCaptcha tidak valid.',
		ERR_00008: 'Username/Password salah.',
		ERR_00009: 'Anda Memiliki Keluarga Yang Bekerja di BAPPEBTI / Bursa Berjangka / Lembaga Kliring Berjangka, Anda Tidak Bisa Melanjutkan.',
		ERR_00010: 'Anda dinyatakan Pailit, Anda Tidak Bisa Melanjutkan.',
		ERR_00011: 'Tempat lahir diperlukan.',
		ERR_00012: 'Tanggal lahir diperlukan.',
		ERR_00013: 'Alamat rumah diperlukan.',
		ERR_00014: 'Kota diperlukan.',
		ERR_00015: 'Kode pos diperlukan.',
		ERR_00016: 'Nama Ibu Kandung diperlukan.',
		ERR_00017: 'Data yang diminta tidak cocok. Coba hapus cache browser Anda dan muat ulang halaman ini.',
		ERR_00018: 'Silahkan isi semua kolom yang diperlukan dengan informasi yang benar, atau pilih satu dari opsi yang diberikan.',
		ERR_00019: 'Sesi berakhir atau tidak valid. Silakan coba memuat kembali halaman ini.',
		ERR_00020: 'Akun ini sudah ada. Anda dapat login melalui halaman login.',
		ERR_00021: 'Silahkan lengkapi step sebelumnya, atau coba muat ulang halaman ini.',
		ERR_00022: 'Ada masalah dalam memproses permintaan. Silakan coba lagi nanti.',
		ERR_00023: 'Jaringan terputus. Silakan periksa koneksi internet Anda.',
		ERR_00024: 'Akun Anda belum aktif. Silahkan hubungi Wakil Pialang untuk aktifasi akun Anda.',
		ERR_00025: 'Tujuan hanya untuk spekulasi.',
		ERR_00026: 'NPWP harus diisi dengan 15 atau 16 digit angka.',
		ERR_00027: 'NPWP diperlukan.',
		ERR_00028: 'Document ini perlu di upload.',
		ERR_00029: 'Umur Anda tidak memenuhi syarat.',
		ERR_00030: 'akses ditolak! Akun sudah digunakan.',
		ERR_00031: 'NPWP harus diisi dengan setidaknya 5 karakter.',
		ERR_00032 : 'Kolom ini harus diisi dengan setidaknya 5 angka.',
		ERR_00033 : 'Kolom ini harus diisi dengan 16 digit.',
		ERR_00034 : 'Kolom ini harus diisi dengan 12-16 digit.',
	};

}

export default contents;