import React                      from 'react';
import classnames                 from 'classnames';
import RealAccountComponentStyles from './scss/RealAccountComponentStyles.module.scss';
import Language                   from 'app/utils/language/Language';
import Helpers                    from 'app/utils/helpers/Helpers';
// import {RouteLinks}               from 'app/components/routes/Routes';

function StepCommonFooter(props) {
	return  <div className={RealAccountComponentStyles.common_footer}>
				<div className={RealAccountComponentStyles.accept_statement}>
					{props.AcceptStatement}
				</div>
				<div className={RealAccountComponentStyles.timer}>
					<div className={RealAccountComponentStyles.timer_wrapper}>
						{props.Timer}
					</div>
				</div>
				<div className={RealAccountComponentStyles.buttons}>
					{props.Buttons}
				</div>
			</div>;
}

function StepPageLayout(props) {
	var title    = Language.get('realaccount.step'+props.step+'_title','id');
	var subtitle = Language.get_strict('realaccount.step'+props.step+'_subtitle','id');
	return 	<div className={RealAccountComponentStyles.wrapper}>
				<div className={RealAccountComponentStyles.background_filler}></div>
				<div 
					className = {classnames([
									RealAccountComponentStyles.main_container,
									'GlobalStyles-slant_container_secondary'
								])}
				>
					<div className = {classnames([
										RealAccountComponentStyles.main_container_background,
										'GlobalStyles-slant_container_background'
									])}
					></div>

					<div className={classnames([
										RealAccountComponentStyles.content_boundary,
										'AppStyles-container'
									])}>

						<div className={RealAccountComponentStyles.content_wrapper}>

							{ 
								props.hasOwnProperty('header') ? props.header :

								<h3 
									className = {classnames([
													'RealAccountStyles-content_title',
													'AppStyles-no_margin'
												])}
								>
									<div>{title}</div>
									{
										!Helpers.is_empty(subtitle) && 
										<div className={'RealAccountStyles-content_subtitle'}>{subtitle}</div>
									}
								</h3>

							}
							
							<div className={RealAccountComponentStyles.content_body}  >
								{props.body}  

								<div className={RealAccountComponentStyles.content_footer_text}>{props.footer}</div>
							</div>

							<div className={RealAccountComponentStyles.content_footer}>
								<StepCommonFooter 
									AcceptStatement={props.AcceptStatement}
									Timer          ={props.Timer}
									Buttons        ={props.Buttons}
								/>
							</div>

						</div>

					</div>
				</div>
			</div>;
}

export default {StepPageLayout};