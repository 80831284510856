import React, { useState, useEffect }  from 'react';
import Image                           from 'app/components/image/Image';
import ActivationPageStyles            from '../scss/ActivationPageStyles.module.scss';
import CustomerActivationStyles        from './scss/CustomerActivationStyles.module.scss';
import Loader                          from 'app/components/loader/Loader';
import Model                           from 'app/modules/model/Model';
import ErrorPages                      from 'app/pages/errorpages/ErrorPages';
import Language                        from 'app/utils/language/Language';
import MessageDisplay				   from 'app/components/messagedisplay/MessageDisplay';
import GoogleAnalytics 				   from 'app/utils/googleanalytics/GoogleAnalytics';
import Form 						   from 'app/components/form/Form';

function CustomerActivation(props) {
	const [loading, set_loading] = useState(true);
	const [error  , set_error  ] = useState(false);
	const [submit , set_submit ] = useState(0);
	const [details, set_details] = useState({});

	useEffect(() => {
		GoogleAnalytics.page_view([], 'Customer Activation Page');
	}, []);

	useState(() => {
		Model.get_customer_account_status(props.match.params.code, {
			success: () => { set_error(true); },
			fail   : (response) => {
						set_details(response.error);
						set_error(true); 
					},
			error  : () => { set_error(true); },
		})
	},[]);

	return 	<React.Fragment>
				{
					error ?
						details.code === '1' ?
							<Form.Form
								className 	= {ActivationPageStyles.activate_button_wrapper}
								submitting 	= {submit}
								onSubmit	= {() => { 
												set_submit(1);
												Model.activate_customer_account(props.match.params.code, {
													success: (response) => {
																set_details(response.data);
																set_error(false);
																set_loading(false);
																set_submit(0);
															},
													fail	: (response) => {
																set_details(response.error);
																set_error(true);
															},
													error 	: () => { set_error(true); }
												});
											}}
							>
								<Form.PrimaryButton main={1} >
									{Language.get('activation.click_to_activate_demo', 'id')}
								</Form.PrimaryButton>
							</Form.Form>
						: 
							details.code === '-9' ?
								<MessageDisplay.OnPage type='success' code='SUC_00002' />
							: 
								<ErrorPages.HasExpired/>
					: 
						loading ? <Loader.Spinner/> 
						:
							<div className={CustomerActivationStyles.wrapper}>
								<div className={CustomerActivationStyles.logo}>
									<Image.VerticalLogo />
								</div>
								<div className={CustomerActivationStyles.content_wrapper}>
									<div className={CustomerActivationStyles.title}>
										DATA PERUSAHAAN
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											{Language.get('form_labels.name', 'id')}
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>{details.companyName}</div>
										</div>
									</div>


									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											Kantor
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>{details.branchName}</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											Wakil Pialang Berjangka
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>{details.brokerName}</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.title}>
										DATA PEMOHON
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											Nama Lengkap
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>{details.fullName}</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											No Identitas
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>
												{details.identificationNumber}
											</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											{Language.get('form_labels.mobile_number', 'id')}
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>
												{details.mobileNumber}
											</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.title}>
										DEMO ACCOUNT
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											{Language.get('form_labels.username', 'id')}
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>
												{details.username}
											</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											{Language.get('form_labels.password', 'id')}
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>
												{details.password}
											</div>
										</div>
									</div>

									<div className={CustomerActivationStyles.block}>
										<div className={CustomerActivationStyles.label}  >
											Status
										</div>
										<div className={CustomerActivationStyles.content}>
											<div className={CustomerActivationStyles.content_colon}>:</div>
											<div className={CustomerActivationStyles.content_text}>Terkirim</div>
										</div>
									</div>

								</div>
							</div>
				}
			</React.Fragment>
	
}

export default CustomerActivation;