import React              from 'react';
import ReactDOM           from 'react-dom';
import App                from './app/App';
import * as serviceWorker from './serviceWorker';
import Store              from 'app/store/Store';
import { Provider }       from 'react-redux';

ReactDOM.render(
  	<Provider store={Store}>
		<App appVersion="1.8.3"/>
	</Provider>, 
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
