function contents() {

	return {
		'form_title'           : '',
		'login'                : 'Registrasi di sini',
		'login_button'         : 'Ke halaman login',
		'already_have_account' : 'Sudah punya akun demo?'
	};

}

export default contents;