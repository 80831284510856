import React,{Component, useEffect}  from 'react';
import classnames                    from 'classnames';
import Form                          from 'app/components/form/Form';
import RealAccountComponents         from '../realaccountcomponents/RealAccountComponents';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Model                         from 'app/modules/model/Model';
import { callables as StoreMethods } from 'app/store/Store';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Wysiwyg                 		 from 'app/components/wysiwyg/Wysiwyg';
import Loader                        from 'app/components/loader/Loader';
import Language                      from 'app/utils/language/Language';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';

const ScrollToTop = ({ children }) => {
	useEffect(() => {
		return function () {
			window.scrollTo(0, 0);
		}
	}, []);

	return children;
}

class DisclosureStatement extends Component {
	constructor(props) {
		super(props);

		// create refs and validations
	    this.inputs =   {
							accept_statement:{ ref: React.createRef(),validations:FormValidatorCommon.accept_statement},
							wysiwyg_0       :{ 
												ref        :React.createRef(),
												validations:{}
											}
						};
		this.state  = {
						can_submit: false,
						loading   : 1,
						ui_loading: 1, 
						submitting: 0,
						error     : false,
						code      : null
					};
		this.form   = React.createRef();
		this.wysiwyg_loaded  = [];
		this.wysiwyg_length  = 0;
	}

  	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step ' + this.props.step + ' Disclosure Statement');
  		this.check_loading();
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	render() {
		return  <ScrollToTop>
					<Form.Form 
						onValidateSuccess= {this.on_submit} 
						inputs           = {this.inputs} 
						loading          = {this.state.ui_loading}
			    		submitting       = {this.state.submitting}
			    		ref              = {this.form}
	    				className        = {classnames([this.state.error && 'AppStyles-hidden'])}
					>
						<RealAccountComponents.StepPageLayout
							step           = {this.props.step}
							header         = {''}
							body           = {
												this.state.loading ? <Loader.Ghost/> : 
												<React.Fragment>
													{
														this.props.DisclosureStatement.content &&
														this.props.DisclosureStatement.content.map((file,index) => {
															var assign_ref=this.inputs['wysiwyg_'+index].ref; 
															return <Wysiwyg.Parse 
																key         = {index} 
																file        = {file.file_path}
																onLoad      = {(id) => {
																					this.wysiwyg_onload(
																						assign_ref,
																						id
																					)
																				}} 
																ref         = {assign_ref}
															/>
													})}
												</React.Fragment>
											}
							footer         = {
												<React.Fragment>
													<p> 
														{Language.get('realaccount.disclosure_footer_1','id')}<br/>
														<b>
															{Language.get('realaccount.disclosure_footer_2','id')}
															<i>
																{Language.get('realaccount.disclosure_footer_3','id')}
															</i>
														</b>
														<br/>
														{Language.get('realaccount.disclosure_footer_4','id')}
													</p>
												</React.Fragment>
											}
							AcceptStatement= {RealAccountComponents.AcceptStatement(
												this.inputs.accept_statement.ref,
												this.on_accept_statement
											)}
							acceptTermsRef = {this.inputs.accept_statement.ref}
							canSubmit      = {this.state.can_submit}
							timerLabel     = {Language.get('realaccount.declare_on')}
						/>
					</Form.Form>
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message' 
							code      = {this.state.code} 
							type      = 'error' 
						/> 
					}
				</ScrollToTop>;
	} 

	on_accept_statement = () => {
		this.setState({ can_submit:  parseInt(this.inputs.accept_statement.ref.current.value())===1 });
	}


	on_submit = (inputs) => {
  		this.setState({ submitting: 1 });
		if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
		}else{
			// var component = this;
  		
			Model.update_next_progressName({
				precomplete: () => {
					this.setState({ submitting: 0 });
				},
				fail    : this.form.current.submit_fail,
				error   : this.form.current.submit_error
		  });
		}
	}

	wysiwyg_onload = (wysiwyg_ref, id) => {
		this.wysiwyg_loaded.push(id);
		this.check_ui_loading();
	}

	check_ui_loading = () => {
		if (this.state.ui_loading===1) {
			if (this.wysiwyg_loaded.length >= this.wysiwyg_length) {
				this.setState({ ui_loading: 0 });
				if(this.checkStep8()){
					this.disableAllandYa()
					StoreMethods.step_enabled_change(8)
					this.changeDateSuccess()
				}
			}
		}
	}

	check_loading = () => {

		if (this.state.loading===1) {

  			var has_disclosureStatement = !Helpers.is_empty(this.props.DisclosureStatement);
  			if (has_disclosureStatement) {
  				this.setState({ loading: 0 },() => {

			  		if (this.props.DisclosureStatement.error.code !== '0') {

						this.setState({ error: true, code: this.props.DisclosureStatement.error.code });

			  		} else {

			  			var wysiwyg_length = this.props.DisclosureStatement.content.length;
			  			if (wysiwyg_length > 0) {
			  				this.wysiwyg_length = wysiwyg_length;
			  			}

			  		}

			  		this.check_ui_loading();
  				});
  			}

		}

	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Disclosurestatement')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		StoreMethods.step_enabled_change(8)
		//disable date
		//document.getElementById("date").disabled = true;
		//this.disableElement('FormStyles_dropdown')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var elements = [], child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) === classname)
      			elements.push(child);
  		}
		//console.log(elements)
  		if(elements.length === 1){
			elements[0].disabled = true
		}else{
			
		}
		
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}
}

export default StoreMethods.connect_stores(DisclosureStatement,['DisclosureStatement'],Model.get_disclosure_statement);