import React,{useEffect,useState}    from 'react';
import RealAccountTheme              from 'theme/pages/realaccount/RealAccount';
import Helpers                       from 'app/utils/helpers/Helpers';
import Step1                         from './step1/Step1';
import Step2                         from './step2/Step2';
import Step3                         from './step3/Step3';
import Step4                         from './step4/Step4';
import Step5                         from './step5/Step5';
import Step6                         from './step6/Step6';
import Step7                         from './step7/Step7';
import Step8                         from './step8/Step8';
import { callables as StoreMethods } from 'app/store/Store';
import { RouteComponents }           from 'app/components/routes/Routes';
import Language                      from 'app/utils/language/Language';
import Model                 		 from 'app/modules/model/Model';
import Loader                        from 'app/components/loader/Loader';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';

function set_step_id(id, enabledSteps) {
	//console.log(id,enabledSteps)
	if(checkStep8()){
		StoreMethods.step_enabled_change(8)
		enabledSteps = 8
	}
	if (!Helpers.is_empty(id)) {

		var number = parseInt(Helpers.remove_prefix('step', id));

		if (number<=enabledSteps) {

			StoreMethods.step_change(number);
			Model.number_to_activeProgressName(number);

			return true;

		} else {

			return enabledSteps;
			
		}

	} else {

		return enabledSteps > 8 ? 8 : enabledSteps;

	}

}

function step_input_onTouched() {
	StoreMethods.step_has_changes();
}

function RealAccount(props) {
	const [redirect     , set_redirect]      = useState(0);
	const [loading      , set_loading ]      = useState(1);
	const [error        , set_error   ]      = useState(false);
	const [code         , set_code    ]      = useState(null);

	function detect_step_redirect() {
		if (redirect===0) {
			//console.log(props)
	  		var setStep = set_step_id(props.match.params.id, props.Step.content.enabledSteps);

	  		if (setStep!==true) {
	  			set_redirect(setStep);
	  		}	

  		} else {
	  		set_redirect(0);
  		}

	}
	
	// initialization
  	useEffect(() => {
  		if (loading === 1) {
			
			var has_User = !Helpers.is_empty(props.User.details);
			var has_Step = !Helpers.is_empty(props.Step);

			if (has_User && has_Step) {

	  			if (props.Step.error.code === '0') {
	  				detect_step_redirect();
		  		} else {
		  			set_error(true);
		  			set_code(props.Step.error.code);
		  		}

				set_loading(0);
	  		}
	  		
  		}

  	},[props.match.params.id, redirect, props.User, props.Step]);

	// URL changes after initialization, must change the display
  	useEffect(() => {

  		if (loading === 0) {
			detect_step_redirect();
  		}

  	},[props.match.params.id, redirect]);

  	// activeStep changes in store, must also change the URL
  	useEffect(() => {
  		if (loading === 0) {
  			var current_number = parseInt(Helpers.remove_prefix('step', props.match.params.id));
  			if (current_number!==props.Step.content.activeStep) {
				set_redirect(props.Step.content.activeStep);
  			}
  		}
  	},[props.Step.content.activeStep]);

  	if (loading === 1) {

  		return <Loader.Spinner />;

  	} else if (error) {

		return <MessageDisplay.OnPage code={code} type='error' className='RealAccountStyles-error_message' />;

  	} else {

		return  <React.Fragment>
					{ redirect > 0 && <RouteComponents type='redirect_step' number={redirect} /> }
					<RouteComponents 
						type      = 'prompt' 
						when      = { props.Step.content.hasChanges } 
						message   = { Language.get('realaccount.leave_page') }
					/>
					<RealAccountTheme 
						{...props}
						pages = {{
									1: (<Step1 onTouched ={step_input_onTouched} />),
									2: (<Step2 onTouched ={step_input_onTouched} />),
									3: (<Step3 onTouched ={step_input_onTouched} />),
									4: (<Step4 onTouched ={step_input_onTouched} />),
									5: (<Step5 onTouched ={step_input_onTouched} />),
									6: (<Step6 onTouched ={step_input_onTouched} />),
									7: (<Step7 onTouched ={step_input_onTouched} />),
									8: (<Step8 onTouched ={step_input_onTouched} />),
								}}
					/>
				</React.Fragment>;

  	}
}

//Added on 20220614---------
function checkStep8(){
	var done = false;
	var temp = StoreMethods.get_FormChange()
	var array = temp.StepReducers.content.previous_progress
	//console.log(this.props,temp,'step1')
	for (let index = 0; index < array.length; index++) {
		if(array[index]==='Regol Successful'){
			done = true;
			break;
		}
		
	}

	return done
}

export default StoreMethods.connect_stores(RealAccount,['User','Step'], Model.get_realaccount_data);