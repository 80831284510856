import React, { Component }          from 'react';
import classnames                    from 'classnames';
import AdHeaderStyles                from './scss/AdHeaderStyles.module.scss';
import { callables as StoreMethods } from 'app/store/Store';
import Helpers 			 			 from 'app/utils/helpers/Helpers';
import Model                 		 from 'app/modules/model/Model';
import Loader                        from 'app/components/loader/Loader';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import Wysiwyg                       from 'app/components/wysiwyg/Wysiwyg';

class AdHeader extends Component {

	constructor(props) {
		super(props);
	    this.state = {loading: 1, error:false, code:null};
	}

  	componentDidMount = () => {
  		this.check_loading();
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	render() {
    	return (
    		<div className={AdHeaderStyles.content}>
                <div className={classnames([
                                    AdHeaderStyles.boundary,
                                    this.state.error && 'AppStyles-invisible'
                                ])}
                >
                    {   
                        this.state.loading ? <Loader.Ghost/> : 
                        <React.Fragment>
                            {
                                this.props.CompanyAds.content 
                                && this.props.CompanyAds.content.main 
                                && this.props.CompanyAds.content.main.map((file,index) => (
                                    <Wysiwyg.Parse 
                                        key       = {index} 
                                        file      = {file.file_path}
                                        className = {AdHeaderStyles.wysiwyg}
                                    />
                                ))
                            }
                        </React.Fragment>
                    }
                </div>
                {
                    this.state.error && 
                    <MessageDisplay.OnPage 
                        code     ={this.state.code} 
                        type     ='error' 
                        className={AdHeaderStyles.error}
                    /> 
                }
      		</div>
    	);
 	}

  	check_loading = () => {
  		if (this.state.loading===1) {
  			var has_CompanyAds = !Helpers.is_empty(this.props.CompanyAds);

  			if (has_CompanyAds) {

                if (this.props.CompanyAds.error.code === '0') {
                    this.setState({ loading: 0 });
                } else {
                    this.setState({ loading: 0, error: true, code: this.props.CompanyAds.error.code});
                }
  			}
  		}
  	}
}

export default StoreMethods.connect_stores(AdHeader,['CompanyAds'], Model.get_company_ads);