const ActionTypes = {update_step6:'update_step6'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object files
	 * @return object
	 */
	update_step6: function(step6) {
		return { type: ActionTypes.update_step6, step6 };
	}

};

export default callables;
export { ActionTypes };