import React, { Component, useState } from 'react';
import classnames                     from 'classnames';
import Helpers                        from 'app/utils/helpers/Helpers';
import ImageStyles                    from './scss/ImageStyles.module.scss';
import BrokenImage                    from './images/BrokenImage.png';
import ImageTheme                     from 'theme/components/image/Image';
import Loader                         from 'app/components/loader/Loader';
import DownscaleLib                   from 'downscale';

var callables = {
    /**
     * This will load image src
     * @param  string src 
     * @param  object callbacks 
     * @return void
     */
    load_image: function(src, callbacks) {

        var image_preload   = new Image();
        var callback_called = false; 

        image_preload.addEventListener('load', function (response, status, xhr) {
            if (!callback_called) {
                callbacks.success(src, image_preload);
            }
        });

        image_preload.addEventListener('error', function (response, status, xhr) {
            callbacks.error(src, image_preload);
        });

        image_preload.src = src;
        if (image_preload.complete || image_preload.readyState === 4) {
            callback_called = true;
            callbacks.success(src, image_preload);
        }
    },

    /**
     * This will create temporary image
     * @param  number width 
     * @param  number height 
     * @return string
     */
    create_temporary: function(width, height) {
        var lowest_ratio = Helpers.lowest_ratio(width, height);
        var canvas = document.createElement("canvas");
        canvas.width  = lowest_ratio.num1;
        canvas.height = lowest_ratio.num2;
        return canvas.toDataURL('image/jpeg');
    },

    /**
     * This will read file and convert to img src
     * @param  file     file     
     * @param  function onloadend 
     * @return void
     */
    file_reader: function(file, onloadend) {
        let   reader = new FileReader();
        reader.onloadend = () => {
            onloadend(reader.result);
        }
        reader.readAsDataURL(file);
    }
};

class Load extends Component {

	constructor(props) {
		super(props);

        this.state = {
            loading: true,
            final_src : '',
            is_broken : false
        };
  	}

  	componentDidMount = () => {
        var class_instance = this;


        // load real image
        if (!Helpers.is_empty(this.props.src)) {

            callables.load_image(this.props.src, {

                success: function(src) {
                            class_instance.image_loaded(src);
                        },
                error   : function() {
                            class_instance.image_error();
                        }
            });

        } else {

            class_instance.image_error();

        }

  	}

	render() {
        var {src,ratio,alt,ImgProps,...filtered_props} = this.props;

    	return <div 
                {...filtered_props} 
                    className = {classnames([
                                    this.props.className,
                                    ImageStyles.image_wrapper,
                                    this.state.loading && ImageStyles.loading,
                                    this.state.is_broken  && ImageStyles.is_broken
                                ])}
                >
                {
                    Helpers.is_object(this.props.ratio) 
                    && <img 
                        {...this.props.ImgProps}
                        className = {classnames([
                                        ImageStyles.place_holder,
                                        'ImageStyles-image_size'
                                    ])}
                        alt       = {this.props.alt}
                        src       = {callables.create_temporary(this.props.ratio.width,this.props.ratio.height)} 
                    />
                }
    			{
                    this.state.loading ? 
                        <Loader.Spinner/> 
                    : 
                        <img 
                            {...this.props.ImgProps}
                            className = {classnames([
                                            ImageStyles.img_tag,
                                            Helpers.is_object(this.props.ratio) && ImageStyles.has_ratio
                                        ])}
                            alt       = {this.props.alt}
                            src       = {this.state.final_src} 
                        />
                  }
             </div>;
    }

    /**
     * This will try to load default image passed if the first image got error
     * @return void
     */
    image_error = () => {

        this.setState({ is_broken: true });

        var class_instance = this;


        // load real image
        if (!Helpers.is_empty(this.props.default)) {

            callables.load_image(this.props.default, {

                success: function(src) {
                            class_instance.image_loaded(src);
                        },
                error   : function() {
                            class_instance.image_default();
                        }
            });

        } else {

            class_instance.image_default();

        }

    }


    /**
     * Last resort image if original is not available
     * @return void
     */
    image_default = () => {

        var class_instance = this;

        callables.load_image(BrokenImage, {
            success: function(src) {
                        class_instance.image_loaded(src);
                    }
        });

    }

    /**
     * This will trigger after image src has been finalized
     * @param  string src
     * @return void
     */
    image_loaded = (src) => {
        this.setState({ loading: false ,  final_src: src });
    }
}

function Logo(props) {
    return <ImageTheme.Logo {...props} Load={Load} />;
}

function VerticalLogo(props) {
    return <ImageTheme.VerticalLogo {...props} Load={Load} />;
}

function Preview(props) {
    const [result, set_result] = useState(null);

    if (result === null) {
        if (props.file.is_src === true) {
            set_result(props.file.src);
        } else {
            callables.file_reader(props.file,set_result);
        }
    }
    
    var {file,...filtered_props} = props;
    return result === null ?  <Loader.Spinner/> : <Load {...filtered_props} src={result}/>;
}

/**
 * Downscale file input to best fit from given width and height
 * @param  file input_file 
 * @param  file options   
 * @return void
 */
function downscale(input_file, options) {
    options        = options || {};
    options.width  = options.width || 0;
    options.height = options.height || 0;

    function execute_callback(original, downscaled) {
        if (Helpers.is_function(options.callback)) {
            options.callback(original, downscaled);
        }
    }

    if (Helpers.is_empty(input_file)) {

        execute_callback(input_file,input_file);

    } else {
        

        Object.keys(input_file).map((key) => {
            var file        = input_file[key];
            var is_min_size = (Helpers.is_empty(options.min_size) ||  file.size >= options.min_size);
            var filter      = true;

            if (Helpers.is_function(options.filter)) {
                filter = options.filter(file);
            }

            if (filter && file.type.includes('image') && is_min_size) {

                (function(file) {

                    function execute_downscale(final_width, final_height) {
                        DownscaleLib(file, final_width, final_height, {returnBlob: 1}).then((blob) => {

                            if (blob.size < file.size) {
                                // var blob_to_file = new File([blob], file.name);
                                execute_callback(blob,file);
                            } else {
                                execute_callback(file,file);
                            }

                        });
                    }

                    if (options.bestfit === true && options.width!==0 && options.height!==0) {

                        callables.file_reader(file, (result) => {

                            callables.load_image(result, { success: (base64, image) => {

                                if (image.width > image.height) {
                                    execute_downscale(0, options.height);
                                } else {
                                    execute_downscale(options.width, 0);
                                }
                            }});

                        });

                    } else {
                        execute_downscale(options.width, options.height);
                    }

                }(file));

            } else {

                execute_callback(file,file);

            }
        return true;   
        });

    }

}

function rotate(input_file, callback) {

}

export default { Load, Logo, Preview, downscale, VerticalLogo, rotate };
