const ActionTypes = { update_company_ads:'update_company_ads' };

var callables = {

	/**	
	 * Add company to store
	 * @param  object company
	 * @return object
	 */
	update_company_ads: function(company_ads) {
		return { type: ActionTypes.update_company_ads, company_ads };
	}
};

export default callables;
export { ActionTypes };