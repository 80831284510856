import React,{useState, useEffect} from 'react';
import RealAccountComponentsTheme  from 'theme/pages/realaccount/realaccountcomponents/RealAccountComponents';
import Form                        from 'app/components/form/Form';
import Language                    from 'app/utils/language/Language';
import Helpers                     from 'app/utils/helpers/Helpers';
import ReactMoment                 from 'react-moment';
import FontIcons                   from 'app/components/fonticons/FontIcons';
import Model                 	   from 'app/modules/model/Model';
import 'moment/locale/id';
import 'moment-timezone';
// import FormValidatorCommon         from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';


function AcceptStatement(ref, onChange , accept_statement_label) {
	return  <Form.Radio 
				ref    ={ref}
				options={[
							{value:1, description:Language.get('realaccount.yes','id')},
							{value:2, description:Language.get('realaccount.no','id')}
					 	]} 
				label = {
							Helpers.is_empty(accept_statement_label) ? 
							'accept_statement':accept_statement_label
					  	}
				onChange= {onChange}
			/>;
}

function Timer(props) {
	const [date, setDate] = useState(new Date());

	useEffect(() => {
	    const date_interval = window.setInterval(() => {
						      	setDate(new Date());
						    }, 1000);

	    return () => {
	      	window.clearInterval(date_interval);
	    };
	});

	return  <React.Fragment>
				<div className='RealAccountComponentStyles-timer_label'>
					{props.timerLabel}
				</div>	
				<div className='RealAccountComponentStyles-timer'>
					<ReactMoment 
						format  ={'DD-MM-YYYY'} 
						interval={0} 
						locale  ='id'
						element ='span'
						tz      ={process.env.REACT_APP_TIMEZONE}
					>{date}</ReactMoment>
				</div>	
			</React.Fragment>;
}

function Buttons(props) {
	return  <React.Fragment>
				{
					props.step > 1 &&
					<span className='RealAccountComponentStyles-back_button'>
						<Form.SecondaryButton 
							onClick     = {Model.update_previous_progressName}
							buttonProps = {{type: 'button'}}
						>
							<FontIcons.Material icon='arrow_back'/> {Language.get('form_labels.back')}
						</Form.SecondaryButton>
					</span>
				}

				<Form.PrimaryButton 
					buttonProps = {{disabled:!props.canSubmit}}
					className   = 'RealAccountComponentStyles-submit_button'
					main        = {1}
				>
					{Language.get('form_labels.submit')} <FontIcons.Material icon='arrow_forward'/>
				</Form.PrimaryButton>
			</React.Fragment>;
}

function StepPageLayout(props) {
	return  <RealAccountComponentsTheme.StepPageLayout 
				{...props} 
				AcceptStatement = {props.AcceptStatement}
				Timer           = {<Timer timerLabel = {props.timerLabel}/>}
				Buttons         = {<Buttons step={props.step} canSubmit={props.canSubmit}/>}
			>{props.children}</RealAccountComponentsTheme.StepPageLayout>;
}

function ButtonsCUD(props) {
	return  <React.Fragment>
				{
					props.step > 1 &&
					<span className='RealAccountComponentStyles-back_button'>
						<Form.SecondaryButton 
							onClick     = {props.batal}//{Model.closeCUD}
							buttonProps = {{type: 'button'}}
						>
							BATAL
						</Form.SecondaryButton>
					</span>	
				}

				<Form.PrimaryButton 
					buttonProps = {{disabled:!props.canSubmit}}
					className   = 'RealAccountComponentStyles-submit_button'
					main        = {1}
				>
					SIMPAN
				</Form.PrimaryButton>
			</React.Fragment>;
}

function StepPageLayoutCUD(props) {
	return  <RealAccountComponentsTheme.StepPageLayout 
				{...props} 
				AcceptStatement = {props.AcceptStatement}
				Timer           = {<Timer timerLabel = {props.timerLabel}/>}
				Buttons         = {<ButtonsCUD step={props.step} canSubmit={props.canSubmit} batal={props.batal}/>}
			>{props.children}</RealAccountComponentsTheme.StepPageLayout>;
}

export default {StepPageLayout, StepPageLayoutCUD, AcceptStatement, Timer };