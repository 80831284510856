function contents() {

	return {
		SUC_00001       :  'Terima kasih anda sudah melakukan pendaftaran Demo Account SPA, permintaan '
							+'pendaftaran anda akan kami proses, username dan password akan kami kirim via email.',
		SUC_00001_header: 'Pendaftaran Berhasil',
		SUC_00002		: 'Demo Account sudah terkirim sebelumnya',
		SUC_00003       :  'Terima kasih anda sudah melakukan pendaftaran Demo Account PBK, permintaan '
							+'pendaftaran anda akan kami proses, username dan password akan kami kirim via email.',
		SUC_00004		: 'Terima Kasih, Telegram Anda Sudah terdaftar.',
		first_login: 'Pengisian data formulir akun demo akan digunakan untuk proses pendaftaran nasabah'

	};

}

export default contents;