import Helpers from 'app/utils/helpers/Helpers';

function id_number_code(validate_obj) {
	let id_type = validate_obj.id_type().toLowerCase();
	return id_type === 'paspor' || id_type === 'kitas' ? 'ERR_00004' : 'ERR_00005';
}

export default {

	/**
	 * This will validate if value is not empty
	 * @param  string value        
	 * @param  object validate_obj [description]
	 * @return object
	 */
	required: function(value, validate_obj) {
		if (Helpers.is_empty(value, true)) {

			switch (validate_obj.type) {
                case 'multiple'          : return { result: false, err_code: 'ERR_00002' }; 
                case 'id_number'         : return { result: false, err_code: id_number_code(validate_obj) }; 
                case 'email'             : return { result: false, err_code: 'ERR_00006' }; 
                case 'captcha'           : return { result: false, err_code: 'ERR_00007' }; 
                case 'credential'        : return { result: false, err_code: 'ERR_00008' }; 
                case 'place_of_birth'    : return { result: false, err_code: 'ERR_00011' }; 
                case 'date_of_birth'     : return { result: false, err_code: 'ERR_00012' }; 
                case 'home_address'      : return { result: false, err_code: 'ERR_00013' }; 
                case 'city'              : return { result: false, err_code: 'ERR_00014' }; 
                case 'postal_code'       : return { result: false, err_code: 'ERR_00015' }; 
               	case 'mother_maiden_name': return { result: false, err_code: 'ERR_00016' }; 
               	case 'npwp'              : return { result: false, err_code: 'ERR_00027' };   
               	case 'document'          : return { result: false, err_code: 'ERR_00028' };   
				default                  : return { result: false, err_code: 'ERR_00001' }; 
			}

		} else {

			return { result: true }; 

		}
	},

	/**
	 * This will validate if value is not empty
	 * @param  string value        
	 * @param  object validate_obj [description]
	 * @return object
	 */
	accept_statement: function(value, validate_obj) {
        return { result: parseInt(value)===1 }; 
	},

	/**
	 * This will validate if value is valid full name
	 * Must start with letter
	 * Limited to letter, number and .,'
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	full_name: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {
			return { result: /^[a-z]{1,}([a-z ,.'])*$/i.test(value), err_code:'ERR_00003' };
        }

        return { result: true }; 
	},

	/**
	 * This will validate if value is valid bank account name
	 * Must start with letter
	 * Limited to letter, number and .,'
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	bank_account_name: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {
			return { result: /^[a-z]{1,}([a-z ,.'\-/&])*$/i.test(value), err_code:'ERR_00003' };
        }

        return { result: true }; 
	},

	/**
	 * This will validate if value is valid id number based on selected id type
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	id_number: function(value, validate_obj) {
		let id_type  = validate_obj.id_type().toLowerCase();

		if (id_type === 'paspor' || id_type === 'kitas') {
        	return { result: Helpers.is_alpha_num(value), err_code:id_number_code(validate_obj) }; 
		} else {
        	return { result: Helpers.is_number_only(value), err_code:id_number_code(validate_obj) }; 
		}
	},

	/**
	 * This will prevent id_number ['sim', 'ktp'] when it reached the maximum length
	 * @param {string} value 
	 * @param {object} validate_obj 
	 * @return object
	 */
	id_number_max: function (value, validate_obj) {
		let id_type = validate_obj.id_type().toLowerCase();

		if (!Helpers.is_empty(value)) {
			if (id_type === 'sim' || id_type === 'ktp') {
				return { result: value.length <= 16 };
			}
		}

		return { result: true };
	},

	/**
	 * This will check if id_number reached its minimum digit requirement
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	id_number_min: function (value, validate_obj) {

		let id_type = validate_obj.id_type().toLowerCase();

		if(!Helpers.is_empty(value)) {
			if(id_type === 'sim') {
				return { result : value.length >= 12, err_code: 'ERR_00034' }
			} else if (id_type === 'ktp') {
				return { result : value.length >= 16, err_code: 'ERR_00033' }
			}
		}

		return { result: true }
	},

	/**
	 * This will validate if value is a valid email
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	email: function(value, validate_obj) {
        return { result: Helpers.is_email(value), err_code:'ERR_00006' }; 
	},

	/**
	 * This will validate if value is a valid integer
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	is_number: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {
        	return { result: Helpers.is_number_only(value) }; 
		}

        return { result: true }; 
	},

	/**
	 * This will check if number reached its minimum digit requirement
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
    is_number_minimum: (value, validate_obj) => {
		if (!Helpers.is_empty(value)) {
        	return { result: value.length >= 5, err_code: 'ERR_00032' }; 
		}
		
        return { result: true }; 
	},

	/**
	 * This will validate if value is not empty if the callback was met
	 * @param  string value        
	 * @param  object validate_obj 
	 * @return object
	 */
	requiredif: function(value, validate_obj) {

		if (validate_obj.checker() && Helpers.is_empty(value)) {
			switch (validate_obj.type) { 
               	case 'document' : return { result: false, err_code: 'ERR_00028' };   
               	case 'nomessage': return { result: false, err_code: '' };  
				default         : return { result: false, err_code: 'ERR_00001' }; 
			}
		}

        return { result: true }; 
	},

	/**
	 * This will validate if value is a valid integer or decimal
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	is_amount: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {
        	return { 
        		result: Helpers.is_number_only(value)  
        				|| Helpers.is_starting_decimal(value) 
        				|| Helpers.is_decimal(value) 
        	}; 
		}
		
        return { result: true }; 
	},

	/**
	 * This will maximum length of value
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	max_length: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {
			return { result: value.length <= validate_obj.max }
		}
        return { result: true }; 
	},

	/**
	 * This will min length of value
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	min_length: function(value, validate_obj) {
		if (!Helpers.is_empty(value)) {

			return {
						result  : value.length >= validate_obj.min,
						err_code: 'ERR_00026'
					};

		}
        return { result: true }; 
	},

	truthy: function(value, validate_obj) {

		if (!validate_obj.checker()) {
			switch(validate_obj.type) {
				case 'age': return { result :false, err_code: 'ERR_00029'};
				default   : return { result :false, err_code: 'ERR_00001'};
			}
		}

		return {result: true};

	},

	/**
	 * This validate if value have symbols.
	 * Must not have symbols (Including space)
	 * @param  string value        
	 * @param  object validate_obj
	 * @return object
	 */
	no_special_characters: function(value, validate_obj) {

		if(!Helpers.is_empty(value)) {
			return {
				result: Helpers.is_alpha_num(value)
			};
		}

		return {result: true}
	},

	/**
	 * Validate the bank value. 
	 * Must start with a letter
	 * Accepts letters and space only
	 * @param {string} value 
	 * @param {object} validate_obj 
	 */
	bank_name: function(value, validate_obj) {

		if(!Helpers.is_empty(value)) {
			return { 
				result: /^[a-z]{1,}([A-Za-z\s])*$/i.test(value)
			};
		}

		return {result: true};
	},
	
	/**
	 * Tax number validation
	 * WNI validation
	 *    - Accept numbers only
	 * WNA validations  
	 *    - Accept letters, characters, and numbers
	 *    - Initial character cannot be space or symbol
	 *    - Must be initiated by letters or numbers
	 */
	tax_number: function(value, validate_obj) {		
		
		if(!Helpers.is_empty(value)) {

			if(validate_obj.nationality() === "WNA") {
				return { result: /^[a-z0-9]{1,}([^ ])*$/i.test(value) };
			}
			return { result: Helpers.is_number_only(value) };

		}
		
		return {result: true};
	},

	/**
	 * This will check if tax number reached the maximum length
	 * nationality:
	 *   - WNI max length is 16
	 *   - WNA max length is unlimited
	 * @param {string} value 
	 * @param {object} validate_obj 
	 */
	tax_number_max: function(value, validate_obj) {

		if(!Helpers.is_empty(value)) {

			if (validate_obj.nationality() === "WNI") {
				return { result: value.length <= 16 }
			}

		}

		return { result: true };
	},

	/**
	 * This will check if tax number reached its minimum length
	 * nationality:
	 *   - WNI min length is 15
	 *   - WNA min length is 5
	 * @param {string} value 
	 * @param {object} validate_obj 
	 */
	tax_number_min: function(value, validate_obj) {

		let nationality = validate_obj.nationality();
		
		if (!Helpers.is_empty(value)) {

			if (nationality === "WNA") {
				return { result: value.length >= 5, err_code: 'ERR_00031' }
			} else if (nationality === "WNI") {

				if(!validate_obj.stop_op()) {
					return { result: value.length >= 15, err_code: 'ERR_00026' }
				}
			}

		}

		return { result: true };
	},

	/**
	 * This will prevent user to type 0 as the first number in mobile number
	 * @param {string} value 
	 * @param {object} validate_obj 
	 */
	mobile_number: function(value, validate_obj) {

		if(!Helpers.is_empty(value)) {			
			return { result: /^[1-9]{1}[0-9]*$/.test(value)};
		}

		return { result: true };
	},

	/**
	 * This will prevent user to type space
	 * @param {string} value 
	 * @param {object} validate_obj 
	 */
	no_space_allowed: function(value, validate_obj) {

		if(!Helpers.is_empty(value)) {

			let has_space = /[\s]/.test(value);
			return { result: !has_space };
			
		}

		return { result: true };
	}
};