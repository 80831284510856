const ActionTypes = {update_countryCodes:'update_countryCodes'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object CountryCodes
	 * @return object
	 */
	update_countryCodes: function(countryCodes) {
		return { type: ActionTypes.update_countryCodes, countryCodes };
	}

};

export default callables;
export { ActionTypes };