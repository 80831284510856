
// import Helpers from 'app/utils/helpers/Helpers';

export default {

	full_name:  {
					triggers: ['onBlur'],
					prevent : true,
					validate: [{
								as              : 'required',
								type            : 'full_name',
								exclude_triggers: ['prevent']
							},{
								as              : 'full_name'
							}]
				},
	full_name_with_limit:  {
					triggers: ['onBlur'],
					prevent : true,
					validate: [{
								as              : 'required',
								type            : 'full_name',
								exclude_triggers: ['prevent']
							},{
								as              : 'full_name'
							},{
								as				: 'max_length',
								max 			: 50
							}]
				},
	accept_statement:{
    					triggers:['onBlur'],
    					validate:[{as: 'accept_statement'}]
    				},
	country_code : {
					triggers: ['onBlur'],
					validate: [{as: 'required'}]
				},

	id_type:    {
					triggers: ['onBlur'],
					validate: [{ as: 'required'}]
				},
				
	id_number:  (id_type_ref) => ({
					triggers: ['onBlur'],
					prevent : true,
					validate: [{
								as              : 'required',
								exclude_triggers: ['prevent']
							},{
								as              : 'id_number',
								id_type         : () => (id_type_ref.current.value())
							}, {
								as              : 'id_number_max',
								id_type         : () => (id_type_ref.current.value())								
							}, {
								as              : 'id_number_min',
								exclude_triggers: ['prevent'],
								id_type         : () => (id_type_ref.current.value())								
							}]
				}),

	place_of_birth: {
						triggers: ['onBlur'],
						validate: [{ as: 'required', type: 'place_of_birth' }]
					},

	date_of_birth : {
						triggers: ['onBlur'],
						validate: [{ as: 'required', type: 'date_of_birth' }]
					},

	home_address  : {
						triggers: ['onBlur'],
						validate: [{ as: 'required', type: 'home_address' }]
					},

	city            : {},
	postal_code     : {
						triggers: ['onBlur'],
						prevent : true,
						validate: [{
										as              : 'required',
										type            : 'postal_code',
										exclude_triggers: ['prevent']
									}, {
										as: 'no_special_characters'
									}]
					},
					
	email_address   : {
						triggers: ['onBlur'],
						prevent : true,
						validate: [
									{ as : 'required', type : 'email', exclude_triggers:['prevent'] },
									{ as : 'email', exclude_triggers: ['prevent'] }, 
									{ as : 'no_space_allowed' }
								]
    				},
    required_number: {
						triggers: ['onBlur'],
						prevent : true,
						validate: [
									{as: 'required',exclude_triggers:['prevent']}, 
									{as:'is_number'},
									{as:'is_number_minimum', exclude_triggers:['prevent']}
								]
    				},
	required_area_code: {
						triggers: ['onBlur'],
						prevent : true,
						validate: [{as: 'required',exclude_triggers:['prevent']}, {as:'is_number'}]
					},
    optional_number : (require_if) => ({
						triggers: ['onBlur'],
						prevent : true,
						validate: [
									{as:'is_number'},
									{as:'is_number_minimum', exclude_triggers:['prevent']},
									{as:'requiredif', checker: require_if, exclude_triggers:['prevent']}
								]
					}),
    optional_area_code : (require_if, other_validation= []) => ({
		triggers: ['onBlur'],
		prevent : true,
		validate: [
					{as:'is_number'},
					{as:'requiredif', checker: require_if, exclude_triggers:['prevent']},
					...other_validation
				]
	}),
    required_amount : {
						triggers: ['onBlur'],
						prevent : true,
						validate: [{as: 'required',exclude_triggers:['prevent']},{as:'is_amount'}]
    				},
    optional_amount : {
						triggers: ['onBlur'],
						prevent : true,
						validate: [{as:'is_amount'}]
    				},

	wysiwyg_checkbox: {
						triggers: ['onBlur'],
						validate: [{ as: 'required'  }]
					},

	mobile_number: {
						triggers: ['onBlur'],
						prevent : true,
						validate: [
									{as:'required',exclude_triggers:['prevent']}, 
									{as:'mobile_number'},
									{as:'is_number_minimum', exclude_triggers:['prevent']}
								]
    				},
					
};