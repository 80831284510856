import ReactGA from 'react-ga4';

const callables = {

    /**
     * Initialize Google Analytics
     * @param {string} tracking_id 
     * @param {object} options 
     */
    initialize(tracking_id, options) {
        ReactGA.initialize(tracking_id, options);
    },

    /**
     * Track the page
     */
    page_view(trackers, title) {
        // ReactGA.send({hitType:"pageview",page:window.location.pathname, title:`${title} - Regol PT ${process.env.REACT_APP_NAMEPIALANG}`})
        ReactGA.send({hitType:"pageview",page:window.location.pathname, title:title})
        // ReactGA.set({ page: window.location.pathname})
        // ReactGA.pageview(window.location.pathname, trackers, title);
    },

    /**
     * Just like the pageview, but it will track the modal
     * @param {string} modal_name 
     */
    modal_view(modal_name) {
        ReactGA.modalView(modal_name)
    },

    /**
     * Track the events, like clicking of buttons.
     * { catergory, action, label }
     * @param {object} options 
     */
    event(options) {
        ReactGA.event(options);
    }

}

export default {...ReactGA, ...callables};