/**
 * ---------------------------------------------------------------------------------------------------------------------
 * NOTES
 * ---------------------------------------------------------------------------------------------------------------------
 * - Use classes instead of functions only, so refs could be assign for every components.
 * 
 */

import React, { Component } from 'react';
import classnames           from 'classnames';
import FontIconStyles       from './scss/FontIconStyles.module.scss';


class Material extends Component {

	render() {
		return  <i 
					{ ...this.props }
					className={
								classnames([
									this.props.className,
									FontIconStyles.material,
									FontIconStyles['material_'+this.props.icon]
								])
							}
				></i>;
	}

}

export default { Material };