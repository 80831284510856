import React        from 'react';
import classnames   from 'classnames';
import Step8Styles  from './scss/Step8Styles.module.scss';
import Language     from 'app/utils/language/Language';
import {RouteLinks} from 'app/components/routes/Routes';
import Helpers      from 'app/utils/helpers/Helpers'; 

export default function(props) {
	return <div className={classnames([Step8Styles.wrapper, props.className])}>
				<div className={Step8Styles.background_filler}></div>
				<div className={classnames([Step8Styles.v_center_container,'GlobalStyles-slant_container_secondary'])}>
					<div className = {classnames([
										Step8Styles.body_background,
										'GlobalStyles-slant_container_background'
									])}
					>
					</div>
					<div className={classnames([Step8Styles.content_boundary,'AppStyles-container'])}>
						<div className={Step8Styles.body_content_wrapper}>
							<h5 className={classnames([Step8Styles.deposit_to_account,'AppStyles-no_margin'])}>
								{Language.get('realaccount.deposit_to_account','id')} 
								{' '}
								{ 
									!Helpers.is_empty(props.Company) && 
									<span className={Step8Styles.company_name}>
										{
											props.Company.content.company_name
										}
									</span>
								}
								<div className={Step8Styles.deposit_to_en}>
									(
										{Language.get('realaccount.deposit_to_account','en')} 
										{ 
											!Helpers.is_empty(props.Company) && 
											' of ' + props.Company.content.company_name
										}
									):
								</div>
							</h5>
							<div className={Step8Styles.deposit_table}>
								{
									props.bankGrouped.map((bank, bank_index) => (
										<div className={Step8Styles.bank_name} key={bank_index+'bank'}>
											<div className={Step8Styles.bank_name_content}>
												{Language.get('realaccount.bank', 'id')}
												{' ' + bank.bank_name}
												{' ' + Language.get('form_labels.branch', 'id')}
												{' ' + bank.bank_branch}
											</div>
											{  
												bank.bank_accounts.map((account,account_index) => (
													<div 
														className = {Step8Styles.row} 
														key       ={bank_index+account_index+'account'}
													>
														<div className={Step8Styles.row_title}>
															{account.currency}
														</div>
														<div className={Step8Styles.row_content}>
															{account.account_number}
														</div>
													</div>
												))
											}
										</div>
									))
								}
							</div>
							<h5 className={classnames([Step8Styles.send_deposit_proof,'AppStyles-no_margin'])}>
								{Language.get('realaccount.send_deposit_proof')}
							</h5>
							<div className={Step8Styles.deposit_proof_contact}>
								<div 
									className = {Step8Styles.row} 
								>
									<div className={Step8Styles.row_title}>
										<div className={Step8Styles.label_primary}>
										 	{Language.get_strict('form_labels.email_address','id')}
										</div>
										<div className={Step8Styles.label_secondary}>
										 	{Language.get_strict('form_labels.email_address','en')}
										</div>
									</div>
									<div className={Step8Styles.row_content}>
										{ 
											!Helpers.is_empty(props.Company) && 
											props.Company.content.registration_email_address 
										}
									</div>
								</div>
								<div 
									className = {Step8Styles.row} 
								>
									<div className={Step8Styles.row_title}>
										<div className={Step8Styles.label_primary}>
										 	{Language.get_strict('realaccount.fax_number_short','id')}
										</div>
										<div className={Step8Styles.label_secondary}>
										 	{Language.get_strict('realaccount.fax_number_short','en')}
										</div>
									</div>
									<div className={Step8Styles.row_content}>
										{ !Helpers.is_empty(props.branchInfo) && props.branchInfo.fax_number }
									</div>
								</div>
							</div>
							<div className={Step8Styles.home_button}>
								<RouteLinks type='home'/>
							</div>
						</div>
					</div>
				</div>
			</div>;
}
