import classNames from 'classnames';
import React,{Component} from 'react';
import AccordionStyles    from './scss/AccordionStyles.module.scss';

class Accordion extends Component {

    constructor(props) {
        super(props);
        this.title = props.title
        this.state = {
            active:false
        }
    }

   

    render() {
        return (
            <div className={AccordionStyles.accordion}>
            <div className={AccordionStyles.accordionItem}>
              <div
                className={AccordionStyles.accordionHeader}
                onClick={() => this.setState({active: !this.state.active})}
              >
                <div style={{width:'100%'}}>{this.title}</div>
                <div className={AccordionStyles.accordionButton}>{this.state.active ? '-' : '+'}</div>
              </div>
              <div className={classNames([AccordionStyles.accordionContent, !this.state.active ? 'AppStyles-hidden' : ''])}>{this.props.children}</div>
            </div>
          </div>
        )
    }

}



export default Accordion