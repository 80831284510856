import React,{ Component }                  from 'react';
import { 
		toast as Toastify,
		ToastContainer as ToastifyContainer 
	}                                       from 'react-toastify';
import classnames                           from 'classnames';
import FontIcons                            from 'app/components/fonticons/FontIcons';
import ToastStyles                          from './scss/ToastStyles.module.scss';
import 'react-toastify/dist/ReactToastify.css';

class ToastTemplate extends Component {

	constructor(props) {
		super(props);
		this.state = {
						has_icon : props.hasOwnProperty('icon'),
						has_title: props.hasOwnProperty('title')
					};
  	}


  	render() {
    	return  <div className={classnames([
                                    ToastStyles.wrapper,
                                    this.props.className,
                                    this.state.has_icon && ToastStyles.has_icon
                                ])}
    			>
    				{
    					this.state.has_icon 
    					&& <div className={ToastStyles.icon}><FontIcons.Material icon={this.props.icon} /></div>
    				}

    				{
    					this.state.has_title 
    					&& <div className={ToastStyles.title}>{this.props.title}</div>
    				}
    				<div className={ToastStyles.content}>{this.props.content}</div>
    				<div className={ToastStyles.add_content}>{ this.props.children }</div>
    			</div>;
 	}
}


class ToastClose extends Component {
	
  	render() {
    	return  <FontIcons.Material 
    				icon     = 'close' 
    				className= { ToastStyles.close_button } 
    				onClick  = { this.props.closeToast }
    			/>;
 	}

}

class ToastContainer extends Component {

  	render() {
    	return  <ToastifyContainer {...this.props} closeButton={<ToastClose/>}>
    				{this.props.children}
                </ToastifyContainer>;
 	}
}

var callables = {

	/**
	 * A simple wrapper for react-toastify toast()
	 * @param  string   content  
	 * @param  object   toastify_settings 
	 * @param  object   custom_settings    { title, type, add_content }
	 * @return string
	 */
	open(content, toastify_settings, custom_settings) {

		toastify_settings    = toastify_settings || {};
		var has_closeOnCLick = toastify_settings.hasOwnProperty('closeOnClick');
		toastify_settings    = {...toastify_settings, ...{
								className   : classnames([ToastStyles.default, toastify_settings.className]),
								closeOnClick: has_closeOnCLick ? toastify_settings.closeOnClick : false
							}};

		custom_settings = custom_settings || {};
		var {add_content, ...filtered_settings} = custom_settings;

		return Toastify(
			<ToastTemplate 
				{...filtered_settings} 
				content={content}
			>{custom_settings.add_content}</ToastTemplate>,
			toastify_settings
		);

	},


	/**
	 * A simple wrapper for react-toastify toast(), with success design
	 * @param  string   content  
	 * @param  object   toastify_settings 
	 * @param  object   custom_settings    { title, type, add_content }
	 * @return string
	 */
	success(content, toastify_settings, custom_settings) {

		toastify_settings = toastify_settings || {};
		toastify_settings = {...toastify_settings, ...{
								className: ToastStyles.success
							}};

		custom_settings = custom_settings || {};
		custom_settings = {...custom_settings, ...{
							icon: custom_settings.icon || 'done'
						}};

		return callables.open(content, toastify_settings, custom_settings);

	},


	/**
	 * A simple wrapper for react-toastify toast(), with error design
	 * @param  string   content  
	 * @param  object   toastify_settings 
	 * @param  object   custom_settings    { title, type, add_content }
	 * @return string
	 */
	error(content, toastify_settings, custom_settings) {

		toastify_settings = toastify_settings || {};
		toastify_settings = {...toastify_settings, ...{
								className: ToastStyles.error
							}};

		custom_settings = custom_settings || {};
		custom_settings = {...custom_settings, ...{
							icon: custom_settings.icon || 'error'
						}};

		return callables.open(content, toastify_settings, custom_settings);

	},

	close(toastId) {
		Toastify.dismiss(toastId);
	}


};

export default callables;
export { ToastContainer };