import Helpers            from 'app/utils/helpers/Helpers';
import { ActionTypes }    from '../actions/UserActions';
import { createSelector } from 'reselect';

var initial_state = {
						auth   : false, 
						is_full: false,
						error  : {code:0},
						details: {} 
					};

/**
 * This will convert the values of user details if necessary.
 * Sample of values to be converted is Area Code from '0' to blank
 * @param  object details 
 * @return object
 */
function details_value_converter(details) {

	var converters = {
					    asset_value                     : Helpers.remove_trailing_zero,
					    savings_amount                  : Helpers.remove_trailing_zero,
					    other_asset_amount              : Helpers.remove_trailing_zero
					};
	var converters_length = Object.keys(converters).length;
	var details_length    = Object.keys(details).length;

	if (converters_length < details_length) {

		for (var converter_field_key in converters) {

			if (details.hasOwnProperty(converter_field_key)) {
				let converter_function       = converters[converter_field_key];
				details[converter_field_key] = converter_function(details[converter_field_key]);
			}

		}

	} else {

		for (var details_field_key in details) {

			if (converters.hasOwnProperty(details_field_key)) {
				let converter_function     = converters[details_field_key];
				details[details_field_key] = converter_function(details[details_field_key]);
			}

		}

	}

	return details;
}

var callables = {
	[ActionTypes.update_user](state, action) {
		return Object.assign({}, state, { details: details_value_converter(action.user) });
	},

	[ActionTypes.update_user_details](state, action) {
		var new_details = Object.assign({},state.details,action.details);
		return Object.assign({}, state, { details: details_value_converter(new_details) });
	},

	[ActionTypes.update_auth](state, action) {
		return Object.assign({}, state, { auth:action.auth });
	},

	[ActionTypes.update_user_error](state, action) {
		return Object.assign({}, state, { error:action.error });
	},

	[ActionTypes.update_user_full](state, action) {
		return Object.assign({}, state, { is_full:action.is_full });
	}
};


//selectors
const Selectors = {

	AccountTypeCode: createSelector(
		[state => state.UserReducers.details], (details) => {
			if (!Helpers.is_empty(details.account_type)) {
				return details.account_type === 'Bilateral' ? 'SPA' : 'PBK';
			} else {
				return '';
			}
		}
	)
}

export default function(state = initial_state, action) {

	if (callables.hasOwnProperty(action.type)) {
		return callables[action.type](state, action);
	}

	return state;
};


export {Selectors};