import React,{ Component }           from 'react';
import { withRouter }                from 'react-router-dom';
import Helpers                       from 'app/utils/helpers/Helpers';
import PrivateLayoutTheme            from 'theme/components/layout/privatelayout/PrivateLayout';
import PrivateLayoutStyles           from './scss/PrivateLayoutStyles.module.scss';
import Model                         from 'app/modules/model/Model';
import { callables as StoreMethods } from 'app/store/Store';
import Toast                         from 'app/components/toast/Toast';
import Language                      from 'app/utils/language/Language';
import Form                          from 'app/components/form/Form';
import LocalForage                   from 'app/utils/localforage/LocalForage';

class PrivateLayout extends Component {

	constructor(props) {
		super(props);
		this.delay_id = Helpers.uniqid();
		this.state    = {menu:false, logging_out: false};
        this.form     = React.createRef();
  	}


  	UNSAFE_componentWillMount = () => {
	    window.addEventListener('resize', this.onResize);
  	}

  	componentDidUpdate(prevProps) {
	    if (this.props.location !== prevProps.location) {
	      	this.hideMenu();
	    }
	}

  	render() {
  		return  <React.Fragment>
                    <PrivateLayoutTheme 
            			{...this.props}
            			menu      = {this.state.menu}
            			showMenu  = {this.showMenu}
            			hideMenu  = {this.hideMenu}
                        logout    = {this.logout}
            		/>
                    <Form.Form ref={this.form}></Form.Form>
                </React.Fragment>
  	}

  	body_scroll = () => {
    	if (this.state.menu===true && window.outerWidth<=parseFloat(PrivateLayoutStyles.mobile_width)) {
    		Helpers.body_scroll(this.delay_id, false, 'PrivateLayoutStyles-menu_open');
    	} else {
    		Helpers.body_scroll(this.delay_id, true);
    	}
  	}

  	onResize = () => {
  		Helpers.event_delay(function() {
	    	if (window.outerWidth>parseFloat(PrivateLayoutStyles.mobile_width)) {
	    		this.hideMenu();
	    	}
	    }.bind(this), 100, this.delay_id);
  	}

  	showMenu = () => {
  		this.setState({ menu:true }, this.body_scroll);
  	}


  	hideMenu = () => {
  		this.setState({ menu:false }, this.body_scroll);
  	}

    logout = () => {
        var component = this;
        this.setState({logging_out: true});
        
        Toast.open(Language.get('menu.please_wait','id'), {
            autoClose   : false,
            closeOnClick: false,
            closeButton : false,
            position    : 'top-center',
            toastId     : 'logout'
        }, {
            icon        : 'exit_to_app',
            title       : Language.get('menu.logging_out','id')
        });

        Model.logout({  
			success : () => {  
						LocalForage.storage_unsubscribe(() => { window.location = '/login'; }); 
					},
            fail    : (response) => { 
                        Toast.close('logout');
                        component.setState({logging_out: false});         
                        component.form.current.submit_fail(response);
                    },
            error   : (xhr, status) => { 
                        Toast.close('logout');
                        component.setState({logging_out: false}); 
                        component.form.current.submit_error(xhr, status);
                    }
        });
    }
}

export default withRouter(StoreMethods.connect_stores(PrivateLayout,['Company']));