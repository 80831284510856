import Helpers            from 'app/utils/helpers/Helpers';
import { ActionTypes }    from '../actions/BrokersActions';
import { createSelector } from 'reselect';

var initial_state = {};

var callables = {
	[ActionTypes.update_brokers](state, action) {
		return Object.assign({}, state, action.brokers);
	}
};

//selectors
const Selectors = {

	BranchGrouped: createSelector(
		[state => state.BrokersReducers], (Brokers) => {
			if (!Helpers.is_empty(Brokers)) {
				var branched_grouped = {};

				if (Helpers.is_array(Brokers.content)) {
					Brokers.content.forEach((broker) => {
						if (!branched_grouped.hasOwnProperty(broker.branch_name)) {
							branched_grouped[broker.branch_name] = [];
						}

						branched_grouped[broker.branch_name].push({ 
							value      : broker.broker_id,
							description: broker.broker_name
						});
					});	
				}
				
				for(let branch in branched_grouped) {
					// Sort branched_grouped by description
					Helpers.sort_array_object_by_key(branched_grouped[branch], 'description');
				}

				return { error: Brokers.error, content: branched_grouped };

			} else {

				return {};
				
			}
		}
	),

	Branches: createSelector(
		[state => state.BrokersReducers], (Brokers) => {
			if (!Helpers.is_empty(Brokers)) {

				var branch_name = [];
				var branches    = [];

				if (Helpers.is_array(Brokers.content)) {
					Brokers.content.forEach((broker) => {
						if (!Helpers.in_array(broker.branch_name, branch_name)) {
							
							branch_name.push(broker.branch_name);
							branches.push({ 
											value      : broker.branch_id,
											description: broker.branch_name,
											is_hq      : broker.is_hq,
											order      : broker.order,
											is_tax_number_fix: broker.is_tax_number_fix
										});

						}
					});	
				}
				
				Helpers.sort_branches(branches, {
													hq_flag     : 'is_hq',
													branch_order: 'order',
													branch_name : 'description',
												});				

				return { error: Brokers.error, content: branches };

			} else {

				return {};

			}
		}
	)
}

export default function(state = initial_state, action) {

	if (callables.hasOwnProperty(action.type)) {
		return callables[action.type](state, action);
	}

	return state;
};

export {Selectors};