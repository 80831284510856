const ActionTypes = { update_company:'update_company' };

var callables = {

	/**	
	 * Add company to store
	 * @param  object company
	 * @return object
	 */
	update_company: function(company) {
		return { type: ActionTypes.update_company, company };
	}
};

export default callables;
export { ActionTypes };