const ActionTypes = {update_AccountTypes:'update_AccountTypes'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object AccountTypes
	 * @return object
	 */
	update_AccountTypes: function(AccountTypes) {
		return { type: ActionTypes.update_AccountTypes, AccountTypes };
	}

};

export default callables;
export { ActionTypes };