import React	 		    from 'react';
import Form                 from 'app/components/form/Form';
import FormValidatorCommon  from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Language             from 'app/utils/language/Language';
import Helpers              from 'app/utils/helpers/Helpers'; 

function Inputs(inputs, props, component) {
	return 	<React.Fragment>
		  		<h3 className='RealAccountStyles-form_title AppStyles-no_margin'>
		  			{Language.get('realaccount.mailing_address_data','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.mailing_address_data','en')}
		  			</div>
		  		</h3>
		        <h3 className='RealAccountStyles-form_category_title AppStyles-no_margin'>
		  			{Language.get('realaccount.give_sequence','id')}
		  			<div className='RealAccountStyles-form_subtitle'>
		  				{Language.get('realaccount.give_sequence','en')}
		  			</div>
		  		</h3>
				<Form.Row>
					<div className = 'AppStyles-half_size_input'>
						<div className='RealAccountStyles-field_confirmation_checkbox'>
				  			<Form.Checkbox     
				  				label        = 'short_address' 
			      				onTouched    = {props.onTouched}
			      				ref          = {inputs.mailing_address_check.ref}
								options      = {[
													{
														value      :'1', 
														description:''
													}
											 	]} 
								defaultValue = {
													(!Helpers.is_empty(props.User.details.mailing_address) 
													|| !Helpers.is_empty(props.User.details.mailing_postal_code))
													&& ['1']
												}
			      				onChange     = {component.mailing_address_check_changed}
			      				required     = {Helpers.in_array('1', component.state.mailing_address_check_value)}
				  			/>
		  				</div>
						<div className='RealAccountStyles-field_confirmation'>
				  			<Form.TextArea     
				  				label        = 'blank' 
		      					className    = 'AppStyles-full_size_input'
				  				ref          = {inputs.mailing_address.ref}
				  				defaultValue = {props.User.details.mailing_address}
			      				onTouched    = {props.onTouched}
			      				onChange     = {component.mailing_address_changed}
				  			/>
				  		</div>
		  			</div>
		  			<Form.Textbox     
		  				label        = 'postal_code' 
		  				className    = 'AppStyles-half_size_input'
		  				ref          = {inputs.mailing_postal_code.ref}
		  				defaultValue = {props.User.details.mailing_postal_code}
	      				onTouched    = {props.onTouched}
			      		onChange     = {component.mailing_address_changed}
			      		required     = {Helpers.in_array('1', component.state.mailing_address_check_value)}
		  			/>
		  		</Form.Row>

		  		<Form.Row>

					<div className = 'AppStyles-half_size_input'>
						<div className='RealAccountStyles-field_confirmation_checkbox'>
				  			<Form.Checkbox     
				  				label        = 'short_fax_number' 
			      				ref          = {inputs.mailing_fax_check.ref}
			      				onTouched    = {props.onTouched}
								options      = {[
													{
														value      :'1', 
														description:''
													}
											 	]} 
								defaultValue = {
													(!Helpers.is_empty(props.User.details.mailing_fax_area_code) 
													|| !Helpers.is_empty(props.User.details.mailing_fax))
													&& ['1']
												}
			      				onChange     = {component.mailing_fax_check_changed}
			      				required     = {Helpers.in_array('1', component.state.mailing_fax_check_value)}
				  			/>
		  				</div>
						<div className='RealAccountStyles-field_confirmation'>
			      			<div className = 'RealAccountStyles-number_code'>
				      			<Form.Textbox     
				      				label        = 'blank' 
				      				className    = 'AppStyles-full_size_input'
				      				ref          = { inputs.mailing_fax_area_code.ref }
				      				defaultValue = { props.User.details.mailing_fax_area_code }
		      						onTouched    = {props.onTouched}
			      					onChange     = {component.mailing_fax_changed}
		      						placeholder  = {component.area_code_placeholder}
				      			/>
				      		</div>
			      			<div className = 'RealAccountStyles-number'>
				      			<Form.Textbox     
				      				label        = 'blank' 
				      				className    = 'AppStyles-full_size_input'
				      				ref          = { inputs.mailing_fax.ref }
				      				defaultValue = { props.User.details.mailing_fax }
		      						onTouched    = {props.onTouched}
			      					onChange     = {component.mailing_fax_changed}
		      						placeholder  = {component.contact_number_placeholder}
				      			/>
				      		</div>
				      	</div>
					</div>

					<div className = 'AppStyles-half_size_input'>
						<div className='RealAccountStyles-field_confirmation_checkbox'>
				  			<Form.Checkbox     
				  				label        = 'dashed_email' 
			      				onTouched    = {props.onTouched}
			      				ref          = {inputs.mailing_email_check.ref}
								options      = {[
													{
														value      :'1', 
														description:''
													}
											 	]} 
			      				required     = {1}
								defaultValue = {
													!Helpers.is_empty(props.User.details.email_address)
													&& ['1']
												}
			      				onChange     = {component.mailing_email_check_changed}
				  			/>
		  				</div>
						<div className='RealAccountStyles-field_confirmation'>
			      			<Form.Textbox     
			      				label        = 'blank' 
		      					className    = 'AppStyles-full_size_input'
			      				ref          = { inputs.email_address.ref } 
			      				defaultValue = {props.User.details.email_address}
			      				onTouched    = {props.onTouched}
			      				onChange     = {component.email_address_changed}
			      			/>
			      		</div>
			      		
	      			</div>

		  		</Form.Row>
		  	</React.Fragment>;

}

function RefValidations(props, component) {
	var mailing_address_check =  React.createRef();
	var mailing_fax_check     =  React.createRef();

	return { 
		mailing_address_check  :{ ref: mailing_address_check,validations:{}},
		mailing_address        :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									validate: [
												{ 
													as     : 'requiredif',
													checker: () => (
																Helpers.in_array(
																	'1',
																	mailing_address_check.current.value()
																)
															)
												}
											] 
								}},
		mailing_postal_code    :{ ref: React.createRef(),validations:{
									triggers: ['onBlur'],
									prevent : true,
									validate: [
												{ 
													as     : 'requiredif',
													checker: () => (
																Helpers.in_array(
																	'1',
																	mailing_address_check.current.value()
																)
															),
													exclude_triggers: ['prevent']
												}, {
													as : 'no_special_characters' 
												}
											] 
								}},
		mailing_fax_check      :{ ref: mailing_fax_check,validations:{}},
		mailing_fax_area_code  :{ ref: React.createRef(), validations:FormValidatorCommon.optional_area_code(() => (
										Helpers.in_array(
											'1',
											mailing_fax_check.current.value()
										)
									), [
										{
											as: 'max_length',
											max: 4
										},
									]
								)},
		mailing_fax            :{ ref: React.createRef(), validations:FormValidatorCommon.optional_number(() => (
										Helpers.in_array(
											'1',
											mailing_fax_check.current.value()
										)
									)
								)},
		mailing_email_check    :{ ref: React.createRef(),validations:{}},
		email_address          :{ ref: React.createRef(),validations:FormValidatorCommon.email_address}
	};
};

export default { Inputs, RefValidations }