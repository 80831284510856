import React, { useState, useEffect }  from 'react'
import ActivationPageStyles            from '../scss/ActivationPageStyles.module.scss';
import Loader                          from 'app/components/loader/Loader';
import Model                           from 'app/modules/model/Model';
import ErrorPages                      from 'app/pages/errorpages/ErrorPages';
import Language                        from 'app/utils/language/Language';
import MessageDisplay				   from 'app/components/messagedisplay/MessageDisplay';
import GoogleAnalytics 				   from 'app/utils/googleanalytics/GoogleAnalytics';
import Form 						   from 'app/components/form/Form';

function TelegramActivation(props) {
	const [loading, set_loading] = useState(true);
	const [error  , set_error  ] = useState(false);
	const [submit , set_submit ] = useState(0);
	const [details, set_details] = useState({});

	useEffect(() => {
		GoogleAnalytics.page_view([], 'Telegram Activation Page');
	}, []);

	useState(() => {
		Model.get_broker_telegram_status(props.match.params.code, {
			success: () => { set_error(true); },
			fail   : (response) => {
                        set_details(response.error);
						set_error(true); 
					},
			error  : () => { set_error(true); },
		})
	},[]);
	
	return 	<React.Fragment>
				{
					error ?
						details.code === '1' ?
							<Form.Form
								className 	= {ActivationPageStyles.activate_button_wrapper}
								submitting 	= {submit}
								onSubmit	= {() => { 
												set_submit(1);
												Model.activate_telegram_account(props.match.params.code, {
													success: (response) => {
																set_details(response.error);
																set_error(false);
																set_loading(false);
																set_submit(0);
															},
													fail	: (response) => {
																set_details(response.error);
																set_error(true);
															},
													error 	: () => { set_error(true); }
												});
											}}
							>
								<Form.PrimaryButton main={1} >
									{Language.get('activation.click_to_activate_telegram', 'id')}
								</Form.PrimaryButton>
							</Form.Form>
						: 
							<ErrorPages.HasExpired/>								
					: 
						loading ? <Loader.Spinner/>
								: <MessageDisplay.OnPage type='success' code='SUC_00004' />
				}
			</React.Fragment>
	
}

export default TelegramActivation;