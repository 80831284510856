import Helpers from 'app/utils/helpers/Helpers';

/**
 * Add Event API
 * - A simple helper only for components
 * - This will add add_event() and trigger_event() method to component
 * - This is not the same with addEventListener, the trigger_event should still be manually triggered.
 * - This should be called upon creation of component class
 * @param  {[type]} component 
 * @return {[type]}           
 */
export default function(component, events) {
	
	// add event keys to component state
	if(!component.hasOwnProperty('state')) {
		component.state = {};
	}

	events.forEach(function(event) {
		var state_key = 'event_'+event;
		if (!component.state.hasOwnProperty(state_key)) {
			component.state[state_key] = [];
		}
	});


	function add_single_event(event, callback) {
		var state_key = 'event_'+event;
		if (component.state.hasOwnProperty(state_key) && Helpers.is_function(callback)) {
			component.setState(prevState => ({ 
				[state_key]:[...prevState[state_key], callback]
			}));
		}
    }

  	component.add_event    = (events, callback) => {
  								if (Helpers.is_array(events)) {
  									events.forEach(function(event) {
  										add_single_event(event, callback);
  									});
  								} else {
  									add_single_event(events, callback);
  								}
					  	    };

  	component.trigger_event = (event, event_data) => {
						  		var state_key = 'event_'+event;
						  		if (component.state.hasOwnProperty(state_key)) {
						  			component.state[state_key].forEach(function(callback) {
						  				if (Helpers.is_function(callback)) {
					  						event_data      = event_data || {};
					  						event_data.type = event;
					  						callback(event_data);
						  				}
						  			});

						  		}
						  	};
};