function contents() {

	return {
		'form_title_bilateral' : 'Permintaan Demo Account Sistem Perdagangan Alternatif (BILATERAL)',
		'form_title_multilateral' : 'Permintaan Demo Account Perdagangan Berjangka Komoditi (MULTILATERAL)',
		'login'                : 'Registrasi di sini',
		'login_button'         : 'Ke halaman login',
		'already_have_account' : 'Sudah punya akun demo?',
		'bilateral_description' : 'Sistem Perdagangan Alternatif (Bilateral)',
		'multilateral_description' : 'Perdagangan Berjangka Komoditi (Multilateral)'
	};

}

export default contents;