import React, { useState } 			  from 'react';
import classnames                     from 'classnames';
import MessageDisplayStyles           from './scss/MessageDisplayStyles.module.scss';
import Helpers                        from 'app/utils/helpers/Helpers';
import Language                       from 'app/utils/language/Language';
import FontIcons                      from 'app/components/fonticons/FontIcons';
import {RouteLinks}                   from 'app/components/routes/Routes';


function message_parts(code, type, options) {
	options = options || {};
    var id = Language.convert_get(code, type, 'id');
    var en = Language.convert_get(code, type, 'en');
	return {
		icon      : (<MessageIcon code={code} type={type} converted_code={id.converted_code}/>),
		title 	  : (<MessageHeader 
						code           = {code} 
						converted_code = {id.converted_code}
						type           = {type} 
						id             = {id} 
						en             = {en} 
					/>),
		body  	  : (<MessageBody 
						code           = {code} 
						type           = {type} 
						converted_code = {id.converted_code}
						id             = {id} 
						en             = {en} 
						formatter      = {options.formatter}
					/>),
		actions   : actions(code, type, id.converted_code),
		final_type: final_type(code, type, id.converted_code)
	};

}

function final_type(code, type, converted_code) {
	converted_code = converted_code || Language.converted_code(code, type);

	switch(converted_code) {
		case 'ERR_00020' :  return 'info';
		default          :  return type;
	}

}

function actions(code, type, converted_code) {
	converted_code = converted_code || Language.converted_code(code, type);

	switch(converted_code) {
		case 'SUC_00001' :
		case 'SUC_00003' :
		case 'ERR_00020' :  return [
								(
									<div 
										className={classnames([
													MessageDisplayStyles.link_button,
													MessageDisplayStyles.login_button
												])}
										key      ={1}
									>
										<RouteLinks type='registration_popup'/>
									</div>
								)
							];
		case 'ERR_00017' :  
		case 'ERR_00019' :  
		case 'ERR_00021' :  return [
								<a href='.' className={MessageDisplayStyles.link_button} key={1}>
									{Language.get('form_labels.reload')}
								</a>
							];

		default          :  return [];
	}
}

function MessageIcon(props) {
	var converted_code = props.converted_code || Language.converted_code(props.code, props.type);
	var get_final_type = final_type(props.code, props.type, converted_code);
	var icon = "";

	if(converted_code === 'first_login'){
		get_final_type = 'info'
	}

	switch(converted_code) {
		case 'first_login' : icon = 'info';break;
		case 'ERR_00023': icon = 'cloud_off'; break;
		default         : icon = {success:'done', error: 'clear', info: 'info'}[get_final_type]
	}
	return  <div 
				className={classnames([
							MessageDisplayStyles.icon, 
							MessageDisplayStyles['message_'+get_final_type]
						])}
			>
				<FontIcons.Material icon={icon}/>
			</div>;
}

function MessageHeader(props) {
	const [id_message] = useState(props.id || Language.convert_get(props.code, props.type, 'id'));
	// const [en_message, set_en] = useState(props.en || Language.convert_get(props.code, props.type, 'en'));
 
	return  <div 
				className={classnames([
							MessageDisplayStyles.message_header, 
							MessageDisplayStyles['message_'+final_type(props.code, props.type, props.converted_code)]
						])}
			>
				{id_message.header}
			</div>;
}

function MessageBody(props) {
	const [id_message] = useState(props.id || Language.convert_get(props.code, props.type, 'id'));
	const [en_message] = useState(props.en || Language.convert_get(props.code, props.type, 'en'));

	return <div 
				className={classnames([
							MessageDisplayStyles.message_body, 
							MessageDisplayStyles['message_'+final_type(props.code, props.type, props.converted_code)]
						])}
			>
		        <div className='AppStyles-notif_id'>
		            {
		                Helpers.is_function(props.formatter) ? 
		                    props.formatter(id_message) : id_message.message
		            }
		        </div>
		        {
		            !Helpers.is_empty(en_message.message) &&
		            <div className='AppStyles-notif_en'>
		                {
		                    Helpers.is_function(props.formatter) ? 
		                        props.formatter(en_message) : en_message.message
		                }
		            </div>
		        }
		    </div>;
}

function OnPage(props) {
	const get_message_parts = message_parts(props.code, props.type);
	return  <div className={classnames([MessageDisplayStyles.onpage, props.className])}>
				<div 
					className={classnames([
								MessageDisplayStyles.onpage_container, 
								MessageDisplayStyles['message_'+get_message_parts.final_type]
							])}
				>
					<div className={MessageDisplayStyles.icon}>
						{get_message_parts.icon}
					</div>
					<div className={MessageDisplayStyles.header}>
						{get_message_parts.header}
					</div>
					<div className={MessageDisplayStyles.body}>
						{get_message_parts.body}
					</div>
					<div className={MessageDisplayStyles.actions}>
						{get_message_parts.actions.map((value)=>(value))} 
					</div>
				</div>
			</div>;
}

export default { message_parts, OnPage }