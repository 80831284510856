import LocalForage from 'localforage';


function get_key(name) {
	return 'regol_'+name;
}

var indexed_db = {};

const callables = {

	createInstance(options) {
		options = options || {};

		return LocalForage.createInstance({ ...options, ...{
			name: get_key(options.name)
		}});
	},

    /**
     * This will listen to specific localstorage key changes then trigger the callback event
     * @param  string   instance_name    
     * @param  string   key    
     * @param  string   callback 
     * @return void
     */
   	watch(instance_name, key, callback) {
        window.addEventListener('storage', function (e) {
            if (e.key === get_key(instance_name) +'/'+ key ) {
                callback(e.newValue);
            }
        });
    },

    /**
     * Save the name and instance of the created instance of localForage to indexed_db
     * @param {string} name 
     * @param {object} instance 
     */
    storage_subscribe(name, instance) {

        if (!indexed_db.hasOwnProperty(name)) {
            indexed_db[name] = {
                db  : instance,
                name: get_key(name)
            }
        }

    },

    /**
     * Drop instance saved in indexed_db
     * @param {function} callback 
     */
    storage_unsubscribe(callback) {

        for (var storage in indexed_db) {
            if (indexed_db.hasOwnProperty(storage)) {
                indexed_db[storage].db.dropInstance({ name: indexed_db[storage].name });
                delete indexed_db[storage];
            }
        }

        callback();
    },

    /**
     * This will drop the list of db in localstorage
     * @param {function} callback 
     */
    drop_existing_storage(callback) {

        // list of possible existing db in localstorage
        const local_storage = {
                                step2: get_key('form_step2'),
                                step3: get_key('form_step3'),
                                step7: get_key('form_step7'),
                                local: 'localforage',
                            }

        // loop and dropinstance of that db
        for(let storage in local_storage) {
            LocalForage.dropInstance({name: local_storage[storage]});
        }
        
        callback();
    }

};

export default {...LocalForage, ...callables};