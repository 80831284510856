import { createStore }      from 'redux';
import { connect }          from 'react-redux';
import Helpers              from 'app/utils/helpers/Helpers';
import Reducers,{Selectors} from './reducers/Reducers';
import Actions              from './actions/Actions';

const Store = createStore(Reducers);

var callables = {

	/**
	 * This will check if key exists or not empty in store
	 * @param  string key
	 * @return boolean
	 */
	store_exists(key) {
		var state = Store.getState();
		return !Helpers.is_empty(Helpers.get_property(state, key+'Reducers'));
	},

	/**
	 * This will check if key exists or not empty in store
	 * @param  string keys
	 * @return boolean
	 */
	get_not_exists(keys) {
		var state        = Store.getState();
		var keys_length  = keys.length;
		var non_existing = []; 

		for(var i=0; i<keys_length; i++) {
			if (Helpers.is_empty(Helpers.get_property(state, keys[i]+'Reducers'))) {
				non_existing.push(keys[i]);
			}
		}

		return non_existing;
	},

	/**
	 * This will set user reducer state to component props
	 * @param  function component  The component function
	 * @return object
	 */
	user_connect(component) {
		return callables.connect_stores(component,['User']);
	},

	/**
	 * Connect multiple reducers at once
	 * @param  function component
	 * @param  array    items    
	 * @param  
	 * @return object
	 */
	connect_stores(component, items, getter) {
		return connect(state => { 
			var connections = {};
			var final_items = [];

			items.forEach((value, index)=> {
				var split = value.split('.');

				if (split.length > 1) {

					if (split.length>2) {
						console.warn('Store.connect_stores takes 2 segments only from items. Rest will be ignored!');
					}

					var selector = Selectors[split[0]+'Selectors'];
					if (Helpers.is_function(selector[split[1]])) {
						connections[split[1]] = selector[split[1]](state);
					} else {
						throw new Error(value+' is not a valid selector, it should be a createSelector result'); 
					}

					final_items.push(split[0]);

				} else {
					connections[value] = state[value+'Reducers'];
					final_items.push(value);
				}
			});

			if (Helpers.is_function(getter)) {
				getter(final_items);
			}

			return connections; 
		})(component);
	},

	step_enabled_change(number) {
		Store.dispatch(Actions.StepActions.update_enabledSteps(number));
	},

	step_change(number) {
		Store.dispatch(Actions.StepActions.update_activeStep(number));
	},

	step_latest(number) {
		Store.dispatch(Actions.StepActions.update_enabledSteps(number));
		Store.dispatch(Actions.StepActions.update_activeStep(number));
	},

	step_has_changes() {
		Store.dispatch(Actions.StepActions.update_hasChanges(true));
	},

	/**
	 * This will set brokers lists
	 * @param array brokers 
	 */
	update_brokers(brokers) {
		Store.dispatch(Actions.BrokersActions.update_brokers(brokers));
	},

	/**
	 * This will set countryCodes lists
	 * @param array countryCodes 
	 */
	update_countryCodes(countryCodes) {
		Store.dispatch(Actions.CountryCodesActions.update_countryCodes(countryCodes));
	},

	/**
	 * This will set accountTypes
	 * @param array accountTypes 
	 */
	update_accountTypes(accountTypes) {
		Store.dispatch(Actions.AccountTypesActions.update_AccountTypes(accountTypes));
	},

	/**
	 * Update user details
	 * @param  object user_details 
	 * @return void
	 */
	update_user(user_details) {
		Store.dispatch(Actions.UserActions.update_user(user_details));
	},

	/**
	 * This will clear user store details
	 * @return void
	 */
	clear_user() {
		Store.dispatch(Actions.UserActions.update_user({}));
		callables.update_user_full(false);
	},

	/**
	 * This will clear user store details
	 * @return void
	 */
	clear_step() {
		Store.dispatch(Actions.StepActions.clear_step());
	},

	/**
	 * This will update auth
	 * @param  boolean countryCodes 
	 * @return void
	 */
	update_auth(user_auth) {
		Store.dispatch(Actions.UserActions.update_auth(user_auth));
	},

	update_Step1(files) {
		Store.dispatch(Actions.Step1Actions.update_step1(files));
	},

	update_Step4(step4) {
		Store.dispatch(Actions.Step4Actions.update_step4(step4));
	},

	update_Step5(step5) {
		Store.dispatch(Actions.Step5Actions.update_step5(step5));
	},

	update_Step6(step6) {
		Store.dispatch(Actions.Step6Actions.update_step6(step6));
	},

	update_disclosure_statement(disclosure_statement) {
		Store.dispatch(Actions.DisclosureStatementActions.update_disclosure_statement(disclosure_statement));
	},

	update_company_ads(company_ads) {
		Store.dispatch(Actions.CompanyAdsActions.update_company_ads(company_ads));
	},

	set_step_progress(progress) {
		Store.dispatch(Actions.StepActions.set_step_progress(progress));
	},

	update_user_details(details) {
		Store.dispatch(Actions.UserActions.update_user_details(details));
	},

	update_user_error(error) {
		Store.dispatch(Actions.UserActions.update_user_error(error));
	},

	update_user_full(is_full) {
		Store.dispatch(Actions.UserActions.update_user_full(is_full));
	},

	update_company(company) {
		Store.dispatch(Actions.CompanyActions.update_company(company));
	},

	is_user_full() {
		var state = Store.getState();
		return state.UserReducers.is_full;
	},

	is_user_login() {
		var state = Store.getState();
		return state.UserReducers.auth;
	},

	get_user() {
		var state = Store.getState();
		return state.UserReducers;
	},

	get_conflictcourt() {
		var state = Store.getState();
		return state.ConflictCourtReducers;
	},

	update_conflictcourt(conflict_court) {
		Store.dispatch(Actions.ConflictCourtActions.update_conflictcourt(conflict_court));
	},

	update_progressName(progressName) {
		Store.dispatch(Actions.StepActions.update_progressName(progressName));
	},

	update_activeProgressName(progressName) {
		Store.dispatch(Actions.StepActions.update_activeProgressName(progressName));
	},

	get_activeProgressName() {
		var state = Store.getState();
		return state.StepReducers.content.activeProgressName;
	},

	add_previous_progress(progressName) {
		Store.dispatch(Actions.StepActions.add_previous_progress(progressName));
	},

	//added by Lukas 20220614
	get_enabledSteps(){
		var state = Store.getState();
		return state.StepReducers.content.enabledSteps;
	},
	get_FormChange(){
		var state = Store.getState();
		return state;
	}

};

export default Store;
export { callables };