const ActionTypes = {update_step1:'update_step1'};

var callables = {

	/**	
	 * Add user to store
	 * @param  object files
	 * @return object
	 */
	update_step1: function(step1) {
		return { type: ActionTypes.update_step1, step1 };
	}

};

export default callables;
export { ActionTypes };