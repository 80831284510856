import React                           from 'react';
import PrivateLayout                   from './privatelayout/PrivateLayout';
import PublicLayout                    from './publiclayout/PublicLayout';
import Routes                          from 'app/components/routes/Routes';
import LayoutTheme                     from 'theme/components/layout/Layout';
import { callables as StoreMethods }   from 'app/store/Store';

function Layout(props) {
    return  <LayoutTheme {...props}><Routes container={props.User.auth? PrivateLayout : PublicLayout}/></LayoutTheme>;
}

export default StoreMethods.user_connect(Layout);
