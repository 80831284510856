function contents() {

	return {
		'full_name'                 : 'Complete Name (According to Identity Card)',
		'id_types'                  : 'Resident Identity Card / Driving License / Passport / Limited Staying Permit in Indonesia',
		'id_types_1'                : 'ID Card / Driving License / Passport / Limited Staying Permit in Indonesia',
		'id_number'                 : 'Identity Number (Resident Identity Card / Driving License / Passport / Limited Staying Permit in Indonesia)',
		'email_address'             : 'Email Address',
		'country_code'              : 'Country Code',
		'phone_number'              : 'Home Telephone Number',
		'branch'                    : 'Branch',
		'broker'                    : 'Broker',
		// 'account_type'              : '',
		'register'                  : 'Daftar',
		'login'                     : 'Masuk',
		'username'                  : '',
		'password'                  : '',
		'accept_statement'          : '',
		'submit'                    : 'Lanjut',
		'back'                      : 'Kembali',
		'accept_truth_responsibilty': '',
		'mobile_number'             : 'Mobilephone Number',
		'place_of_birth'            : 'Place Of Birth',
		'date_of_birth'             : 'Date Of Birth',
		'address'                   : 'Home Address (According to Identity Card)',
		'city'                      : 'City',
		'postal_code'               : 'Post Code',
		'nationality'               : 'Nationality',
		'tax_number'                : 'Taxpayer Registration Number',
		'gender'                    : 'Sex',
		'mother_maiden_name'        : "Mother's Maiden Name",
		'marital_status'            : 'Marital Status',
		'home_ownership_status'     : 'Ownership Status',
		'fax_number'                : 'Home Facsimile Number',
		'area_code'                 : 'Area Code',
		'account_opening_purpose'   : 'Account Opening Purpose',
		'investment_experience'     : 'Investment Experience',
		'family_work_at'			: 'Do you have a family who working in CoFTRA / Futures Exchange / Derivatives Clearing House?',
		'bankrupt'                  : 'Have you been declared bankrupt by the court?',
		'name'                      : 'Name',
		'relationship'              : 'Relationship with you',
		'job_type'                  : 'Occupation',
		'company_name'              : 'Company Name',
		'company_line'              : 'Type of Business',
		'position'                  : 'Position',
		'current_tenure'            : 'Working Experience',
		'previous_tenure'           : 'Previous Office',
		'office_address'            : 'Office Address',
		'office_phone'              : 'Office Telephone Number',
		'office_fax'                : 'Facsimile Number',
		'other_job_type'            : ' ',
		'annual_income'             : 'Income per Year',
		'asset_address'             : 'House, Location',
		'asset_value'               : 'Selling Value of Tax Object',
		'bank_deposit'              : 'Bank Deposit',
		'amount'                    : 'Amount',
		'other'                     : 'Other',
		'bank_name'                 : 'Bank Name',
		'account_number'            : 'Account Number',
		'account_type'              : 'Account Type',
		'currency'                  : 'Currency',
		'recent_photo'              : 'Recent Photo',
		'select_file'               : 'Drop files or click to upload',
		'other_document'            : 'Other Attached Documents',
		'captcha'                   : ' ',
		'close'                     : 'Tutup',
		'reload'                    : 'Muat Ulang',
		'spouse'                    : 'Name of Wife / Husband',
		'blank'                     : ' ',
		'real_account_number'       : '',
		'short_fax_number'          : 'Facsimile Number',
		'short_address'             : 'Address',
		'short_phone_number'        : 'Telephone Number',
		'bank_phone_number'         : 'Telephone Number',
		'trading_experience'        : 'Experience of Futures Trading Transactions',
		'short_email_address'       : 'Email',
		'save'                      : 'Simpan',
		'dashed_email'              : 'E-mail',
		'ok' : 'Setuju'
	}; 

}

export default contents;