import React, { Component } from 'react';
import classnames from 'classnames';
import Language from 'app/utils/language/Language';
import Model from 'app/modules/model/Model';
import Form from 'app/components/form/Form';
import Accordion from 'app/components/accordion/Accordion';
import { RouteLinks } from 'app/components/routes/Routes';
import RegistrationFormStyles from './scss/RegistrationFormStyles.module.scss';
import { callables as StoreMethods } from 'app/store/Store';
import Helpers from 'app/utils/helpers/Helpers';
import FormValidatorCommon from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import MessageDisplay from 'app/components/messagedisplay/MessageDisplay';
import Moment from 'moment';
import Image from 'app/components/image/Image';
import FontIcons from 'app/components/fonticons/FontIcons';

class RegistrationForm extends Component {

	constructor(props) {
		super(props);

		const id_type_ref = React.createRef();
		const nationality = React.createRef();

		// create refs and validations
		this.inputs = {
			full_name: { ref: React.createRef(), validations: FormValidatorCommon.full_name_with_limit },
			id_type: { ref: id_type_ref, validations: FormValidatorCommon.id_type },
			id_number: {
				ref: React.createRef(),
				validations: FormValidatorCommon.id_number(id_type_ref)
			},
			email_address: { ref: React.createRef(), validations: FormValidatorCommon.email_address },
			personal_country_code: { ref: React.createRef(), validations: FormValidatorCommon.country_code },
			personal_mobile_number: { ref: React.createRef(), validations: FormValidatorCommon.mobile_number },
			branch_id: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required' }]
				}
			},
			broker_id: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required' }]
				}
			},
			account_type_id: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{ as: 'required' }]
				}
			},
			captcha: {
				ref: React.createRef(), validations: {
					triggers: ['onChange'],
					validate: [{ as: 'required', type: 'captcha' }]
				}
			},

			// add input (FIKRI - 21-07-23)
			place_of_birth: { ref: React.createRef(), },
			date_of_birth: {
				ref: React.createRef(), validations: {
					triggers: ['onChange'],
					validate: [
						{ as: 'truthy', checker: this.birth_marital_valid, type: 'age' }
					]
				}
			},
			gender: { ref: React.createRef() },
			nationality: { ref: nationality },
			tax_number: { ref: React.createRef(),validations:{
				triggers: ['onBlur'],
				prevent : true,
				validate: [
					{
						as         : 'tax_number',
						nationality: () => (nationality.current.value())
					},
					{
						as         : 'tax_number_max',
						stop       : () => !this.state.is_tax_number_fix,
						nationality: () => (nationality.current.value())
					},
					{
						as              : 'tax_number_min',
						stop_op         : () => !this.state.is_tax_number_fix,
						nationality     : () => (nationality.current.value()),
						exclude_triggers: ['prevent']
					}
				] 
			} },
			mother_maiden_name: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					prevent: true,
					validate: [
						{
							as: 'full_name'
						}
					]
				}
			},
			marital_status: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur', 'onChange'],
					validate: [
						{ as: 'truthy', checker: this.birth_marital_valid, type: 'age' }

					]
				}
			},
			spouse_name: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					prevent: true,
					validate: [{
						as: 'requiredif',
						checker: this.show_marital_status,
						exclude_triggers: ['prevent']
					},
					{
						as: 'full_name'
					}
					]
				}
			},
			home_address: { ref: React.createRef() },
			home_postal_code: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					prevent: true,
					validate: [
						{
							as              : 'requiredif',
							checker         : this.check_postal_code,
							exclude_triggers: ['prevent']
						}
						,{
						as: 'no_special_characters'
					}]
				}
			},
			home_ownership_status: { ref: React.createRef() },
			other_ownership_status: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [{
						as: 'requiredif',
						type: 'nomessage',
						checker: this.show_other_ownership
					}]
				}
			},
			emergency_detail_full_name: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					prevent: true,
					validate: [{
						as: 'full_name'
					}, {
						as: 'max_length',
						max: 50
					}]
				},
			},
			emergency_detail_address: { ref: React.createRef(), },
			emergency_detail_postal_code: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					prevent: true,
					validate: [{ as: 'no_special_characters' }]
				}
			},
		
			emergency_detail_phone_area_code: {
				ref: React.createRef(), validations: 
				FormValidatorCommon.optional_area_code(
					this.contact_grouped_required('emergency_detail_phone'), 	[
						{
							as: 'max_length',
							max: 4
						},
					]
				)
			},
			emergency_detail_phone: {
				ref: React.createRef(), validations: FormValidatorCommon.optional_number(
					this.contact_grouped_required('emergency_detail_phone')
				)
			},
			emergency_detail_relationship: { ref: React.createRef() },
			job_type: {
				ref: React.createRef(),
			
			},
			other_job_type: {
				ref: React.createRef(), validations: {
					triggers: ['onBlur'],
					validate: [
						{
							as: 'requiredif',
							type: 'nomessage',
							checker: () => (
								this.job_type_currentValue() === this.other_job_value
							)
						}
					]
				}
			},

			company_name: {
				ref: React.createRef()
			},
			company_line: {
				ref: React.createRef()
			},
			position: {
				ref: React.createRef(),
			},
			current_tenure: { ref: React.createRef(), },
			// previous_tenure: { ref: React.createRef(), },
			office_address: {
				ref: React.createRef(),
			},
			office_postal_code: {
				ref: React.createRef(),validations:{
					triggers: ['onBlur'],
					prevent : true,
					validate: [{as: 'no_special_characters'}]
				}
			},
			main_account_bank_account_name: { ref: React.createRef(),validations: {
				triggers: ['onBlur'],
				prevent : true,
				validate: [{
					as              : 'bank_account_name'
				},{
					as				: 'max_length',
					max 			: 50
				}]
			} },
			main_account_bank_name: { ref: React.createRef(),validations:{
				triggers: ['onBlur'],
				prevent : true,
				validate: [
							{
								as      : 'bank_name',
								category: 'withdraw'
							}
						]
			} },
			main_account_branch_name: { ref: React.createRef()},
			main_account_number: { ref: React.createRef(),validations:{
				triggers: ['onBlur'],
				prevent : true,
				validate: [
							{as: 'is_number'}
						]
			}  },
			main_account_type: { ref: React.createRef() },
			main_other_account_type: { ref: React.createRef(),validations:{
				triggers: ['onBlur'],
				validate: [
							{
								as     : 'requiredif',
								type   : 'nomessage',
								checker: () => {
											let val = this.account_type_currentValue('main');
											return val === this.other_account_value;
										}
							}
						]
			} },
			main_account_currency: { ref: React.createRef() },
			recent_photo: {
				ref: React.createRef(), store_exempt: true
			},
			id_photo: {
				ref: React.createRef(), store_exempt: true
			},
			other_document_1: {
				ref: React.createRef(), store_exempt: true
			},
			other_document_2: { ref: React.createRef(), validations: {}, store_exempt: true },
			other_document_3: { ref: React.createRef(), validations: {}, store_exempt: true }
		};
		this.form = React.createRef();
		this.state = { loading: 1, submitting: 0, error: false, code: null, selected_account_type: null, selected_job_type: null, show_detail: false, is_tax_number_fix: false };
		this.accountTypeDescription = {
			Bilateral: Language.get('registration.bilateral_description', 'id'),
			Multilateral: Language.get('registration.multilateral_description', 'id')
		}
		this.other_job_value = 'Lainnya, sebutkan';
		this.job_type_options = [
			'Swasta',
			'Wiraswasta',
			'Ibu RT',
			'Profesional',
			'Peg. Negeri',
			'Peg. BUMN',
			'Mahasiswa',
			this.other_job_value
		];

		this.area_code_placeholder = '021';
		this.contact_number_placeholder = '29*****';

		this.other_account_value        = 'Lainnya';



	}

	account_type_currentValue = (type) => {
		let account_type = 'selected_'+type+'_account_type';
		
		if (!Helpers.is_empty(this.state[account_type])) {
			return this.state[account_type];
		}

	}

	componentDidMount = () => {
		this.check_loading();
	}

	componentDidUpdate = () => {
		this.check_loading();
	}

	job_type_currentValue = () => {
		if (!Helpers.is_empty(this.state.selected_job_type)) {
			return this.state.selected_job_type;
		}
	}



	check_age = (event_data) => {
		event_data = event_data || {};

		// early checking
		if (this.state.loading === 0 && event_data.setter !== 'form') {
			var is_valid = this.birth_marital_valid();
			if (!is_valid) {
				this.form.current.display_message('ERR_00029', 'error');
			} else {
				this.inputs.date_of_birth.ref.current.error_clear();
				this.inputs.marital_status.ref.current.error_clear();
			}
		}

	}

	birth_marital_valid = () => {
		var date_of_birth = this.inputs.date_of_birth.ref.current.value();
		var marital_status = this.inputs.marital_status.ref.current.value();

		if (!Helpers.is_empty(date_of_birth) && !Helpers.is_empty(marital_status)) {
			var age = Moment().diff(Moment(date_of_birth), 'years');

			if (age < 17) {
				return false;
			} else if (age < 21 && marital_status === 'Tidak Kawin') {
				return false;
			} else {
				return true;
			}

		} else {

			return true;

		}
	}

	contact_grouped_required = (group_name) => {
		return () => {
			return !Helpers.is_empty(this.inputs[group_name].ref.current.value())
				|| !Helpers.is_empty(this.inputs[group_name+'_area_code'].ref.current.value());
		}
	}

	nationality_changed = () => {
		let tax_number = this.inputs.tax_number.ref.current;
		tax_number.set_value('');
	}

	marital_status_changed = (event_data) => {
		event_data = event_data || {};
		var selected_marital = this.inputs.marital_status.ref.current.value();

		this.setState({ selected_marital }, () => {
			if (this.show_marital_status()) {

				this.inputs.spouse_name.ref.current.enable(() => {

					if (this.state.loading === 0 && event_data.setter !== 'form') {
						this.inputs.spouse_name.ref.current.set_focus();
					}

				});

			} else {

				this.inputs.spouse_name.ref.current.set_value('');
				this.inputs.spouse_name.ref.current.disable();
			}
		});

		this.check_age(event_data);
	}

	show_marital_status = () => {
		return this.state.selected_marital === 'Kawin';
	}

	check_postal_code = () => {
		if(this.inputs.home_address.ref.current !== null){
			this.setState({home_address_value: this.inputs.home_address.ref.current.value() })
			return	this.inputs.home_address.ref.current.value() !== '';
		}
		return false
	}

	show_other_ownership = () => {
		return this.state.selected_ownership_status === 'Lainnya';
	}

	ownership_status_changed = (event_data) => {
		event_data = event_data || {};
		var selected_ownership_status = this.inputs.home_ownership_status.ref.current.value();

		this.setState({ selected_ownership_status }, () => {
			if (this.show_other_ownership()) {

				this.inputs.other_ownership_status.ref.current.enable(() => {
					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs.other_ownership_status.ref.current.set_focus();
					}
				});

			} else {

				this.inputs.other_ownership_status.ref.current.set_value('');
				this.inputs.other_ownership_status.ref.current.disable();
			}
		});
	}

	show_other_job = () => {
		return this.state.selected_job_type === this.other_job_value;
	}

	job_type_changed = (event_data) => {
		event_data = event_data || {};
		var selected_job_type = this.inputs.job_type.ref.current.value();

		this.setState({ selected_job_type }, () => {
			if (this.show_other_job()) {

				this.inputs.other_job_type.ref.current.enable(() => {

					if (this.state.loading === 0 && event_data.setter !== 'form') {
						this.inputs.other_job_type.ref.current.set_focus();
					}

				});

			} else {

				this.inputs.other_job_type.ref.current.set_value('');
				this.inputs.other_job_type.ref.current.disable();

			}
		});
	}

	account_type_changed = (event_data, type) => {
		event_data = event_data || {};
		var changes = {};
		changes['selected_' + type + '_account_type'] = this.inputs[type + '_account_type'].ref.current.value();

		this.setState(changes, () => {
			if (this.show_other_account_type(type)) {

				this.inputs[type + '_other_account_type'].ref.current.enable(() => {

					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs[type + '_other_account_type'].ref.current.set_focus();
					}

				});

			} else {

				this.inputs[type + '_other_account_type'].ref.current.set_value('');
				this.inputs[type + '_other_account_type'].ref.current.disable();
			}
		});
	}

	show_other_account_type = (type) => {
		return this.state['selected_' + type + '_account_type'] === this.other_account_value;
	}

	render() {
		return <React.Fragment>
			<h3 className={classnames([RegistrationFormStyles.form_title, 'AppStyles-no_margin'])}>
				{this.state.selected_account_type === 'Multilateral' ?
					Language.get('registration.form_title_multilateral', 'id')
					:
					Language.get('registration.form_title_bilateral', 'id')
				}
			</h3>
			<div className={RegistrationFormStyles.form_boundary}>
				<Form.Form 
				style={{position:'relative'}}
					inputs={this.inputs}
					onValidateSuccess={this.submit_registration}
					ref={this.form}
					className={classnames([
						RegistrationFormStyles.form,
						this.state.error && 'AppStyles-invisible'
					])}
					loading={this.state.loading}
					submitting={this.state.submitting}
				>
					<Form.Row>
						<Form.Textbox
							label='full_name'
							ref={this.inputs.full_name.ref}
							className={classnames([
								RegistrationFormStyles.full_name_textbox,
								'AppStyles-full_size_input'
							])}
							required={1}
						/>
					</Form.Row>
					<Form.Row>
						<Form.idTypeNumber
							inputs={this.inputs}
							id_type={{ required: 1 }}
							id_number={{ required: 1 }}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Textbox
							label='email_address'
							ref={this.inputs.email_address.ref}
							className={classnames([
								RegistrationFormStyles.email_address_textbox,
								'AppStyles-full_size_input'
							])}
							required={1}
						/>
					</Form.Row>
					<Form.Row>
						<Form.CountryCodeDropdown
						
						required={1}

							ref={this.inputs.personal_country_code.ref}
							className={classnames([
								RegistrationFormStyles.country_code_dropdown,
								'AppStyles-half_size_input'
							])}
						/>
						<Form.MobileNumberInput
													required={1}

							ref={this.inputs.personal_mobile_number.ref}
							className={classnames([
								RegistrationFormStyles.mobile_number_textbox,
								'AppStyles-half_size_input'
							])}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Dropdown
							label='branch'
							placeholder='Pilih Cabang'
							ref={this.inputs.branch_id.ref}
							options={[]}
							className={classnames([
								RegistrationFormStyles.branch_dropdown,
								'AppStyles-half_size_input'
							])}
							onChange={this.branch_changed}
							required={1}
						/>
						<Form.Dropdown
							label='broker'
							placeholder='Pilih Wakil Pialang'
							ref={this.inputs.broker_id.ref}
							options={[]}
							className={classnames([
								RegistrationFormStyles.broker_dropdown,
								'AppStyles-half_size_input'
							])}
							required={1}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Dropdown
							optionsFormatted={false}
							label='account_type'
							ref={this.inputs.account_type_id.ref}
							className={classnames([
								RegistrationFormStyles.account_type_dropdown,
								'AppStyles-half_size_input'
							])}
							required={1}
							formatter={item => ({
								value: item.account_type_id,
								description: this.accountTypeDescription[item.account_type_name]
							})}
							onChange={this.accountType_onChange}
						/>
						{ 
						// <div>
							<Form.Captcha
							className={classnames([
								'AppStyles-half_size_input',
								RegistrationFormStyles.captcha_component,
								this.state.show_detail ? RegistrationFormStyles.captcha_component_bottom : ''
							])}
							ref={this.inputs.captcha.ref}
							/>
						}

					</Form.Row>

					{!this.state.show_detail ? <Form.Row>
							<div className={classnames([
							RegistrationFormStyles.container_captcha
						])}></div>
						</Form.Row> : null }
					<Form.Row buttonContainer>
					
						<div className={classnames([RegistrationFormStyles.container_button_wrapper])}>
							<Form.CustomButton  buttonProps={{ type: 'button', onClick: (e) => {
								this.setState({ show_detail: !this.state.show_detail })
								e.target.blur()
							} }} main={0}>
								Lihat Detail <FontIcons.Material icon={this.state.show_detail ? 'expand_more' : 'chevron_right'} />
							</Form.CustomButton>
							{!this.state.show_detail ? <Form.PrimaryButton main={1}>
								{Language.get('form_labels.register')}
							</Form.PrimaryButton> : null}
						</div>
						<div className={classnames([RegistrationFormStyles.login_link, this.state.show_detail ? 'AppStyles-hidden' : ''])}>
							{Language.get('registration.already_have_account')} <RouteLinks type='registration' />
						</div>
					</Form.Row>



					{/* add  */}<div className={classnames([
						!this.state.show_detail ? 'AppStyles-hidden' : ''
					])}>
						<Accordion title={
							<h3 style={{ background: 'inherit', margin: 0, textAlign: 'center' }} className='RealAccountStyles-form_title AppStyles-no_margin-important'>
								{Language.get('realaccount.personal_data', 'id')}
								<div className='RealAccountStyles-form_subtitle'>
									{Language.get('realaccount.personal_data', 'en')}
								</div>
							</h3>
						}>
							<Form.Row>
								<Form.Textbox
									label='place_of_birth'
									className='AppStyles-half_size_input'
									ref={this.inputs.place_of_birth.ref}
								/>
								<Form.DatePicker
									label='date_of_birth'
									className='AppStyles-half_size_input'
									ref={this.inputs.date_of_birth.ref}
									onChange={this.check_age}
								/>
							</Form.Row>

							<Form.Row>
								<Form.Radio
									label='gender'
									ref={this.inputs.gender.ref}
									className='AppStyles-half_size_input'
									options={[
										{
											value: 'L',
											description: <React.Fragment>
												{Language.get('realaccount.male', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.male', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'P',
											description: <React.Fragment>
												{Language.get('realaccount.female', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.female', 'en')}
												</div>
											</React.Fragment>
										}
									]}
								/>
								<Form.Radio
									onChange={this.nationality_changed}
									label='nationality'
									ref={this.inputs.nationality.ref}
									className='AppStyles-half_size_input'
									options={[
										{
											value: 'WNI',
											description: <React.Fragment>
												{Language.get('realaccount.indonesian', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.indonesian', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'WNA',
											description: <React.Fragment>
												{Language.get('realaccount.foreigner', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.foreigner', 'en')}
												</div>
											</React.Fragment>
										}
									]}
								/>
							</Form.Row>

							<Form.Row>
								<Form.Textbox
									label='tax_number'
									ref={this.inputs.tax_number.ref}
									className='AppStyles-full_size_input'
								/>
							</Form.Row>
							<Form.Row>
								<Form.Textbox
									label='mother_maiden_name'
									ref={this.inputs.mother_maiden_name.ref}
									className='AppStyles-full_size_input'
									defaultValue={''}
									onTouched={this.props.onTouched}
								/>
							</Form.Row>
							<Form.Row>
								<Form.Radio
									label='marital_status'
									ref={this.inputs.marital_status.ref}
									className='AppStyles-half_size_input'
									options={[
										{
											value: 'Tidak Kawin',
											description: <React.Fragment>
												{Language.get('realaccount.single', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.single', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Kawin',
											description: <React.Fragment>
												{Language.get('realaccount.married', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.married', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Janda',
											description: <React.Fragment>
												{Language.get('realaccount.widowed', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.widowed', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Duda',
											description: <React.Fragment>
												{Language.get('realaccount.widower', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.widower', 'en')}
												</div>
											</React.Fragment>
										}
									]}
									onChange={this.marital_status_changed}

								/>
								<Form.Textbox
									label='spouse'
									ref={this.inputs.spouse_name.ref}
									className={classnames([
										'AppStyles-half_size_input',
									])}
									required={this.show_marital_status()}
									disabled={!this.show_marital_status()}
								/>
							</Form.Row>
							<Form.Row>
								<Form.TextArea
								onBlur={() => {
									this.inputs.home_postal_code.ref.current.set_focus()
								}}
									label='id_address'
									className='AppStyles-half_size_input'
									ref={this.inputs.home_address.ref}
								/>
								<Form.Textbox
									label='id_postal_code'
									className='AppStyles-half_size_input'
									ref={this.inputs.home_postal_code.ref}
									required={this.state.home_address_value && this.state.home_address_value !== '' ? true: false}
								/>
							</Form.Row>
							<Form.Row>
								<Form.Radio
									label='home_ownership_status'
									ref={this.inputs.home_ownership_status.ref}
									className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
									options={[
										{
											value: 'Pribadi',
											description: <React.Fragment>
												{Language.get('realaccount.personal', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.personal', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Keluarga',
											description: <React.Fragment>
												{Language.get('realaccount.family', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.family', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Sewa/Kontrak',
											description: <React.Fragment>
												{Language.get('realaccount.rent', 'id')}
												<div className='AppStyles-label_secondary'>
													{Language.get('realaccount.rent', 'en')}
												</div>
											</React.Fragment>
										},
										{
											value: 'Lainnya',
											description: <Form.Textbox
												className='RealAccountStyles-radio_input'
												ref={this.inputs.other_ownership_status.ref}
												beforeInput={
													<span
														className='RealAccountStyles-radio_text'
													>
														{Language.get(
															'realaccount.others',
															'id'
														)}
														<div className='AppStyles-label_secondary'>
															{Language.get(
																'realaccount.others',
																'en'
															)}
														</div>
													</span>
												}
												disabled={!this.show_other_ownership()}
											/>
										}
									]}

									onChange={this.ownership_status_changed}
								/>
							</Form.Row>
						</Accordion>

						<Accordion title={
							<h3 style={{ background: 'inherit', margin: 0, textAlign: 'center' }} className='RealAccountStyles-form_title AppStyles-no_margin-important'>
								{Language.get('realaccount.emergency_contact', 'id')}
								<div className='RealAccountStyles-form_subtitle'>
									{Language.get('realaccount.emergency_contact', 'en')}
								</div>
							</h3>
						}>
							<Form.Row>
								<Form.Textbox
									label='name'
									className='AppStyles-full_size_input'
									ref={this.inputs.emergency_detail_full_name.ref}

								/>
							</Form.Row>
							<Form.Row>
								<Form.TextArea
									label='short_address'
									className='AppStyles-half_size_input'
									ref={this.inputs.emergency_detail_address.ref}
								/>
								<Form.Textbox
									label='postal_code'
									className='AppStyles-half_size_input'
									ref={this.inputs.emergency_detail_postal_code.ref}
								/>
							</Form.Row>
							<Form.Row>
								<div className='AppStyles-half_size_input'>
									<div className='RealAccountStyles-number_code'>
										<Form.Textbox
											label='short_phone_number'
											className='AppStyles-full_size_input'
											ref={this.inputs.emergency_detail_phone_area_code.ref}
											placeholder={'021'}
										/>
									</div>
									<div className='RealAccountStyles-number'>
										<Form.Textbox
											label='blank'
											className='AppStyles-full_size_input'
											ref={this.inputs.emergency_detail_phone.ref}
											placeholder={'29*****'}
										/>
									</div>
								</div>
								<Form.Textbox
									label='relationship'
									ref={this.inputs.emergency_detail_relationship.ref}
									className='AppStyles-half_size_input'
								/>
							</Form.Row>
						</Accordion>

						<Accordion title={
							<h3 style={{ background: 'inherit', margin: 0, textAlign: 'center' }} className='RealAccountStyles-form_title AppStyles-no_margin-important'>
								{Language.get('realaccount.job_data', 'id')}
								<div className='RealAccountStyles-form_subtitle'>
									{Language.get('realaccount.job_data', 'en')}
								</div>
							</h3>
						}>

							<Form.Row>
								<Form.Radio
									label='job_type'
									className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
									ref={this.inputs.job_type.ref}
									options={this.job_type_options}
									onChange={this.job_type_changed}
									optionsFormatted={false}
									formatter={job_data_formatter(this.inputs, this.props, this)}

								/>
							</Form.Row>
							<Form.Row>
								<Form.Textbox
									label='company_name'
									className='AppStyles-full_size_input'
									ref={this.inputs.company_name.ref}
								/>
							</Form.Row>
							<Form.Row>
								<Form.Textbox
									label='company_line'
									className='AppStyles-half_size_input'
									ref={this.inputs.company_line.ref}
								/>
								<Form.Textbox
									label='position'
									className='AppStyles-half_size_input'
									ref={this.inputs.position.ref}
								/>
							</Form.Row>
							<Form.Row>
								<div className='AppStyles-half_size_input'>
									<Form.Dropdown
										optionsFormatted={false}
										label='current_tenure'
										className='AppStyles-full_size_input RealAccountStyles-input_labled_year'
										ref={this.inputs.current_tenure.ref}
										options={Helpers.range(0, 22)}
										formatter={(value) => {
											var final_value = value !== 0 ? value : '';
											var description = "";

											if (value !== 0) {
												description = value > 20 ? '>= 21' : value
											} else {
												description = '--';
											}

											return {
												value: final_value,
												description: description
											};
										}}
										postLabel={
											<span className='RealAccountStyles-post_label_year'>
												Tahun
											</span>
										}
									/>
								</div>
							</Form.Row>
							<Form.Row>
								<Form.TextArea
									label='office_address'
									className='AppStyles-half_size_input'
									ref={this.inputs.office_address.ref}

								/>
								<Form.Textbox
									label='postal_code'
									className='AppStyles-half_size_input'
									ref={this.inputs.office_postal_code.ref}
								/>
							</Form.Row>
						</Accordion>

						<Accordion title={
							<h3 style={{ background: 'inherit', margin: 0, textAlign: 'center' }} className='RealAccountStyles-form_title AppStyles-no_margin-important'>
								{Language.get('realaccount.bank_account_withdraw', 'id')}
								<div className='RealAccountStyles-form_subtitle'>
									{Language.get('realaccount.bank_account_withdraw', 'en')}
								</div>
							</h3>
						}>
							{bank_inputs('main', this, {
								account_type: {
									// required: 1,
									onChange: (event_data) => {
										this.account_type_changed(event_data, 'main');
									}
								},

							})}
						</Accordion>

						<Accordion title={
							<h3 style={{ background: 'inherit', margin: 0, textAlign: 'center' }} className='RealAccountStyles-form_title AppStyles-no_margin-important'>
								{Language.get('realaccount.attached_documents', 'id')}
								<div className='RealAccountStyles-form_subtitle'>
									{Language.get('realaccount.attached_documents', 'en')}
								</div>
							</h3>
						}>
							<Form.Row>
								<Form.UploadBox
									label='recent_photo'
									className='AppStyles-full_size_input'
									labelSubText={Language.get('realaccount.max_file_size', 'id')}
									ref={this.inputs.recent_photo.ref}
									accept='image/jpg,image/jpeg,image/png'
								/>
							</Form.Row>
							<Form.Row>
								<Form.UploadBox
									label='id_types_1'
									className='AppStyles-full_size_input'
									labelSubText={Language.get('realaccount.max_file_size', 'id')}
									ref={this.inputs.id_photo.ref}
									accept='image/jpg,image/jpeg,image/png'
								/>
							</Form.Row>
							<Form.Row>
								<Form.UploadBox
									label='other_document'
									className='AppStyles-full_size_input'
									labelSubText={
										<React.Fragment>
											<div>{Language.get('realaccount.other_document_types', 'id')}</div>
											<div>{Language.get('realaccount.other_document_types', 'en')}</div>
											<div>({Language.get('realaccount.max_file_size', 'id')})</div>
										</React.Fragment>
									}
									labelSubTextRaw={true}
									ref={this.inputs.other_document_1.ref}
									accept={
										'.pdf,.doc,.docx,application/pdf,application/msword,'
										+ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
										+ 'image/jpg,image/jpeg,image/png'
									}
								/>
							</Form.Row>
							<Form.Row>
								<Form.UploadBox
									className='AppStyles-full_size_input'
									ref={this.inputs.other_document_2.ref}
									accept={
										'.pdf,.doc,.docx,application/pdf,application/msword,'
										+ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
										+ 'image/jpg,image/jpeg,image/png'
									}
								/>
							</Form.Row>
							<Form.Row>
								<Form.UploadBox
									className='AppStyles-full_size_input'
									ref={this.inputs.other_document_3.ref}
									accept={
										'.pdf,.doc,.docx,application/pdf,application/msword,'
										+ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
										+ 'image/jpg,image/jpeg,image/png'
									}
								/>
							</Form.Row>
						</Accordion>
					</div>
					{/* add */}



					{this.state.show_detail ? <>
						<Form.Row>
							<div className={classnames([RegistrationFormStyles.container_captcha_view_detail])}></div>
						</Form.Row>
						<Form.Row buttonContainer>
							
							<div style={{ float: 'right' }} className={classnames([
								'AppStyles-half_size_input'
							])}>
								<Form.PrimaryButton main={1}>
									{Language.get('form_labels.register')}
								</Form.PrimaryButton>
							</div>
							<div className={RegistrationFormStyles.login_link}>
								{Language.get('registration.already_have_account')} <RouteLinks type='registration' />
							</div>
						</Form.Row>
					</> : null}

				</Form.Form>
				{this.state.error && <MessageDisplay.OnPage code={this.state.code} type='error' />}
			</div>
		</React.Fragment>;

	}

	reset_form = () => {
		this.form.current.reset();
	}

	branch_changed = () => {
		this.get_brokers(this.inputs.branch_id.ref.current.value());
	}

	get_brokers = (branch) => {
		branch = branch || this.props.Branches.content[0].value;
		var selected_branch = this.props.Branches.content.find((item) => (item.value === branch));
		if (!Helpers.is_empty(this.props.BranchGrouped.content)) {
			if (!Helpers.is_empty(selected_branch)) {
				this.setState({ is_tax_number_fix: selected_branch.is_tax_number_fix });
				this.inputs.broker_id.ref.current.set_options(
					this.props.BranchGrouped.content[selected_branch.description] || []
				);
			}
		}
	}

	check_loading = () => {

		if (this.state.loading === 1) {
			var has_BranchGrouped = !Helpers.is_empty(this.props.BranchGrouped);
			var has_Branches = !Helpers.is_empty(this.props.Branches);
			var has_countryCodes = !Helpers.is_empty(this.props.CountryCodes);
			var has_accountTypes = !Helpers.is_empty(this.props.AccountTypes);

			if (has_BranchGrouped && has_Branches && has_countryCodes && has_accountTypes) {
				this.setState({ loading: 0 }, this.onload);
			}
		}

	}

	has_error = (error) => {
		this.setState({ error: true, code: error.code });
	}

	onload = () => {
		if (this.props.CountryCodes.error.code === '0') {
			this.inputs.personal_country_code.ref.current.set_options(this.props.CountryCodes.content);
		} else {
			return this.has_error(this.props.CountryCodes.error);
		}

		if (this.props.Branches.error.code === '0') {
			this.inputs.branch_id.ref.current.set_options(this.props.Branches.content);
		} else {
			return this.has_error(this.props.Branches.error);
		}

		if (this.props.BranchGrouped.error.code === '0') {
			this.get_brokers();
		} else {
			return this.has_error(this.props.BranchGrouped.error);
		}

		if (this.props.AccountTypes.error.code.toString() === '0') {
			this.inputs.account_type_id.ref.current.set_options(this.props.AccountTypes.content);
		} else {
			return this.has_error(this.props.AccountTypes.error);
		}
	}

	final_job_type = (job_type, other_job_type) => {

		if (job_type === this.other_job_value) {
			return other_job_type;
		} else {
			return job_type;
		}
	}

	
	final_account_type = (account_type, other_account_type) => {

		if (account_type === this.other_account_value) {
			return other_account_type;
		} else {
			return account_type;
		}
	}

	final_ownership_status = (home_ownership_status, other_ownership_status) => {

		if (home_ownership_status === 'Lainnya') {
			return other_ownership_status;
		} else {
			return home_ownership_status;
		}

	}

	submit_registration = (inputs) => {
		this.setState({ submitting: 1 });
		// get the file inputs first
		var recent_photo = inputs.recent_photo;
		var id_photo = inputs.id_photo;
		var other_document_1 = inputs.other_document_1;
		var other_document_2 = inputs.other_document_2;
		var other_document_3 = inputs.other_document_3;

		delete inputs.recent_photo;
		delete inputs.id_photo;
		delete inputs.other_document_1;
		delete inputs.other_document_2;
		delete inputs.other_document_3;

		inputs.job_type                =    this.final_job_type(inputs.job_type, inputs.other_job_type);
		inputs.main_account_type 	   =    this.final_account_type(
											inputs.main_account_type, 
											inputs.main_other_account_type
										);
		inputs.home_ownership_status   =  this.final_ownership_status(
										inputs.home_ownership_status, 
										inputs.other_ownership_status
									);
		Model.register(inputs
			, {
				success: (response) => {
					Helpers.set_cookie(process.env.REACT_APP_CUSTOMERID_NAME, response.data.customer_id);
					this.upload_files([
						{
							file: recent_photo,
							uploader: { ajax: Model.upload_customer_photo },
							updater: { ajax: Model.rotate_customer_photo },
							rotation: this.inputs.recent_photo.ref.current ? this.inputs.recent_photo.ref.current.rotate() : null,
						},
						{
							file: id_photo,
							uploader: { ajax: Model.upload_customer_id },
							updater: { ajax: Model.rotate_customer_valid_id },
							rotation: this.inputs.id_photo.ref.current ? this.inputs.id_photo.ref.current.rotate() : null,
						},
						{
							file: other_document_1,
							uploader: {
								ajax: Model.upload_customer_other,
								ajax_setup: { order: 1 }
							},
							deleter: {
								ajax: Model.delete_customer_other,
								ajax_setup: { order: 1 }
							},
							updater: {
								ajax: Model.rotate_customer_other_document,
								ajax_setup: { order: 1 }
							},
							rotation: this.inputs.other_document_1.ref.current ? this.inputs.other_document_1.ref.current.rotate() : null,
						},
						{
							file: other_document_2,
							uploader: {
								ajax: Model.upload_customer_other,
								ajax_setup: { order: 2 }
							},
							deleter: {
								ajax: Model.delete_customer_other,
								ajax_setup: { order: 2 }
							},
							updater: {
								ajax: Model.rotate_customer_other_document,
								ajax_setup: { order: 2 }
							},
							rotation: this.inputs.other_document_2.ref.current ? this.inputs.other_document_2.ref.current.rotate() : null,
						},
						{
							file: other_document_3,
							uploader: {
								ajax: Model.upload_customer_other,
								ajax_setup: { order: 3 }
							},
							deleter: {
								ajax: Model.delete_customer_other,
								ajax_setup: { order: 3 }
							},
							updater: {
								ajax: Model.rotate_customer_other_document,
								ajax_setup: { order: 3 }
							},
							rotation: this.inputs.other_document_3.ref.current ? this.inputs.other_document_3.ref.current.rotate() : null,
						},
					]);
				},
				fail: (response) => {
					this.form.current.submit_fail(response)
					this.setState({ submitting: 0 });

				},
				error: (xhr, status)=>{
					this.form.current.submit_error(xhr, status)
					this.setState({ submitting: 0 });

				},
				complete: () => {
					this.inputs.captcha.ref.current.reset();
				}
			});
	}


	upload_files = (files) => {
		var component = this;

		if (files.length > 0) {

			var first_file = files.shift();

			if (Helpers.is_empty(first_file.file)) {

				if (first_file.hasOwnProperty('deleter')) {

					first_file.deleter.ajax({
						...first_file.deleter.ajax_setup,
						...{
							success: (response) => {
								component.upload_files(files);
							},
							fail: (response) => {
								component.setState({ submitting: 0 });
								component.form.current.submit_fail(response);
							},
							error: (xhr, status) => {
								component.setState({ submitting: 0 });
								component.form.current.submit_error(xhr, status);
							}
						}
					});

				} else {

					component.upload_files(files);

				}

			} else {

				// new file, upload and continue
				Image.downscale(first_file.file, {
					min_size: 500000,
					callback: (downscaled, original) => {
						var has_uploader = first_file.hasOwnProperty('uploader');
						if (original.is_src !== true && !Helpers.is_empty(downscaled) && has_uploader) {

							first_file.uploader.ajax_setup = first_file.uploader.ajax_setup || {};
							first_file.uploader.ajax(downscaled, original.name, first_file.rotation, {
								...first_file.uploader.ajax_setup,
								...{
									success: (response) => {
										component.upload_files(files);
									},
									fail: (response) => {

										component.setState({ submitting: 0 });
										component.form.current.submit_fail(response);
									},
									error: (xhr, status) => {
										component.setState({ submitting: 0 });
										component.form.current.submit_error(xhr, status);
									}
								}
							});

						} else {

							// this ajax will be called if the rotation was changed.
							if (first_file.rotation !== 0) {
								first_file.updater.ajax_setup = first_file.updater.ajax_setup || {};
								first_file.updater.ajax(first_file.rotation, {
									...first_file.updater.ajax_setup,
									...{
										success: (response) => {
											component.upload_files(files);
										},
										fail: (response) => {
											component.setState({ submitting: 0 });
											component.form.current.submit_fail(response);
										},
										error: (xhr, status) => {
											component.setState({ submitting: 0 });
											component.form.current.submit_error(xhr, status);
										}
									}
								})
							} else {
								component.upload_files(files);
							}

						}

					},
					filter: (file) => (file.is_src !== true),
					bestfit: true,
					width: 506,
					height: 319
				});
			}

		} else {
			Helpers.delete_cookie(process.env.REACT_APP_CUSTOMERID_NAME);
			Helpers.delete_cookie(process.env.REACT_APP_TOKEN_NAME);
			this.form.current.display_message(
				this.state.selected_account_type === 'Multilateral' ? 'SUC_00003' : 'SUC_00001',
				'success'
			);
			this.setState({selected_marital: '',home_address_value:''})
			this.reset_form();
			this.inputs.date_of_birth.ref.current.state.value = null
			this.setState({ submitting: 0 });

		}

	}

	accountType_onChange = () => {

		// account_type_id
		let account_type_id = this.inputs.account_type_id.ref.current.value();

		// Get the current account type
		let current_account_type = this.props.AccountTypes.content.find(value => {
			return value.account_type_id === account_type_id;
		});

		this.setState({ selected_account_type: current_account_type.account_type_name });
		// this.setState({selected_account_type: this.inputs.account_type.ref.current.value()})
	}

}

function bank_inputs(type, component, add_props) {
	var inputs = component.inputs;
	var props = component.props;

	return <React.Fragment>
		<Form.Row>
			<Form.Textbox
				{...add_props.bank_account_name}
				label='name'
				className='AppStyles-full_size_input'
				ref={inputs[type + '_account_bank_account_name'].ref}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Textbox
				{...add_props.bank_name}
				label='bank_name'
				className='AppStyles-half_size_input'
				ref={inputs[type + '_account_bank_name'].ref}
			/>
			<Form.Textbox
				{...add_props.branch_name}
				label='branch'
				className='AppStyles-half_size_input'
				ref={inputs[type + '_account_branch_name'].ref}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Textbox
				{...add_props.account_number}
				label='account_number'
				className='AppStyles-half_size_input'
				ref={inputs[type + '_account_number'].ref}
			/>

			<Form.Radio
				{...add_props.currency}
				label='currency'
				ref={inputs[type + '_account_currency'].ref}
				className='AppStyles-half_size_input'
				options={[
					{
						value: 'IDR',
						description: <React.Fragment>
							{Language.get('realaccount.idr', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.idr', 'en')}
							</div>
						</React.Fragment>
					},
					{
						value: 'USD',
						description: <React.Fragment>
							{Language.get('realaccount.usd', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.usd', 'en')}
							</div>
						</React.Fragment>
					}
				]}
			/>
		</Form.Row>
		<Form.Row>
			<Form.Radio
				{...add_props.account_type}
				label='account_type'
				ref={inputs[type + '_account_type'].ref}
				className='AppStyles-full_size_input RealAccountStyles-radio_with_input'
				options={[
					{
						value: 'Giro',
						description: <React.Fragment>
							{Language.get('realaccount.current_account', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.current_account', 'en')}
							</div>
						</React.Fragment>
					},
					{
						value: 'Tabungan',
						description: <React.Fragment>
							{Language.get('realaccount.savings', 'id')}
							<div className='AppStyles-label_secondary'>
								{Language.get('realaccount.savings', 'en')}
							</div>
						</React.Fragment>
					},
					{
						value: 'Lainnya',
						description: <Form.Textbox
							className={classnames(['RealAccountStyles-radio_input'])}
							ref={inputs[type + '_other_account_type'].ref}
							beforeInput={
								<span
									className='RealAccountStyles-radio_text'
								>
									{Language.get(
										'realaccount.others',
										'id'
									)}:
									<div className='AppStyles-label_secondary'>
										{Language.get(
											'realaccount.others',
											'en'
										)}
									</div>
								</span>
							}
							disabled={!component.show_other_account_type(type)}
						/>
					}
				]}
			/>

		</Form.Row>
	</React.Fragment>;
}

function get_document(document_array, document_order) {
	var document_item = document_array.find((document_item) => (document_item.order === document_order));
	return !Helpers.is_empty(document_item)
		&& [{
			is_src: true,
			name: document_item.original_file_name,
			src: document_item.file_path
		}];
}


function job_data_formatter(inputs, props, component) {
	return (item) => {
		switch (item) {
			case 'Swasta':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.private',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.private',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Wiraswasta':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.self_employed',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.self_employed',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Ibu RT':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.house_wife',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.house_wife',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Profesional':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.professional',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.professional',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Peg. Negeri':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.public_servant',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.public_servant',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Peg. BUMN':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.bumn_employee',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.bumn_employee',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Mahasiswa':
				return {
					value: item,
					description: <React.Fragment>
						{Language.get(
							'realaccount.student',
							'id'
						)}
						<div
							className='AppStyles-label_secondary'
						>
							{Language.get(
								'realaccount.student',
								'en'
							)}
						</div>
					</React.Fragment>
				};
			case 'Lainnya, sebutkan':
				return {
					value: item,
					description: <Form.Textbox
						className={classnames(['RealAccountStyles-radio_input'])}
						ref={inputs.other_job_type.ref}
						required={1}
						beforeInput={
							<span
								className='RealAccountStyles-radio_text'
							>
								{Language.get(
									'realaccount.other_mention',
									'id'
								)}:
								<div className='AppStyles-label_secondary'>
									{Language.get(
										'realaccount.other_mention',
										'en'
									)}
								</div>
							</span>
						}

						disabled={!component.show_other_job()}
					/>
				};
			// no default
		}
	}
}


export default StoreMethods.connect_stores(RegistrationForm, [
	'Brokers.BranchGrouped',
	'Brokers.Branches',
	'AccountTypes',
	'CountryCodes'
], Model.get_registration_data);