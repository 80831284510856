import React,{Component}             from 'react';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Step8Theme                    from 'theme/pages/realaccount/step8/Step8';
import { callables as StoreMethods } from 'app/store/Store';
import Model                 		 from 'app/modules/model/Model';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';
//added 20220621
import Loader                        from 'app/components/loader/Loader';
import CustomerDataUpdatedForm       from './customerDataUpdatedform/CustomerDataUpdatedForm';
import Step8Styles  				 from 'theme/pages/realaccount/step8/scss/Step8Styles.module.scss';
import classnames                    from 'classnames';

import LocalForage                          from 'app/utils/localforage/LocalForage';

class Step8 extends Component {

	constructor(props) {
		super(props);

		this.state = {
						loading    : 1,
						error      : false,
						code       : null,
						branch_info: {},
						hide       : false
					};
		this.handleClick = this.handleClick.bind(this);			
		this.hideCUD = this.hideCUD.bind(this);
		this.setHideCUD = this.setHideCUD.bind(this);
		this.readCUD = this.readCUD.bind(this);
			
	}

  	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step 8');
		let keys = ['email', 'faxNumber'];
		this.get_branch_info(keys, this.check_loading);
		//console.log(this.props)
		var self = this
		this.step8Interval(self)
	//	this.initCUD()
	//LocalForage.setItem('CUD',false)
  	}

	step8Interval = (self) =>{
		setInterval(function () {
			self.readCUD()
			//console.log('timer')
		}, 100);
	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	componentWillUnmount = () => {
		//console.log('willunmount')
		this.initCUD(false)
  	}

	render() {
		if (this.state.loading) {
			return <Loader.Spinner />;
		} else if (!this.state.hide) {
			return   <React.Fragment>
					{/*<div>
						<div className={classnames([Step8Styles.content_boundary,'AppStyles-container'])}>
						<div className={Step8Styles.home_button}>
							<button onClick={this.handleClick}>
        						Pengkinian Data
      						</button>
						</div>
						</div>
		</div>*/}
					<Step8Theme 
						loading     = {this.state.loading}
						className   = {this.state.error && 'AppStyles-hidden'}
						Company     = {this.props.Company}
						bankGrouped = {this.bank_grouped()}
						branchInfo  = {this.state.branch_info}
						hide  = {this.state.hide}
					/>
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message'
							code      = {this.state.code}
							type      = 'error'
						/> 
					}
					
				</React.Fragment>;
				
		}else{
			return <CustomerDataUpdatedForm 
						{...this.props}
						hideCUD  = {this.hideCUD}
					/>;
		}

	}

	setHideCUD(value) {
		//console.log('here sethideCUD')
		this.setState({hide: value}, () => {
			if(value){
				var interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
				// Clear any timeout/interval up to that id
				for (let i = 1; i < interval_id; i++) {
  					window.clearInterval(i);
				}
			}
			//console.log(this.state.hide,'state')
		})
	}

	handleClick() {
		//this.setState({ hide: true });
		this.initCUD(true)
	}

	showCUD(){
		LocalForage.setItem('CUD', true).then(function () {
			return LocalForage.getItem('CUD');
		  }).then(function (value) {
			//console.log(value,'showCUD')
			this.setState({hide: value}, () => {
				//console.log(this.state.hide,'state')
			})
		  }).catch(function (err) {
			// we got an error
		  });
	}

	hideCUD(){
		//console.log('here')
		//this.setState({hide: false}, () => {})
        this.initCUD(false)
		var self = this
		this.step8Interval(self)
	}

	async initCUD(v){
		/*var temp = true
		LocalForage.setItem('CUD', false).then(function () {
			return LocalForage.getItem('CUD');
		}).then(function (value) {
				console.log(value,'init')
				temp = value
		}).catch(function (err) {
				// we got an error
		});
		this.setHideCUD(temp)*/
    	try {
			var value = ''
			await LocalForage.setItem('CUD',v)
			value = await LocalForage.getItem('CUD');
			// This code runs once the value has been loaded
			// from the offline store.
			this.setHideCUD(value)
		} catch (err) {
			// This code runs if there were any errors.
			console.log(err);
		}
	}

	async readCUD(){
		/*var temp = true
		LocalForage.setItem('CUD', false).then(function () {
			return LocalForage.getItem('CUD');
		}).then(function (value) {
				console.log(value,'init')
				temp = value
		}).catch(function (err) {
				// we got an error
		});
		this.setHideCUD(temp)*/
    	try {
			var value = ''
			//await LocalForage.setItem('CUD',v)
			value = await LocalForage.getItem('CUD');
			// This code runs once the value has been loaded
			// from the offline store.
			this.setHideCUD(value)
		} catch (err) {
			// This code runs if there were any errors.
			console.log(err);
		}
	}

	bank_grouped = () => {
		var grouped      = [];
		let sorted_banks = [];

		if (!Helpers.is_empty(this.props.Company)) {

			sorted_banks = [...this.props.Company.content.bank_accounts];
			Helpers.sort_array_object_by_key(sorted_banks, 'currency');

			sorted_banks.forEach((bank) => {

				var index = grouped.findIndex((grouped_bank) => (grouped_bank.bank_name === bank.bank_name));

				if (index <= -1) {
					grouped.push({
						bank_name    : bank.bank_name,
						bank_accounts: [bank],
						bank_branch  : bank.bank_branch
					});
				} else {
					grouped[index].bank_accounts.push(bank);
				}

			});

		}

		return grouped;
	}

	/**
	 * Will setState branch_info with the value of parameter keys
	 * @param array keys
	 * @param function callback
	 */
	get_branch_info = (keys, callback) => {
		Model.get_branch_view(keys, {
			success: (response) => {
										const { data } = response;

										if (!Helpers.is_empty(data)) {
																
											let segment    = data.faxNumber.split('-');
											let fax_format = segment[0] + ' ' + segment[1];

											this.setState({
												branch_info: { email_address: data.email, fax_number: fax_format }
											}, callback);
										}
									},
			fail : (response) => { this.get_branch_info(keys, callback); },
			error: (response) => { this.get_branch_info(keys, callback); },
		});
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_company = !Helpers.is_empty(this.props.Company);

  			if (has_company) {

  				this.setState({ loading: 0 }, () => {
  					if (this.props.Company.error.code !== '0') {
						this.setState({ error: true, code: this.props.Company.error.code });
  					}
					this.readCUD()
  				});

  			}
		}
	}

}

export default StoreMethods.connect_stores(Step8, ['Company'], Model.get_company_details);