import React          from 'react';
import HomeStyles     from './scss/HomeStyles.module.scss';
import classnames     from 'classnames';
import FontIcons      from 'app/components/fonticons/FontIcons';
import {RouteLinks}   from 'app/components/routes/Routes';
import Language       from 'app/utils/language/Language';
// import Image          from 'app/components/image/Image';
// import Helpers        from 'app/utils/helpers/Helpers'; 

function Home(props) {
	return  <div className={classnames([HomeStyles.wrapper, props.state.loading && HomeStyles.loading, props.className])}>
				<div className={HomeStyles.background_filler}></div>
				<div 
					className = {classnames([
									HomeStyles.v_center_container,
									'GlobalStyles-slant_container_secondary'
								])}
				>
					<div className = {classnames([
										HomeStyles.body_background,
										'GlobalStyles-slant_container_background'
									])}
					>
					</div>
					<div className={classnames([HomeStyles.content_boundary,'AppStyles-container'])}>
						<div className={HomeStyles.body_content_wrapper}>
							<div className={HomeStyles.body_content}>
								<div className={HomeStyles.account_type}>
									{Language.get('home.account_demo')} {props.User.details.account_type}
								</div>

								<div className={classnames([HomeStyles.row, HomeStyles.username])}>
									<FontIcons.Material icon='person' className={HomeStyles.row_icon}/> 
									<div className={classnames([HomeStyles.row_text])}>
										{props.User.details.username}
	                                    <div className='GlobalStyles-ghost_ui'></div>
									</div>
								</div>

								<div className={classnames([HomeStyles.row, HomeStyles.etradedemo])}>

									<FontIcons.Material icon='timeline' className={HomeStyles.row_icon}/> 

									<div className={HomeStyles.row_text}>
										<div className={HomeStyles.row_text_multiline}>
											<div className={HomeStyles.etradedemo_title_container}>
												<a   
													className = {HomeStyles.etradedemo_title}
													href      = {props.links.demo_link}
													target    = '_blank'
													rel       = 'noopener noreferrer'
												>
													{Language.get('home.etrade_demo')}
		                                    		<div className='GlobalStyles-ghost_ui'></div>
												</a>
											</div>
											<div className={HomeStyles.tradepro_fx_container}>
												<a   
													className = {HomeStyles.etradedemo_tradepro_fx}
													href      = {props.links.real_link}
													target    = '_blank'
													rel       = 'noopener noreferrer'
												>
													{Language.get('home.tradeprofx')}
												</a>
		                                    	<div className='GlobalStyles-ghost_ui'></div>
											</div>
										</div>
									</div>
								</div>
								{
									// props.showRealAccountButton && 
									<div className={classnames([HomeStyles.row, HomeStyles.realaccount_button])}>										
										<div className={HomeStyles.button_container}>
												<RouteLinks type='realaccount' />											
											<div className='GlobalStyles-ghost_ui'></div>
										</div>
									</div>
								}								
							</div>
						</div>
					</div>
				</div>
			</div>;
}

export default Home;
