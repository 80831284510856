import React,{Component}             from 'react';
import classnames                    from 'classnames';
import RealAccountComponents         from '../../realaccountcomponents/RealAccountComponents';
import { callables as StoreMethods } from 'app/store/Store';
import Form                          from 'app/components/form/Form';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Language                      from 'app/utils/language/Language';
import Step3PersonalData             from './step3personaldata/Step3PersonalData';
import Step3InvestmentPurpose        from './step3investmentpurpose/Step3InvestmentPurpose';
import Step3MailingAddressData       from './step3mailingaddressdata/Step3MailingAddressData';
import Step3EmergencyContact         from './step3emergencycontact/Step3EmergencyContact';
import Step3JobData                  from './step3jobdata/Step3JobData';
import Step3ListOfWealth             from './step3listofwealth/Step3ListOfWealth';
import Step3BankAccountWithdraw      from './step3bankaccountwithdraw/Step3BankAccountWithdraw';
import Step3AttachedDocument         from './step3attacheddocument/Step3AttachedDocument';
import Model                 		 from 'app/modules/model/Model';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import Image                         from 'app/components/image/Image';
import Moment                        from 'moment';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';

function Step3FormBody(inputs, props, component) {
	return 	<React.Fragment>
	    		{Step3PersonalData.Inputs(inputs, props, component)}
	    		{Step3InvestmentPurpose.Inputs(inputs, props, component)}
	    		{Step3MailingAddressData.Inputs(inputs, props, component)}
	    		{Step3EmergencyContact.Inputs(inputs, props, component)}
	    		{Step3JobData.Inputs(inputs, props, component)}
	    		{Step3ListOfWealth.Inputs(inputs, props, component)}
	    		{Step3BankAccountWithdraw.Inputs(inputs, props, component)}
	    		{Step3AttachedDocument.Inputs(inputs, props, component)}
			</React.Fragment>
}


class Step3Form extends Component {

	constructor(props) {
		super(props);

		// create refs and validations
	    this.inputs ={...{
							accept_statement:{ 
												ref         : React.createRef(),
												validations : FormValidatorCommon.accept_statement, 
												store_exempt: true
							}
						},
						...Step3PersonalData.RefValidations(this.props,this),
						...Step3InvestmentPurpose.RefValidations(this.props,this),
						...Step3MailingAddressData.RefValidations(this.props,this),
						...Step3EmergencyContact.RefValidations(this.props,this),
						...Step3JobData.RefValidations(this.props,this),
						...Step3ListOfWealth.RefValidations(this.props,this),
						...Step3BankAccountWithdraw.RefValidations(this.props,this),
						...Step3AttachedDocument.RefValidations(this.props,this)
					};
		this.modals = {
						trading_experience: React.createRef()
					};

		this.state  = {
						can_submit				   : false,
						// loadig is just indicator if necessary data is loaded
						// ui_loading is indicator if form UI should be ghost loading or not
						loading                    : 1,
						ui_loading                 : 1,
						selected_job_type          : null,
						selected_marital           : null,
						selected_account_purpose   : null,
						selected_ownership_status  : null,
						selected_main_account_type : null,
						selected_other_account_type: null,
						selected_trading_experience: null,
						other_bank_required        : false,
						submitting                 : 0,
						error                      : false,
						code                       : null,
						user_files_fetched         : false,
						recent_photo               : [],
						id_photo                   : [],
						other_documents            : [],
						mailing_address_check_value: [],
						mailing_fax_check_value    : [],
						'personal_phone'           : false,
						'personal_fax'             : false,
						'main_account_phone'       : false,
						'other_account_phone'      : false,
						'office_phone'             : false,
						'office_fax'               : false,
						'mailing_fax'              : false,
						'mailing'				   : false,
					};

		this.grouped_contacts = [
									'personal_phone',
									'personal_fax',
									'main_account_phone',
									'other_account_phone',
									'office_phone',
									'office_fax',
									'mailing_fax'
								];

		this.grouped_address = ['mailing'];

		this.form                       = React.createRef();
		this.other_job_value            = 'Lainnya, sebutkan';
		this.job_type_options           = [
											'Swasta',
											'Wiraswasta',
											'Ibu RT',
											'Profesional',
											'Peg. Negeri',
											'Peg. BUMN',
											'Mahasiswa',
											this.other_job_value
										];
		this.other_account_value        = 'Lainnya';
		this.account_type_options       = ['Giro','Tabungan',this.other_account_value];
		this.other_account_opening      = 'Lainnya';
		this.account_opening_options    = [
											'Lindung Nilai',
											'Spekulasi',
											this.other_account_opening
										];
		this.ownership_status_options   = ['Pribadi','Keluarga','Sewa/Kontrak','Lainnya'];
		this.unmounted                  = false;
		this.area_code_placeholder      = '021';
		this.contact_number_placeholder = '29*****';
	}

	file_fetch_fail = (response) => {
		!this.unmounted
		&& this.setState({ error: true, code: response.error.code, user_files_fetched: true  }, this.check_loading);
	}

	file_fetch_error = (xhr, status) => {
  		!this.unmounted 
  		&& this.setState({ error: true, code: status, user_files_fetched: true  }, this.check_loading);
	}

	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step 3');
		var headers = {};	
		
		Model.get_customer_photo({
			success: (response, status, xhr) => {
				headers = this.get_file_info(xhr);

				!this.unmounted && this.setState({
					recent_photo: [
						...this.state.recent_photo,
						this.get_customer_photo_info(response, headers.regol_filename)
					]
				}, () => {
					Model.get_customer_id({
						success: (response, status, xhr) => {
							headers = this.get_file_info(xhr);

							!this.unmounted && this.setState({
								id_photo: [
									...this.state.id_photo,
									this.get_customer_photo_info(response, headers.regol_filename)
								],
							}, () => {
								this.get_other_document_by_order(1, () => {
									this.get_other_document_by_order(2, () => {
										this.get_other_document_by_order(3, this.check_loading);
									});
								});						
							});
						},
						fail: this.file_fetch_fail,
						error: this.file_fetch_error
					});

				});

			},

			fail: this.file_fetch_fail,
			error: this.file_fetch_error
		});
	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

  	componentWillUnmount = () => {
		this.unmounted = true;
  	}

	render() {
		return  <React.Fragment>
					<Form.Form 
						onValidateSuccess= {this.on_submit} 
						inputs           = {this.inputs} 
						loading          = {this.state.ui_loading}
	    				storevalues      = {true}
	    				storename        = 'step3'
		    			submitting       = {this.state.submitting}
	    				ref              = {this.form}
	    				className        = {classnames([
	    										this.state.error && 'AppStyles-hidden'
	    									])}
	    				key              = {this.state.loading+'form'}
					>
						<RealAccountComponents.StepPageLayout
							step           = {3}
							body           = {Step3FormBody(this.inputs, this.props, this)}
							footer         = {
												<React.Fragment>
													<h3 
													>
														{Language.get('realaccount.Step3_footer_1','id')}
													</h3>
													<div>
														<div>{Language.get('realaccount.Step3_footer_2','id')}</div>
														<div>
															<b>
																{Language.get('realaccount.Step3_footer_3','id')}
															</b>
														</div>
														<div>{Language.get('realaccount.Step3_footer_4','id')}</div>
													</div>
												</React.Fragment>
											}
							AcceptStatement= {RealAccountComponents.AcceptStatement(
												this.inputs.accept_statement.ref,
												this.check_can_submit,
												'declaration_of_truth'
											)}
							acceptTermsRef = {this.inputs.accept_statement.ref}
							canSubmit      = {this.state.can_submit}
							timerLabel     = {Language.get('realaccount.declare_on','id')}
						></RealAccountComponents.StepPageLayout>
					</Form.Form>
					{Step3InvestmentPurpose.ModalUI(this)}
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message' 
							code      = {this.state.code} 
							type      = 'error' 
						/> 
					}
		    	</React.Fragment>;
	}

	check_can_submit = () => {
		this.setState({ 
			can_submit: parseInt(this.inputs.accept_statement.ref.current.value())===1
						&& parseInt(this.inputs.family_work_at.ref.current.value())===2
						&& parseInt(this.inputs.bankrupt.ref.current.value())===2 && parseInt(this.inputs.not_money_laundering.ref.current.value())===1
						&& (
							this.inputs.account_opening_purpose.ref.current.value()==='Spekulasi' 
							|| !this.required_account_spekulasi()
						)
		});
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_countryCodes = !Helpers.is_empty(this.props.CountryCodes);
  			var has_user         = !Helpers.is_empty(this.props.User.details);
  			var has_step         = !Helpers.is_empty(this.props.Step);

  			if (has_countryCodes && has_user && this.props.User.is_full && has_step && this.state.user_files_fetched) {

  				this.check_other_banks();
				this.grouped_contacts.forEach((key) => {
					this.check_contact_group(key);
				});
				this.grouped_address.forEach((key) => {
					this.check_address_group(key);
				});

  				this.setState({ 
  					loading                    : 0,
  					selected_job_type          : this.job_type_defaultValue(),
  					selected_main_account_type : this.account_type_defaultValue('main'),
  					selected_other_account_type: this.account_type_defaultValue('other'),
  					selected_marital           : this.props.User.details.marital_status,
  					selected_account_purpose   : this.account_opening_defaultValue(),
  					selected_ownership_status  : this.ownership_status_defaultValue(),
  					selected_investment_experience:this.props.User.details.has_investment_experience,
  					selected_trading_experience:  this.props.User.details.has_trading_experience,
  					mailing_address_check_value: (
  													!Helpers.is_empty(this.props.User.details.mailing_address) 
													|| !Helpers.is_empty(this.props.User.details.mailing_postal_code)
												) ? ['1'] : [],
  					mailing_fax_check_value     : (
  													!Helpers.is_empty(this.props.User.details.mailing_fax_area_code) 
													|| !Helpers.is_empty(this.props.User.details.mailing_fax)
												) ? ['1'] : [],
  				}, () => {

			  		if (this.props.CountryCodes.error.code === '0') {
			  			this.inputs.personal_country_code.ref.current.set_options(this.props.CountryCodes.content);
			  		} else {
						this.setState({ error: true, code: this.props.CountryCodes.error.code });
					}
					  
			  		if (this.props.User.error.code !== 0) {
						this.setState({ error: true, code: this.props.User.error.code });
			  		}

			  		this.setState({ ui_loading: 0 });
					  if(this.checkStep8()){
						StoreMethods.step_enabled_change(8)
						this.disableAllandYa()
						this.changeDateSuccess()
						//console.log('step3Disclosure')
					}
  				});
  			}
		}
	}

	on_submit = (inputs) => {	
		this.setState({ submitting: 1 });
		if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
		}else{
			// get the file inputs first
			var recent_photo     = inputs.recent_photo;
			var id_photo         = inputs.id_photo;
			var other_document_1 = inputs.other_document_1;
			var other_document_2 = inputs.other_document_2;
			var other_document_3 = inputs.other_document_3;

			delete inputs.recent_photo;
			delete inputs.id_photo;
			delete inputs.other_document_1;
			delete inputs.other_document_2;
			delete inputs.other_document_3;

			// NOTE Delete has_trading_experience;
			delete inputs.has_trading_experience;

			inputs.not_money_laundering = parseInt(this.inputs.not_money_laundering.ref.current.value()) === 1 ? true : false

			inputs.job_type                =    this.final_job_type(inputs.job_type, inputs.other_job_type);
			inputs.main_account_type 	   =    this.final_account_type(
												inputs.main_account_type, 
												inputs.main_other_account_type
											);
			inputs.other_account_type 	   =    this.final_account_type(
												inputs.other_account_type, 
												inputs.other_other_account_type
											);
			inputs.account_opening_purpose =    this.final_account_opening(
												inputs.account_opening_purpose, 
												inputs.account_opening_text
											);
			inputs.home_ownership_status   =  this.final_ownership_status(
											inputs.home_ownership_status, 
											inputs.other_ownership_status
										);

  			var component = this;
			Model.update_customer_full(inputs, {
				success: () => { 
							this.upload_files([
								{ 
									file    : recent_photo, 
									uploader: { ajax: Model.upload_customer_photo },
									updater : { ajax: Model.rotate_customer_photo },
									rotation: this.inputs.recent_photo.ref.current.rotate(),
								},
								{ 
									file    : id_photo, 
									uploader: { ajax: Model.upload_customer_id },
									updater : { ajax: Model.rotate_customer_valid_id },
									rotation: this.inputs.id_photo.ref.current.rotate(),
								},
								{ 
									file    : other_document_1, 
									uploader: { 
												ajax      : Model.upload_customer_other,
												ajax_setup: {order: 1}
											},
									deleter : {
												ajax      : Model.delete_customer_other,
												ajax_setup: {order: 1}
											},
									updater : { 
												ajax: Model.rotate_customer_other_document,
												ajax_setup: {order: 1}
											},
									rotation: this.inputs.other_document_1.ref.current.rotate(),
								},
								{ 
									file    : other_document_2, 
									uploader: { 
												ajax      : Model.upload_customer_other,
												ajax_setup: {order: 2}
											},
									deleter : {
												ajax      : Model.delete_customer_other,
												ajax_setup: {order: 2}
											},
									updater : { 
												ajax: Model.rotate_customer_other_document,
												ajax_setup: {order: 2}
											},
									rotation: this.inputs.other_document_2.ref.current.rotate(),
								},
								{ 
									file    : other_document_3, 
									uploader: { 
												ajax      : Model.upload_customer_other,
												ajax_setup: {order: 3}
											},
									deleter : {
												ajax      : Model.delete_customer_other,
												ajax_setup: {order: 3}
											},
									updater : { 
												ajax: Model.rotate_customer_other_document,
												ajax_setup: {order: 3}
											},
									rotation: this.inputs.other_document_3.ref.current.rotate(),
								},
							]);
						},
				fail   : (response) => {
							component.setState({ submitting: 0 });
							component.form.current.submit_fail(response);
						},
				error  : (xhr, status) => {
							component.setState({ submitting: 0 });
							component.form.current.submit_error(xhr, status);
						}
			});
		}
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Disclosurestatement')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		StoreMethods.step_enabled_change(8)
		//disable date
		document.getElementById("date").disabled = true;
		this.disableElement('FormStyles_dropdown')
		this.disableElement('FormStyles_upload')
		this.disableElement('FormStyles_textbox')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) == classname){
				//console.log(child)
				child.disabled = true;
			}
  		}
		/*console.log(elements)
	
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			for (var i = 0, length = elements.length; i < length; i++) {
				child = elements[i];
				if (child.className.substring(0, classname.length) == classname)
					  child[i].disabled = true;
			  }		
		}*/
		
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}

	//---------------------------

	upload_files = (files) => {
		var component = this;

		if (files.length > 0) {

			var first_file = files.shift();

			if (Helpers.is_empty(first_file.file)) {

				if (first_file.hasOwnProperty('deleter')) {

					first_file.deleter.ajax({
						...first_file.deleter.ajax_setup, 
						...{
							success: (response) => { 
										component.upload_files(files);
								    },
				  			fail   : (response) => {
				  						component.setState({ submitting: 0 });
				  						component.form.current.submit_fail(response);
				  					},
				  			error  : (xhr, status) => {
				  						component.setState({ submitting: 0 });
				  						component.form.current.submit_error(xhr, status);
				  					}
						}
					});

				} else {
					
					component.upload_files(files);

				}

			} else {

				// new file, upload and continue
				Image.downscale(first_file.file, {
					min_size: 500000,
					callback: (downscaled, original) => {
								var has_uploader = first_file.hasOwnProperty('uploader');
								if (original.is_src !== true && !Helpers.is_empty(downscaled) && has_uploader) {

									first_file.uploader.ajax_setup = first_file.uploader.ajax_setup || {};
									first_file.uploader.ajax(downscaled, original.name, first_file.rotation, {
										...first_file.uploader.ajax_setup, 
										...{
											success: (response) => { 
														component.upload_files(files);
												    },
								  			fail   : (response) => {
								  						component.setState({ submitting: 0 });
								  						component.form.current.submit_fail(response);
								  					},
								  			error  : (xhr, status) => {
								  						component.setState({ submitting: 0 });
								  						component.form.current.submit_error(xhr, status);
								  					}
										}
									});

								} else {

									// this ajax will be called if the rotation was changed.
									if (first_file.rotation !== 0) {
										first_file.updater.ajax_setup = first_file.updater.ajax_setup || {};
										first_file.updater.ajax(first_file.rotation, {
											...first_file.updater.ajax_setup,
											...{
												success: (response) => { 
															component.upload_files(files);
												    	},
								  				fail   : (response) => {
															component.setState({ submitting: 0 });
															component.form.current.submit_fail(response);
														},
								  				error  : (xhr, status) => {
															component.setState({ submitting: 0 });
															component.form.current.submit_error(xhr, status);
														}
											}
										})
									} else {
										component.upload_files(files);
									}
									
								}

							},
					filter  : (file) => (file.is_src !== true),
					bestfit : true,
					width   : 506,
					height  : 319
				});

			}

		} else {
		
	  		Model.update_next_progressName({
	  			precomplete: () => {
	  				this.setState({ submitting: 0 });
	  			},
	  			fail    : this.form.current.submit_fail,
	  			error   : this.form.current.submit_error
			});
			
		}

	}

	final_job_type = (job_type, other_job_type) => {

		if (job_type === this.other_job_value) {
			return other_job_type;
		} else {
			return job_type;
		}

	}

	final_account_type = (account_type, other_account_type) => {

		if (account_type === this.other_account_value) {
			return other_account_type;
		} else {
			return account_type;
		}
	}
	
	final_account_opening = (account_opening_purpose, account_opening_text) => {

		if (account_opening_purpose === this.other_account_opening) {
			return account_opening_text;
		} else {
			return account_opening_purpose;
		}

	}

	final_ownership_status = (home_ownership_status, other_ownership_status) => {

		if (home_ownership_status === 'Lainnya') {
			return other_ownership_status;
		} else {
			return home_ownership_status;
		}

	}

	trading_experience_changed = (event_data) => {
		event_data = event_data || {};
		var selected_trading_experience = this.inputs.has_trading_experience.ref.current.value(); 

		this.setState({ selected_trading_experience },() => {
			if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
				if (this.inputs.has_trading_experience.ref.current.value()==='true') {
					this.modals.trading_experience.current.open();
				}
				else {
					// NOTE: call API if has_trading_experience is 'No'
					Model.update_customer_trading({
						has_trading_experience: 'false',
						trading_account       : '',
						trading_company       : '',
					}, {
						fail : (response) => { this.form.current.submit_fail(response); },
						error: (xhr, status) => { this.form.current.submit_error(xhr, status); }
					});
				}
			}
		});
	}

	family_work_changed = () => {


		if (parseInt(this.inputs.family_work_at.ref.current.value())===1) {
			this.inputs.family_work_text.ref.current.enable();
			this.form.current.display_message('ERR_00009','error', {
				onClose: () => {  this.inputs.family_work_at.ref.current.set_value('')  }
			});
		} else {
			this.inputs.family_work_text.ref.current.disable();
		}

		this.check_can_submit();
	}

	investment_experience_changed = (event_data) => {
		event_data = event_data || {};
		this.inputs.investment_experience.ref.current.error_clear();

		if (this.inputs.has_investment_experience.ref.current.value()==='true') {
			this.inputs.investment_experience.ref.current.enable(() => {
				if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
					this.inputs.investment_experience.ref.current.set_focus();
				}
			});
		} else {
			this.inputs.investment_experience.ref.current.disable();
			this.inputs.investment_experience.ref.current.set_value('');
		}

		this.check_can_submit();
	}

	bankrupt_changed = () => {
		
		if (parseInt(this.inputs.bankrupt.ref.current.value())===1) {
			this.form.current.display_message('ERR_00010','error', {
				onClose: () => {  this.inputs.bankrupt.ref.current.set_value('')  }
			});
		}

		this.check_can_submit();
	}
	not_money_laundering_changed = () => {

		this.check_can_submit();
	}

	job_type_changed = (event_data) => {
		event_data = event_data || {};
		var selected_job_type = this.inputs.job_type.ref.current.value(); 

		this.setState({ selected_job_type },() => {
			if (this.show_other_job()) {

				this.inputs.other_job_type.ref.current.enable(() => {

					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs.other_job_type.ref.current.set_focus();
					}

				});
				
			} else {

				this.inputs.other_job_type.ref.current.set_value('');
				this.inputs.other_job_type.ref.current.disable();

			}
		});
	}

	account_type_changed =  (event_data, type) => {
		event_data  = event_data || {};
		var changes = {};
		changes['selected_'+type+'_account_type'] = this.inputs[type+'_account_type'].ref.current.value();

		this.setState(changes,() => {
			if (this.show_other_account_type(type)) {

				this.inputs[type+'_other_account_type'].ref.current.enable(() => {

					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs[type+'_other_account_type'].ref.current.set_focus();
					}

				});

			} else {

				this.inputs[type+'_other_account_type'].ref.current.set_value('');
				this.inputs[type+'_other_account_type'].ref.current.disable();
			}
		});
	}

	ownership_status_changed = (event_data) => {
		event_data = event_data || {};
		var selected_ownership_status = this.inputs.home_ownership_status.ref.current.value(); 

		this.setState({ selected_ownership_status },() => {
			if (this.show_other_ownership()) {

				this.inputs.other_ownership_status.ref.current.enable(() => {
					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs.other_ownership_status.ref.current.set_focus();
					}
				});

			} else {

				this.inputs.other_ownership_status.ref.current.set_value('');
				this.inputs.other_ownership_status.ref.current.disable();
			}
		});
	}

	marital_status_changed = (event_data) => {
		event_data = event_data || {};
		var selected_marital = this.inputs.marital_status.ref.current.value(); 

		this.setState({ selected_marital },() => {
			if (this.show_marital_status()) {

				this.inputs.spouse_name.ref.current.enable(() => {

					if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
						this.inputs.spouse_name.ref.current.set_focus();
					}

				});

			} else {

				this.inputs.spouse_name.ref.current.set_value('');
				this.inputs.spouse_name.ref.current.disable();
			}
		});

		this.check_age(event_data);
	}

	/**
	 * will remove the value of tax_number when nationality is changed
	 */
	nationality_changed = () => {
		let tax_number  = this.inputs.tax_number.ref.current;
		tax_number.set_value('');
	}

	required_account_spekulasi = () => {
		return this.props.User.details.account_type=== 'Bilateral';
	}

	account_purpose_changed = (event_data) => {
		event_data = event_data || {};

		// showing of others input
		var selected_account_purpose = this.inputs.account_opening_purpose.ref.current.value(); 

		this.setState({ selected_account_purpose });


		if (this.state.ui_loading === 0) {

			this.inputs.account_opening_text.ref.current.set_value('');
			if (selected_account_purpose === 'Lainnya') {
				this.inputs.account_opening_text.ref.current.enable(() => {
					if (!this.required_account_spekulasi() && event_data.setter !== 'form') {
						this.inputs.account_opening_text.ref.current.set_focus();
					}
				});
			} else {
				this.inputs.account_opening_text.ref.current.disable();
			}

			// showing of error modal
			var has_value = !Helpers.is_empty(selected_account_purpose);
			if (this.required_account_spekulasi() && selected_account_purpose !== 'Spekulasi' && has_value) {
				this.form.current.display_message('ERR_00025','error', {
					onClose: () => {  this.inputs.account_opening_purpose.ref.current.set_value('')  }
				});
			}

		}

		this.check_can_submit();
	}


	show_account_purpose = () => {
		return this.state.selected_account_purpose === this.other_account_opening;
	}

	show_other_job = () => {
		return this.state.selected_job_type === this.other_job_value;
	}

	show_other_account_type = (type) => {
		return this.state['selected_'+type+'_account_type'] === this.other_account_value;
	}

	show_marital_status = () => {
		return this.state.selected_marital === 'Kawin';
	}

	show_other_ownership = () => {
		return this.state.selected_ownership_status === 'Lainnya';
	}

	account_opening_defaultValue = () => {
		if (!Helpers.is_empty(this.props.User.details.account_opening_purpose)) {

			if (Helpers.in_array(this.props.User.details.account_opening_purpose, this.account_opening_options)) {
				return this.props.User.details.account_opening_purpose;
			} else {
				return this.other_account_opening;
			}

		}
	}

	job_type_defaultValue = () => {
		if (!Helpers.is_empty(this.props.User.details.job_type)) {

			if (Helpers.in_array(this.props.User.details.job_type, this.job_type_options)) {
				return this.props.User.details.job_type;
			} else {
				return this.other_job_value;
			}

		}
	}

	account_type_defaultValue = (type) => {
		if (!Helpers.is_empty(this.props.User.details[type+'_account_type'])) {

			if (Helpers.in_array(this.props.User.details[type+'_account_type'], this.account_type_options)) {
				return this.props.User.details[type+'_account_type'];
			} else {
				return this.other_account_value;
			}

		}
	}

	ownership_status_defaultValue = () => {

		if (!Helpers.is_empty(this.props.User.details.home_ownership_status)) {

			if (Helpers.in_array(this.props.User.details.home_ownership_status, this.ownership_status_options)) {
				return this.props.User.details.home_ownership_status;
			} else {
				return 'Lainnya';
			}

		}
		
	}

	job_type_currentValue = () => {
		
		if (!Helpers.is_empty(this.state.selected_job_type)) {
			return this.state.selected_job_type;
		}

	}

	account_type_currentValue = (type) => {
		let account_type = 'selected_'+type+'_account_type';
		
		if (!Helpers.is_empty(this.state[account_type])) {
			return this.state[account_type];
		}

	}

	check_other_banks = () => {
		var current_status = this.other_banks_required();
		
		if (current_status !== this.state.other_bank_required) {

			this.setState({ other_bank_required: current_status },() => {
				if (!this.state.other_bank_required) {
					this.inputs.other_account_bank_account_name.ref.current.error_clear();
					this.inputs.other_account_bank_name.ref.current.error_clear();
					this.inputs.other_account_branch_name.ref.current.error_clear();
					this.inputs.other_account_number.ref.current.error_clear();
					this.inputs.other_account_type.ref.current.error_clear();
					this.inputs.other_account_currency.ref.current.error_clear();
				}
			});

		}
	}

	other_banks_required = () => {
		return !Helpers.is_empty(this.inputs.other_account_bank_account_name.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_bank_name.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_branch_name.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_number.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_type.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_currency.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_phone_area_code.ref.current.value())
				|| !Helpers.is_empty(this.inputs.other_account_phone.ref.current.value());
	}

	trading_experience_submitted = () => {
		this.modals.trading_experience.current.close();
	}

	check_contact_group = (group_name) => {
		var current_status = this.contact_grouped_required(group_name)();

		if (current_status !== this.state[group_name]) {
			var change_status         = {};
			change_status[group_name] = current_status;

			this.setState(change_status,() => {

				if (!this.state[group_name]) {
					this.inputs[group_name].ref.current.error_clear();
					this.inputs[group_name+'_area_code'].ref.current.error_clear();
				}

			});
		}
	}

	contact_grouped_required = (group_name) => {
		return () => {
			return !Helpers.is_empty(this.inputs[group_name].ref.current.value())
				|| !Helpers.is_empty(this.inputs[group_name+'_area_code'].ref.current.value());
		}
	}

	check_address_group = (group_name) => {
		var current_status = this.address_grouped_required(group_name)();

		if (current_status !== this.state[group_name]) {
			var change_status         = {};
			change_status[group_name] = current_status;

			this.setState(change_status,() => {

				if (!this.state[group_name]) {
					this.inputs[group_name+'_address'].ref.current.error_clear();
					this.inputs[group_name+'_postal_code'].ref.current.error_clear();
				}

			});
		}
	}

	address_grouped_required = (group_name) => {
		return () => {
			return !Helpers.is_empty(this.inputs[group_name+'_address'].ref.current.value())
				|| !Helpers.is_empty(this.inputs[group_name+'_postal_code'].ref.current.value());
		}
	}

	mailing_address_changed = () => {
		Helpers.event_delay(() => {
			if (!Helpers.in_array(1, this.inputs.mailing_address_check.ref.current.value())) {
				var has_mailing_address = !Helpers.is_empty(this.inputs.mailing_address.ref.current.value());
				var has_mailing_postal  = !Helpers.is_empty(this.inputs.mailing_postal_code.ref.current.value());
				if (has_mailing_address || has_mailing_postal) {
					this.inputs.mailing_address_check.ref.current.set_value(['1']);
				}
			}
		}, 1000);
	}

	mailing_address_check_changed = () => {
		this.setState({ mailing_address_check_value: this.inputs.mailing_address_check.ref.current.value() });

		if (!Helpers.in_array('1',this.inputs.mailing_address_check.ref.current.value())) {
			this.inputs.mailing_address.ref.current.set_value('');
			this.inputs.mailing_postal_code.ref.current.set_value('');
		}
	}

	email_address_changed = () => {
		Helpers.event_delay(() => {

			if (!Helpers.in_array(1, this.inputs.mailing_email_check.ref.current.value())) {

				if (!Helpers.is_empty(this.inputs.email_address.ref.current.value())) {
					this.inputs.mailing_email_check.ref.current.set_value(['1']);
				}

			}
		}, 1000);
	}

	mailing_email_check_changed = () => {
		if (!Helpers.in_array('1',this.inputs.mailing_email_check.ref.current.value())) {
			this.inputs.email_address.ref.current.set_value('');
		}
	}

	mailing_fax_check_changed = () => {
		this.setState({ mailing_fax_check_value: this.inputs.mailing_fax_check.ref.current.value() });

		if (!Helpers.in_array('1',this.inputs.mailing_fax_check.ref.current.value())) {
			this.inputs.mailing_fax_area_code.ref.current.set_value('');
			this.inputs.mailing_fax.ref.current.set_value('');
		}
	}

	mailing_fax_changed = () => {
		Helpers.event_delay(() => {
			if (!Helpers.in_array(1, this.inputs.mailing_fax_check.ref.current.value())) {
				var has_mailing_fax_area_code=!Helpers.is_empty(this.inputs.mailing_fax_area_code.ref.current.value());
				var has_mailing_fax          =!Helpers.is_empty(this.inputs.mailing_fax.ref.current.value());
				if (has_mailing_fax_area_code || has_mailing_fax) {
					this.inputs.mailing_fax_check.ref.current.set_value(['1']);
				}
			}
		}, 1000);
	}

	birth_marital_valid = () => {
		var date_of_birth  = this.inputs.date_of_birth.ref.current.value();
		var marital_status = this.inputs.marital_status.ref.current.value();

		if (!Helpers.is_empty(date_of_birth) && !Helpers.is_empty(marital_status)) {
			var age = Moment().diff(Moment(date_of_birth), 'years');
			if (age<17) {
				return false;
			} else if(age < 21 && marital_status === 'Tidak Kawin') {
				return false;
			} else {
				return true;
			}				

		} else {

			return true;

		}
	}

	check_age = (event_data) => {
		event_data = event_data || {};

		// early checking
		if (this.state.ui_loading === 0 && event_data.setter !== 'form') {
			var is_valid = this.birth_marital_valid();

			if (!is_valid) {
				this.form.current.display_message('ERR_00029','error');
			} else {
				this.inputs.date_of_birth.ref.current.error_clear();
				this.inputs.marital_status.ref.current.error_clear();
			}
		}

	}

	/**
	 * Get the Filename and Filetype in response header
	 * @param object xhr
	 * @returns object
	 */
	get_file_info = (xhr) => {
		if (!Helpers.is_empty(xhr.getResponseHeader('Regol-Token'))) {
			return {
				regol_filename  : xhr.getResponseHeader('Regol-Filename'),
				regol_file_type : xhr.getResponseHeader('Regol-File-Type')
			}
		}
	}

	/**
	 * If the file is image, then will return the blob URL, else filename
	 * @param blob blob
	 * @param string filename
	 * @returns string
	 */
	get_file_path = (blob, filename) => {
		return Helpers.is_image(filename) ? URL.createObjectURL(blob) : filename;
	}

	/**
	 * will check if filename exist and return customer photo info (recent_photo, id_photo)
	 * else will return an empty object
	 * @param blob blob
	 * @param string filename
	 * @returns object
	 */
	get_customer_photo_info = (blob, filename) => {
		if (!Helpers.is_empty(filename)) {
			return {
				file_path: this.get_file_path(blob, filename),
				original_file_name: filename,
			}
		}
		return {};
	}

	/**
	 * will check if filename exist and return other document data,
	 * else will return an empty object
	 * @param blob blob
	 * @param string filename
	 * @param int order
	 * @returns object
	 */
	get_other_documents_info = (blob, filename, order) => {
		if (!Helpers.is_empty(filename)) {
			return {
				file_path: this.get_file_path(blob, filename),
				original_file_name: filename,
				order: order
			}
		}
		return {};
	}

	/**
	 * get other documents by order
	 * @param int order
	 * @param function callback
	 * @returns 
	 */
	get_other_document_by_order(order, callback) {
		Model.get_other_documents({
			order,
			success: (response, status, xhr) => {
				let headers = this.get_file_info(xhr);
				!this.mounted && this.setState({
					other_documents: [
						...this.state.other_documents,
						this.get_other_documents_info(response, headers.regol_filename, order)
					],
				}, callback);

				if (order === 3) {
					this.setState({
						user_files_fetched: true
					});
				}
			},
			fail: this.file_fetch_fail,
			error: this.file_fetch_error
		})
	}
}

export default StoreMethods.connect_stores(Step3Form, [
	'User',
	'CountryCodes',
	'Step'
],Model.get_step3);