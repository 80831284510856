import React,{Component}             from 'react';
import classnames                    from 'classnames';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import RealAccountComponents         from '../../realaccountcomponents/RealAccountComponents';
import { callables as StoreMethods } from 'app/store/Store';
import BasicInfoForm                 from '../../basicinfoform/BasicInfoForm';
import Form                          from 'app/components/form/Form';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Language                      from 'app/utils/language/Language';
import Model                 		 from 'app/modules/model/Model';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';

class Step2BasicInfoForm extends Component {

	constructor(props) {
		super(props);

		// const id_type_ref = React.createRef();

		// create refs and validations
	    this.inputs =   {...BasicInfoForm.inputs(),...{
							accept_statement:{ 
												ref         : React.createRef(),
												validations : FormValidatorCommon.accept_statement, 
												store_exempt: true
											}
	    				}};
		this.state  = {
						can_submit: false,
						loading   : 1,
						submitting: 0,
						error     : false,
						code      : null
					};
		this.form   = React.createRef();
	}

  	componentDidMount = () => {
  		this.check_loading();
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

  	is_multilateral() {
  		return this.props.User.details.account_type.toLowerCase()==='multilateral';
  	}

	render() {

		return  <React.Fragment>
					<Form.Form 
						onValidateSuccess= {this.on_submit} 
						inputs           = {this.inputs} 
						loading          = {this.state.loading}
	    				storevalues      = {true}
	    				storename        = 'step2'
			    		submitting       = {this.state.submitting}
			    		ref              = {this.form}
		    			key              = {this.state.loading+'form'}
	    				className        = {classnames([
	    										this.state.error && 'AppStyles-hidden'
	    									])}
					>
						<RealAccountComponents.StepPageLayout
							step           = {2}
							header         = {
												<h3 
													className = {classnames([
																	'RealAccountStyles-content_title',
																	'AppStyles-no_margin'
																])}
												>
													{
														this.is_multilateral() ?
															Language.get('realaccount.step2_pbk_title','id')
														:
															Language.get('realaccount.step2_asp_title','id')
													}
												</h3>
											}
							body           = {
												<React.Fragment>
													{ BasicInfoForm.editable(this.inputs, this.props, this) } 

													<Form.Row>
										      			<Form.TextView     
										      				label        = 'demo_account_number' 
										      				className    = 'AppStyles-half_size_input'
										      			>
										      				{this.props.User.details.username}
										      			</Form.TextView>
										      		</Form.Row>
										      		
												</React.Fragment>
											}
							footer         = {
												<React.Fragment>
													<p>
														{
															this.is_multilateral() ?
																<React.Fragment>
																	{Language.get('realaccount.step2_pbk_footer_1','id')}
																	{
																		!Helpers.is_empty(this.props.Company)
																		&& <b>{this.props.Company.content.company_name}</b>
																	}
																	{Language.get('realaccount.step2_pbk_footer_2','id')}
																</React.Fragment>
															:
																<React.Fragment>
																	{Language.get('realaccount.step2_asp_footer_1','id')}
																	{
																		!Helpers.is_empty(this.props.Company)
																		&& <b>{this.props.Company.content.company_name}</b>
																	}
																	{Language.get('realaccount.step2_asp_footer_2','id')}
																</React.Fragment>
														}
													</p>
													<p>{Language.get('realaccount.step2_footer_3','id')}</p>
												</React.Fragment>
											}
							AcceptStatement= {RealAccountComponents.AcceptStatement(
												this.inputs.accept_statement.ref,
												this.on_accept_statement
											)}
							acceptTermsRef = {this.inputs.accept_statement.ref}
							canSubmit      = {this.state.can_submit}
							timerLabel     = {Language.get('realaccount.statement_on','id')}
						/>
					</Form.Form>
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message' 
							code      = {this.state.code} 
							type      = 'error' 
						/> 
					}
				</React.Fragment>;
	}


	on_accept_statement = () => {
		this.setState({ can_submit:  parseInt(this.inputs.accept_statement.ref.current.value())===1 });
	}

	check_loading = () => {
		if (this.state.loading===1) {
  			var has_user    = !Helpers.is_empty(this.props.User.details);
  			var has_company = !Helpers.is_empty(this.props.Company);

  			if (has_user && has_company) {
  				this.setState({ loading: 0 }, () => {
  					if (this.props.Company.error.code !== '0') {
						this.setState({ error: true, code: this.props.Company.error.code });
			  		}
					  if(this.checkStep8()){
						this.disableAllandYa()
						StoreMethods.step_enabled_change(8)
						this.changeDateSuccess()
					}  
  				});
  			}
		}
	}

	on_submit = (inputs) => {
  		this.setState({ submitting: 1 });
		  if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
		}else{
			var component = this;
			Model.update_customer_basic(inputs, {
			success: () => { 
				  		Model.update_next_progressName({
				  			precomplete: () => {
				  				this.setState({ submitting: 0 });
				  			},
				  			fail    : this.form.current.submit_fail,
				  			error   : this.form.current.submit_error
						});
					},
  			fail   : (response) => {
  						component.setState({ submitting: 0 });
  						component.form.current.submit_fail(response);
  					},
  			error  : (xhr, status) => {
  						component.setState({ submitting: 0 });
  						component.form.current.submit_error(xhr, status);
  					}
			});
		}
  		
	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'step2BasicInfo')
		//console.log(this.props,temp,'step2BasicInfo')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		//disable date
		document.getElementById("date").disabled = true;
		this.disableElement('FormStyles_dropdown')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var elements = [], child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) == classname)
      			elements.push(child);
  		}
		//console.log(elements)
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			
		}
		
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}

}

export default StoreMethods.connect_stores(Step2BasicInfoForm, ['User', 'Company'],Model.get_step2);