function contents() {

	return {
		step1          : 'Profil Perusahaan',
		step2          : 'Simulasi Transaksi',
		step3          : 'Input Data',
		step4          : 'Pemberitahuan Resiko',
		step5          : 'Pemberian Amanat',
		step6          : 'Peraturan Perdagangan',
		step7          : 'Surat Pernyataan',
		step8          : 'Selesai',
		leave_page     : 'You haven’t saved your changes. Are you sure you want to leave this page?',
		step1_title    : 'Profil Perusahaan Pialang Berjangka',
		step2_title    : 'Pernyataan Telah Melakukan Simulasi Perdagangan Berjangka Alternatif',
		step3_title    : 'Aplikasi Pembukaan Rekening Transaksi Secara Elektronik On-line',
		step4_title    : 'Pernyataan Menerima Pemberitahuan Adanya Risiko',
		step5_title    : 'Pernyataan Perjanjian Pemberian Amanat',
		step6_title    : 'Pernyataan Peraturan Perdagangan (Trading Rules)',
		step7_title    : 'Pernyataan Bertanggung Jawab Atas',
		step7_subtitle_1: 'Kode Akses Transaksi Nasabah',
		step7_subtitle_2: '(Personal Access Password)',
		yes            : 'Yes',
		declare_on     : 'Menyatakan pada tanggal',
		age_requirement: '',
		personal_data  : 'Customer Personal Data',
		investment_purpose: 'The Purpose and Background of Customer Investment',
		mailing_address_data: 'Customer Correspondence Address Data',
		emergency_contact: 'In case of emergency, the person who could be contacted',
		job_data         : 'Customer Occupation Data',
		list_of_wealth   : 'List Of Wealth',
		wealth           : 'List of Assets',
		bank_account_withdraw: 'Customer Bank Account for Withdrawal',
		main_account         : 'Main Account',
		other_account        : 'Other Account',
		attached_documents   : 'Attached Document',
		other_document_types : 'Bank Account / Credit Card Bill / Electricity Account or Phone Account / Taxpayer '
								+'Identification Number / Letter of land ownership or building or vehicle / Certificate'
								+' of Work / Other document',
		deposit_to_account   : 'Thank you for registering online, please deposit to segregated account',
		send_deposit_proof   : 'dan kirimkan bukti penyetoran ke',
		sure_to_submit       : 'Apakah Informasi yang diisi sudah benar?',
		fax_number_short     : 'Facsimile Number',
		bank                 : 'BANK',
		male                 : 'Male',
		female               : 'Female',
		indonesian           : 'Indonesian Citizens',
		foreigner            : 'Foreign Nationals',		
		single               : 'Single',
		married              : 'Married',
		widowed              : 'Widow/Women',
		widower              : 'Widow/Man',
		personal             : 'Personal',
		family               : 'Family',
		rent                 : 'Lease/Contract',
		others               : 'Other',
		hedging              : 'Hedging',
		gain                 : 'Gain',
		speculation          : 'Speculation',
		yes_in_sector        : 'Yes, in sector',
		no                   : 'No',
		give_sequence        : 'Give the sequence based on priority',
		private             : 'Private',
		self_employed       : 'Entrepreneur',
		house_wife          : 'House Wife',
		professional        : 'Professional',
		public_servant      : 'Civil Servant',
		bumn_employee       : 'BUMN Employee',
		student             : 'Student',
		other_mention       : 'Other, mention it',
		income_100_250      : 'Between IDR 100 – 250 millions',
		income_250_500      : 'Between IDR 250 – 500 millions',
		income_500_plus     : 'Above IDR 500 millions',
		current_account     : 'Current Account',
		savings             : 'Saving',
		idr                  : 'IDR',
		usd                  : 'USD',
		company_shareholders: 'Susunan Pemegang Saham Perusahaan'
	}; 

}

export default contents;