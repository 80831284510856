function contents() {

	return {
		'dont_have_account': 'Belum punya akun demo?',
		'go_to_register'   : 'Registrasi akun demo di sini',
		'login_header_msg' : 'Gunakan username dan password akun demo',
	};

}

export default contents;