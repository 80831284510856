const ActionTypes = {
						update_user        :'update_user',
						update_auth        :'update_auth',
						update_user_details:'update_user_details',
						update_user_error  :'update_user_error',
						update_user_full   :'update_user_full'
					};

var callables = {

	/**	
	 * Add user to store
	 * @param  object user
	 * @return object
	 */
	update_user(user) {
		return { type: ActionTypes.update_user, user };
	},

	/**	
	 * Add user to store
	 * @param  boolean auth
	 * @return object
	 */
	update_auth(auth) {
		return { type: ActionTypes.update_auth, auth };
	},

	/**	
	 * Add user to store
	 * @param  object details
	 * @return object
	 */
	update_user_details(details) {
		return { type: ActionTypes.update_user_details, details };
	},

	/**
	 * This will update user error code
	 * @param  object error
	 * @return object
	 */
	update_user_error(error) {
		return { type: ActionTypes.update_user_error, error };
	},

	/**
	 * This will update user error code
	 * @param  object is_full
	 * @return object
	 */
	update_user_full(is_full) {
		return { type: ActionTypes.update_user_full, is_full };
	}

};

export default callables;
export { ActionTypes };