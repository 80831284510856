import React,{Component}             from 'react';
import RealAccountComponents         from '../../realaccountcomponents/RealAccountComponents';
import { callables as StoreMethods } from 'app/store/Store';
import Form                          from 'app/components/form/Form';
import FormValidatorCommon           from 'app/components/form/formvalidator/formvalidatorcommon/FormValidatorCommon';
import Helpers                       from 'app/utils/helpers/Helpers'; 
import Language                      from 'app/utils/language/Language';
import Model                 		 from 'app/modules/model/Model';
import Wysiwyg                 		 from 'app/components/wysiwyg/Wysiwyg';
import Loader                        from 'app/components/loader/Loader';
import MessageDisplay                from 'app/components/messagedisplay/MessageDisplay';
import Image                         from 'app/components/image/Image';
import GoogleAnalytics 				 from 'app/utils/googleanalytics/GoogleAnalytics';
import Moment                        from 'moment';
import 'moment/locale/id';
import 'moment-timezone';

class Step5 extends Component {

	constructor(props) {
		super(props);

		// create refs and validations
	    this.inputs =   {
							accept_statement:{ 
												ref        :React.createRef(),
												validations:FormValidatorCommon.accept_statement},
							wysiwyg_0       :{ 
												ref        :React.createRef(),
												validations:FormValidatorCommon.wysiwyg_require_all
											}
						};

		this.state  =   {
							can_submit    : false,
							loading       : 1,
							ui_loading    : 1, 
							error         : false, 
							submitting    : 0, 
							code          : null
					    };

		this.form              = React.createRef();
		this.wysiwyg_loaded    = [];
		this.wysiwyg_length    = 0;
		this.wysiwyg_changed   = false;
		this.custom_components = ['custom_broker_branch','custom_conflict_court']; 
	}

	componentDidMount = () => {
		GoogleAnalytics.page_view([], 'Step 5');
  		this.check_loading();
  	}

  	componentDidUpdate = () => {
  		this.check_loading();
  	}

	render() {
		return  <React.Fragment>
				 	<Form.Form 
				 		inputs             = {this.inputs}
						onValidateSuccess  = {this.on_submit} 
						loading            = {this.state.ui_loading}
		    			submitting         = {this.state.submitting}
	    				className          = {this.state.error && 'AppStyles-hidden'}
	    				ref                = {this.form}
					>
						<RealAccountComponents.StepPageLayout
							step           = {5}
							header         = {''}
							body           = {
												this.state.loading ? <Loader.Ghost/> : 
												<React.Fragment>
													<div className={'RealAccountStyles-logo'}>
														<Image.VerticalLogo/>
													</div>
													{
														this.props.Step5.content &&
														this.props.Step5.content.map((file,index) => {
															var assign_ref=this.inputs['wysiwyg_'+index].ref; 
															return <Wysiwyg.Parse 
																key         = {index} 
																file        = {file.file_path}
																onLoad      = {(id) => {
																					this.wysiwyg_onload(
																						assign_ref,
																						id
																					)
																				}} 
																ref         = {assign_ref}
																onChange    =  {(target, event_data) => {
																					this.wysiwyg_onchange(
																						assign_ref,
																						target, 
																						event_data
																					)
																				}} 
																onInputLoad =  {(target, event_data) => {
																					this.wysiwyg_inputload(
																						assign_ref,
																						target, 
																						event_data
																					)
																				}}
																onTouched   =  {(target, event_data) => {
																					event_data = event_data || {};
																					if (event_data.setter!=='init') {
																						this.props.onTouched();
																					}
																				}}
															/>
													})}
												</React.Fragment>
											 }
							footer         = {
												<React.Fragment>
													<p> 
														{Language.get('realaccount.step5_footer_1','id')}<br/>
														{
															this.is_multilateral() ? 
																<b>
																	{Language.get('realaccount.step5_footer_pbk','id')}
																</b>
															:
																<b>
																	{Language.get('realaccount.step5_footer_asp','id')}
																</b>
														}
														<br/>
														{Language.get('realaccount.step5_footer_3','id')}
													</p>
												</React.Fragment>
											}
							AcceptStatement= {RealAccountComponents.AcceptStatement(
												this.inputs.accept_statement.ref,
												this.on_accept_statement
											)}
							acceptTermsRef = {this.inputs.accept_statement.ref}
							canSubmit      = {this.state.can_submit}
							timerLabel     = {Language.get('realaccount.receive_on','id')}
						/>
					</Form.Form>
					{
						this.state.error && 
						<MessageDisplay.OnPage 
							className = 'RealAccountStyles-error_message' 
							code      = {this.state.code} 
							type      = 'error' 
						/> 
					}
		    	</React.Fragment>;
	}


  	is_multilateral() {
  		return this.props.User.details.account_type.toLowerCase()==='multilateral';
  	}

	on_accept_statement = () => {
		var wysiwyg_values= this.inputs.wysiwyg_0.ref.current.value();
		var all_checked   = true;

		for (var i in wysiwyg_values) {
			if (Helpers.is_empty(wysiwyg_values[i])) {
				all_checked = false;
			}
		}

		this.setState({ can_submit:  parseInt(this.inputs.accept_statement.ref.current.value())===1 && all_checked });
	}

	on_submit = (inputs) => {
		this.setState({ submitting: 1 });
		if(this.checkStep8()){
			this.setState({ submitting: 0 });
			Model.on_progressName_updated()
			StoreMethods.step_enabled_change(8)
		}else{
			var component = this;
		
			Model.update_customer_conflictcourts(inputs.wysiwyg_0.custom_conflict_court, {
				precomplete: () => {
									component.setState({ submitting: 0 });
								},
				success    : () => { 

								Model.update_customer_conflictbranch(inputs.wysiwyg_0.custom_broker_branch, {
									precomplete: () => {
										component.setState({ submitting: 0 });
									},
									success    : () => { 

													
													Model.update_next_progressName({
														precomplete: () => {
															this.setState({ submitting: 0 });
														},
														fail    : this.form.current.submit_fail,
														error   : this.form.current.submit_error
													});

												},
									fail       : component.form.current.submit_fail,
									error      : component.form.current.submit_error
								});

							},
				fail       : component.form.current.submit_fail,
				error      : component.form.current.submit_error
			});
		}
	}

	wysiwyg_onload = (wysiwyg_ref, id) => {
		this.wysiwyg_loaded.push(id);
		this.check_ui_loading();
	}

	wysiwyg_onchange = (wysiwyg_ref, target, event_data) => {
		event_data = event_data || {};

		if (target && target.ref.current && event_data.setter!=='init' ) {
			this.wysiwyg_changed = true;

			var ref_list        = wysiwyg_ref.current.get_refs();
			var has_value       = !Helpers.is_empty(target.ref.current.value());
			var index           = ref_list.findIndex((curr_ref) => (curr_ref.id === target.id ));
			var ref_list_length = 0;

			if (has_value) {
				var next_index      = index + 1;
				    ref_list_length = ref_list.length;

				if (ref_list[next_index] && ref_list[next_index].ref.current) {
					ref_list[next_index].ref.current.set_focus();
				}

				for (let i = next_index; i < ref_list_length; i++) {
					var next = ref_list[i];

					if (next && next.ref.current) {
						next.ref.current.enable();

						if (Helpers.is_empty(next.ref.current.value())) {
							break;
						}
					}
				}

			} else {

				    ref_list_length = ref_list.length;
				var start           = index+1

				for (let i = start; i < ref_list_length; i++) {
					var curr_ref = ref_list[i];

					if (!Helpers.in_array(target.name,this.custom_components)) {
						curr_ref.ref.current.set_value([]);
					}

					curr_ref.ref.current.disable();
				}

			}

			this.on_accept_statement();

		}
	}

	wysiwyg_inputload = (wysiwyg_ref, target, event_data) => {


		var in_previous_progress =  Helpers.in_array(
										Model.get_step_name('step5'), 
										this.props.Step.content.previous_progress
									);

		if (!in_previous_progress || this.wysiwyg_changed) {

			Helpers.event_delay(() => {
				if (wysiwyg_ref.current) {

					var ref_list = wysiwyg_ref.current.get_refs();

					ref_list.forEach((curr_ref, index) => {
						if (index > 0 && curr_ref.ref.current) {
							var is_enabled_previous = ref_list[index-1].ref.current.is_enabled();
							var is_empty_previous   = Helpers.is_empty(ref_list[index-1].ref.current.value());
							if (!is_enabled_previous || is_empty_previous) {
								Helpers.is_function(curr_ref.ref.current.disable) && curr_ref.ref.current.disable();
							}
						}
					});
				}

			},500);

		} else {

			if (!Helpers.in_array(target.name,this.custom_components)) {
				target.ref.current && target.ref.current.set_value(["0"],'init');
			}

		}
	}

	check_ui_loading = () => {
		if (this.state.ui_loading===1) {
			if (this.wysiwyg_loaded.length >= this.wysiwyg_length) {
				this.setState({ ui_loading: 0 });
				if(this.checkStep8()){
					StoreMethods.step_enabled_change(8)
					this.disableAllandYa()
					this.changeDateSuccess()
					//console.log('step3Disclosure')
				}
			}
		}
	}

	check_loading = () => {

		if (this.state.loading===1) {

  			var has_step5 = !Helpers.is_empty(this.props.Step5);
  			var has_step  = !Helpers.is_empty(this.props.Step);
  			var has_user  = !Helpers.is_empty(this.props.User.details);

  			if (has_step5 && has_step && has_user) {
  				this.setState({ loading: 0 },() => {

			  		if (this.props.Step5.error.code !== '0') {

						this.setState({ error: true, code: this.props.Step5.error.code });

			  		} else {

			  			var wysiwyg_length = this.props.Step5.content.length;
			  			if (wysiwyg_length > 0) {
			  				this.wysiwyg_length = wysiwyg_length;
			  			}

			  		}

			  		this.check_ui_loading();
  				});
  			}

		}

	}

	//Added on 20220614---------
	checkStep8(){
		var done = false;
		var temp = StoreMethods.get_FormChange()
		var array = temp.StepReducers.content.previous_progress
		//console.log(temp,'Step4')
		for (let index = 0; index < array.length; index++) {
			if(array[index]==='Regol Successful'){
				done = true;
				break;
			}
			
		}

		return done
	}

	disableAllandYa(){
		StoreMethods.step_enabled_change(8)
		//disable date
		//document.getElementById("date").disabled = true;
		this.disableElement('FormStyles_option_box')
		//this.disableElement('FormStyles_upload')
		//this.disableElement('FormStyles_textbox')
		var temp = this.inputs
		for(var keys in temp){
			if(keys === 'accept_statement'){
				if(temp[keys].ref.current){
					temp[keys].ref.current.set_value(1)
					temp[keys].ref.current.disable()
				}
			}else{
				//console.log(keys,temp[keys])
				if(temp[keys].ref.current.disable){
					temp[keys].ref.current.disable()
				}
			}

		}
	}

	disableElement(classname){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i];
    		if (child.className.substring(0, classname.length) == classname){
				//console.log(child)
				child.disabled = true;
			}
  		}
		/*console.log(elements)
	
  		if(elements.length == 1){
			elements[0].disabled = true
		}else{
			for (var i = 0, length = elements.length; i < length; i++) {
				child = elements[i];
				if (child.className.substring(0, classname.length) == classname)
					  child[i].disabled = true;
			  }		
		}*/
		
	}

	changeDateSuccess(){
		var temp = StoreMethods.get_FormChange()
		var regSuc = temp.StepReducers.content.regol_sucessfull_date
		var regSucUtc = temp.StepReducers.content.regol_sucessfull_date_utc

		if(regSuc){
			this.changeTextDateSuccess('RealAccountComponentStyles-timer',regSuc)
			this.changeTextDateSuccess2('WysiwygStyles_container',regSucUtc)
		}
	}

	changeTextDateSuccess(classname,regSuc){
		//var add = add || false;
		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className === classname) {
					child.innerText = regSuc
					//console.log(child)
					
				}
			}
  		}
	}

	changeTextDateSuccess2(classname,regSuc){
		//var add = add || false;

		var children = document.body.getElementsByTagName('*');
  		var child;
  		for (var i = 0, length = children.length; i < length; i++) {
    		child = children[i] || {};
			if(child.className){
				if (child.className.indexOf(classname) > -1 ) {
					// var d = regSuc.split('-')
					// var dd = d[1] + '/' + d[0] + '/' + d[2] + " 12:00:00"
					// var ddf = Moment(new Date(dd)).tz("Asia/Jakarta").locale('id');
					// var tgl = ddf.format('DD')
					// var bulan = ddf.format('MMMM')
					// var tahun = ddf.format('YYYY')
					// var hari = ddf.format('dddd')
					const date = Moment(new Date(regSuc)).locale('id')
					child.children[2].children[0].children[1].innerText = date.format('dddd')
					child.children[2].children[0].children[3].innerText = date.format('DD')
					child.children[2].children[0].children[5].innerText = date.format('MMMM')
					child.children[2].children[0].children[7].innerText = date.format('YYYY')
				}
			}
  		}
	}
	//---------------------------
}

export default StoreMethods.connect_stores(Step5,['Step5','Step', 'User'],Model.get_step5);