import axios   from 'axios';
import Helpers from 'app/utils/helpers/Helpers'

function token_name() {
    return Helpers.is_empty(process.env.REACT_APP_TOKEN_NAME) ? 'Token' : process.env.REACT_APP_TOKEN_NAME;
}

function token_reponse_header() {

    if (Helpers.is_empty(process.env.REACT_APP_TOKEN_RESPONSE_HEADER)) {
        return 'Token';
    } else {
        return process.env.REACT_APP_TOKEN_RESPONSE_HEADER;
    }

}

function token_request_name() {
    if (Helpers.is_empty(process.env.REACT_APP_TOKEN_REQUEST_NAME)) {
        return 'token';
    } else {
        return process.env.REACT_APP_TOKEN_REQUEST_NAME;
    }
}


function authorization_name() {
    return Helpers.is_empty(process.env.REACT_APP_AUTHORIZATION_NAME) ? 
        'Authorization' : process.env.REACT_APP_AUTHORIZATION_NAME;
}

function authorization_response_header() {
    if (Helpers.is_empty(process.env.REACT_APP_AUTHORIZATION_RESPONSE_HEADER)) {
        return 'Authorization';
    } else {
        return process.env.REACT_APP_AUTHORIZATION_RESPONSE_HEADER;
    }
}

function reset_token() {
    if (parseInt(process.env.REACT_APP_MULTI_TOKEN) === 1) {
        return null;
    } else {
        var cookie = Helpers.get_cookie(token_name());
        if (Helpers.is_empty(cookie)) {
            return process.env.REACT_APP_INITIAL_TOKEN;
        } else {
            return cookie;
        }
    }
}

var globals = {
                debug        :  false,
                authorization:  {
                                    value : Helpers.get_cookie(authorization_name()),
                                    header: authorization_response_header(),
                                    need  : parseInt(process.env.REACT_APP_AUTHORIZATION_ENABLED)
                                },
                csrf         :  {
                                    ongoing      : null,
                                    multi_token  : parseInt(process.env.REACT_APP_MULTI_TOKEN),
                                    token        : reset_token(),
                                    max_retry   : 3,
                                    pending     : {},
                                    queue       : [],
                                    header      : token_reponse_header(),
                                    request_name: token_request_name()
                                },
                blocking     :  { 
                                    ongoing     : {},
                                    default_max : 1,
                                    pendings    : {}
                                },

                // all ps_model ajax callbacks that can be subscribed
                subscriptions: {
                                    precomplete : {},
                                    success     : {},
                                    error       : {},
                                    fail        : {},
                                    complete    : {},
                                    progress    : {},
                                    retry       : {},
                                    tokenchanged: {}
                                },
                retry_err_code: '-4',
                session_expire: '-3'
            };

var callables = {
    /**
     * Extend helper debug using local configuration
     */
    debug: Helpers.debug(globals.debug),

    /**
     * This will be the ajax core request function
     * @param  object ajax_setup       settings for axios
     * @param  object internal_setup   additional settings that is not directly for axios
     * @return void 
     */
    request(ajax_setup, internal_setup) {
        ajax_setup             = ajax_setup || {}
        internal_setup         = internal_setup || {}
        ajax_setup.request_key = internal_setup.request_key || Helpers.uniqid();

        var data_object = ajax_setup.data;

        if (!Helpers.is_empty(data_object) && !Helpers.is_object(data_object) && !data_object instanceof FormData) {
            throw new Error('ajax callables.request accepts object data option only');
        }

        // blocking
        if (ajax_setup.abort_success !== true && Helpers.is_object(internal_setup.block)) {
            if (callables.block_ongoing(internal_setup.block, ajax_setup, internal_setup)) {
                return false;
            }
        }

        // csrf queueing
        if (ajax_setup.abort_success !== true && callables.csrf_queue(ajax_setup, internal_setup)) {
            return false;
        }

        // first time ajax call, not yet retried
        if (ajax_setup.is_retry !== true) {
            
            ajax_setup.is_retry     = true;
            ajax_setup.dataFilter   = callables.ajax_dataFilter(ajax_setup.dataFilter, ajax_setup);
            ajax_setup.precomplete  = callables.ajax_precomplete(ajax_setup.precomplete, ajax_setup);
            ajax_setup.fail         = callables.ajax_fail(ajax_setup.fail, ajax_setup);
            ajax_setup.retry        = callables.ajax_retry(ajax_setup.retry, ajax_setup, internal_setup);
            ajax_setup.success      = callables.ajax_success(ajax_setup.success, ajax_setup);
            ajax_setup.error        = callables.ajax_error(ajax_setup.error, ajax_setup);
            ajax_setup.progress     = callables.ajax_progress(ajax_setup.progress, ajax_setup);
            ajax_setup.complete     = callables.ajax_complete(ajax_setup.complete, ajax_setup);
        }


        if (Helpers.is_function(ajax_setup.beforeSend) && !ajax_setup.beforeSend()) {
            return false;
        }

        // if callables.request should continue
        axios(callables.finalize_Settings(ajax_setup))

        .then(response => {

            var xhr               = response.request;
            var status            = response.status;
            var modified_response = ajax_setup.dataFilter(response.data);

            ajax_setup.success(modified_response, status, xhr);

        })

        .catch(error => {
            console.warn(error);

            var xhr               = null;
            var status            = null;
            var modified_response = null;

            if (error.response) {
                
                xhr               = error.response.request;
                status            = 'NET_'+error.response.status;
                modified_response = error.response.statusText;

            } else {

                xhr               = error.request;
                status            = 'NET_0';
                modified_response = 'Aborted';
                
            }

            ajax_setup.error(xhr, status ,modified_response);
        });
    },

    /**
     * Set ajax default configs
     * @param  object ajax_setup 
     * @return object
     */
    finalize_Settings(ajax_setup) {

        ajax_setup         = ajax_setup || {};
        var progress       = { upload:0, download:0 };

        if (!Helpers.is_empty(globals.authorization.value)) {
            ajax_setup.headers = ajax_setup.headers || {};

            ajax_setup.headers = {...ajax_setup.headers, ...{
                                    Authorization:'Bearer '+globals.authorization.value
                                }}

        }

        return {...ajax_setup, ...{
            url               : ajax_setup.url,
            method            : ajax_setup.method  || 'post',
            baseURL           : ajax_setup.baseURL || process.env.REACT_APP_API_HOST,
            params            : ajax_setup.params,
            data              : ajax_setup.data,
            withCredentials   : callables.ajax_withCredentials(ajax_setup), 
            responseType      : ajax_setup.responseType, 
            xsrfCookieName    : '', 
            xsrfHeaderName    : '',
            onUploadProgress  : callables.onprogress('upload', progress, ajax_setup),
            onDownloadProgress: callables.onprogress('download', progress, ajax_setup),
            headers           : ajax_setup.headers || null
        }};
    },

    /**
     * This will determine reuqest withCredetials param
     * @param  object ajax_setup 
     * @return boolean
     */
    ajax_withCredentials(ajax_setup) {
        if (ajax_setup.hasOwnProperty(ajax_setup.withCredentials)) {
            return ajax_setup.withCredentials;
        } else {
            if (process.env.hasOwnProperty('REACT_APP_AJAX_WITHCREDENTIALS')) {
                return parseInt(process.env.REACT_APP_AJAX_WITHCREDENTIALS) === 1;
            } else {
                return true;
            }
        }
    },

    /**
     * This will get the over all progress of ajax callables.request
     * @param  string type
     * @param  object progress
     * @param  object ajax_setup
     * @return function
     */
    onprogress(type, progress, ajax_setup) {

        return function(e) {

            if (e.lengthComputable && Helpers.is_function(ajax_setup.progress)) {
                progress[type] = Helpers.get_percentage(e.total,e.loaded);

                var overall_progress = Helpers.get_percent_average(progress);
                overall_progress     = (overall_progress>99) ? overall_progress-1 : overall_progress;

                // event
                ajax_setup.progress(overall_progress);
            }

        };
    },


    /**
     * This will block a callables.request if the same block.key ongoing callables.request is detected
     * If block_options.pending_events is true blocked callables.request callbacks will still be executed 
     * along with ongoing the callables.request
     * @param  object   block_options { max_request, pending_events }
     * @param  object   ajax_setup    
     * @param  object   internal_setup    
     * @return boolean
     */
    block_ongoing(block_options, ajax_setup, internal_setup) {

        block_options                         = block_options || {};
        var request_key                       = internal_setup.request_key;
        globals.blocking.ongoing[request_key] = globals.blocking.ongoing[request_key] || {};
        var block_id                          = ajax_setup.block_id || Helpers.uniqid();

        var max_request   = block_options.max_request || globals.blocking.default_max;
        var ongoing_count = Object.keys(globals.blocking.ongoing[request_key]).length;

        // We also check if block_id has been added before
        // Some callables.request might come back here because of retry feature and therefore shouldn't be blocked
        if (ongoing_count >= max_request && !globals.blocking.ongoing[request_key].hasOwnProperty(block_id)) {

            callables.debug(
                request_key +' has been blocked because there are still '+ongoing_count+' ongoing callables.requests.'
            );

            if (Helpers.is_function(block_options.fallback)) {
                block_options.fallback();
            }

            // if set as pending, we will add it to pending callback queue
            if (block_options.pending_events) {

                callables.debug(request_key +' has new pending callbacks.');
                globals.blocking.pendings[request_key] = globals.blocking.pendings[request_key] || [];
                globals.blocking.pendings[request_key].push(ajax_setup);

            }

            return true;

        } else {

            if (ajax_setup.has_block !== true) {

                ajax_setup.has_block = true;
                ajax_setup.block_id  = block_id;
                globals.blocking.ongoing[request_key][block_id] = block_id;

                // we use subscription callback events list as reference only on what events should be overriden
                var subscriptions_callbacks = Object.keys(globals.subscriptions);
                subscriptions_callbacks.forEach(function(cb_name) {
                    ajax_setup[cb_name] = (function(orig_callback, request_key, block_id, cb_name) {
                                                    return function() {
                                                        var args = arguments;

                                                        if (Helpers.is_function(orig_callback)) {
                                                            orig_callback.apply(this, args);
                                                        }

                                                        var pending_cbs = globals.blocking.pendings[request_key];
                                                        
                                                        if (!Helpers.is_empty(pending_cbs)) {
                                                            pending_cbs.forEach(function(pending_cb) {
                                                                if (Helpers.is_function(pending_cb[cb_name])) {
                                                                    var callback = pending_cb[cb_name];

                                                                    // avoid multiple execution
                                                                    delete pending_cb[cb_name];

                                                                    callback.apply(pending_cb, args);
                                                                }
                                                            });
                                                        }

                                                        // delete pendings after
                                                        if (cb_name === 'complete') {
                                                            if (!Helpers.is_empty(pending_cbs)) {
                                                                delete globals.blocking.pendings[request_key];
                                                            }

                                                            delete globals.blocking.ongoing[request_key][block_id];
                                                        }
                                                    };

                                            }(ajax_setup[cb_name], request_key, block_id, cb_name));

                });
            }

            return false;
        }

    },

    /**
     * This will check if there's no ongoing request with csrf token
     * If there is, this will pending the current request until tken is available again
     * @param  object  ajax_setup     
     * @param  object  internal_setup 
     * @return boolean
     */
    csrf_queue: function(ajax_setup, internal_setup) {

        if (!ajax_setup.is_csrf) {
            ajax_setup.is_csrf = (Helpers.is_object(ajax_setup.data) &&  ajax_setup.data.hasOwnProperty('token'));
        }

        if (ajax_setup.is_csrf) {

            ajax_setup.queue_id = ajax_setup.queue_id || Helpers.uniqid();
            if (globals.csrf.ongoing === ajax_setup.queue_id || globals.csrf.ongoing == null) {

                // there's no other pending tokenized request
                globals.csrf.ongoing = ajax_setup.queue_id;

                // let's put latest token to the request
                ajax_setup.data = ajax_setup.data || {};

                if (ajax_setup.data instanceof FormData) {
                    ajax_setup.data.append(globals.csrf.request_name, callables.get_token());
                } else {
                    ajax_setup.data[globals.csrf.request_name] = callables.get_token();
                }

                return false;

            } else {

                // pending the request until token is released
                globals.csrf.pending[ajax_setup.queue_id] = [ajax_setup,internal_setup];

                // we have to add queue array to maintain the request order
                globals.csrf.queue.push(ajax_setup.queue_id);

                callables.debug('A request is pending: ' + ajax_setup.url);

                return true;

            }

        } else {

            return false;

        }

    },

    /**
     * Wrapper of $.ajax dataFilter
     * @param  function orig_dataFilter 
     * @param  object   ajax_setup    
     * @return function
     */
    ajax_dataFilter: function(orig_dataFilter, ajax_setup) {
        return function(data) {

            if (Helpers.is_function(orig_dataFilter)) {
                data = orig_dataFilter.apply(this, arguments);
            }
            
            return data;

        };
    },

    /**
     * Wrapper of precomplete custom callback
     * @param  function orig_precomplete 
     * @return function
     */
    ajax_precomplete: function (orig_precomplete, ajax_setup) {

        return function(xhr, status, response) {
            var callback_args = [xhr, status, ajax_setup, response];

            // update request token
            callables.update_global_headers(xhr, response);

            if (Helpers.is_function(orig_precomplete)) {
                orig_precomplete.apply(this, callback_args);
            }

            callables.trigger_subscribed_events('precomplete', this, callback_args);

        };

    },

    /**
     * This will update all model data according to header informations set on backend
     * @param  object xhr 
     * @param  object response
     * @return void
     */
    update_global_headers: function(xhr, response) {

        if (xhr) {

            if (globals.authorization.need!==0) {
                callables.authorization_parser(xhr, response);
            }

            callables.token_parser(xhr, response);
        }
    },

    /**
     * This will be the default athorization parser
     * @param  object xhr      
     * @param  mixed  response 
     * @return void
     */
    authorization_parser(xhr, response) {
        var authorization = xhr.getResponseHeader(globals.authorization.header);
        callables.set_authorization(authorization);
    },

    /**
     * This will set authorization parser to custom
     * The custom authorization parser  function should return the authorization string
     * @param function custom_authorization_parser 
     */
    set_authorization_parser(custom_authorization_parser) {
        if (Helpers.is_function(custom_authorization_parser)) {
            callables.authorization_parser = (xhr, response) => {
                var authorization = custom_authorization_parser(xhr, response);
                callables.set_authorization(authorization);
            };
        }
    },

    /**
     * This will set authorization 
     * @param function authorization 
     */
    set_authorization(authorization) {
        if (!Helpers.is_empty(authorization)) {
            globals.authorization.value = authorization;
            Helpers.set_cookie(globals.authorization.header, authorization);
        }
    },

    /**
     * This will be the default token parser
     * @param  object xhr      
     * @param  mixed  response 
     * @return void
     */
    token_parser(xhr, response) {
        var token = xhr.getResponseHeader(globals.csrf.header);
        callables.set_token(token);
    },

    /**
     * This will set token parser to custom
     * The custom token parser  function should return the token string
     * @param function custom_token_parser 
     */
    set_token_parser(custom_token_parser) {
        if (Helpers.is_function(custom_token_parser)) {
            callables.token_parser = (xhr, response) => {
                var token = custom_token_parser(xhr, response);
                callables.set_token(token);
            };
        }
    },

    /**
     * Wrapper of ajax success
     * @param  function orig_success 
     * @param  object   ajax_setup    
     * @return function
     */
    ajax_success: function (orig_success, ajax_setup) {
        return function(response, status, xhr) {
            var callback_args = [response, status, xhr, ajax_setup];

            if (Helpers.is_function(ajax_setup.precomplete)) {
                ajax_setup.precomplete.call(this, xhr, status, response);
            }

            var false_positive = callables.detect_false_positive(response);

            if (false_positive) {

                if (Helpers.is_function(ajax_setup.fail)) {
                    ajax_setup.fail.apply(this, callback_args);
                } 

            } else {
                if (Helpers.is_function(orig_success)) {
                    orig_success.apply(this, callback_args);
                } 
                callables.trigger_subscribed_events('success', this, callback_args);

                if (Helpers.is_function(ajax_setup.progress)) {
                    ajax_setup.progress.call(this, 100);
                }

                if (Helpers.is_function(ajax_setup.complete)) {
                    ajax_setup.complete.call(this, xhr, status);
                }

            }
        };
    },

    /**
     * This will default detector of false positive
     * @param  object response 
     * @return boolean
     */
    detect_false_positive(response) {
        return (Helpers.is_object(response) && response.result === false);
    },

    /**
     * This will set custom false positive detector
     * The custom authorization parser function should return the boolean
     * @param  function custom_detector 
     * @return void
     */
    false_positive_detector(custom_detector) {
        if (Helpers.is_function(custom_detector)) {
            callables.detect_false_positive = custom_detector;
        }
    },

    /**
     * Wrapper of $.ajax error
     * @param  function orig_error 
     * @param  object   ajax_setup    
     * @return function
     */
    ajax_error: function (orig_error, ajax_setup) {

        return function(xhr, status, error) {
            var callback_args = [xhr, status, error, ajax_setup];

            if (Helpers.is_function(ajax_setup.precomplete)) {
                ajax_setup.precomplete.call(this, xhr, status);
            }

            if (Helpers.is_function(orig_error)) {
                orig_error.apply(this, callback_args);
            }
            callables.trigger_subscribed_events('error', this, callback_args);

            if (Helpers.is_function(ajax_setup.progress)) {
                ajax_setup.progress.call(this, -1);
            }

            if (Helpers.is_function(ajax_setup.complete)) {
                ajax_setup.complete.call(this, xhr, status);
            }
        };
    },

    /**
     * This will trigger subscribed events to ps ajax
     * @param  string   event_name 
     * @param  mixed    context       
     * @param  array    params       
     * @return void
     */
    trigger_subscribed_events: function(event_name, context, params) {
        if (Helpers.is_object(globals.subscriptions[event_name])) {

            var ctr = 0;
            for (var event_id in globals.subscriptions[event_name]) {

                (function(callback) {
                    setTimeout(function(){
                        callback.apply(context, params);
                    }, 0);
                }(globals.subscriptions[event_name][event_id]));

                ctr++;
            }

            if (ctr > 0) { 
                callables.debug(ctr + ' ' + event_name + ' subcribed callbacks has been executed!'); 
            }

        } else {
            callables.debug('Unknown callback subscription '+ event_name);
        }
    },

    /**
     * Wrapper of fail custom callback
     * @param  function orig_fail 
     * @param  object   ajax_setup    
     * @return function
     */
    ajax_fail: function(orig_fail, ajax_setup) {

        return function(response, status, xhr) {
            var callback_args = [response, status, xhr, ajax_setup];

            switch (response.err_code) {

                case globals.retry_err_code: 

                    // abort fail operation if still retrying
                    if (ajax_setup.retry.call(this)) {
                        return false;
                    }

                    break;


                case globals.session_expire: 

                    // Delete auth session token
                    Helpers.delete_cookie(globals.authorization.header);

                    break;

                default: 
                    break;
            }

            if (Helpers.is_function(orig_fail)) {
                orig_fail.apply(this, callback_args);
            } 
            callables.trigger_subscribed_events('fail', this, callback_args);

            if (Helpers.is_function(ajax_setup.progress)) {
                ajax_setup.progress.call(this, -1);
            }

            if (Helpers.is_function(ajax_setup.complete)) {
                ajax_setup.complete.call(this, xhr, status);
            }

        };

    },

    /**
     * Wrapper for retry call
     * @param  function orig_retry      original retry callback 
     * @param  object   ajax_setup      our ajax_setup object
     * @param  object   internal_setup  our internal_setup object
     * @return function
     */
    ajax_retry: function(orig_retry, ajax_setup, internal_setup) {

        return function() {
            var callback_args = [ajax_setup];

            if (Helpers.is_empty(ajax_setup.retry_flag)) {

                ajax_setup.retry_flag = 1;

            } else {

                ajax_setup.retry_flag++;

            }

            if (ajax_setup.retry_flag < globals.csrf.max_retry) {

                if (Helpers.is_function(orig_retry)) {
                    orig_retry.apply(this, callback_args);
                }

                callables.trigger_subscribed_events('retry', this, callback_args);
                callables.request(ajax_setup, internal_setup);

                return true;

            } else {

                return false;

            }

        };

    },

    /**
     * Wrapper of progress custom callback
     * @param  function orig_progress 
     * @param  object   ajax_setup 
     * @return function
     */
    ajax_progress: function (orig_progress, ajax_setup) {

        return function(percent) {
            var callback_args = [percent, ajax_setup];

            if (Helpers.is_function(orig_progress)) {
                orig_progress.apply(this, callback_args);
            }
            callables.trigger_subscribed_events('progress', this, callback_args);

        };

    },

    /**
     * Wrapper of $.ajax complete
     * @param  function orig_complete 
     * @param  object   ajax_setup    
     * @return function
     */
    ajax_complete: function(orig_complete, ajax_setup) {

        return function(jqXHR, status) {
            var callback_args = [jqXHR, status, ajax_setup];

            if (Helpers.is_function(orig_complete)) {
                orig_complete.apply(this, callback_args);
            }
            callables.trigger_subscribed_events('complete', this, callback_args);

            if (ajax_setup.is_csrf) {
                callables.release_token(ajax_setup);
            }

        };

    },

    /** 
     * This will release ongoing token and trigger the next ajax that was pending becuase of token
     * @return void
     */
    release_token: function(ajax_setup) {
        if (!Helpers.is_empty(globals.csrf.pending)) {

            globals.csrf.ongoing = globals.csrf.queue[0];
            var next_request     = globals.csrf.pending[globals.csrf.ongoing];

            if (!Helpers.is_empty(globals.csrf.pending[globals.csrf.ongoing])) {
                delete globals.csrf.pending[globals.csrf.ongoing];
            }

            if (Helpers.in_array(globals.csrf.ongoing, globals.csrf.queue)) {
                globals.csrf.queue.splice(globals.csrf.queue.indexOf(globals.csrf.ongoing), 1);
            }

            if (!Helpers.is_empty(next_request)) {
                callables.request.apply(this, next_request);
            } 

        }  else {

            globals.csrf.ongoing = null;

        }
    },

    /**
     * This will add abort + success callback in beforeSend of ajax_setup
     * @param  object     ajax_setup   
     * @param  function   success_data 
     * @param  function   callback   
     * @return void
     */
    abort_success: function(ajax_setup, success_data, callback) {

        // prevent double binding
        if (ajax_setup.abort_success !== true) {

            ajax_setup.abort_success = true;

            ajax_setup.beforeSend = (function(orig_beforeSend, ajax_setup, success_info) {

                                        return function() {

                                            if (Helpers.is_function(orig_beforeSend)) {
                                                orig_beforeSend.apply(this, arguments);
                                            }

                                            ajax_setup.success.call(this, success_info.data, 200);

                                            if (Helpers.is_function(success_info.callback)) {
                                                success_info.callback.apply(this, ajax_setup);
                                            }

                                            return false;
                                        };

                                    }(ajax_setup.beforeSend, ajax_setup, { data:success_data, callback:callback }));


        } else {

            // Disallowed, to prevent triggering success callback multiple times
            callables.debug('Multiple abort success has been detected, only the first one will be executed!');

        }

    },

    /**
     * This will get current authorization value
     * @return string
     */
    get_authorization: function() {
        return globals.authorization.value;
    },

    /**
     * This will set token to given string
     * @param string token 
     * return void
     */
    set_token(token) {

        if (!Helpers.is_empty(token)) {
            globals.csrf.token = token;

            if (globals.csrf.multi_token===0) {
                Helpers.set_cookie(token_name(), token);
            }

            callables.trigger_subscribed_events('tokenchanged', this, [token]);
        }

    },

    /** 
     * This will get the latest token to use
     * return void
    */
    get_token() {
        if (globals.csrf.multi_token===0) {
            return Helpers.get_cookie(token_name());
        } else {
            return globals.csrf.token;
        }
    },

    /**
     * This will set local token to given string
     * @param string token 
     * return voi
     */
    update_local_token(token) {
        if (globals.csrf.multi_token===0) {
            globals.csrf.token = Helpers.get_cookie(token_name());
        }
    },

    reset_token() {
        callables.set_token(reset_token());
    },  

    /**
     * This will add event subcription
     * @param  string   event_name   
     * @param  function event_function
     * @param  string   event_id
     * @return void
     */
    subscribe: function(event_name, event_function, event_id) {
        if (globals.subscriptions.hasOwnProperty(event_name)) {

            if (Helpers.is_function(event_function)) {

                if (Helpers.is_empty(event_id)) {
                    event_id = Helpers.uniqid();
                }

                if (Helpers.is_function(globals.subscriptions[event_name][event_id])) {
                    callables.debug(event_name + ' event subscription with id '+event_id+ ' will be overwritten!');
                }

                globals.subscriptions[event_name][event_id] = event_function;

            } else {

                throw new Error('Model.subscribe 2nd argument must be a function');

            }

        } else {

            callables.debug('Unknown callback subscription '+ event_name);

        }
    }
};

export default callables;