import React,{Component,useEffect}			 from 'react';
import classnames                            from 'classnames';
import Loader                                from 'app/components/loader/Loader';
import Model                                 from 'app/modules/model/Model';
import WysiwygStyles                         from './scss/WysiwygStyles.module.scss';
import Image                                 from 'app/components/image/Image';
import Table                                 from 'app/components/table/Table';
import Helpers                               from 'app/utils/helpers/Helpers'; 
import Form                                  from 'app/components/form/Form';
import MessageDisplay                        from 'app/components/messagedisplay/MessageDisplay';
import { callables as StoreMethods }         from 'app/store/Store';
import Language                              from 'app/utils/language/Language';

function style_string_props(style) {
	var split_semicolon = style.split(';');
	var style_props     = {};

	split_semicolon.forEach((style_item) => {
		var split_colon = style_item.split(':');

		if (split_colon.length > 1) {
			style_props[Helpers.camelCase(split_colon[0].trim())] = split_colon[1];
		}

	});

	return style_props;
}

function attr_to_props(attr) {
	var props = {};
	attr      = attr || {};

	for (var attr_name in attr) {
		var attr_value = attr[attr_name];
		switch (attr_name) {
			case 'class'  : props.className = attr_value; break;
			case 'style'  : props.style     = style_string_props(attr_value); break;
			case 'colspan': props.colSpan   = attr_value; break;
			default       : props[attr_name]= attr_value; break;
		}
	}

	return props;
}

function attach_tag(inline, props) {
	var final_props = attr_to_props(inline.attr);
	return  <inline.tag 
				{...final_props} 
				className={classnames([
							WysiwygStyles['inline_tag_'+inline.tag],
							final_props.className
						])}
			>
				{inline.nodes && parse_inline(inline.nodes, props)}
			</inline.tag>;
}

function attach_com(com, props) {

	switch(com.type) {
		case 'placeholder': 
			var segments = com.target.split('.');
			if (props && props.placeholder && props.placeholder[segments[0]]) {

				if (com.display === 'link') {
					return  <a href={props.placeholder[segments[0]][segments[1]]}>
								{props.placeholder[segments[0]][segments[1]]}
							</a>;
				} else {
					return props.placeholder[segments[0]][segments[1]];
				}

			} else {
				return null;
			}
		// no default
	}
}

function parse_inline(inline_array, props) {
	return  <React.Fragment>
			{ 
				Helpers.isArray(inline_array) && inline_array.map((inline, index) => {
					return  <React.Fragment key={index}>
								{ inline.tag && attach_tag(inline, props) }
								{ inline.txt && <span dangerouslySetInnerHTML={{__html:inline.txt}} /> }
								{ inline.com && attach_com(inline.com, props) }
							</React.Fragment>
				}) 
			}
			</React.Fragment>;
}

var Types = {
	content  :  React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return props.block.data.text[0].tag === "div" ? 
			<div>{parse_inline(props.block.data.text, props)}</div>
			: <p>{parse_inline(props.block.data.text, props)}</p>;
	}),

	paragraph: React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <p>{parse_inline(props.block.data.text, props)}</p>;
	}),

	image: React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <React.Fragment>
					<Image.Load 
						className = {WysiwygStyles.image}
						alt       = {props.block.data.caption} 
						src       = {props.block.data.image.url}
						style     = {{width:props.block.data.width}}
					/>
					<span dangerouslySetInnerHTML={{__html:props.block.data.caption}} />
				</React.Fragment>;
	}),

	delimiter: React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <br/>;
	}),

	header: React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		switch(props.block.data.size) {
			case 'large' : return <h1 className={WysiwygStyles.header_large}>
									{parse_inline(props.block.data.text, props)}
								</h1>;
			case 'medium': return <h2 className={WysiwygStyles.header_medium}>
									{parse_inline(props.block.data.text, props)}
								</h2>;
			default      : return <h3 className={WysiwygStyles.header_default}>
									{parse_inline(props.block.data.text, props)}
								</h3>;
		}
	}),

	list: React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		let list_items = [];

		props.block.data.items.forEach((item, index) => {
			list_items.push(
				<li dangerouslySetInnerHTML={{__html:item}} key={index}></li>
			);
		});

		switch(props.block.data.style) {
			case 'ordered' : return <ol>{list_items}</ol>;
			default		   : return <ul>{list_items}</ul>;
		}
	}),

	check_list:  React.forwardRef((props,ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props, ref, id); 

  			return () => {
  				block_unload(props, id);
  			};
  		});

		return  <Form.Checkbox 
					options              = {props.block.data.items.map((item)=>(parse_inline(item, props)))} 
				 	optionsFormatted     = {false}
					ref                  = {ref}
					onChange             = {(event_data) => props.onChange(id, event_data)}
					onTouched            = {(event_data) => props.onTouched(id, event_data)}
					className            = {WysiwygStyles.checkbox}
				/>;

	}),

	ordered_list: React.forwardRef((props, ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <ol 
					type     ={props.block.data.type} 
					start    ={props.block.data.start} 
					className={WysiwygStyles.ol}
				>
					{props.block.data.items.map((item, index)=> (
						<li key={index}>{parse_inline(item, props)}</li>
					))}
				</ol>;
	}),

	unordered_list: React.forwardRef((props, ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <ul type={props.block.data.type} className={WysiwygStyles.ul}>
					{props.block.data.items.map((item, index)=> (
						<li key={index}>{parse_inline(item, props)}</li>
					))}
				</ul>;
	}),

	table: React.forwardRef((props, ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);

  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <Table 
					striped  = {props.block.data.striped}
					bordered = {props.block.data.bordered}
				>
					{parse_inline(props.block.data.table, props)}
				</Table>;
	}),

	custom_company_bank_account: StoreMethods.connect_stores(React.forwardRef((props, ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 

  			var has_company = !Helpers.is_empty(props.Company);

  			if (has_company) {

  				if (props.Company.error.code !== '0') {
					props.onError(props.Company.error.code);
				} else {
					block_onload(props, ref, id);
				}

  			}

  		}, [props.Company]);

  		useEffect(() => { 

  			return () => {
  				block_unload(props, id);
  			};
  		});

  		const bank_grouped = () => {
			var grouped = [];

			if (!Helpers.is_empty(props.Company)) {

				props.Company.content.bank_accounts.forEach((bank) => {

					var index = grouped.findIndex((grouped_bank) => (grouped_bank.bank_name === bank.bank_name));

					if (index <= -1) {
						grouped.push({
							bank_name    : Language.get('realaccount.bank','id') +' '+ bank.bank_name,
							bank_accounts: [bank],
							bank_branch  : bank.bank_branch
						});
					} else {
						grouped[index].bank_accounts.push(bank);
					}

				});

			}

			return grouped;
		}

		return  <div className={WysiwygStyles.company_bank_account} ref={ref}>
					{
						bank_grouped().map((bank, bank_index) => (
							<div key={bank_index+'bank'}>
								<div className={WysiwygStyles.company_bank_name}>
									{bank.bank_name} {Language.get('form_labels.branch','id')} {bank.bank_branch}
								</div>
								{  
									bank.bank_accounts.map((account,account_index) => (
										<div key={bank_index+account_index+'account'}>
											<div className={WysiwygStyles.company_bank_currency}>
												{account.currency}
											</div>
											<div className={WysiwygStyles.company_bank_number}>
												{account.account_number}
											</div>
										</div>
									))
								}
							</div>
						))
					}
				</div>;

	}),['Company'], Model.get_company_details),

	NotFound: React.forwardRef((props, ref) => {
		var id = Helpers.uniqid();

  		useEffect(() => { 
  			block_onload(props,ref,id);
  			return () => {
  				block_unload(props, id);
  			};
  		}, []);

		return  <div>WYSIWYG nothing to render for type {props.block.type}</div>;
	}),

	custom_conflict_court: React.forwardRef((props, ref) => {		
		var id = 'custom_conflict_court';

  		useEffect(() => { 
  			block_onload(props,ref,id);
  			return () => {
  				block_unload(props, id);
  			};

  		});

  		const get_selected = () => {
  			var selected = props.customComponent.custom_conflict_court.find((court) => (court.is_selected));
  			return Helpers.is_empty(selected) ? null:selected.court_id;
  		}

		return  <div className={WysiwygStyles.custom_conflict_court}>
					<Form.Radio
						options          =  {props.customComponent.custom_conflict_court}
						optionsFormatted =  {false}
						formatter        =  {(item, index) => {
												/*static*/
												var add_desc = index === 0? ' berdasarkan Peraturan dan Prosedur Badan '
															+'Arbitrase Perdagangan Berjangka Komoditi (BAKTI); atau'
															: '';

												return {
													value      : item.court_id,
													description: item.court_name + add_desc
												}
											}}
						defaultValue     = {get_selected()}
						ref              = {ref}
						onChange         = {(event_data) => props.onChange(id, event_data)}
						onTouched        = {(event_data) => props.onTouched(id, event_data)}
						className        = {WysiwygStyles.radio}
					/>
				</div>;
	}),

	custom_broker_branch: React.forwardRef((props, ref) => {
		var id             = 'custom_broker_branch';
		let final_branches = [];

  		useEffect(() => { 
  			block_onload(props,ref,id);
  			return () => {
  				block_unload(props, id);
  			};

  		});  		
		
		// Format branches
		props.customComponent.custom_broker_branch.forEach(branch => {			

			let city        = Helpers.is_empty(branch.city) ? '' : branch.city + ' ';
			let city_branch = city + branch.branch_name;
			let props = { city_branch, ...branch };

			final_branches.push(props);

		});
		
		// Sort Branches
		Helpers.sort_branches(final_branches, {
													hq_flag     : 'is_hq',
													branch_order: 'order',
													branch_name : 'city_branch',
												});

		const get_selected = () => {
			var selected = props.customComponent.custom_broker_branch.find((branch) => (branch.is_selected));
			return Helpers.is_empty(selected) ? null : selected.branch_id;
		}

		return  <div className={WysiwygStyles.custom_broker_branch}>
					<Form.Radio
						options          =  {final_branches}
						optionsFormatted =  {false}
						formatter        =  {(item) => {
													let prefix = item.is_hq ? 
																Language.get('form_labels.head_office','id')
																: Language.get('form_labels.branch_office','id');													

													return {
														value      : item.branch_id,
														description: prefix 
																	+' '
																	+item.city_branch
													}
											}}
						defaultValue     = {get_selected()}
						ref              = {ref}
						onChange         = {(event_data) => props.onChange(id, event_data)}
						onTouched        = {(event_data) => props.onTouched(id, event_data)}
						className        = {WysiwygStyles.radio}
						displayFormat    = 'table-right'
						header           = {[
												Language.get('form_labels.office_list','id'),
												Language.get('form_labels.choosed_office','id')
											]}
					/>
				</div>;
	}),

	custom_page_break: React.forwardRef((props, ref) => {
		let id = Helpers.uniqid();

		useEffect(() => {
			block_onload(props, ref, id);

			return () => {
				block_unload(props, id);
			}
		});

		return null;
	}),

	custom_step_5_section_16: StoreMethods.connect_stores(React.forwardRef((props, ref) => {

		let company = props.Company.content || {};
		let id      = "custom_step_5_section_16";
		
		useEffect(() => {
			var has_company = !Helpers.is_empty(props.Company);

			if (has_company) {
				if (props.Company.error.code !== '0') {
					props.onError(props.Company.error.code);
				} else {
					block_onload(props, ref, id);
				}
			}
		}, [props.Company]);

		useEffect(() => {
			return () => {
				block_unload(props, id);
			};
		});

		const bank_grouped = () => {
			var grouped      = [];
			let sorted_banks = [];

			if (!Helpers.is_empty(props.Company)) {

				sorted_banks = [...props.Company.content.bank_accounts];
				Helpers.sort_array_object_by_key(sorted_banks, 'currency');

				sorted_banks.forEach((bank) => {
					var index = grouped.findIndex((grouped_bank) => (grouped_bank.bank_name === bank.bank_name));

					if (index <= -1) {
						grouped.push({
							bank_name    : bank.bank_name,
							bank_accounts: [bank],
							bank_branch  : bank.bank_branch
						});
					} else {
						grouped[index].bank_accounts.push(bank);
					}
				});
			}

			return grouped;
		}

		return  <div className={classnames([
									WysiwygStyles.custom_step_5_section_16,
									WysiwygStyles.indention_2
								])}
				>
					<ol>
						<li>
							Semua komunikasi, uang, surat berharga, dan kekayaan lainnya harus dikirimkan langsung ke
							alamat Nasabah seperti tercantum dalam rekeningnya atau alamat lain yang
							ditetapkan/diberitahukan secara tertulis oleh Nasabah.
						</li>
						<li>
							Semua uang harus disetor atau ditransfer langsung oleh Nasabah ke Rekening 
							Terpisah <i>(Segregated Account)</i> Pialang Berjangka:
							
							<div className={WysiwygStyles.custom_step_5_section_16_row}>
								<div>Nama</div>
								<div>:</div>
								<div>{company.company_name}</div>
							</div>
							<div className={WysiwygStyles.custom_step_5_section_16_row}>
								<div>Alamat</div>
								<div>:</div>
								<div>{company.address}, {company.city}, {company.postal_code}</div>
							</div>
							<div className={WysiwygStyles.custom_step_5_section_16_row}>
								<div>No. Rekening Terpisah</div>
								<div>:</div>
								<div>
									{
										bank_grouped().map((bank, bank_index) => (
											<div 
												className={WysiwygStyles.banks}
												key={bank_index + 'bank'}
											>
												<div>
													{Language.get('realaccount.bank', 'id')}
													{' ' + bank.bank_name} 
													{' ' + Language.get('form_labels.branch', 'id')}
													{' ' + bank.bank_branch}
												</div>
												{
													bank.bank_accounts.map((account, account_index) => (
														<div key={bank_index + account_index + 'account'}>
															<div className={WysiwygStyles.bank_accounts}>
																<div>{account.currency}</div>
																<div>:</div>
																<div>{account.account_number}</div>
															</div>
														</div>
													))
												}
											</div>
										))
									}	
								</div>
							</div>							

							dan dianggap sudah diterima oleh Pialang Berjangka apabila sudah ada tanda terima bukti 
							setor atau transfer dari pegawai Pialang Berjangka.
						</li>
						<li>
							Semua surat berharga, kekayaan lainnya, atau komunikasi harus dikirim kepada Pialang 
							Berjangka:
								
								<div className={WysiwygStyles.custom_step_5_section_16_row}>
									<div>Nama</div>
									<div>:</div>
									<div>{company.company_name}</div>
								</div>
								<div className={WysiwygStyles.custom_step_5_section_16_row}>
									<div>Alamat</div>
									<div>:</div>
									<div>{company.address}, {company.city}, {company.postal_code}</div>
								</div>
								<div className={WysiwygStyles.custom_step_5_section_16_row}>
									<div>Telepon</div>
									<div>:</div>
									<div>
										<span>{company.phone_area_code}-{company.phone}</span>
										,&nbsp;
										<span>Facsimile : {company.fax_area_code}-{company.fax}</span>
									</div>
								</div>
								<div className={WysiwygStyles.custom_step_5_section_16_row}>
									<div>Email</div>
									<div>:</div>
									<div>{company.email_address}</div>
								</div>

							dan dianggap sudah diterima oleh Pialang Berjangka apabila sudah ada tanda bukti penerimaan 
							dari pegawai Pialang Berjangka.
						</li>
					</ol>
				</div>
	}), ['Company'], Model.get_company_details),
	
};

function block_onload(props, ref, id) {
	if (Helpers.is_function(props.onLoad)) {
		props.onLoad(id, ref, get_ref_name(id, props));
	}
}

function block_unload(props, id) {
	if (Helpers.is_function(props.unLoad)) {
		props.unLoad(id);
	}
}

function get_ref_name(id, props) {
	return props.block.data.name || id;
}

class Parse extends Component {

	constructor(props) {
		super(props);
		this.state            = {
									loading         : 1, 
									content         : [],
									error           : false, 
									code            : null,
									placeholder     : {},
									custom_block    : {}
								};
		this.init             = false;
		this.blocks_length    = 0;
		this.blocks_loaded    = [];
		this.ref_list         = [];
		this.placeholder      = {};
		this.custom_block 	  = {};
		this.unmounted 		  = false;
	}

  	componentDidMount = () => {
  		Model.request_file(this.props.file, {
  			success:(response) => {
  				this.blocks_length = response.blocks.length;
  				var data_to_load   = ['placeholder','custom_block'];
  				var data_loaded    = [];
  				var onload_data    = (type) => {
  										if (!Helpers.in_array(type, data_loaded)) {
  											data_loaded.push(type);
  										}

  										if (data_loaded.length >= data_to_load.length) {
											!this.unmounted && this.setState({ content: response.blocks }, () => {
							  					this.setState({ loading:0 }, () => {
							  						this.check_loading();
							  					});
							  				});
										  }
  									}

  				if (Helpers.is_empty(response.placeholder)) {
  					onload_data('placeholder')
  				} else {
  					this.get_placeholders(response.placeholder, ()=>{ onload_data('placeholder') });
  				}


  				if (Helpers.is_empty(response.custom_block)) {
  					onload_data('custom_block');
  				} else {
  					this.get_custom_blocks(response.custom_block, ()=>{ onload_data('custom_block') });
  				}
  			},
  			fail : (response) => {
  				this.onerror(response.error.code);
  			},
  			error: (xhr, status) => {
  				this.onerror(status);
  			}
  		});

  	}

	componentWillUnmount = () => {
		this.unmounted = true;
	}

  	/**
  	 * This will get placeholders from API and execute call back after
  	 * @param  array      placeholders
  	 * @param  function   callback   
  	 * @return void
  	 */
  	get_placeholders = (placeholders, callback) => {
  		var grouped_placeholders = this.grouped_placeholders(placeholders);
  		var placeholder_length   = Object.keys(grouped_placeholders).length;
  		var done_placeholders    = [];
  		var component            = this;

  		function placeholder_callback(placeholder) {
			done_placeholders.push(placeholder);

			if (done_placeholders.length >= placeholder_length) {
				component.setState({ 
					placeholder: component.placeholder
				},callback);
			}
		}

  		for (var placeholder in grouped_placeholders) {
  			var keys = grouped_placeholders[placeholder];

  			(function(placeholder, keys) {

	  			Model['get_'+placeholder+'_view'](keys, {
	  				success:(response) => {
	  							var placeholder_result          = {};
	  							placeholder_result[placeholder] = response.data;
	  							component.placeholder = {...component.placeholder,...placeholder_result};
	  							placeholder_callback(placeholder); 
	  						},
	  				fail    :(response) => {
	  							component.placeholder[placeholder] = {};
	  							placeholder_callback(placeholder);
  								component.onerror(response.error.code);
	  						},
	  				error   :(xhr, status) => {
	  							component.placeholder[placeholder] = {};
	  							placeholder_callback(placeholder);
  								component.onerror(status);
	  						}
	  			});

  			})(placeholder, keys);
  		}

  	}

  	get_custom_blocks = (custom_block, callback) => {
  		var done_custom_block = [];
  		var component             = this; 

  		var custom_block_callback = (type) => {

			done_custom_block.push(type);

			if (done_custom_block.length >= custom_block.length) {
				component.setState({ 
					custom_block: component.custom_block
				},callback);
			}

  		}

  		custom_block.forEach((item) => {
  			(function(item) {

  				switch(item) {
  					case 'custom_conflict_court': 

			  			Model.get_customer_conflictcourts(['ConflictCourt'],{
			  				success:(response) => {
			  							component.custom_block[item] = response.data.courts;
			  							custom_block_callback(item); 
			  						},
			  				fail    :(response) => {
			  							component.custom_block[item] = [];
			  							custom_block_callback(item);
		  								component.onerror(response.error.code);
			  						},
			  				error   :(xhr, status) => {
			  							component.custom_block[item] = [];
			  							custom_block_callback(item);
		  								component.onerror(status);
			  						}
			  			}, true);

  						break;

  					case 'custom_broker_branch': 

			  			Model.get_customer_conflictbranch({
			  				success:(response) => {
			  							component.custom_block[item] = response.data.branch;
			  							custom_block_callback(item); 
			  						},
			  				fail    :(response) => {
			  							component.custom_block[item] = [];
			  							custom_block_callback(item);
		  								component.onerror(response.error.code);
			  						},
			  				error   :(xhr, status) => {
			  							component.custom_block[item] = [];
			  							custom_block_callback(item);
		  								component.onerror(status);
			  						}
			  			});

  						break;

  					default: custom_block_callback(item); 
  				}

  			})(item);
  		});

  	}

  	onerror = (code) => {
  		this.setState({ loading: 0, error: true, code: code});
  	}

  	/**
  	 * This will format placeholders in group
  	 * @param  array placeholders 
  	 * @return object
  	 */
  	grouped_placeholders = (placeholders) => {
  		var grouped = {};

  		placeholders.forEach((item) => {
  			var segments = item.split('.');

  			if (!grouped.hasOwnProperty(segments[0])) {
  				grouped[segments[0]] = [];
  			}

  			if (!Helpers.is_empty(segments[1])) {
  				grouped[segments[0]].push(segments[1]);
  			}

  		});

  		return grouped;
  	}

  	onload = (id, ref, name) => {
  		if (!Helpers.is_empty(ref)) {
  			var target = { id, name, ref };
  			this.ref_list.push(target);

  			if (Helpers.is_function(this.props.onInputLoad)) {
  				this.props.onInputLoad(target);
  			}
  		}

  		this.blocks_loaded.push(id);
  		this.check_loading();
  	}

  	unload = (id) => {
  		this.ref_list      = this.ref_list.filter((value) => (value.id !== id));
  		this.blocks_loaded = this.blocks_loaded.filter((value) => (value !== id));
  		this.check_loading();
  	}

  	check_loading = () => {
  		var is_loaded = this.state.loading === 0;
  		if (is_loaded && Helpers.is_function(this.props.onLoad) && this.blocks_loaded.length === this.blocks_length) {
  			this.props.onLoad(Helpers.uniqid());
  		}
  	}

  	changed = (id, event_data) => {

  		if (Helpers.is_function(this.props.onChange)) {
  			this.props.onChange(this.ref_list.find((value) => (value.id === id)), event_data);
  		}

  	}

  	touched = (id, event_data) => {

  		if (Helpers.is_function(this.props.onTouched)) {
  			this.props.onTouched(this.ref_list.find((value) => (value.id === id)), event_data);
  		}
  		
  	}

  	value = () => {
  		var values = {};
  		this.ref_list.forEach((curr_ref) => {
  			if (curr_ref.ref.current) {
  				values[curr_ref.name] = curr_ref.ref.current.value();
  			};
  		});

  		return values;
  	}

  	get_refs = () => {
  		var ref_list = [];
  		this.ref_list.forEach((curr_ref) => {
  			if (curr_ref.ref.current) {
  				ref_list.push(curr_ref);
  			};
  		});

  		return ref_list;
  	}

	render() {
		return 	<React.Fragment>
					<div 
						className={
									classnames([
										WysiwygStyles.container, 
										this.state.error && 'AppStyles-hidden',
										this.props.className
									])}>
	 					{ 
	 						this.state.content.map((block, index) => {
	 							var BlockType = Types[block.type];
	 							var stretched = block.data.stretched ? '1' : '0';
	 							var border    = block.data.border    ? '1' : '0';
								var fixed     = block.data.fixed     ? '1' : 
												Helpers.is_empty(block.data.fixed) ? '1' : '0'; 

	 							return  <div 
	 										className = {classnames([
	 														WysiwygStyles.block,
	 														WysiwygStyles['indention_'+block.data.indention],
	 														WysiwygStyles['stretched_'+stretched],
	 														WysiwygStyles['align_'+block.data.align],
	 														WysiwygStyles['border_'+border],
															WysiwygStyles['fixed_'+fixed],
	 														this.state.loading && 'AppStyles-hidden'
	 													])} 
	 										key       = {index}
	 									>
		 									{
		 										Types.hasOwnProperty(block.type) ? 
				 								<BlockType 
				 									block           = {block} 
				 									key             = {index}
				 									onLoad          = {this.onload}
				 									unLoad          = {this.unload}
				 									onError         = {this.onerror}
				 									placeholder     = {this.state.placeholder}
				 									customComponent = {this.state.custom_block}
				 									onChange        = {this.changed}
				 									onTouched       = {this.touched}
				 									ref             = {React.createRef()}
				 								/> :

				 								<Types.NotFound
				 									block  = {block} 
				 									onLoad = {this.onload}
				 									unLoad = {this.unload}
				 									onError= {this.onerror}
				 									ref    = {React.createRef()}
				 								/>	
				 							}
			 							</div>	
	 						})
	 					}
	 					{
	 						this.state.loading ? <Loader.Ghost blocks={this.props.loaderBlocks}/> : null
	 					}
					</div>
                	{
                		this.state.error 
                		&& <MessageDisplay.OnPage className={WysiwygStyles.error} code={this.state.code} type='error' /> 
                	}
				</React.Fragment>;
	}

}

export default { Parse };
